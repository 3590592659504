import React, { useContext } from 'react';

import './style/contract.scss';
import { Checkbox, Col, Form } from 'antd';
import { CheckoutContext } from '../shared/CheckoutProvider';

import config from '../../../config';

const Contract = ({ columns = 24 }) => {
  const { values, setContract, setContractPrivacity, course } = useContext(CheckoutContext);

  return (
    <>
    <Col className="gutter-row box-contract payment" xs={24} md={columns}>
      <Form.Item className="input-form from-terms" name="contract" valuePropName="contract">
        <Checkbox
          checked={values.contract}
          className="input-contract"
          value={values.contract}
          onChange={setContract}
        >
          Li e aceito os &nbsp;
          <a
            href={config.s3Url + course.contrato}
            className="btn-accept-the-contract"
            target="_blank"
            rel="noopener noreferrer"
          >
            termos de serviço
          </a>
        </Checkbox>
      </Form.Item>
    </Col>
    <Col className="gutter-row box-contract" xs={24} md={columns}>
      <Form.Item className="input-form" name="contract" valuePropName="contract">
        <Checkbox
          checked={values.contractPrivacity}
          className="input-contract"
          value={values.contractPrivacity}
          onChange={setContractPrivacity}
        >
          Li e aceito os &nbsp;
          <a
            href='https://www.academiarafaeltoro.com.br/politica-de-privacidade/'
            className="btn-accept-the-contract"
            target="_blank"
            rel="noopener noreferrer"
          >
            política de privacidade
          </a>
        </Checkbox>
      </Form.Item>
    </Col>
    </>
  );
};

export default Contract;
