import styled from "styled-components";

export const MainClass = styled.div`
  margin: 0;
  display: grid;
  grid-template-areas:
  "header aside"
  "main aside";
  grid-template-rows: 129px 1fr;
  grid-template-columns: 60vw 256px;

  .header {
    grid-area: header;
    background-color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1px;
    width: 100.1%;
  }
  .main {
    padding: 25px;
    grid-area: main;
    background-color: ${({ theme }) => theme.colors.white};
  }
  .aside {
    grid-area: aside;
    background-color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1px;
    z-index: 0;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-areas:
    "header header"
    "aside aside"
    "main main";
    grid-template-rows: 131px;
    grid-template-columns: 1fr;

    .header {
      width: 100%;
    }
    .aside {
      margin-bottom: 1px;
      margin-left: 0;
    }
  }
`;

export const HeaderContent = styled.div`
  padding: 25px;
  border-radius: 5px 0px 0px 0px;
  box-shadow: inset -1px 0px 0px #EFEFEF;

  @media only screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TagTitle = styled.div`
  background-color: ${({ theme }) => theme.colors.gray_90};
  color: ${({ theme }) => theme.colors.black};
  padding: 5px 10px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: ruby-base;
  margin-bottom: 20px;
`;

export const Title = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: ${({ theme }) => theme.colors.black};

  @media only screen and (max-width: 1200px) {
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.gray_500};
  }
`;
