import styled from 'styled-components';

export const SubTitle = styled.h3`
  color: var(--escala-de-cinza-50, #858585);

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.48px;
`;

export const PanelHeader = styled.div`
  position: relative;
  width: calc(100% + 53px);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-left: -24px;
  padding: 0 24px 20px 24px;
  gap: 4px;
  margin-top: 8px;

  align-self: stretch;
  border-bottom: 1px solid var(--escala-de-cinza-10, #efefef);
`;
