import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/Gabarito/LayoutTwo';
import { actions as gabaritoActions } from '../../../redux/modules/gabarito';
import { IGabarito } from '../../../shared/Models/gabarito/IGabarito';
import Fill from './Fill';
import RegisteredAnswers from './RegisteredAnswers';

const FillAnswers = () => {
  const [current, setCurrent] = useState(0);
  const [page, setPage] = useState<string>('fill');

  const dispatch = useDispatch();
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    dispatch(
      gabaritoActions.getGabaritoByToken(token, (_gabarito: unknown) => {
        const gabarito = _gabarito as IGabarito;
        if (gabarito.student_filled && !gabarito.filled) {
          setPage('registered');
        }
      })
    );
  }, [dispatch, token]);

  const renderPage = () => {
    switch (page) {
      case 'fill':
        return <Fill setPage={setPage} current={current} setCurrent={setCurrent} />;
      case 'registered':
        return <RegisteredAnswers />;
      default:
        return null;
    }
  };

  return <Layout padding={false}>{renderPage()}</Layout>;
};

export default FillAnswers;
