import styled from 'styled-components';

export const Container = styled.div``;

export const Box = styled.div`
    .content {
        display: flex !important;
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            flex-direction: column;
        }

        justify-content: space-between;
        background-color: white;
        padding: 60px;
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            padding: 20px 0px;
        }
    }

    .box-score {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            width: 100%;
            justify-content: center;
            margin-top: 20px;
        }
    }

    .box-calendar {
        width: 50%;
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            width: 100%;
            padding: 15px;
        }

        .box-title {
            h2 {
                font-size: 60px;
            }

            h3 {
                margin-top: -20px;
                font-size: 50px;
                text-transform: uppercase;
            }
        }
    }
`;

export const Title = styled.span`
    font-weight: 700;
    font-size: 30px;
    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        font-size: 16px;    
        color: ${({ theme }) => theme.colors.blue_500};
    }
`;