import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { Spin } from 'antd';

import { CourseContext } from '../../../ClassContent/CourseDetail.container';
import { actions as studentActions } from '../../../../../redux/modules/student';

import CircleChart from '../../../../../components/CircleChart';
import BarChart from '../../../../../components/BarChart';
import Balloon from '../../../../../components/Balloon';

import { ReactComponent as GraphicSVG } from '../../../../../img/performance/graphic.svg';

import { ContextProps, getFiltersToFetchScoreToro, StateProps } from './common';

import { Title } from '../Layout/styles';
import { Container, Square, Divider } from './styles';

import config from '../../../../../config';

export default function Score() {
  const theme = useTheme();

  const [isHoveringClasses, setIsHoveringClasses] = useState(false);
  const [isHoveringGeneralAverage, setIsHoveringGeneralAverage] = useState(false);
  const [isHoveringMockAverage, setIsHoveringMockAverage] = useState(false);

  const dispatch = useDispatch();
  const { auth, student } = useSelector((state: StateProps) => state);
  const { scoreToro, isLoadingScoreToro } = student;
  const { course } = useContext(CourseContext) as ContextProps;

  useEffect(() => {
    if (course?.id) {
      const filters = getFiltersToFetchScoreToro(
        {
          date_start: course.data_inicio_aulas,
          date_end: course.data_fim_aulas,
          students: auth.user.id_profile,
          id_team: course.id_turma,
          id_curse: course.id,
        },
        course
      );

      dispatch(studentActions.fetchScoreToro(filters));
    }
    // eslint-disable-next-line
  }, [course?.id]);

  function renderScoreInformation() {
    if (!student.scoreToro.progress) {
      const scoreInformation = course.scorePerformanceInformation.find(
        (item) => item.gte === 0 && item.lte === 0
      );
      return (
        scoreInformation && <p dangerouslySetInnerHTML={{ __html: scoreInformation?.content }} />
      );
    } else {
      const result = course.scorePerformanceInformation
        ?.filter((item) => item.gte > 0 || item.lte > 0)
        .filter((item) => {
          return student.scoreToro.average >= item.gte && student.scoreToro.average <= item.lte;
        })
        .map((item) => {
          return <p dangerouslySetInnerHTML={{ __html: item.content }} />;
        });
      return result;
    }
  }

  return (
    <Container>
      <div className="box-title">
        <GraphicSVG />
        <Title>Meu desempenho</Title>
      </div>
      <div className="box-status">
        {isLoadingScoreToro ? (
          <div className="box-loading">
            <Spin />
          </div>
        ) : (
          <>
            <p>
              Olá, {Array.isArray(auth.user.name.split(' ')) && auth.user.name.split(' ')[0] + ' !'}
            </p>
            {renderScoreInformation()}
          </>
        )}
      </div>
      {isLoadingScoreToro ? (
        <div className="box-loading-score-toro">
          <Spin />
        </div>
      ) : (
        <>
          <div className="box-chart">
            <Title>Score Toro</Title>
            <p>O Score Toro é a soma da sua média geral com as aulas concluidas</p>
            <main>
              <div className="box-chart-legend">
                <CircleChart
                  percent={scoreToro.score}
                  doublePercent={70}
                  color={theme.colors.primary}
                  size={180}
                />

                <div className="legends">
                  <div className="box-legend-information">
                    <Square color={theme.colors.primary} />
                    <span>
                      É seu <b>Score Toro</b>
                    </span>
                  </div>

                  <div className="box-legend-information">
                    <Square />

                    <span>É o recomendado, 70%</span>
                  </div>
                </div>
              </div>

              <div className="informations">
                <div
                  className="box-chart-information"
                  onMouseEnter={() => setIsHoveringClasses(true)}
                  onMouseLeave={() => setIsHoveringClasses(false)}
                >
                  <CircleChart
                    percent={scoreToro.accomplish_classes_progress}
                    information={`${scoreToro.accomplish_classes}/${student.scoreToro.total_classes}`}
                    isPercent={false}
                  />
                  <p>Aulas concluidas</p>
                  <Balloon
                    title="Aulas concluídas"
                    content="Medido quando você completa 100% as etapas de uma aula"
                    isHovering={isHoveringClasses}
                  />
                </div>

                <div
                  className="box-chart-information"
                  onMouseEnter={() => setIsHoveringGeneralAverage(true)}
                  onMouseLeave={() => setIsHoveringGeneralAverage(false)}
                >
                  <CircleChart percent={scoreToro.average} />
                  <p>Média geral</p>
                  <Balloon
                    title="Média Geral"
                    content="Seu desempenho em todas as provas realizadas, não sendo simulado"
                    isHovering={isHoveringGeneralAverage}
                  />
                </div>

                {!config?.hiddenMenu?.includes('scoreToroV2') && (
                  <div
                    className="box-chart-information"
                    onMouseEnter={() => setIsHoveringMockAverage(true)}
                    onMouseLeave={() => setIsHoveringMockAverage(false)}
                  >
                    <CircleChart percent={scoreToro.simulated_average} />
                    <p>Média dos simulados</p>
                    <Balloon
                      title="Média dos Simulados"
                      content="Seu desempenho somente nas aulas do tipo Simulado"
                      isHovering={isHoveringMockAverage}
                    />
                  </div>
                )}
              </div>
            </main>
          </div>

          <Divider />
          <div className="box-footer">
            <Title>Progresso</Title>
            <p>Aulas, provas e simulados concluidos</p>

            <div>
              <BarChart percent={student.scoreToro.progress} />
              <h6>do curso completo</h6>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
