import styled, { css } from 'styled-components';
interface ContainerProps {
    correct?: boolean;
};

export const Container = styled.div<ContainerProps>`
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1px;
    height: 100%;

    ${({ correct }) => (
        correct && css`
            border: 2px solid #2EB85C;    
        `
    )}
`;