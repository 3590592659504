import styled from 'styled-components';

export const Container = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 8px;

  @media(max-width: 728px) {
    margin: 20px 20px 0;
  }
`;

const Tipography = styled.p`
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  letter-spacing: 0.2px;

  margin: 0;
`;

export const Title = styled(Tipography)`
  color: #101010;
  font-size: 24px;

  font-weight: 500;
`;

export const Subtitle = styled(Tipography)`
  font-family: "Manrope", sans-serif;
  color: #858585;
  font-size: 16px;

  font-weight: 400;
`;