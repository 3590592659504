import React from 'react';
import { useTheme } from 'styled-components';

import { Container } from './styles';

type Props = {
    percent: number;
    isPercent?: boolean;
    information?: string;
    size?: number;
    color?: string;
    doublePercent?: number;
    fontColor?: string;
    animation?: boolean;
    responsive?: boolean;
};

export default function CircleChart ({
    information,
    percent,
    isPercent = true,
    color,
    fontColor,
    size=140,
    doublePercent = 0,
    animation = false,
    responsive = true
}: Props) {
    const theme = useTheme();
    const calcPercent = Math.floor(((percent * 255) / 100));
    const calcDoublePercent = Math.floor(((doublePercent * 326) / 100));

    return (
        <Container
            size={size}
            color={color ?? theme.colors.warning}
            fontColor={fontColor ?? theme.colors.warning}
            percent={calcPercent}
            doublePercent={calcDoublePercent}
            className="circle-chart"
            responsive={responsive}
        >
            <div className="graph">
                {
                    !!(doublePercent) && (
                        <div className="secondary">
                            <svg>
                                <circle cx="75" cy="75" r="52" />
                            </svg>
                            <svg className={`secondary-cor ${animation && 'cor-secondary-anim'}`}>
                                <circle cx="75" cy="75" r="52" />
                            </svg>
                        </div>
                    )
                }

                <svg>
                    <circle cx="75" cy="75" r="40" />
                </svg>
                <svg className={`cor ${animation && 'cor-anim'}`}>
                    <circle cx="75" cy="75" r="40" />
                </svg>
                <span>{isPercent ? `${percent}%` : information}</span>
            </div>
        </Container>
    );
};
