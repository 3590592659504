import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/student'
import { constants } from '../modules/student'

function* fetchStudentsById(action) {
  try {
    const payload = yield call(api.fetchById, action.id)
    yield put({ type: constants.STUDENT_FETCH_BY_ID.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_BY_ID.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* fetchReportCFP(action) {
  try {
    const payload = yield call(api.fetchReportCFP, action.courseClassId, action.courseId);
    yield put({
      type: constants.STUDENT_FETCH_REPORT_CFP.SUCCESS,
      payload,
    });

    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_REPORT_CFP.FAILED,
      error: true,
      errorMessage: e.message || e,
    });
  }
}

function* fetchReportSimulatedFullResult(action) {
  try {
    const payload = yield call(
      api.fetchReportSimulatedFullResult,
      action.courseClassId,
      action.courseId,
      action.classId
    );

    yield put({
      type: constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.SUCCESS,
      payload,
    });

    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.FAILED,
      error: true,
      errorMessage: (e.message || e)
    });

    action.next && action.next();
  }
};

function* handleCfpSimulado(action) {
  try {
    const payload = yield call(
      api.handleCfpSimulado,
      action.userId,
      action.courseId,
      action.classId,
      action.media,
      action.start,
      action.end,
      action.kind,
      action.classes,
    );

    yield put({
      type: constants.CFP_FETCH_REPORT_SIMULATED_PDF.SUCCESS,
      payload,
    });

    action.next && action.next(payload);
  } catch (e) {
    yield put({
      type: constants.CFP_FETCH_REPORT_SIMULATED_PDF.FAILED,
      error: true,
      errorMessage: (e.message || e)
    });

    action.next && action.next();
  }
};

function* fetchScoreToro(action) {
  try {
    const payload = yield call(api.fetchScoreToro, action.params);
    yield put({ type: constants.STUDENT_FETCH_SCORE_TORO.SUCCESS, payload  });

    action.success && action.success();
  } catch (e) {
    yield put({
      type: constants.STUDENT_FETCH_SCORE_TORO.FAILED,
      error: true,
      errorMessage: (e.message || e)
    });

    action.error && action.error();
  }
}

function* editStudent(action) {
  try {
    //
    // notifications
    notification.info({
      key: 'student_edit',
      message: 'Editando seu cadastro',
    })

    const payload = yield call(api.edit, action.formData)
    yield put({ type: constants.STUDENT_EDIT.SUCCESS, payload })

    // notifications
    notification.close('student_edit')
    notification.success({
      message: 'Cadastro editado com sucesso',
    })

    action.next && action.next()
  } catch (e) {
    // notifications
    notification.close('student_edit')
    notification.error({
      message: 'Problemas ao editar seu cadastro',
      description: e.message || e,
    })
    yield put({
      type: constants.STUDENT_EDIT.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* changePassword(action) {
  try {
    const response = yield call(api.changeStudentPassword, action.formData)
    yield put({ type: constants.STUDENT_CHANGE_PASSWORD.SUCCESS })

    // notifications
    if(response){
      notification.success({
        message: 'Senha alterada com sucesso',
      })
    }

    action.next && action.next(false)
  } catch (e) {
    // notifications
    notification.error({
      message: 'Problemas ao alterar sua senha',
      description: e.message || e,
    })
    yield put({
      type: constants.STUDENT_CHANGE_PASSWORD.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
    action.next && action.next(true)
  }
}

function* storeCommunicationsChannels(action) {
  try {
    notification.info({
      key: 'channels_add',
      message: 'Atualizando preferências do usuário',
    });

    const payload = yield call(api.storeCommunicationsChannels, action.formData);
    yield put({ type: constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.SUCCESS });

    notification.close('channels_add');

    action.success && action.success(payload);
  } catch (e) {
    notification.close('channels_add');
    const message = e.message || e;
    yield put({
        type: constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.FAILED,
        error: true,
        message
    });

    notification.error({
      message
    });
    action.error && action.error();
  }
};

function* saveScheduledTest(action) {
  try {
    notification.info({
      key: 'scheduled_test',
      message: 'Salvando a data da sua prova.',
    });

    const payload = yield call(api.saveScheduledTest, action.formData);
    yield put({ type: constants.STUDENT_SCHEDULED_TEST.SUCCESS });

    notification.close('scheduled_test');
    notification.success({
      message: 'Informação salva com sucesso !',
    });

    action.success && action.success(payload);
  } catch (e) {
    notification.close('scheduled_test');
    const message = e.message || e;
    yield put({
      type: constants.STUDENT_SCHEDULED_TEST.FAILED,
      error: true,
      message
    });

    notification.error({
      message
    });
    action.error && action.error();
  }
};

function* trackDownloadReport(action) {
  try {
    yield call(api.trackDownloadReport, action.formData);
    yield put({ type: constants.STUDENT_TRACK_DOWNLOAD_REPORT.SUCCESS });

  } catch (e) {
    yield put({
      type: constants.STUDENT_TRACK_DOWNLOAD_REPORT.FAILED,
      error: true,
    });
  }
};

function* getScoreToroSugestionsByType(action) {
  try {
    const payload = yield call(api.getScoreToroSugestionsByType, action.categoryCourseId, action.typeReportId, action.categoryReportId);
    yield put({
        type: constants.STUDENT_SCORE_INFORMATION_BY_TYPE.SUCCESS,
        payload
    });
  } catch (e) {
    yield put({
        type: constants.STUDENT_SCORE_INFORMATION_BY_TYPE.FAILED,
    });
  }
};

/**
 * Saga
*/
function* watchFetchStudentsById() {
  yield takeLatest(constants.STUDENT_FETCH_BY_ID.ACTION, fetchStudentsById)
}

function* watchFetchReportCFP() {
  yield takeLatest(constants.STUDENT_FETCH_REPORT_CFP.ACTION, fetchReportCFP);
}

function* watchFetchReportSimulatedFullResult() {
  yield takeLatest(constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.ACTION, fetchReportSimulatedFullResult);
}

function* watchHandleCfpSimulado() {
  yield takeLatest(constants.CFP_FETCH_REPORT_SIMULATED_PDF.ACTION, handleCfpSimulado);
}

function* watchFetchScoreToro() {
  yield takeLatest(constants.STUDENT_FETCH_SCORE_TORO.ACTION, fetchScoreToro);
}

function* watchEditStudent() {
  yield takeLatest(constants.STUDENT_EDIT.ACTION, editStudent)
}

function* watchChangePasswordStudent() {
  yield takeLatest(constants.STUDENT_CHANGE_PASSWORD.ACTION, changePassword)
}

function* watchStoreCommunicationsChannels() {
  yield takeLatest(constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.ACTION, storeCommunicationsChannels);
}

function* watchSaveScheduledTest() {
  yield takeLatest(constants.STUDENT_SCHEDULED_TEST.ACTION, saveScheduledTest);
}

function* watchTrackDownloadReport() {
  yield takeLatest(constants.STUDENT_TRACK_DOWNLOAD_REPORT.ACTION, trackDownloadReport);
}

function* watchGetScoreToroSugestionsByType() {
  yield takeLatest(constants.STUDENT_SCORE_INFORMATION_BY_TYPE.ACTION, getScoreToroSugestionsByType);
}

/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  // add more sagas here
  yield all([
    fork(watchFetchStudentsById),
    fork(watchFetchScoreToro),
    fork(watchFetchReportCFP),
    fork(watchFetchReportSimulatedFullResult),
    fork(watchHandleCfpSimulado),
    fork(watchEditStudent),
    fork(watchChangePasswordStudent),
    fork(watchStoreCommunicationsChannels),
    fork(watchSaveScheduledTest),
    fork(watchTrackDownloadReport),
    fork(watchGetScoreToroSugestionsByType)
  ])
}
