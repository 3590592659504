import styled from "styled-components";
import { Col, Row } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;

  .report-cfp-div {
    width: 100%;
  }

  .chart-cfp-div {
    width: 100%;
  }

  .spin {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 60vh;
  background-color: #FFF;
  }
`

export const TitleRow = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  gap: 20px;

  width: 100%;

  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #EFEFEF, inset -1px 0px 0px #EFEFEF;
  border-radius: 5px 0px 0px 0px;

  @media (max-width: 767px) {
    padding: 20px;
    gap: 15px;
  }
`

export const TitleColumn = styled(Col)`
  display: flex;
  justify-content: space-between;
  padding: 0px;
  gap: 20px;
  width: 100%;
  flex-direction: row;
  align-items: center;

  @media (max-width: 1365px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ChartsRow = styled(Row)`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: 30px;
  gap: 35px;
  justify-content: space-between;

  background: #FFFFFF;
  box-shadow: inset -1px 0px 0px #EFEFEF;
  border-radius: 0px 0px 0px 5px;

  @media (min-width: 1366px) {
    flex-direction: row;
  }

  @media (min-width: 767px) and (max-width: 1365px) {
    flex-direction: column;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 30px 20px;
    gap: 20px;
  }
`

export const ScoreToroColumn = styled(Col)`
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;

  @media (min-width: 1366px) {
    height: 500px;
  }

  @media (max-width: 1365px) {
    width: 100%;
    max-width: none;
    min-height: none;
  }
`

export const PerformanceChart = styled(Col)`
  display: flex;

  flex-direction: row;
  padding: 20px;
  gap: 20px;

  border: 1px solid #EFEFEF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #FFFFFF;

  @media (min-width: 1366px) {
    align-items: start;
    overflow-y: scroll;
    height: 500px;
  }
  @media (max-width: 1365px) {
    align-items: center;
    width: 100%;
    max-width: none;
    min-height: none;
    margin-top: 20px;
  }
`

export const TitleField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;


  .gray-box {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 10px;
    gap: 10px;
    height: 28px;

    background: #EFEFEF;
    border-radius: 2px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #000000;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .course-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    color: #000000;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`

export const ScoreToroChart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 20px 30px;
  gap: 40px;

  width: 100%;

  background: #FFFFFF;

  border: 1px solid #EFEFEF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
`

export const CircleChartBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 10px;

  .circle-chartbox-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636363;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

export const BarChartBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 20px;
    width: 100%;
    max-width: 264px;
    max-height: 122px;

  h3 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: #252525;
  }

  .bar-chartbox {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #636363;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`

export const GrayLine = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0px;
    gap: 10px;
  }
`

export const PerformanceBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100%;
`

export const PerformanceTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  .performance-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #252525;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .performance-subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;

    color: #A4A4A4;

    @media (max-width: 767px) {
      line-height: 16px;
    }
  }
`

export const PerformancePerClass = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;

  @media (min-width: 1366px) {
    gap: 8px;
    overflow-y: hidden;
  }
  @media (max-width: 1365px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 5px;
    width: 100%;
  }
`

export const PerformancePerModule = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: start;
  padding: 20px;
  gap: 40px;
  width: 100%;
  justify-content: center;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  min-height: 280px;

  @media (max-width: 1365px) {
    margin-top: 20px;
  }
`
export const PerModuleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  .module-title {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    color: #252525;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .module-subtitle {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #A4A4A4;

    @media (max-width: 767px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
`

export const GraphBarBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  position: relative;
  padding: 0 26px;
  gap: 14px;
  width: 100%;
  max-width: 570px;
  height: 100%;
  max-height: 140px;
  margin: 0 auto;
  justify-content: center;
  border-bottom: 1px solid #999999;


  @media (max-width: 767px) {
    padding: 0;
  }

  &:before {
    content: '70%';
    position: absolute;
    bottom: 70%;
    border-bottom: 0.5px dashed #252525;
    z-index: 1;

    @media (min-width: 1366px) {
      width: 580px;
      margin-right: 20px;
    }
    @media (min-width: 767px) and (max-width: 1365px) {
      width: 110%;
      margin-right: 10%;
    }
    @media (max-width: 767px) {
      content: '';
      width: 100%;
      margin-right: 0%;
    }
  }

  &:after {
    content: '30%';
    position: absolute;
    bottom: 30%;
    border-bottom: 0.5px dashed #252525;
    z-index: 1;

    @media (min-width: 1366px) {
      width: 580px;
      margin-right: 20px;
    }
    @media (min-width: 767px) and (max-width: 1365px) {
      width: 110%;
      margin-right: 10%;
    }
    @media (max-width: 767px) {
      content: '';
      width: 100%;
      margin-right: 0%;
    }
  }
`

export const DashedTop = styled.div`
  display: flex;
  position: absolute;
  border-top: 0.5px dashed #252525;
  bottom: 100%;
  width: 100%;
`
