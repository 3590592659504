import styled, { css } from 'styled-components';
import radioEmpty from '../../../../../../img/radioEmpty.svg';
import radioChecked from '../../../../../../img/radioChecked.svg';
import radioCurrent from '../../../../../../img/radioCurrent.svg';

interface Props {
    forgotten?: boolean;
    active: boolean;
};

export const Container = styled.div`
    width: 25%;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: none !important;

    @media only screen and (max-width: 1000px) {
        max-height: calc(100vh - 115px);
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;

    ul {
        padding: 5px;
        margin-top: 10px;
        flex: 1;
    }
`;

export const Question = styled.li<Props>`    
    border: 1px solid #A4A4A4;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: 16px;

    ${({ forgotten }) => forgotten ? css`
        color: #101010;
    ` : css`
        color: #fff;
        border-color: #62CF7A;
        background: #62CF7A;
    `}

    ${({ active }) => active && css`
        color: #fff;
        background: #5768FF;
        border-color: #5768FF;
        box-shadow: 0px 0px 15px rgba(87, 104, 255, 0.6);
    `};

    cursor: pointer;
    
    span {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0 18px;
        height: 45px;
        gap: 14px;
        
        &::before {
            margin-top: 8px;
            ${({ forgotten }) => forgotten ? css`
                content: url(${radioEmpty});
            ` : css`
                content: url(${radioChecked});
            `}
            ${({ active }) => active && css`
                content: url(${radioCurrent});
            `}
        }

        em {
            font-style: normal;
            opacity: 0.5;
        }
    }
`;