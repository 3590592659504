import { createSagaAction } from '../../shared/sagas';
import { createReducer } from '../../shared/reducers';

// Constants
export const constants = {
  COURSE_GET_COURSES_BY_USER_ID: createSagaAction('COURSE_GET_COURSES_BY_USER_ID'),
  COURSE_GET_DETAILS: createSagaAction('COURSE_GET_DETAILS'),
  COURSE_GET_DETAILS_BY_TAG: createSagaAction('COURSE_GET_DETAILS_BY_TAG'),
  COURSE_UPDATE_CLASS: createSagaAction('COURSE_UPDATE_CLASS'),
  COURSE_UPDATE_SCHEDULE_TEST: 'COURSE_UPDATE_SCHEDULE_TEST',
};

export const actions = {
  getCoursesByUserId: (next, error) => {
    return {
      type: constants.COURSE_GET_COURSES_BY_USER_ID.ACTION,
      next,
      error,
    };
  },
  getCourseDetails: (courseId, courseClassId = null, next) => {
    return {
      type: constants.COURSE_GET_DETAILS.ACTION,
      courseId,
      courseClassId,
      next,
    };
  },
  getCourseDetailsByTag: (courseTag, next) => {
    return {
      type: constants.COURSE_GET_DETAILS_BY_TAG.ACTION,
      courseTag,
      next,
    };
  },
  setClassProperties: (selectedClass, step, next) => {
    return {
      type: constants.COURSE_UPDATE_CLASS.ACTION,
      selectedClass,
      step,
      next,
    };
  },
  updateScheduleTest: (date) => {
    return {
      type: constants.COURSE_UPDATE_SCHEDULE_TEST,
      date
    }
  },
};

const ACTION_HANDLERS = {
  // COURSE_GET_COURSES_BY_USER_ID.ACTION
  [constants.COURSE_GET_COURSES_BY_USER_ID.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.COURSE_GET_COURSES_BY_USER_ID.SUCCESS]: (state, action) => {
    return { ...state, enrollmentsList: action.payload, isLoading: false };
  },
  [constants.COURSE_GET_COURSES_BY_USER_ID.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  // COURSE_GET_DETAILS.ACTION
  [constants.COURSE_GET_DETAILS.ACTION]: (state) => {
    return { ...state, error: null, isFetchingCourse: true };
  },
  [constants.COURSE_GET_DETAILS.SUCCESS]: (state, action) => {
    return { 
      ...state, 
      ...action.payload.report[0], 
      scheduledTest: action.payload.scheduled_exam,
      scorePerformanceInformation: action.payload.score_performance_informations,
      isFetchingCourse: false 
    };
  },
  [constants.COURSE_GET_DETAILS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isFetchingCourse: false };
  },

  // COURSE_GET_DETAILS_BY_TAG.ACTION
  [constants.COURSE_GET_DETAILS_BY_TAG.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.COURSE_GET_DETAILS_BY_TAG.SUCCESS]: (state, action) => {
    return {
      ...state,
      course: action.payload,
      isLoading: false,
    };
  },
  [constants.COURSE_GET_DETAILS_BY_TAG.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  // COURSE_UPDATE_SCHEDULE_TEST
  [constants.COURSE_UPDATE_SCHEDULE_TEST]: (state, action) => {
    return {
      ...state, 
      scheduledTest: {
        date_exam: action.date
      }
    };
  },

  // COURSE_UPDATE_CLASS
  [constants.COURSE_UPDATE_CLASS.ACTION]: (state, { selectedClass, step }) => {
    const existVideo =
    Object.keys(selectedClass).find((element) => element === 'aula_videos') &&
    selectedClass.video_embed
      ? selectedClass.video_embed
      : selectedClass.aula_videos.length > 0
      ? selectedClass.aula_videos[0].video_embed
      : '';

    const existPDF = selectedClass.pdf_file;
    const existTest = selectedClass.have_lecture;

    let stepsCt = 0;
    stepsCt += existPDF ? 1 : 0;
    stepsCt += existVideo ? 1 : 0;
    stepsCt += existTest ? 1 : 0;

    const completionPercentage = 100 / stepsCt;

    //
    // percantage for video played
    if (step.video_played && !selectedClass.video_played) {
      step.percent = parseInt(selectedClass.percent) + completionPercentage;
    }

    //
    // percantage for downloaded_pdf
    if (step.downloaded_pdf && !selectedClass.downloaded_pdf) {
      step.percent = parseInt(selectedClass.percent) + completionPercentage;
    }
    //
    // percantage for first_test
    if (step.first_test && !selectedClass.first_test) {
      step.percent = parseInt(selectedClass.percent) + completionPercentage;
    }

    if (parseInt(step.percent) >= 99) {
      step.percent = 100;
    }

    const classIndex = state.classes.findIndex((classItem) => classItem.id === selectedClass.id);
    const newClassesArray = state.classes;

    newClassesArray[classIndex] = {
      ...newClassesArray[classIndex],
      ...step,
    };

    return {
      ...state,
      classes: [...newClassesArray],
    };
  },
};

export const initialState = {
  enrollmentsList: [],
  scheduledTest: {},
  scorePerformanceInformation: [],
  isFetchingCourse: false
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
