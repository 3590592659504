import styled from 'styled-components';

type TResult = {
  result?: number | string;
}

type TResultP = {
  margin?: number;
}

type TText = {
  color: string;
  result?: number | string;
}

type TDashed = {
  linePercent?: number;
  linePercentTwo?: number;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

export const ChartBarResume = styled.div`
  display: flex;
  height: 140px;
  align-items: flex-end;
`;

export const GraphBarResult = styled.div<TResult>`
    background: #F89C3A;
    border-radius: 6px 6px 0px 0px;


    height: ${({ result }) => result}%;
    display: flex;
    justify-content: center;
    padding: ${({ result }) => result !== 0 ? '1.056vw 0.352vw 0.352vw' : ''};
    margin: ${({ result }) => result !== 0 ? '' : '-10px 0 0 0'};
    margin-right: 0.528vw;

    @media (min-width: 1366px) {
      width: 73px;
    }
    @media (min-width: 767px) and (max-width: 1365px) {
      width: 53px;
    }
    @media (max-width: 767px) {
      width: 30px;
    }
`;

export const GraphBarResultP = styled.div<TResultP>`
    margin-top: -1vw;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.1vw;
    line-height: 1.725vw;
    text-align: center;
    color: #FFFFFF;

    z-index: 2;
`;

export const Text = styled.div<TText>`
  display: flex;
  align-items: center;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;

  color: ${(({ result, color }) => result !== 0 ? color : '#000000')};

  width: 100%;
  margin: ${(({ result }) => result !== 0 ? '6px' : '-6px')};

  z-index: 2;

  @media (min-width: 1366px) {
    font-size: 14px;
    line-height: 18px;
    margin: ${(({ result }) => result !== 0 ? '4px 0' : '-4px 0')};
  }
  @media (min-width: 767px) and (max-width: 1365px) {
    font-size: 12px;
    line-height: 17px;
    margin: ${(({ result }) => result !== 0 ? '8px 0' : '-8px 0')};
  }
  @media (max-width: 767px) {
    font-size: 9px;
    line-height: 16px;
    margin: ${(({ result }) => result !== 0 ? '8px 0' : '-10px 0')};
  }
`

export const ChartBarContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  width: 100%;
  height: 100%;

  max-width: 73px;

  align-items: center;

  .subtitle {
    position: absolute;
    bottom: -60px;

    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;

    text-align: center;
    color: #101010;

    @media (min-width: 1366px) {
      font-size: 14px;
      line-height: 18px;
    }
    @media (min-width: 767px) and (max-width: 1365px) {
      font-size: 12px;
      line-height: 17px;
    }
    @media (max-width: 767px) {
      font-size: 9px;
      line-height: 16px;
    }
  }
`

export const LineDashed = styled.div<TDashed>`
    position: absolute;
    width: 100%;
    height: 0px;

    display: flex;
    border: 0.06vw dashed #252525;
    z-index: 1;
    bottom: 0px;
    bottom: ${({ linePercent }) => linePercent}%;

    ::after {
    content: '${({ linePercent }) => linePercent}%';

    position: absolute;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.408vw;
    line-height: 1.725vw;

    color: #A4A4A4;
    }
`;
