import styled from 'styled-components';


type ScoreProps = {
    color?: string;
};

export const Container = styled.div`
    border: 4px solid ${({ theme }) => theme.colors.warning};
    border-radius: 10px;
    width: 91%;
    
    padding: 20px;
    @media(max-width: ${({ theme }) => theme.media.notebook}px) {
        padding: 10px;
    } 
    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        padding: 10px;
    }

    .box-title {
        display: flex;
        align-items: center;
        span {
            margin-left: 15px;
        }
    }

    .box-status {
        margin-top: 60px;
        padding: 30px;
        background: #FFF;
        box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);

        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            p {
                text-align: left;
                font-size: 14px;
            }
        }

        .box-loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .box-loading-score-toro {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400px;
    }

    .box-chart {
        margin-top: 50px;

        main {
            margin-top: 20px;
            flex-wrap: wrap;

            width: 100%;
            display: flex;
            justify-content: center;

            .box-chart-information {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-weight: 500;
            }
        }
    }

    .box-chart-legend {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .legends {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap:15px;
            width: 55%;

            .box-legend-information {
                display: flex;
                span {
                    font-weight: 500;
                    font-size: 15px;
                    margin-left: 20px;
                    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
                        font-size: 12px;
                        margin-left: 10px;
                        text-align: left;
                    }
                }
            }
        }
    }

    .informations {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    .box-footer {
        position: relative;
        padding: 30px 0;

        & > div {
            margin-top: 35px;
        }

        h6 {
            position: absolute;
            right: 0;
            text-align: right;
            font-size: 14px;
            margin-top: 5px;
        }
    }

    .box-mobile-chart {
        margin-top: 50px;
        .mobile-title {
            display: flex;
        }

        .mobile-another-informations {
            margin-top: 20px;
            display: flex;
            justify-content: center;

            .circle-chart {
                zoom: 90%;
            }

            .box-chart-information {
                justify-content: center;
                align-items: center;

                font-size: 13px;
                font-weight: 600;
            }
        }
    }
`;




export const Square = styled.div<ScoreProps>`
    width: 25px;
    height: 25px;
    border-radius: 3px;
    background-color: ${({ color, theme }) => color ? color : theme.colors.default};
`;

export const Divider = styled.div`
    margin-top: 60px;
    border: 0.5px solid #ddd;
`;