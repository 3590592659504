import styled from "styled-components";

export const InformationItemsArea = styled.div`
  display: flex;
  padding: var(--spacing-4, 16px);
  align-items: center;
  gap: 12px;
  align-self: stretch;

  > span {
    color: var(--escala-de-cinza-90, #101010);

    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.48px;
  }

  > div.img-area {
    display: flex;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    border-radius: 110px;
    border: 1.5px solid var(--Primary-Purple, #5768ff);

    > img {
      width: 21px;
      height: 21px;
    }
  }
`;
