import React from 'react';
import CacheBuster from 'react-cache-buster';
import { Skeleton } from 'antd';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import { version } from '../package.json';
import history from './history'
import AppRoutes from './routes/AppRoutes'

import { ThemeProvider } from 'styled-components';
import theme from './shared/styled/theme';
import { ToastContainer } from 'react-toastify';

import './styles/main.scss';

import 'react-loading-skeleton/dist/skeleton.css'
import 'react-toastify/dist/ReactToastify.css';

import { TimerProvider } from './hooks/useTimer';
import NotificationsProvider from './contexts/notifications';

function App() {
  const isReport = window.location.pathname.startsWith('/cfp-');
  const isProduction = process.env.NODE_ENV === 'production';
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<Skeleton active />} //If not pass, nothing appears at the time of new version check.
    >
      <Provider store={store}>
        <TimerProvider>
          <ThemeProvider theme={theme}>
            <PersistGate loading={null} persistor={persistor}>
              <NotificationsProvider isReport={isReport}>
                <Router history={history}>
                  <AppRoutes />
                </Router>
              </NotificationsProvider>
            </PersistGate>
          </ThemeProvider>
        </TimerProvider>
        <ToastContainer/>
      </Provider>
    </CacheBuster>
  );
}

export default App;
