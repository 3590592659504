import React from 'react';

import { Formik } from 'formik';

import { Input } from '../../../../../components/Input';
import { ButtonSubmit } from '../../../../../components/ButtonSubmit';

import ErrorMessageIcon from '../../../../../img/svg/ErrorMessageIcon';
import SuccessMessageIcon from '../../../../../img/svg/SuccessMessageIcon';

import { ErrorsArea, ErrorsPassword, FormaArea, GridArea } from './styles';

import useUserForms from '../hooks/useUserForms';

export function FormPassword({ dataPassword, handleSavePasswordUser }: FormPasswordProps) {
  const { formPasswordValidate, getItemsPasswordForm } = useUserForms();

  const handleSubmit = (data: UserPassWordDataType) => {
    handleSavePasswordUser(data);
  };

  const renderIcon = (value: boolean) => {
    if (value) {
      return <ErrorMessageIcon width={18} height={18} />;
    } else {
      return <SuccessMessageIcon width={18} height={18} />;
    }
  };

  return (
    <Formik
      initialValues={dataPassword as unknown as UserPassWordDataType}
      validate={formPasswordValidate}
      onSubmit={(values) => handleSubmit(values)}
      validateOnMount={true}
    >
      {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
        const itemsForm = getItemsPasswordForm();
        const datasErrors = errors as ErrorsType;

        return (
          <FormaArea onSubmit={handleSubmit}>
            {itemsForm.map((items, index) => (
              <GridArea items={items.length} key={index}>
                {items.map(({ label, target, type, onChange, value, maxLength }) => (
                  <Input
                    key={target}
                    errors={errors[target as keyof UserPassWordDataType]}
                    label={label}
                    type={type}
                    name={target}
                    placeholder={label}
                    value={value ? value : values[target as keyof UserPassWordDataType] || ''}
                    onChange={onChange ? onChange : handleChange}
                    onBlur={handleBlur}
                    maxLength={maxLength ? maxLength : undefined}
                  />
                ))}
              </GridArea>
            ))}
            <ErrorsArea>
              <ErrorsPassword>Sua nova senha deve:</ErrorsPassword>
              <ErrorsPassword className={datasErrors.minMax ? 'error' : 'success'}>
                {renderIcon(datasErrors.minMax)} Conter no mínimo 8 e no máximo 16 caracteres
              </ErrorsPassword>
              <ErrorsPassword className={datasErrors.uppercaseLowercase ? 'error' : 'success'}>
                {renderIcon(datasErrors.uppercaseLowercase)} Misturar letras maiúsculas e minúsculas
              </ErrorsPassword>
              <ErrorsPassword className={datasErrors.letterAndNumber ? 'error' : 'success'}>
                {renderIcon(datasErrors.letterAndNumber)} Misturar letras e números
              </ErrorsPassword>
              <ErrorsPassword className={datasErrors.specialCharactere ? 'error' : 'success'}>
                {renderIcon(datasErrors.specialCharactere)} Conter no mínimo 1 caracatere especial
              </ErrorsPassword>
            </ErrorsArea>
            <ButtonSubmit margin="auto 0 0 auto" label="Salvar" type="submit" />
          </FormaArea>
        );
      }}
    </Formik>
  );
}

export interface UserPassWordDataType {
  senha_atual: string | null;
  nova_senha: string | null;
  reNewPassword: string | null;
}

export interface ErrorsType extends UserPassWordDataType {
  minMax: boolean;
  uppercaseLowercase: boolean;
  letterAndNumber: boolean;
  specialCharactere: boolean;
}

interface FormPasswordProps {
  dataPassword: UserPassWordDataType;
  handleSavePasswordUser: (values: UserPassWordDataType) => void;
}
