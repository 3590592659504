import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div<{question?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 0 0;

  padding: 10px;

  gap: 30px;

  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 0;

    ${({ question }) => question && css`
      margin-top: 0;
      gap: 0;
      background-color: #fff;
    `}
  }
`;

export const Panel = styled.div`
  width: 100%;
  height: auto;
  
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 0 0;

  background: #ffffff;

  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);

  padding: 24px 0;

  border-radius: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: fit-content;
    border-radius: 0;
    padding: 0;
    width: calc(100% - 1.6px);
  }
`;

export const ButtonsStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 8px;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const ButtonStyled = styled(Button)`
    border-radius: 8px;
    border-color: #5768FF;
    font-weight: 500;

    @media screen and (max-width: 768px) {
      display: none;
    }
`;

export const ButtonStyledTwo = styled(Button)`
    display: none;

    @media screen and (max-width: 768px) {
      display: block;
      border: none;
      width: 58px;
      height: 58px;
    }
`;