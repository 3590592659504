import { IEpisode } from '../../shared/Models/podcast/IEpisode';
import { IPodcast } from '../../shared/Models/podcast/IPodcast';
import request from '../../shared/request';

export const fetchAll = (): Promise<IPodcast[]> => (
    request('/v1/services/podcast')
);

export const fetchByClass = (courseId: number, tags: string): Promise<IEpisode> => (
    request(`/v1/services/podcast/episode?id_course=${courseId}&tags=${tags}`)
);

export const track = (formData = {}): Promise<boolean> => (
    request('/v1/services/podcast/track', {
        method: 'POST',
        body: JSON.stringify(formData)
    })
);