import styled, {css} from "styled-components";
import background from '../../../img/gabaritoro/background-two.jpeg';

interface ContentProps {
    padding: boolean
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 100px);
    background: linear-gradient(96.64deg, rgba(34, 43, 130, 0.9) 26.19%, rgba(0, 10, 102, 0.9) 102.4%), url(${background});
    background-size: 100%;
    font-family: 'Work Sans', sans-serif;

    @media(max-width: 1080px) {
        html {
            font-size: 93.75%;
        }
    }
    @media(max-width: 720px) {
        box-sizing: border-box;
        
        html {
            font-size: 87.5%;
        }
    }

    @media(max-width: 650px) {
        padding: 1.5625em;
        background: ${({ theme }) => theme.colors.gray_90};
    }
`;

export const Wrapper = styled.div`
    margin: 3.125em 0; 
    border-radius: 0.3125em;
    width: 37em;
    background-color: ${({ theme }) => theme.colors.white};

    @media(max-width: 650px) {
        width: 100%;
        margin: 0;
    }
`;

export const Top = styled.div`
    display: flex;
    border-radius: 0.3125em 0.3125em 0 0;
    height: 8.125em;
    background: linear-gradient(100.59deg, ${({ theme }) => theme.colors.pink} -8.92%, ${({ theme }) => theme.colors.primary} 120.67%);
    align-items: center;
    justify-content: center;

    @media(max-width: 650px) {
        height: 4.9375em;

        svg {
            width: 17.1875em;
            height: 1.8125em;
        }
    }
`;

export const Content = styled.div<ContentProps>`
    ${({ padding }) => padding && css`
        padding: 2.5em;

        @media(max-width: 650px) {
            padding: 2.5em 1.25em;
        }
    `}
`;