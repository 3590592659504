import styled from 'styled-components';

 export const Container = styled.div`
 display: flex;
 flex-direction: column;
 align-items: flex-start;
 padding: 50px 30px 30px;
 gap: 35px;

 width: 100%;
 height: 524px;
 background: #FFFFFF;
 border-radius: 0px 0px 0px 5px;
 flex: none;
 order: 1;
 align-self: stretch;
 flex-grow: 1;
`;

export const TitleIntro = styled.span`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;
export const BoxMaterial = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  @media only screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100%;
  }
`;

