import styled from 'styled-components';
import searchBg from '../../img/search.svg';

export const Container = styled.div`
  position: fixed;
  top: 0;
  z-index: 100;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;
  algin-items: center;
  padding: 0 30px;
  height: 74px;
  background: #fff;
  border-bottom: 1px solid #efefef;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  align-self: center;
  algin-items: center;
  gap: 194px;

  fieldset {
    display: flex;
    border-radius: 10px;
    algin-items: center;
    padding-left: 51px;
    width: 360px;
    height: 44px;
    background: url(${searchBg}) no-repeat #f7f7f7 20px center;
  }

  legend {
    display: none;
  }

  label {
    display: none;
  }

  input {
    border: none;
    width: 280px;
    height: 24px;
    align-self: center;
    font-size: 16px;
    background: none;
    outline: none;
    font-family: 'Manrope', sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    letter-spacing: 0.48px;
  }

  input::placeholder {
    color: #a4a4a4;
    opacity: 1;
  }

  input::-ms-input-placeholder {
    color: #a4a4a4;
  }
`;

export const UserContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  align-self: center;
  justify-content: space-between;

  cursor: pointer;

  border-radius: 50%;
  border: 2px solid #fff;

  transition: all 0.2s ease-in-out;

  &:hover {
    border: 2px solid #5768ff;
  }

  img {
    border-radius: 50%;
  }
`;

export const NameLetterIcon = styled.p`
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  margin: 0 auto;

  color: #5768ff;

  background: #f7f7f7;
  width: 41px;
  height: 37px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
