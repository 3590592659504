import React from 'react'
// import moment fro                                                                                         m 'moment'
import { array, func } from 'prop-types'
import { List, Button, Col, Row } from 'antd'
import { format, parseISO } from 'date-fns'
import { calculateRightAns } from '../../helpers'
import { ArrowRightOutlined } from '@ant-design/icons'

import './styles.scss'

const propTypes = {
  finishedLectures: array,
  handleReview: func.isRequired,
}

function FinishedLectureList({
  finishedLectures,
  handleReview
}) {

  return (
    <>
    <List
    column={80}
    grid={{
      gutter: 40,
      xs: 0,
      sm: 0,
      md: 0,
      lg: 0,
      xl: 0,
      xxl: 0,
    }}
    itemLayout="horizontal"
    dataSource={finishedLectures}
    style={{ marginTop: 20 }}
    renderItem={(lecture) => {
      const media = calculateRightAns({ansArray: lecture.respostas }) / (lecture.respostas?.length ?? 0) * 100
      return (
          <List.Item>
            <Row style={{ width: '100%' }}>
              <div style={{ flexWrap: 'wrap', display: 'flex', width: '100%' }}>
                <Col>
                  <div>
                    Data: <strong>{format(parseISO(lecture.data_prova), 'dd/MM/yyyy')}</strong>
                    <br />
                    Hora: <strong>{format(parseISO(lecture.data_prova), 'HH:mm')}</strong>
                    <br />
                    Respondidas: <strong>{lecture.respostas?.length ?? 0}</strong>
                    <br />
                    Acertos: <strong>{calculateRightAns({ ansArray: lecture.respostas })}</strong>
                    <br />
                    Média de acertos: <strong>{Math.round(media)}%</strong>
                    <br />
                    Duração: <strong>{lecture.tempo_prova.slice(0, 2)}h{lecture.tempo_prova.slice(3, 5)}m{lecture.tempo_prova.slice(6, 8)}s</strong>
                  </div>

                  {handleReview && (
                    <div className="finished-lecture-buttons">
                      <Button type="primary" onClick={() => handleReview(lecture.id_prova)}>
                        Fazer a revisão <ArrowRightOutlined />
                      </Button>
                    </div>
                  )}
                </Col>
              </div>
            </Row>
          </List.Item>
        )
      }}
    />
    </>
  )
}

FinishedLectureList.propTypes = propTypes
export default FinishedLectureList
