import styled from 'styled-components';

import { IEpisode } from '../../../../shared/Models/podcast/IEpisode';

type Props = {
    selected?: boolean;
    onClick: (podcast: IEpisode) => void;
};

export const Container = styled.div<Props>`
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;

    border-radius: 5px;
    border: 2px solid ${({ theme, selected }) => selected  ? theme.colors.warning : theme.colors.gray_150};
    box-sizing: border-box;
    transition: filter 0.2s;
    &:hover {
        filter: brightness(0.8);
    }

    > div {
        flex-basis: 15%;
    }
    
    .id {
        font-size:  10px;
        font-weight: 500;
    }

    margin-bottom: 10px;

    .description {
        flex-basis: 55%;
        display: flex;
        flex-direction: column;

        .item-title {
            font-weight: 700;
            font-size: 15px;
            @media(max-width: 600px) {
                font-size: 13px;
            }
        }

        .item-professor {
            font-size: 13px;
            @media(max-width: 600px) {
                font-size: 12px;
            }
        }
    }

    .class {
        font-size: 13px;
        @media(max-width: 600px) {
            font-size: 12px;
        }
    }

    .time {
        font-size: 13px;
        @media(max-width: 600px) {
            font-size: 12px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;