import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ArrowRight: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 12L18.22 12" stroke={color || '#5768FF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.25 12L14.25 8" stroke={color || '#5768FF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.25 12L14.25 16" stroke={color || '#5768FF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

ArrowRight.defaultProps = defaultProps;

export default ArrowRight;
