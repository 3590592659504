import request from '../../shared/request'

export function getAllAvalibleClassToMentorship() {
  return request('/v1/services/disciplinas/listartodas')
}

export function askForMentorship(formData) {
  return request('/v1/services/aluno/mentoria/solicitar', {
    method: 'POST',
    body: JSON.stringify(formData)
  })
}

export function getMyMentorships() {
  return request('/v1/services/aluno/mentoria/listar')
}

export function getMyMadeMentorships() {
  return request('/v1/services/aluno/mentoria/listartudo')
}

export function cancelMyMentorship(id) {
  return request(`/v1/services/aluno/mentoria/cancelar/${id}`, {
    method: 'POST',
  })
}
