import { useContext } from 'react';
import { CourseContext } from '../pages/course/ClassContent/CourseDetail.container';

export type ContextClassProps = {
    cod_aula: string;
    titulo: string;
};

export type CourseContextProps = {
    course: {
        tag: string;
        title: string;
        turma?: string;
        data_inicio_aulas?: string;
        data_fim_aulas?: string | any;
        days_to_expire?: number;
        end_date?: string;
        id: number;
        id_course_category?: number;
        course_category?: string;
        id_turma: number;
        scheduledTest?: {
            date_exam: string;
        }
    };
    selectedClass?: ContextClassProps;
    setSelectedClass: (selectedClass?: ContextClassProps) => void;
};

export function useCourseDetail() {
    const context = useContext(CourseContext) as CourseContextProps;
    return context;
};
