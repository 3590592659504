import styled, { css } from "styled-components";
import background from '../../../img/gabaritoro/background.png';

interface WrapperProps {
    layoutTheme?: string;
};

interface WrapperCheckboxProps {
    marked?: boolean;
}


export const Container = styled.div`
    font-family: 'Montserrat';
    @media(max-width: 1080px) {
        html {
            font-size: 93.75%;
        }
    }
    @media(max-width: 720px) {
        html {
            font-size: 87.5%;
        }
    }
`;

export const Wrapper = styled.div<WrapperProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${({ layoutTheme }) => {
        return layoutTheme !== 'light' && css`
            background-image: url(${background});
            background-size: 100%;
        `;
    }}

    padding: 4.65rem;
    @media(max-width: 720px) {
        padding: 1rem;
        box-sizing: border-box;
    }
`;


export const WrapperCheckbox = styled.div<WrapperCheckboxProps>`
    background: ${({ theme }) => theme.colors.gray_50};
    border: 1px solid ${({ theme }) => theme.colors.gray_100};
    box-sizing: border-box;
    border-radius: 4px;

    width: 230px;
    height: 45px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 17px;

    ${({ marked }) => marked && css `
        border: 2px solid ${({ theme }) => theme.colors.pink};
    `}

    .checkbox-text {
        margin-left: 14px;
        color: ${({ theme }) => theme.colors.blue_500};
        font-size: 14px;
        font-weight: 500;
    }
`;