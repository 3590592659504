import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconVideo: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.5 0H34.5V15H31.5V0Z" fill="#F0F2FF" />
      <rect y="15" width="66" height="66" rx="33" fill="#F0F2FF" />
      <path d="M34.9333 59.8H23.3666C20.1666 59.8 17.5833 57.2166 17.5833 54.0166V48.2333C17.5833 45.0333 20.1666 42.45 23.3666 42.45H34.9333C38.1333 42.45 40.7166 45.0333 40.7166 48.2333V54.0166C40.6999 57.2166 38.1166 59.8 34.9333 59.8Z" stroke="#5768FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M40.7 54.9833L45.8 56.9C47.0666 57.3667 48.3999 56.4333 48.3999 55.1V47.1667C48.3999 45.8167 47.05 44.8833 45.8 45.3666L40.7 47.2833V54.9833Z" stroke="#5768FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24.15 40.9667C26.5157 40.9667 28.4334 39.0489 28.4334 36.6833C28.4334 34.3177 26.5157 32.4 24.15 32.4C21.7844 32.4 19.8667 34.3177 19.8667 36.6833C19.8667 39.0489 21.7844 40.9667 24.15 40.9667Z" stroke="#5768FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M34.15 40.9667C36.5157 40.9667 38.4334 39.0489 38.4334 36.6833C38.4334 34.3177 36.5157 32.4 34.15 32.4C31.7844 32.4 29.8667 34.3177 29.8667 36.6833C29.8667 39.0489 31.7844 40.9667 34.15 40.9667Z" stroke="#5768FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M31.5 81H34.5V96H31.5V81Z" fill="#F0F2FF" />
    </svg>

  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconVideo.defaultProps = defaultProps;

export default IconVideo;
