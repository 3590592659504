import React from 'react';

import {
  ChartBarResume,
  GraphBarResult,
  GraphBarResultP,
  ChartBarContainer,
  Text
} from './styles'

type Props = {
  result?: any;
  recomended?: number | any;
  margin?: number;
  displayRecomended?: any;
  subtitle: string;
}

const ChartBarTotal = ({ result, recomended, margin, displayRecomended, subtitle }: Props) => {


  return (
    <ChartBarResume>
      <ChartBarContainer>
        <div className='subtitle'>{subtitle}</div>
        <GraphBarResult result={result}>
          <GraphBarResultP>
            <Text
              color='#FFFFFF'
              result={result}
            >
              {result ? result : 0}%
            </Text>
          </GraphBarResultP>
        </GraphBarResult>

      </ChartBarContainer>
    </ChartBarResume>
  );
};

export default ChartBarTotal;
