import React, { useEffect } from 'react';



import {
  Content,
  ColContent,
  LineDashed,
  ColM,
  Line,
  LineDashedTwo
} from './styles'
import ChartBarTotal from '../../../graphs/ChartBarTotal';
import LegendChart from '../SubtitleChart';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultParams, getFormatedModulesPerformanceReport, StudentStateType } from '../../../common';
import { actions as studentActions } from '../../../../../redux/modules/student';
import { getPercentFormatted } from '../../../../../shared/utils/common'
type Props = {
  linePercent?: number;
  linePercentTwo?: number;
  displaySubtitle?: string,
  subtitleMin?: string,
  subtitleRecommended?: string,
  positionSubtitle?: string,
  recomended?: number,
}

function BartChartV({
  linePercent,
  linePercentTwo,
  displaySubtitle,
  subtitleMin,
  subtitleRecommended,
  positionSubtitle,
}: Props) {

  const { reportSimulado: report } = useSelector((state: StudentStateType) => state.student);

  useEffect(() => {
    const { userId, courseId, classId, media, start, end, kind, classes } = getDefaultParams(window.location.search);
    dispatch(studentActions.handleCfpSimulado(
      userId,
      courseId,
      classId,
      media,
      start,
      end,
      kind,
      classes
    ));
    // eslint-disable-next-line
  }, []);

  const dispatch = useDispatch();

  // let modulesTotal = [0, 0, 0, 0, 0, 0];

  // const formattedReport = [0,1,2,3,4,5].map(index => {
  //   if (report?.module_performance?.length && report?.module_performance[index]) {
  //     return report?.module_performance[index];
  //   }
  //   return {
  //     total: 0,
  //     module: `${index + 1}`,
  //   }
  // });


  const moduleFormattedReport = getFormatedModulesPerformanceReport(report?.module_performance);
  // console.log('report', report);

  return (
    <>
      <Content>
        <ColContent xs={24} style={{ height: '15vw', marginLeft: "4vw", marginBottom: '-1vw' }}>
          <>
            {(moduleFormattedReport ?? []).map((item, i) => {
                  let recomended
                  if (i === 0) {
                    recomended = 80
                  } else if (i === 1) {
                    recomended = 75
                  } else if (i === 2) {
                    recomended = 80
                  } else if (i === 3) {
                    recomended = 80
                  } else if ( i === 4) {
                    recomended = 75
                  } else if (i === 5) {
                    recomended = 75
                  }else if (i === 6) {
                    recomended = 0
                  }
                  return (
                    <div key={item.module} style={{marginLeft: '1.9vw'}}>
                      <ChartBarTotal
                        result={getPercentFormatted(item.total)}
                        displayRecomended={recomended === 0 ? 'none': 'flex'}
                        recomended={recomended}
                      />
                    </div>
                  )
              })
            }
          </>
          <LineDashedTwo linePercentTwo={linePercentTwo} />
          <LineDashed linePercent={linePercent} />
        </ColContent>
        <Line />
        <ColM>
        {/* {modulesTotal?.map((item, i) => {
          return(
            <>
              <h3>{formattedReport[i].module}</h3>
            </>
          )
        })} */}

          <h3>M1</h3>
          <h3>M2</h3>
          <h3>M3</h3>
          <h3>M4</h3>
          <h3>M5</h3>
          <h3>M6</h3>
          <h3 style={{marginLeft: -45}}>Revisão</h3>
        </ColM>

        <LegendChart
          displaySubtitle={displaySubtitle}
          subtitleMin={subtitleMin}
          subtitleRecommended={subtitleRecommended}
          positionSubtitle={positionSubtitle}
        />
      </Content>
    </>
  );
};

export default BartChartV;
