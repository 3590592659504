import { IGabarito } from '../../shared/Models/gabarito/IGabarito';

export function formattedResultGabaritos(payload: IGabarito[]) {
    return payload.map(gabarito => {
        if (gabarito.pre) return gabarito;
        
        return {
            ...gabarito,
            summary: `${gabarito.formattedYear} ${gabarito.formmatedMonth} ${gabarito.name}`,
        };
    });
};