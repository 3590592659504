import React from 'react';
import useDocumentSize from '../../../hooks/useDocumentSize';
import { useTheme } from 'styled-components';

import './style/validationError.scss';

type Props = {
    errors: Array<string> | undefined;
};

const ValidationError = ({ errors = [] }: Props) => {
    const size = useDocumentSize();
    const theme = useTheme();
    if (!errors || !errors.length) return false;

    return (
        <ul className={`checkout-validation-error checkout-validation-error-${size.width <= theme.media.tablet ? 'mobile' : 'web'}`}>
            {errors.map(error => (
                <li>{error}</li>
            ))}
        </ul>    
    );
};

export default ValidationError;