import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Container, PropsType } from './styles/PaginastionItem';

function PaginastionItem({
  current,
  answered,
  viewed,
  wrong,
  itemId,
  onClick,
  children,
}: PropsType) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <Container
      current={current}
      answered={answered}
      viewed={viewed}
      wrong={wrong}
      itemId={itemId}
      onClick={() => onClick(visibility)}
    >
      {children}
    </Container>
  );
}

export default PaginastionItem;
