import styled from 'styled-components';

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
        font-size: 1.25rem;
        color: #FFFDED;
        font-weight: 600;
        span {
            font-weight: 400;
            font-size: 1.375rem;
        }
    }
    h3 {
        color: #FFFDED;
        font-weight: 500;
    }

    margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    button {  
        cursor: pointer;
        border-radius: 4px;
        height: 45px; 
        border: none;
        
        & + button {
            margin-top: 1.8125rem;
        }

        svg {
            color: white;
        }

        &.warning {
            color: white;
            background-color: ${({ theme }) => theme.colors.warning};
        }
        
        transition: filter 0.2s;
        &:hover {
            filter: brightness(0.7);
        }
    }
    
    width: 480px;
    @media(max-width:720px) {
        width: 100%;
    }
`;
