import React from 'react';

import { Graph, Color, ColorSecondary, Total, Text, TextTotal, ContainerGraph } from './styles';

interface ChartCfpProps {
  classNumber?: number;
  percent?: number | string;
  percentText?: number | string;
  colorText?: string;
  colorbk0?: string;
  colorbk1?: string;
  colorbk2?: string;
  percentTwo?: number | string;
  ColorSecondary?: string;
  total?: any;
  displayAvaliable?: string;
  textAvaliableDate?: string;
  displaySubtitle?: string;
  subtitleMin?: string;
  subtitleRecommended?: string;
  positionSubtitle?: string;
  description?: string;
  textdescription?: string;
  positionPercent?: string;
  height?: string[];
  fontSize?: string[];
  widthGraph?: string;
  displaytextPercent?: string;
  zIndex?: number;
}

function ChartCFP({
  percent,
  percentText,
  percentTwo,
  colorText,
  colorbk0,
  colorbk1,
  colorbk2,
  total,
  displayAvaliable,
  textAvaliableDate,
  displaySubtitle,
  subtitleMin,
  subtitleRecommended,
  positionSubtitle,
  positionPercent,
  description,
  textdescription,
  height,
  fontSize,
  widthGraph,
  displaytextPercent,
  zIndex,
}: ChartCfpProps) {
  return (
    <ContainerGraph widthGraph={widthGraph}>
      <Graph colorbk0={colorbk0} height={height}>
        <Color
          percent={percent}
          zIndex={zIndex}
          colorbk1={colorbk1}
          positionPercent={positionPercent}
        >
          <Text colorText={colorText} displaytextPercent={displaytextPercent}>
            {percentText}
          </Text>
        </Color>
        <ColorSecondary percentTwo={percentTwo} colorbk2={colorbk2}>
          <p>{percentTwo}</p>
        </ColorSecondary>
        <Total percent={100}>
          <TextTotal colorText={colorText}>{total}</TextTotal>
        </Total>
      </Graph>
    </ContainerGraph>
  );
}

export default ChartCFP;
