
import React from 'react';

import {
  Container,
  Line,
  Graph,
  Yellow,
  Blue,
  Transparent,
  Legend,
  Min,
  Recomended,
} from './styles'

type Props = {
  percent: number;
}

const StaticGraph = ({ percent }: Props) => {
  return (
    <Container>
      <p><strong>Módulo 1:</strong> 54 questões</p>
      <Graph>
        <Yellow percent={50}><p>27 acertos • 50%</p></Yellow>
        <Blue percent={80}><p>43 acertos • 80%</p></Blue>
        <Transparent percent={100}><p>54</p></Transparent>
      </Graph>
      <Line />

      <p><strong>Módulo 2:</strong> 26 questões</p>
      <Graph>
        <Yellow percent={50}><p>13 acertos • 50%</p></Yellow>
        <Blue percent={75}><p>20 acertos • 75%</p></Blue>
        <Transparent percent={100}><p>26</p></Transparent>
      </Graph>
      <Line />

      <p><strong>Módulo 3:</strong> 16 questões</p>
      <Graph>
        <Yellow percent={50}><p>8 acertos • 50%</p></Yellow>
        <Blue percent={80}><p>13 acertos • 80%</p></Blue>
        <Transparent percent={100}><p>16</p></Transparent>
      </Graph>
      <Line />

      <p><strong>Módulo 4:</strong> 14 questões</p>
      <Graph>
        <Yellow percent={50}><p>7 acertos • 50%</p></Yellow>
        <Blue percent={80}><p>11 acertos • 80%</p></Blue>
        <Transparent percent={100}><p>14</p></Transparent>
      </Graph>
      <Line />

      <p><strong>Módulo 5:</strong> 16 questões</p>
      <Graph>
        <Yellow percent={50}><p>8 acertos • 50%</p></Yellow>
        <Blue percent={75}><p>12 acertos • 75%</p></Blue>
        <Transparent percent={100}><p>16</p></Transparent>
      </Graph>
      <Line />

      <p><strong>Módulo 6:</strong> 14 questões</p>
      <Graph>
        <Yellow percent={50}><p>7 acertos • 50%</p></Yellow>
        <Blue percent={75}><p>11 acertos • 75%</p></Blue>
        <Transparent percent={100}><p>14</p></Transparent>
      </Graph>
      <Line />

      <Legend>
        <Min>
          <div />
          <p>Míninimo para aprovação na Planejar</p>
        </Min>
        <Recomended>
          <div />
          <p>Recomendação Academia Rafael Toro nos simulados</p>
        </Recomended>
      </Legend>


    </Container>
  );
};

export default StaticGraph;
