import styled, { css } from 'styled-components';

export type PropsType = {
    current: boolean;
    answered: boolean | undefined;
    viewed: boolean | undefined;
    wrong: boolean;
    itemId: string;
    onClick: Function;
    children: React.ReactNode;
};

export const Container = styled.button<PropsType>`
    margin-right: 6px;
    border: 1px solid #A4A4A4;
    border-radius: 8px;
    width: 33px;
    height: 33px;
    background: none;
    font-size: 12px;
    color: #101010;

    ${({ current, answered, viewed, wrong }) => {
        if (current) { 
            return css`
                border-color: #5768FF;
                background: #5768FF;
                box-shadow: 0px 0px 10px rgba(87, 104, 255, 0.64);
                color: #fff;
            `;
        } else if (wrong) {
            return css`
                border-color: #D81E5B;
                background: #D81E5B;
                color: #fff;
            `;
        } else if (wrong !== undefined && !wrong) {
            return css`
                border-color: #62CF7A;
                background: #62CF7A;
                color: #fff;
            `;
        } else if (answered) {
            return css`
                border-color: #62CF7A;
                background: #62CF7A;
                color: #fff;
            `;
        } else if (viewed) {
            return css`
                border-color: #D81E5B;
                background: #D81E5B;
                color: #fff;
            `;
        }
    }}
`;