import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconDownloadStep: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="66"
      height="96"
      viewBox="0 0 66 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <path
      d="M31.5 0H34.5V15H31.5V0Z"
      fill="#F0F2FF"
    />
    <rect y="15" width="66" height="66" rx="33" fill="#F0F2FF"/>
    <path
      d="M48.4167 48V58.2C48.4756 59.5209 48.0088 60.8113 47.1187 61.789C46.2285 62.7666 44.9873 63.3519 43.6667 63.4167H22.3333C21.0127 63.3519 19.7715 62.7666 18.8813 61.789C17.9912 60.8113 17.5245 59.5209 17.5833 58.2V48"
      stroke={color || '#5768FF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33 32.5833V53"
      stroke={color || '#5768FF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33 53L39.6667 46.3333"
      stroke={color || '#5768FF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9999 53L26.3333 46.3333"
      stroke={color || '#5768FF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M31.5 81H34.5V96H31.5V81Z" fill="#F0F2FF" />
  </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconDownloadStep.defaultProps = defaultProps;

export default IconDownloadStep;
