import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/NewCheckout/Header';
import Footer from '../../../components/NewCheckout/Footer';

import { Container, Box } from './styles';

const ServiceTerms = () => {
    return (
        <>
            <Helmet>
                <title> Gabaritoro | Termos de serviço </title>
            </Helmet>
            <Header />
            <Container>
                <Box>
                    <h1>Termos e Condições de Uso</h1>
                    <h2>LEIA ATENTAMENTE OS TERMOS E CONDIÇÕES DE USO ANTES DE UTILIZAR O GABARITORO</h2>

                    <p className="first-paragraph">
                        Ao utilizar o “GABARITORO”, você concorda com os Termos e
                        Condições descritos abaixo. Se você não concordar, não use este
                        site/plataforma!
                    </p>

                    <p>
                        Ao aceitar estes termos e condições, você autoriza os dados pessoais
                        a serem coletados e processados pela empresa. O consentimento inclui a
                        aceitação das regras de uso da empresa e a conformidade com as
                        regulamentações atuais, exonerando esta de qualquer responsabilidade por uso
                        ilegal. Assim, autoriza o uso dos seus dados pessoais pela Academia Rafael Toro
                        e está ciente das políticas de uso de dados pessoais, disponível no site.
                    </p>

                    <p>
                        A Empresa Rafael Toro poderá revisar e atualizar estes Termos e
                        Condições a qualquer momento. Assim a utilização contínua desta
                        plataforma/site significará que você concorda essas alterações com seu aceite
                        quando ao uso e suas condições.
                    </p>

                    <p>
                        O <b>GABARITORO</b> traz informações atualizadas sobre temas
                        relacionados as provas de certificação do mercado financeiro, para que alunos
                        e o público em geral possam entender as informações com mais facilidade,
                        podendo estar cientes do quanto tiveram de acertos na realização do certame.
                    </p>

                    <p>
                        É importante ressaltar que o resultado do número de questões
                        porventura apresentadas, assim como informações, não substitui o gabarito
                        oficial da ANBIMA /PLANEJAR ou outra Instituição competente/responsável.
                    </p>


                    <h3>PROPÓSITO</h3>

                    <p>
                        Nosso objetivo é compartilhar informação de qualidade e de fácil
                        entendimento para nossos alunos e outros que vierem a usar a plataforma, além
                        de facilitar o controle estimando-se o número de acertos obtidos após a
                        realização da prova de certificação, com intuito informativo e de auxílio/apoio
                        educativo.
                   </p>

                    <p>
                        Dessa forma, além de trazer informações sobre o resultado de sua
                        prova, temos como o objetivo de mantê-lo/la informado/a sobre seus benefícios
                        e oportunidades como aluno da RAFAEL TORO ACADEMIA DE FINANÇAS, bem
                        como, atualizar nossos registros com alguns de seus dados de contato e solicitar
                        seu <b>consentimento para que possamos lhe enviar informações que sejam de seu
                        interesse, via e-mail, telefone, WhatsApp ou outro meio. E, é por isso que
                        grifamos, seu aceite aqui é entendido como autorização para tal contato.</b>
                    </p>

                    <p>
                        Em nosso compromisso com a sua privacidade, asseguramos que
                        estes dados serão utilizados por nossa empresa como um todo para a finalidade
                        aqui descrita e em estrita conformidade com os consentimentos assinalados por
                        você.
                    </p>

                    <p>
                        Reforçamos que a atualização dos seus dados é opcional.
                    </p>

                    <p>
                        Caso queira deixar de receber nossos anúncios, há disponível em
                        nossa plataforma GABARITORO a opção de <b>“PARAR DE RECEBER”</b>
                    </p>

                    <p>
                        Quando você usa nossas plataformas, está confiando a nós suas
                        informações. Entendemos que isso é uma grande responsabilidade e trabalhamos
                        duro para proteger essas informações e colocar você no controle.
                    </p>

                    <h3>CONFIDENCIALIDADE</h3>

                    <p>
                        Os dados coletados NÃO são vendidos ou compartilhados com
                        terceiros, salvo quando solicitado por lei. Leia nossa Política de Privacidade e
                        entenda como funciona através do link:
                        <a 
                            href="https://www.academiarafaeltoro.com.br/politica-de-privacidade/" 
                            target="_blank"
                            rel="noopener noreferrer">
                            https://www.academiarafaeltoro.com.br/politica-de-privacidade/
                        </a>
                    </p> 


                    <h3>O GABARITORO NÃO É UMA FERRAMENTA DE RESULTADO OFICIAL DE ACERTOS DE PROVA</h3>

                    <p>
                        O conteúdo do site e outros materiais contidos aqui, são apenas para
                        fins informativos, com o intuito de facilitar seu controle sobre o resultado de sua
                        prova e obter informações de apoio. Dessa forma, ele visa entregar uma
                        ESTIMATIVA de acertos, não se destinando, assim, a substituir o resultado oficial
                        da ANBIMA/PLANEJAR ou outra banca responsável. Logo, desde já, você
                        declara conhecimento de tal, isentando qualquer responsabilidade da
                        empresa, de qualquer natureza.
                    </p>


                    <h3>USO DO CONTEÚDO</h3>

                    <p>
                        O conteúdo publicado neste site é protegido pelas leis de direitos
                        autorais no Brasil e em países estrangeiros. O GABARITORO autoriza você a
                        visualizar ou baixar uma única cópia do conteúdo, exclusivamente para seu uso
                        pessoal e não comercial.
                    </p>

                    <p>
                        Qualquer uso do conteúdo não expressamente permitido por estes
                        Termos e Condições é uma violação a este termo e pode ferir direitos autorais,
                        marcas registradas e outras leis. O conteúdo e outros recursos estão sujeitos a
                        alterações ou rescisão sem aviso prévio.
                    </p>

                    <p>
                        Caso haja qualquer violação aos Termos e Condições, sua permissão
                        para usar o conteúdo termina automaticamente e você deve destruir
                        imediatamente quaisquer cópias que tenha feito de qualquer parte do
                        conteúdo.
                    </p>


                    <h3>CONTEÚDO E ENVIOS DO USUÁRIO</h3>

                    <p>
                        Os dados que você envia ao GABARITORO são regidos pela Política
                        de Privacidade do site. Na medida em que houver uma inconsistência entre este
                        Contrato e a Política de Privacidade, este Contrato prevalecerá.
                    </p>

                    <p>
                        O site contém funcionalidades e outras áreas interativas, incluindo
                        blogs, avaliações de usuários e informações sobre provas oriundas do mercado
                        financeiro, dentre outros, que permitem que os usuários publiquem ou
                        carreguem conteúdo e outras informações.
                    </p>

                    <p>
                        O GABARITORO se reserva o direito de remover o conteúdo do usuário
                        por qualquer motivo, incluindo o conteúdo do usuário que acreditamos violar
                        estes Termos e Condições ou nossas outras políticas.
                    </p>


                    <h3>SENHAS</h3>
                    <p>
                        O GABARITORO possui várias ferramentas que podem permitir gravar
                        e armazenar informações. Você é responsável por tomar todas as medidas
                        razoáveis para garantir que nenhuma pessoa não autorizada tenha acesso às
                        suas senhas ou contas. É de sua exclusiva responsabilidade: (1) controlar a
                        disseminação e o uso do nome de login, nome de tela e senhas; (2) autorizar,
                        monitorar e controlar o acesso e o uso de sua conta e senha Minuto Saudável;
                        (3) informar prontamente a RAFAEL TORO ACADEMIA DE FINANÇAS se você
                        acredita que sua conta ou senha foi comprometida ou se houver algum outro
                        motivo pelo qual você precise desativar uma senha. Para nos enviar um e-mail,
                        use os links “Fale Conosco” localizados na parte inferior de cada página do nosso
                        site. Você concede à RAFAEL TORO ACADEMIA DE FINANÇAS e a todas as outras
                        pessoas ou entidades envolvidas na operação do site o direito de transmitir,
                        monitorar, recuperar, armazenar e usar suas informações em conexão com a
                        operação do Site. A RAFAEL TORO ACADEMIA DE FINANÇAS não pode e não
                        assume qualquer responsabilidade por qualquer informação que você enviar,
                        ou pelo uso ou uso indevido de informações transmitidas ou recebidas usando
                        ferramentas e serviços por você ou terceiros.
                    </p>


                    <h3>DIRETRIZES DE CONTEÚDO DO USUÁRIO</h3>

                    <p>
                        Se você usar ou publicar conteúdo do usuário em uma Área Pública,
                        como blogs ou publicar em qualquer Plataforma de Mídia Social, você é o único
                        responsável por suas próprias comunicações, pelas consequências de postar
                        essas comunicações e por sua confiança em quaisquer comunicações
                        encontradas nas Áreas Públicas ou nas Plataformas de Mídia Social.
                    </p>

                    <p>
                        Em consideração ao fato de ter permissão para usar as Áreas Públicas
                        e Plataformas de Mídia Social, você concorda que as seguintes ações
                        constituirão uma violação material destes Termos e Condições:
                    </p>

                    <p>
                        1. Usar uma Área Pública ou Plataforma de Mídia Social
                        para qualquer finalidade que viole as leis locais,
                        estaduais, nacionais ou internacionais;
                    </p>

                    <p>
                        2. Usar uma Área Pública ou Plataforma de Mídia Social
                        para qualquer finalidade que viole as leis locais,
                        estaduais, nacionais ou internacionais;
                    </p>

                    <p>
                        3. Publicar material ilegal, obsceno, difamatório,
                        ameaçador, assediador, abusivo, calunioso, odioso ou
                        embaraçoso para qualquer outra pessoa ou entidade,
                        conforme determinado pela RAFAEL TORO ACADEMIA
                        DE FINANÇAS, a seu exclusivo critério;
                    </p>

                    <p>
                        4. Publicar anúncios ou solicitações de negócios;
                    </p>

                    <p>
                        5. Depois de receber um aviso, continuar a interromper o
                        fluxo normal de diálogo ou postar comentários que
                        não estejam relacionados ao tópico que está sendo
                        discutido (a menos que esteja claro que a discussão é
                        de forma livre);
                    </p>

                    <p>
                        6. Postar correntes ou esquemas de pirâmide;
                    </p>

                    <p>
                        7. Representar outra pessoa;
                    </p>

                    <p>
                        8. Distribuir vírus ou outro código de computador prejudicial;
                    </p>

                    <p>
                        9. Coletar, raspar ou coletar informações sobre outras
                        pessoas, incluindo endereços de e-mail, sem sua
                        identificação para postar ou visualizar comentários;
                    </p>

                    <p>
                        10. Permitir que qualquer outra pessoa ou entidade
                        use sua identificação para postar ou visualizar
                        comentários
                    </p>

                    <p>
                        11. Publicar a mesma nota mais de uma vez ou “spam”;
                        ou
                    </p>
                    

                    <p>
                        Envolver-se em qualquer outra conduta que restrinja
                        ou iniba qualquer outra pessoa de usar ou desfrutar da
                        Área Pública, Plataforma de Mídia Social ou do Site, ou
                        que, no julgamento da empresa, exponha a RAFAEL
                        TORO ACADEMIA DE FINANÇAS ou qualquer um de
                        seus clientes ou fornecedores a qualquer
                        responsabilidade ou prejuízo de qualquer tipo.
                    </p>

                    <p>
                        A RAFAEL TORO ACADEMIA DE FINANÇAS se reserva o direito (mas não é obrigada) de fazer qualquer um ou todos os seguintes:
                    </p>

                    <p>
                        1. Gravar eventual diálogo em salas de bate-papo públicas.
                    </p>

                    <p>
                        2. Investigar alegação de que uma(s)
                        comunicação(ões) não está(m) em conformidade
                        com os termos desta seção e determine, a seu
                        exclusivo critério, remover ou solicitar a remoção da(s)
                        comunicação(ões).
                    </p>

                    <p>
                        3. Remover comunicações que sejam abusivas, ilegais
                        ou disruptivas, ou que de outra forma não estejam em
                        conformidade com estes Termos e Condições.
                    </p>

                    <p>
                        4. Encerrar eventual acesso de um usuário a qualquer ou
                        todas as Áreas Públicas e/ou ao RAFAEL TORO
                        ACADEMIA DE FINANÇAS em caso de violação destes
                        Termos e Condições.
                    </p>

                    <p>
                        5. Monitorar, editar ou divulgar qualquer comunicação
                        nas Áreas Públicas ou em uma Plataforma de Mídia
                        Social.
                    </p>

                    <p>
                        6. Editar ou excluir qualquer comunicação(ões)
                        publicada(s) no site RAFAEL TORO ACADEMIA DE
                        FINANÇAS ou no GABARITORO, independentemente
                        de tais comunicações violarem esses padrões.
                    </p>

                    <h3>JURISDIÇÃO</h3>
                    <p>
                        Você concorda expressamente que a jurisdição exclusiva para
                        qualquer disputa com o RAFAEL TORO ACADEMIA DE FINANÇAS, ou de alguma
                        forma relacionada ao seu uso do site RAFAEL TORO ACADEMIA DE FINANÇAS,
                        reside no foro de Porto Alegre, Estado do Rio Grande do Sul e você concorda
                        expressamente com o exercício da jurisdição supra definida em conexão com
                        qualquer disputa, incluindo qualquer reivindicação envolvendo a RAFAEL TORO
                        ACADEMIA DE FINANÇAS ou suas afiliadas, subsidiárias, funcionários,
                        contratados, executivos, diretores, provedores de telecomunicações e
                        provedores de conteúdo.
                    </p>

                    <p>
                        Estes Termos e Condições são regidos pelas leis substantivas internas
                        do Brasil, sem respeito aos seus princípios de conflito de leis. Se qualquer
                        disposição destes Termos e Condições for considerada inválida por qualquer
                        tribunal com jurisdição competente, a invalidade de tal disposição não afetará
                        a validade das demais disposições destes Termos e Condições, que
                        permanecerão em pleno vigor e efeito. Nenhuma renúncia a qualquer um
                        destes Termos e Condições será considerada uma renúncia adicional ou
                        contínua de tal termo ou condição ou qualquer outro termo ou condição.
                    </p>

                    <p>
                        O agente da RAFAEL TORO ACADEMIA DE FINANÇAS para questões
                        de direitos autorais relacionadas a este site é o seguinte:
                    </p>


                    <strong>RAFAEL TORO ACADEMIA DE FINANÇAS</strong>
                    <strong>Escritório Jurídico</strong>
                    <strong>Rua Guararapes, 95/09,</strong>
                    <strong>Petrópolis, na Cidade de Porto Alegre/RS</strong>
                    <strong>juridico@academiarafaeltoro.com.br</strong>
                    <strong>Ou ligue para: (51) 98321.9951</strong>

                    <p>
                        Em um esforço para proteger os direitos dos proprietários de direitos
                        autorais, a Rafael Toro Academia de Finanças mantém uma política para o
                        término, em circunstâncias apropriadas, de assinantes e titulares de contas do
                        site que são infratores reincidentes.
                    </p>

                    <p>
                    Ao usar o método de registro on-line, coletaremos um mínimo de informações suas.
                    </p>

                    <p>
                        Os dados pessoais coletados serão mantidos por um período
                        permitido por Lei. Os dados pessoais podem estar disponíveis para os funcionários
                        da empresa e equipe de suporte de terceiros para fins de suporte técnico e
                        assistência, bem como para cumprir as obrigações legais relevantes, forças de
                        segurança, autoridades públicas ou judiciais, se possível infração.
                    </p>


                    <h3>ACORDO COMPLETO</h3>

                    <p>
                        Fazem parte integrante do presente, os demais termos constantes no
                        Site RAFAEL TORO ACADEMIA DE FINANÇAS, conforme link abaixo: 
                    <a 
                        href="https://www.academiarafaeltoro.com.br/termos-de-uso/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        https://www.academiarafaeltoro.com.br/termos-de-uso/
                    </a>
                    
                    <a 
                       target="_blank"
                       rel="noopener noreferrer"
                       href="https://www.academiarafaeltoro.com.br/direitos-autorais/"
                    >
                            https://www.academiarafaeltoro.com.br/direitos-autorais/
                    </a>
                            
                        Assim, todos constituem o acordo integral entre você e a RAFAEL
                        TORO ACADEMIA DE FINANÇAS – plataforma GABARITORO com relação ao uso
                        do site/plataforma e seu conteúdo.
                    </p>

                    <p>
                        Obrigado pela sua cooperação. Esperamos que você ache o
                        GABARITORO útil e conveniente de usar! Perguntas ou comentários, incluindo
                        quaisquer relatórios de links que não funcionem, devem ser enviados usando
                        nosso Formulário Fale Conosco e outro meio de comunicação disponível e
                        indicado.
                    </p>

                    <p className="last-paragraph">
                        Esclarecemos que todo usuário tem o direito de solicitar acesso a
                        dados pessoais, solicitar a correção ou exclusão, solicitar a limitação de seu uso,
                        se opor ao seu uso e / ou ao direito de portabilidade. Você pode exercer esses direitos nos contatando, através dos nossos meios de comunicação:
                        <br/>
                        contato@academiarafaeltoro.com.br | 51 98321.9951
                    </p>
                </Box>
            </Container>
            <Footer/>
        </>
    );
};

export default ServiceTerms;