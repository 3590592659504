import styled from 'styled-components';

//base converssao vw 2840px

type Props = {
  percent?: number | string;
  colorbk1?: string;
  x?: string;
  positionPercent?: string;
  zIndex?: number;
}
type PropsContainer = {
  widthGraph?: string;
}

type PropsSecundary = {
  percentTwo?: number | string;
  colorbk2?: string;
}
type PropsTitle = {
  displayTitle?: string;
  displayAvaliable?: string;
  displayLegend?: string;
  colorbk0?: string;
  colorText?: string;
  height?: string[];
  fontSize?: string[];
  displaytextPercent?: string;
}
type PropsDescription = {
  description?: string;
}

export const Graph = styled.div<PropsTitle>`
    z-index: 1;
    position: relative;
    width: 100%;
    border-radius: 0px 10px 10px 0px;
    background: ${({ colorbk0 }) => colorbk0} !important;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    P {
        display: contents;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 1.760vw;
        line-height: 3.447vw;
        color: #FFFFFF;
    }

    @media (min-width: 1366px) {
      height: 18px;
    }
    @media (min-width: 767px) and (max-width: 1365px) {
      height: 22px;
    }
    @media (max-width: 767px) {
      height: 22px;
    }
`;
export const Text = styled.div<PropsTitle>`
    display: contents;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 3.447vw;
    color: ${({ colorText }) => colorText} !important;
    display: ${({ displaytextPercent }) => displaytextPercent} !important;

    color: #FFFFFF;

    @media (min-width: 767px) and (max-width: 1365px) {
      font-size: 12px;
      line-height: 4.447vw;
      max-height: 18px;
    }

    @media (max-width: 767px) {
      font-size: 7px;
      line-height: 4.447vw;
    }
`;
export const Title = styled.div<PropsTitle>`
    display: ${({ displayTitle }) => displayTitle};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.760vw;
    line-height: 3.447vw;
    color: #434343;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 1vw;
`;
export const Color = styled.div<Props>`
    z-index: ${({ zIndex }) => zIndex};
    display: flex;
    align-items: center;

    justify-content: ${({ positionPercent }) => positionPercent} !important;
    justify-content: flex-end;
    height: 100%;

    width: ${({ percent }) => percent}%;
    background: ${({ colorbk1 }) => colorbk1};
    border-radius: 0px 10px 10px 0px;
    padding: 0px 0.4vw 0 1.9vw;
    margin-left: -0.1%;
`;
export const ColorSecondary = styled.div<PropsSecundary>`
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    width: ${({ percentTwo }) => percentTwo};
    background: ${({ colorbk2 }) => colorbk2};
    border-radius: 0px 10px 10px 0px;
    padding: 0 1.408vw;
`;

export const Total = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: ${({ percent }) => percent}%;
    padding: 0 1.408vw;
    height: 100%;
    z-index: 0;
`;
export const TextTotal = styled.div<PropsTitle>`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 4.447vw;
    color: ${({ colorText }) => colorText} !important;
    color: #858585;
    z-index: 9;

    @media (min-width: 767px) and (max-width: 1365px) {
      font-size: 10px;
      text-align: right;
    }

    @media (max-width: 767px) {
      font-size: 7px;
      max-width: 60vw;
      text-align: right;
    }
`;
export const AvaliableDate = styled.div<PropsTitle>`
    width: 80vw;
    display: ${({ displayAvaliable }) => displayAvaliable};
    margin-top: 2vw;
    justify-content: flex-end;
    p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 1.056vw;
        line-height: 1.232vw;
        text-align: end;
        color: #434343;
        justify-content: flex-end;
        margin-bottom: 0px;
    }
`;

export const Description = styled.div<PropsDescription>`

    display: ${({ description }) => description};
    margin: 1vw 0;
    p {
        opacity: 0.8;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 1.411vw;
        line-height: 1.653vw;
        color: #000000;

        @media (min-width: 767px) and (max-width: 1365px) {
          font-size: 20px !important;
          padding: 10px 0 20px;
        }

        @media (max-width: 767px) {
          font-size: 16px !important;
          padding: 8px 0 16px;
        }
    }
`;

export const ContainerGraph = styled.div<PropsContainer>`
    width: 100%;
    width: ${({ widthGraph }) => widthGraph};

    @media (max-width: 1365px) {
      width: 100%;
    }
`;
