import React from 'react';

import { Container } from './styles';



type Props = {
  percentage: number;
  radius: number;
  strokeWidth: number;
  colorBackground: string;
  colorGraph: string;
  textColor: string;
  max: number;
};

export default function ({
  percentage = 75,
  radius,
  strokeWidth = 10,
  colorBackground,
  colorGraph,
  textColor,
  max = 100
}: Props) {
  const circumference = 2 * Math.PI * radius;
  const halfCircle = radius + strokeWidth;


  const maxPerc = 100 * percentage / max;
  const strokeDashoffset = circumference - (circumference * maxPerc) / 100;


  return (
    <Container
      radius={radius}
      textColor={textColor}
    >
      <div className='graph'>
        <svg
          height={radius * 2}
          width={radius * 2}
          viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}
          transform='rotate(-90)'
        >
          <g>
            <circle
              cx="50%"
              cy="50%"
              r={radius}
              fill="transparent"
              stroke={colorBackground}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
            />
            <circle
              cx="50%"
              cy="50%"
              r={radius}
              fill="transparent"
              stroke={colorGraph}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              strokeDashoffset={strokeDashoffset}
              strokeDasharray={circumference}
            />
          </g>
        </svg>
        <span className='text'>{percentage}%</span>
      </div>
    </Container>
  )
};
