import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import { actions as studentActions } from '../../../../../redux/modules/student';
import config from '../../../../../config';
import { useCourseDetail } from '../../../../../hooks/useCourseDetail';

import { IReportCFP } from '../../../../../shared/Models/IReportCFP';

import ChartCFP from '../../../../../components/ChartCFP';

import { handleChartCFP } from '../../../../../redux/api/student';

import Select from '../../../../../components/SelectReportCFP';
import CircleChartReportCFP from '../../../../../components/CircleChartReportCFP';
import BarChartReportCfp from '../../../../../components/BarChartReportCfp';
import GraphBarReportCFP from '../../../../../components/GraphBarReportCFP';

import { getPercentFormatted } from '../../../../../shared/utils/common';

import PerformanceCFPNotExist from '../../../../../components/PerformanceCFPNotExist';

import {
  Container,
  TitleRow,
  TitleColumn,
  ChartsRow,
  ScoreToroColumn,
  PerformanceChart,
  TitleField,
  ScoreToroChart,
  CircleChartBox,
  BarChartBox,
  GrayLine,
  PerformanceTitle,
  PerformanceBox,
  PerformancePerClass,
  PerformancePerModule,
  PerModuleBox,
  GraphBarBox,
  DashedTop
} from './styles';

type StudentStateType = {
  student: {
    isFetchingReportCFP: boolean;
    isTrackingDownloadReport: boolean;
    reportCFP: Array<IReportCFP>;
  },
};

type TCourseState = {
  course: {
    id: number;
    id_turma: number;
    data_inicio_aulas: string;
    data_fim_aulas: string;
  }
}

type TAuthState = {
  auth: {
    user: {
      id_profile: number;
    }
  }
}

type TPerformancePerClass = {
  titulo: string;
  porcentagem: number;
  cor: string;
  texto: string,
  peso: number
};

type TModulePerformance = {
  module: string;
  tipo_prova: string;
  total: number;
}

type TTest = {
  prova: number;
}

type TUserPerformance = {
  performance_per_class: TPerformancePerClass[];
  average: {
    acertos_avg: number;
  };
  test: {
    done: Array<TTest>;
    to_be_done: Array<TTest>
  };
  score: {
    score: number;
  };
  module_performance: TModulePerformance[];
}

const defaultValueChartCFP = {
  performance_per_class: [
    {
      titulo: '',
      porcentagem: 0,
      cor: '',
      texto: '',
      peso: 0
    }
  ],
  average: {
    acertos_avg: 0,
  },
  test: {
    done: [
      {
        prova: 0
      }
    ],
    to_be_done: [
      {
        prova: 0
      }
    ]
  },
  score: {
    score: 0
  },
  module_performance: [
    {
      module: '',
      tipo_prova: '',
      total: 0
    }
  ]
}

export default function ReportCFP() {
  const {
    reportCFP,
    isFetchingReportCFP,
  } = useSelector((state: StudentStateType) => state.student);

  const { id, id_turma, data_inicio_aulas, data_fim_aulas } = useSelector((state: TCourseState) => state.course);

  const userId = useSelector((state: TAuthState) => state.auth.user.id_profile);

  const [chartCFP, setChartCFP] = useState<TUserPerformance>();
  const [selectValue, setSelectValue] = useState('');


  const { course } = useCourseDetail();


  const dispatch = useDispatch();

  // Set first value in select
  useEffect(() => {
    setSelectValue(reportCFP[0]?.date);
  }, [isFetchingReportCFP, reportCFP])

  // Set performance chart values
  useEffect(() => {
    const result: any = handleChartCFP(userId, id, id_turma, data_inicio_aulas, data_fim_aulas);

    result.then((result: TUserPerformance) => {
      if (result?.performance_per_class?.length > 0) setChartCFP(result);
      else setChartCFP(defaultValueChartCFP);
    });
  }, [data_fim_aulas, data_inicio_aulas, id, id_turma, userId]);

  function handleDownloadReport(date: string) {
    const result = reportCFP.find((item) => item.date === date);

    dispatch(studentActions.trackDownloadReport({
      file_path: result?.url,
      file_date: date,
      id_course: course.id,
      id_course_class: course.id_turma
    }));

    window.open(`${config.s3Url}${result?.url}`, '_blank');
  };

  const formattedMonth = (month: number) => {
    switch (month) {
      case 1:
        return 'janeiro';
      case 2:
        return 'fevereiro';
      case 3:
        return 'março';
      case 4:
        return 'abril';
      case 5:
        return 'maio';
      case 6:
        return 'junho';
      case 7:
        return 'julho';
      case 8:
        return 'agosto';
      case 9:
        return 'setembro';
      case 10:
        return 'outubro';
      case 11:
        return 'novembro';
      case 12:
        return 'dezembro';
      default:
        return '';
    }
  }

  const formattedDay = (day: number) => {
    if (day <= 9)
      return "0" + day;
    else
      return day;
  }

  const selectArrayFiltered = reportCFP.map((item) => {
    const date = new Date(item.date + 'T12:00:00-03:00');

    const month = formattedMonth(date.getMonth() + 1);

    const day = formattedDay(date.getDate());

    const formattedText = `${day} de ${month} de ${date.getFullYear()}`
    return (
      {
        value: item.date,
        text: formattedText,
        download: true
      }
    )
  })

  function compare(a: TModulePerformance, b: TModulePerformance) {
    if (a.module < b.module) {
      return -1;
    }
    if (a.module > b.module) {
      return 1;
    }
    return 0;
  }

  const filteredModules = chartCFP?.module_performance.filter((item) => item.tipo_prova === "AULA");

  if (filteredModules?.length !== 6) {
    for (let i = 1; i <= 6; i++) {
      if (!filteredModules?.find((item) => item.module === `M${i}`)) {
        const newItem = {
          "module": `M${i}`,
          "tipo_prova": "AULA",
          "total": 0

        }
        filteredModules?.push(newItem);
      }
    }

    filteredModules?.sort(compare);
  }


  let percent: number = 0;

  if (chartCFP?.test.done && chartCFP?.test.to_be_done) {
    const done: number = chartCFP?.test.done[0].prova;
    const toBeDone: number = chartCFP?.test.to_be_done[0].prova;

    percent = Number(done / toBeDone)
  }

  const selectArrayDefault = [{ value: '', text: 'Não há relatórios', download: false }];

  return (
    <Container>
      <TitleRow>
        <TitleColumn>
          <TitleField>
            <div className='gray-box'>
              Curso
            </div>
            <span className='course-title'>
              {course.title}
            </span>
          </TitleField>
          <Select
            title='Relatórios semanais'
            value={selectValue}
            optionArray={!isFetchingReportCFP && selectArrayFiltered.length > 0 ? selectArrayFiltered : selectArrayDefault}
            onChange={(e) => setSelectValue(e.target.value)}
            onClick={() => handleDownloadReport(selectValue)}
          />
        </TitleColumn>
      </TitleRow>
      {chartCFP === undefined ?
        (
          <div className='spin'>
            <Spin />
          </div>
        )
        :
        (
          <div className='report-cfp-div'>

            {
              chartCFP?.performance_per_class[0].titulo !== '' ?
                <div className='chart-cfp-div'>

                  <ChartsRow>
                    <ScoreToroColumn span={14}>
                      <ScoreToroChart>
                        <CircleChartBox>
                          <span className='circle-chartbox-title'>
                            Média geral
                          </span>
                          <CircleChartReportCFP
                            percentage={getPercentFormatted(chartCFP?.average.acertos_avg)}
                            radius={52}
                            strokeWidth={20}
                            colorBackground='#EFEFEF'
                            colorGraph='#F89C3A'
                            textColor='#252525'
                            max={100}
                          />
                        </CircleChartBox>
                        <GrayLine>
                          <svg width="184" height="1" viewBox="0 0 184 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line y1="0.5" x2="184" y2="0.5" stroke="#EFEFEF" />
                          </svg>
                        </GrayLine>
                        <BarChartBox>
                          <span className='bar-chartbox'>
                            Provas feitas
                          </span>
                          <h3>{(chartCFP?.test?.done !== undefined) ? `${chartCFP?.test.done[0].prova}/${chartCFP?.test.to_be_done[0].prova}` : '0/0'}</h3>
                          <BarChartReportCfp
                            percent={getPercentFormatted(percent)}
                            showPercent={false}
                          />
                        </BarChartBox>
                        <GrayLine>
                          <svg width="184" height="1" viewBox="0 0 184 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line y1="0.5" x2="184" y2="0.5" stroke="#EFEFEF" />
                          </svg>
                        </GrayLine>
                        <CircleChartBox>
                          <span className='circle-chartbox'>
                            Score Toro
                          </span>
                          <CircleChartReportCFP
                            percentage={getPercentFormatted(chartCFP?.score.score)}
                            radius={52}
                            strokeWidth={20}
                            colorBackground='#EFEFEF'
                            colorGraph='#F89C3A'
                            textColor='#252525'
                            max={100}
                          />
                        </CircleChartBox>
                      </ScoreToroChart>
                      <PerformancePerModule>
                        <PerModuleBox>
                          <span className='module-title'>
                            Desempenho por módulo
                          </span>
                          <span className='module-subtitle'>
                            Média de acertos por módulo
                          </span>
                          <GraphBarBox>
                            <DashedTop />
                            {
                              filteredModules?.map(({ total, module }) => {
                                return (
                                  <GraphBarReportCFP
                                    result={getPercentFormatted(total)}
                                    subtitle={module}
                                  />
                                )
                              })
                            }
                          </GraphBarBox>
                        </PerModuleBox>
                      </PerformancePerModule>
                    </ScoreToroColumn>
                    <PerformanceChart span={9}>
                      <PerformanceBox>
                        <PerformanceTitle>
                          <span className='performance-title'>
                            Desempenho em aulas
                          </span>
                          <span className='performance-subtitle'>
                            Questões acertadas
                          </span>
                        </PerformanceTitle>
                        <PerformancePerClass>
                          {
                            chartCFP?.performance_per_class.map((item) => {
                              return (
                                <ChartCFP
                                  zIndex={0}
                                  displaySubtitle={'none'}
                                  displayAvaliable={"none"}
                                  classNumber={5}
                                  percentText={`${Math.round(item.porcentagem)}%`}
                                  percent={Math.round(item.porcentagem)}
                                  total={item.titulo}
                                  positionPercent={'start'}
                                  colorbk1={item.cor}
                                  colorText={'#000000'}
                                  description={"flex"}
                                  textdescription={item.texto}
                                  height={['18px', '20px', '22px']}
                                  widthGraph={"100%"}
                                />
                              )
                            })
                          }
                        </PerformancePerClass>
                      </PerformanceBox>
                    </PerformanceChart>
                  </ChartsRow>
                </div>
                :
                <PerformanceCFPNotExist />
            }

          </div>
        )
      }
    </Container>
  )
};
