import React from 'react';
import { useSelector } from 'react-redux';
import useDocumentSize from '../../../hooks/useDocumentSize';
import { useGabaritoro } from '../../../hooks/useGabaritoro';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';
import { IQuestion } from '../../../shared/Models/gabarito/IQuestion';
import Question from '../Question';
import Status from '../Status';
import ShareButton from '../../ShareButton';

import {
  Container,
  ModuleDescription,
  Divider,
  BoxTable,
  Header,
  WrapperStatus,
  Status as DescriptionStatus,
  DescriptionPercent,
} from './styles';

import { Props, UserProps } from './types';

const Module = ({ module, current }: Props) => {
  const { validation, userAnswers, startedValidationQuestion } = useGabaritoro();
  const { gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito);
  const useSize = useDocumentSize();
  const {
    auth: { token },
  } = useSelector((state: UserProps) => state);

  const formattedQuestions = (questions: IQuestion[]): IQuestion[] => {
    if (validation && !(gabarito.filled && gabarito.student_filled)) {
      const validateQuestions = questions.map((question) => {
        if (module.number === current + 1 && question.number <= startedValidationQuestion) {
          const answered = userAnswers.find(
            (answer) => answer.id_gabarito_question === question.id_gabarito_question
          );
          if (answered) {
            return question;
          } else {
            return {
              ...question,
              error: true,
            };
          }
        } else {
          return question;
        }
      });

      return validateQuestions;
    }

    return questions;
  };

  return (
    <Container>
      {gabarito.filled && gabarito.student_filled && (
        <>
          {module.number === 1 && gabarito.gabarito_type === 'FULL' && (
            <WrapperStatus approved={gabarito.approved}>
              <Status approved={gabarito.approved} />
              <span>{gabarito.percent_correct}% da prova</span>
              <span>
                {gabarito.total_correct}/{gabarito.total_questions} acertos
              </span>
              {token && gabarito.approved && (
                <ShareButton
                  token={token}
                  isStudent={true}
                  type={gabarito.gabarito_type === 'FULL' ? 'completa' : 'modular'}
                  hits={gabarito.total_correct}
                />
              )}
            </WrapperStatus>
          )}

          {
            <>
              {gabarito.gabarito_type === 'MODULE' ? (
                module.approved || module.total_correct >= module.minimum_module_hit ? (
                  <>
                    <DescriptionStatus approved={module.approved} module={module.number}>
                      <h1>
                        Aprovado ({module.percent_correct}% - {module.total_correct} acertos)
                      </h1>
                    </DescriptionStatus>
                    {token && (
                      <ShareButton
                        token={token}
                        isStudent={true}
                        type="modular"
                        hits={module.total_correct}
                      />
                    )}
                  </>
                ) : (
                  <DescriptionStatus approved={module.approved} module={module.number}>
                    <h1>
                      Reprovado ({module.percent_correct}% - {module.total_correct} acertos)
                    </h1>
                  </DescriptionStatus>
                )
              ) : (
                <DescriptionPercent number={module.number}>
                  Você acertou {module.percent_correct}% do módulo
                </DescriptionPercent>
              )}
            </>
          }
        </>
      )}

      <ModuleDescription>{module.description}</ModuleDescription>

      <BoxTable>
        {formattedQuestions(module.questions ?? []).map((question, index) => {
          if (index % 10 === 0 || index === 0) {
            return (
              <div key={question.id_gabarito_question}>
                <Divider />
                <Header>
                  <span className="question">{useSize.width <= 720 ? 'Ques.' : 'Questão'}</span>
                  <span className="letter">A</span>
                  <span className="letter">B</span>
                  <span className="letter">C</span>
                  <span className="letter">D</span>
                  {gabarito.student_filled && gabarito.filled && <span className="letter" />}
                </Header>
                <Question key={question.id_gabarito_question} question={question} />
              </div>
            );
          }

          return <Question key={question.id_gabarito_question} question={question} />;
        })}
      </BoxTable>
      <Divider />
    </Container>
  );
};

export default Module;