import React from 'react';

import { ReactComponent as TimeSVG } from '../../../../img/podcast/Time.svg';

import { Container } from  './styles';

export default function Header () {
    return (
        <Container>
            <span className="description">Título</span>
            <span className="class">Aula</span>
            <span className="time">
                <TimeSVG 
                    width="20px"
                    height="20px"
                />
            </span>
        </Container>
    );
};