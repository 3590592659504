import styled from 'styled-components';
import { Col } from 'antd';

//base vw 2840px

type PropsTitle = {
    displayTitle?: string;
    displayAvaliable?: string;
    displaySubtitle?: string;
    positionSubtitle?: string;
}

type Props = {
    colorOne?: string;
    colorTwo?: string;
}
export const ColSubtitle = styled(Col)<PropsTitle>` 
    display: ${({ displaySubtitle }) => displaySubtitle};
    width: 100%;
    justify-content: ${({ positionSubtitle }) => positionSubtitle};
`;
export const Subtitle = styled.div` 
    display: flex;
    /* margin-top: 2vw; */
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.267vw; //36
        line-height: 1.549vw; //44
        color: #636363;
        display: contents;
    }
`;
export const Min = styled.div<Props>` 
    display: flex;
    align-items: center;
    div {
        width: 2.640vw; //75
        height: 2.640vw; //75
        background: ${({ colorOne }) => colorOne} !important;
        background: #F89C3A;
        border-radius: 50%;
        margin-right: 1.584vw; //45
    }
    margin-right: 3.873vw; //110
`;
export const Recomended = styled.div<Props>` 
    display: flex;
    align-items: center;
    div {
        width: 2.640vw; //75
        height: 2.640vw; //75
        background: ${({ colorTwo }) => colorTwo} !important;
        background: #000A66;
        border-radius: 50%;
        margin-right: 1.584vw; //45
    }
`;