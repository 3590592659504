import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconVideoStepDone: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.5 0H34.5V15H31.5V0Z" fill="#5768FF" />
      <rect y="15" width="66" height="66" rx="33" fill="#5768FF" />
      <path d="M34.934 59.7997H23.3673C20.1673 59.7997 17.584 57.2164 17.584 54.0164V48.233C17.584 45.033 20.1673 42.4497 23.3673 42.4497H34.934C38.134 42.4497 40.7173 45.033 40.7173 48.233V54.0164C40.7006 57.2164 38.1173 59.7997 34.934 59.7997Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M40.7012 54.9831L45.8012 56.8998C47.0678 57.3665 48.4012 56.4331 48.4012 55.0998V47.1665C48.4012 45.8165 47.0512 44.8831 45.8012 45.3665L40.7012 47.2831V54.9831Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24.1505 40.9666C26.5161 40.9666 28.4339 39.0489 28.4339 36.6832C28.4339 34.3176 26.5161 32.3999 24.1505 32.3999C21.7849 32.3999 19.8672 34.3176 19.8672 36.6832C19.8672 39.0489 21.7849 40.9666 24.1505 40.9666Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M34.1505 40.9666C36.5161 40.9666 38.4339 39.0489 38.4339 36.6832C38.4339 34.3176 36.5161 32.3999 34.1505 32.3999C31.7849 32.3999 29.8672 34.3176 29.8672 36.6832C29.8672 39.0489 31.7849 40.9666 34.1505 40.9666Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M31.5 81H34.5V96H31.5V81Z" fill="#5768FF" />
    </svg>

  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconVideoStepDone.defaultProps = defaultProps;

export default IconVideoStepDone;
