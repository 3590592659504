//eslint-disable-next-line
import React, { ButtonHTMLAttributes } from 'react';

import { ButtonSubmitComponent, ButtonSubmitProps } from './styles';

interface ButtonSubmit extends ButtonHTMLAttributes<HTMLButtonElement>, ButtonSubmitProps {
  disable?: boolean;
  label: string;
}

export function ButtonSubmit({ disable, label, margin, ...rest }: ButtonSubmit) {
  return (
    <ButtonSubmitComponent margin={margin} type="submit" disabled={disable} {...rest}>
      {label}
    </ButtonSubmitComponent>
  );
}
