import styled from 'styled-components';
import { Row, Layout, Col } from 'antd';

//base converssao vw 2840px

export const Container = styled(Layout)`
  background: rgba(196, 196, 196, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4.861vw 4vw 6.944vw; //70px 100px 100px
`;

export const Header = styled(Row)` 
  background: linear-gradient(96.64deg, #222B82 26.19%, #000A66 102.4%);
  width: 100%;
  height: 9.718vw; //276px

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextHeader = styled.div` 
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw; //40
  line-height: 2.288vw; //65

  text-align: justify;
  color: #434343;
  margin-bottom: 3vw;
`;

export const Img = styled.img`
  width: 17.605vw; //500px
  height: 4.225vw; //120px
`;

export const ImgLogo = styled.img`
  width: 6.520vw; //185.17px
  height: 6.338vw; //180px
`;

export const NameRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  margin-bottom: 2.112vw; //60

  h1 {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 2.816vw; //80px
    line-height: 3.450vw;//98px
    color: #000A66;
    margin-bottom: 1vw;
  }
  p{
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 2.816vw;
    line-height: 3.450vw;

    display: flex;
    align-items: center;

    color: #000A66;
  }
`;
export const TextOne = styled.div` 
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.784vw; //45
    line-height: 2.288vw; //65
    text-align: justify;
    color: #434343;
    margin-bottom: 1.760vw; //50px
  }
`;
export const Content = styled(Row)` 
  background: #FFFFFF;
  box-shadow: 0px 0px 1.408vw rgba(0, 0, 0, 0.05);//40
  border-radius: 0.704vw; //20
  padding: 2.112vw; //60
  margin-bottom: 1.760vw; //50
  p{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.760vw; //50
    line-height: 1.795vw; //61
    text-align: center;
    margin-bottom: 2.112vw; //60
    color: #252525;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;
export const PageOne = styled.div` 
  height: 139.744vw;
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  P, li {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.61vw; //40
    line-height: 2.288vw; //65
    text-align: justify;
    color: #434343;
  }
  div{
    margin-bottom: 3vw;
  }
  ul {
    list-style: disc;
    margin-left: 2.5vw;
  }
`;



export const TitleGraph = styled.div` 
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 3.024vw;
  line-height: 3.548vw;
  color: #000A66;
  margin-bottom: 2.419vw;
`;

export const TitleModule = styled.div` 
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 2.217vw;
  line-height: 2.620vw;
  color: #000533;
  margin-bottom: 2.419vw;
`;
export const SubtitlePerformance = styled.div` 
  display: flex;
`;

export const ColScore = styled(Col)` 
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4.435vw 8.064vw;

  .graphs {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    width: 18.652vw;
    height: 17.174vw;
  }
  .graph-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: -2vw;
  }
`;
export const FooterReport = styled.div` 
  margin-top: 3vw;
  border-top: 1px solid #BBBBBB;
  padding-top: 2.419vw;

  display: flex;
  align-items: center;


  .logo-gold {
    width: 7.466vw;
    height: 7.258vw;
  }
  .description-footer p {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.61vw;
    line-height: 2.62vw;
    text-align: justify;
    margin-left: 2.822vw;
    color: #252525;
  }
  .description-footer p:last-child {
    font-weight: 700;
  }
`;

export const TextDescription = styled.div` 
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 1.51vw;
  line-height: 2.620vw;
  text-align: justify;
  color: #434343;

  p {
    margin-bottom: 3vw;
  }
  strong {
    font-weight: 700; 
  }
  span {
    color: #D81E5B;
  }
  .strong-span {
    font-weight: 700;
    color: #D81E5B; 
  }
  a {
    text-decoration: #D81E5B;
    font-weight: 700; 
  }
`;