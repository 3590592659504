import React from 'react'

import PropTypes from 'prop-types'

import { Modal } from 'antd'

import {CloseOutlined} from '@ant-design/icons'

import './styles/modal.scss'

const propTypes = {
  questionTextShow: PropTypes.bool,
  setQuestionTextShow: PropTypes.func,
  text: PropTypes.string,
  setModalTextOption: PropTypes.func,
  setLSBText: PropTypes.func,
}

function LectureVideoQuestion(props) {

  const { text, questionTextShow, setQuestionTextShow } = props
  
  return (
    <Modal
        title="Resolução em texto"
        width={800}
      zIndex={10001}
      footer={false}
      closable={true}
      maskClosable={false}
      destroyOnClose={true}
      visible={questionTextShow}
      onCancel={() => setQuestionTextShow(false)}
      wrapClassName="lectureContainerVideo"
      className='modal-lecture-video'
      closeIcon={<button className='button-close'><CloseOutlined /></button>}
    >
      <div style={{padding: '20px 25px'}}>{text}</div>
    </Modal>
  )
}

LectureVideoQuestion.propTypes = propTypes
export default LectureVideoQuestion