import styled, { css } from 'styled-components';

interface Props {
    forgotten?: boolean;
    active: boolean;
};

export const Container = styled.div`
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        color: ${({ theme }) => theme.colors.danger};
        font-weight: 600;
        font-size: 17px;
        line-height: 15px;
    }
`;

export const Box = styled.div`
    margin-top: 12px;
    padding: 32px;
    width: 100%;

    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.gray_150};
    box-sizing: border-box;
    border-radius: 6px;

    display: grid;
    grid-template-columns: repeat(8, 1fr);
    @media(max-width: 560px) {
        grid-template-columns: repeat(7, 1fr);
    }
    @media(max-width: 380px) {
        grid-template-columns: repeat(6, 1fr);
    }
    @media(max-width: 360px) {
        grid-template-columns: repeat(5, 1fr);
    }
    gap: 10px;
`;

export const Question = styled.span<Props>`
    height: 45px;
    @media(max-width: 728px) {
        height: 45px;
    }
    @media(max-width: 560px) {
        height: 40px;
    }
    @media(max-width: 480px) {
        height: 35px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    box-sizing: border-box;
    ${({ forgotten }) => forgotten && css`
        border-radius: 50% !important;
        border: 2px solid ${({ theme }) => theme.colors.red_500};
    `}

    ${({ active }) => active && css`
        font-weight: bold !important;
    `}
`;