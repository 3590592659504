export type TypeReportType = 1 | 2;
export type CategoryReportType = 1 | 2;

export type ScoreInformationType = {
    content: string;
    lte: number;
    gte: number;
};

export type Simulado = {
    acertos?: number;
    module?: string;
    porcentagem?: number;
    simulado?: string;
    titulo?: string;
    total?: number;
};

export type TestProps = {
    prova: number;
};

export type ClassPerformanceType = {
    id_aula: number,
    id_usuario: number,
    titulo: string;
    module: string;
    total: number;
};


export type ModulePerformanceType = {
    total: number;
    id_usuario: number;
    module: string;
    tipo_prova: string | 'REVISAO';
};

export type StudentStateType = {
    student: {
        scoreToroInformation: Array<ScoreInformationType>;
        average: number;
        reportSimulado: {
            name: string;
            hits: number;
            module_performance?: Array<ModulePerformanceType>;
            average?: {
                acertos_avg?: number;
            };
            class_performance?: Array<ClassPerformanceType>;
            avarage_simulated?: number;
            score?: {
                score?: number;
            };
            test: {
                done?:  Array<TestProps>,
                to_be_done?:  Array<TestProps>
            };
            performance_per_class?: Array<{
                cor?: string;
                peso?: number;
                porcentagem?: number;
                texto?: string;
                titulo?: string;
            }>;
            simulated_by_module?: {
                simulado_1: Array<Simulado>
                simulado_2: Array<Simulado>
                simulado_3: Array<Simulado>
                simulado_4: Array<Simulado>
                simulado_5: Array<Simulado>
                simulado_6: Array<Simulado>
            }
        };
    }
};

type DefaultParamsType = {
    classId: number | null;
    courseId: number | null;
    userId: number | null;
    kind: string | null;
    end: string | null;
    start: string | null;
    media: string | null;
    classes: string | null;
    report: string | null;
};

export const getSusgestionByScoreToro = (sugestions: ScoreInformationType[], score?: number): string => {
    const studentScore = score ?? 0;
    const [sugestion] = sugestions.filter(item => studentScore>=item.gte && studentScore<=item.lte);

    return sugestion?.content ?? '';
};

export const getDefaultParams = (path?: string): DefaultParamsType => {
    const urlParams = new URLSearchParams(path);
    const userId = urlParams.get('user_id');
    const courseId = urlParams.get('course_id');
    const classId = urlParams.get('class_id');
    const start = urlParams.get('start');
    const media = urlParams.get('media');
    const end = urlParams.get('end');
    const kind = urlParams.get('kind');
    const classes = urlParams.get('classes');
    const report = urlParams.get('report');

    return {
        userId: userId ? parseInt(userId): null,
        courseId: courseId ? parseInt(courseId) : null,
        classId: classId ? parseInt(classId) : null,
        start,
        media,
        end,
        kind,
        classes,
        report
    };
};

export const getFormattedModulesReport = (modules: ClassPerformanceType[] = [], max = 6): ClassPerformanceType[]  => {
    const defaultModules = Array.from(Array(max).keys());

    const formattedReport = defaultModules.map(index => {
        if (modules.length && modules[index]) {
            return modules[index];
        }

        return {
            total: 0.000,
            module: `M${index + 1}`,
            titulo: 'empty'
        } as ClassPerformanceType;
    });

    return formattedReport;
};


export const getFormatedModulesPerformanceReport = (modules: ModulePerformanceType[] = [], max = 6): ModulePerformanceType[]  => {
    const defaultModules = Array.from(Array(max).keys()).map((index) => {
        return 'M' + (index+1);
    });

    defaultModules.push('Revisão')

    const formattedReport = defaultModules.map(nameModule => {
        const exist = modules.find(item => item.module === nameModule);

        if (exist) return exist;

        return {
            total: 0.000,
            module: nameModule
        } as ModulePerformanceType;
    });

    return formattedReport;
};


// Module Constants
export const TYPE_COURSE_CATEGORY_CFP = 1;

export const TYPE_SCORE_INFORMATION_REPORT_PDF = 1;
export const TYPE_SCORE_INFORMATION_REPORT_PNG = 2;

export const TYPE_SCORE_INFORMATION_REPORT_SIMULATED = 1;
export const TYPE_SCORE_INFORMATION_REPORT_WEEKLY = 2;

export const RENDER_CAPTIONBAR_BY_PERCENT = 40;
