import { all, fork, call, put, takeLatest, takeEvery } from 'redux-saga/effects'

import { constants } from '../modules/auth'
import * as api from '../api/auth'

import { notification } from 'antd';

import { persistor } from '../store'

// LOGIN
function* login(action) {
  try {
    const payload = yield call(api.login, action)
    yield put({ type: constants.AUTH_LOGIN.SUCCESS, payload })
    
    action.success && action.success(payload);
  } catch (e) {
    yield put({
      type: constants.AUTH_LOGIN.FAILED,
      message: e.message || e
    })
    notification['error']({
      message: `Problemas no Login: usuário ou senha inválidos`
    })

    action.error && action.error();
  }
}

function* watchLogin() {
  yield takeEvery(constants.AUTH_LOGIN.ACTION, login)
}

// FORGOT PASSWORD
function* forgot(action) {
  try {
    const payload = yield call(api.forgot, action)
    yield put({ type: constants.AUTH_FORGOT_PASSWORD.SUCCESS, payload })
    notification['success']({
      message: `Verifique seu e-mail`
    })

  } catch (e) {
    yield put({
      type: constants.AUTH_FORGOT_PASSWORD.FAILED,
      message: e.message || e
    })
    notification['error']({
      message: `Problemas ao recuperar senha: usuário não encontrado`
    })
    
    action.next && action.next();
  }
}

function* updatePhoto (action) {
  const {foto} = action
  try {
    yield put({type: constants.AUTH_UPDATE_PHOTO.SUCCESS, foto})
  }
  catch(e) {
    yield put({
      type: constants.AUTH_UPDATE_PHOTO.FAILED,
      message: e.message || e
    })
  }
} 

function* watchForgot() {
  yield takeEvery(constants.AUTH_FORGOT_PASSWORD.ACTION, forgot)
}


// LOGOUT
function* logout() {
  yield put({ type: 'LOGOUT' })
  yield call(persistor.purge)
}

function* watchLogout() {
  yield takeLatest(constants.AUTH_LOGOUT, logout)
}

function* watchUpdatePhoto() {
  yield takeEvery(constants.AUTH_UPDATE_PHOTO.ACTION, updatePhoto)
}
/**
 * Export the root saga by forking all available sagas.
 */
export function* rootSaga() {
  yield all([fork(watchLogin), fork(watchLogout), fork(watchForgot), fork(watchUpdatePhoto)])
}
