import _ from 'lodash'
import { createSelector } from 'reselect'
   
export const getStudentQuestions = (state) => _.get(state, 'lecture.studentQuestions', [])
export const finishedLectures = (state) => _.get(state, 'lecture.finishedLectures', [])
export const isLoading = (state) => _.get(state, 'lecture.isLoading', {})
export const getOngoingLecture = (state) => _.get(state, 'lecture.ongoingLecture', [])
export const getLectureResult = (state) => _.get(state, 'lecture.lectureResult', {})
export const lectureWrongQuestions = (state) => _.get(state, 'lecture.lectureWrongQuestions', [])
export const allAnsweredQuestions = (state) => _.get(state, 'lecture.allAnsweredQuestions', [])
export const totalQuestions = (state) => _.get(state, 'lecture.totalQuestions', [])
export const getWrongQuestions = state => questionIndex =>
  createSelector(
    [getStudentQuestions, getOngoingLecture],
    (studentQuestions, ongoingLecture) => {
      let wrongQuestions = studentQuestions.filter(studentQuestion => {
        //
        // get the right question's answer
        let rightAnswer = studentQuestion.answers.find(answer => answer.correta === 1)

        //
        // check if the student marked the right answer for the current question in the loop
        const ongoingQuestionAnswer = ongoingLecture.question[studentQuestion.id_questao]
        if (!ongoingQuestionAnswer || ongoingQuestionAnswer !== rightAnswer.id_resposta) {
          return studentQuestion
        }
        return false
      })
      return questionIndex !== null ? [wrongQuestions[questionIndex]] : wrongQuestions
    }
  )(state)

export const mockStructure = (state) => _.get(state, 'lecture.mockStructure', [])