import React, { InputHTMLAttributes, useState } from 'react';

import { ButtonEyes, ErrorMessage, InputArea, InputComponent, InputLabel } from './styles';

import { ReactComponent as Eyes } from '../../img/svg/eyes.svg';
import { ReactComponent as EyesClosed } from '../../img/svg/eyes-close.svg';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  errors: string | undefined;
}

export function Input({ label, errors, ...rest }: InputProps) {
  const isPassword = rest.type === 'password';
  const [type, setType] = useState(rest.type);

  const changeType = () => {
    setType(type === 'password' ? 'text' : 'password');
  };

  return (
    <InputArea>
      <InputLabel>{label}</InputLabel>
      <InputComponent {...rest} type={type} className={isPassword ? 'is-password' : ''} />
      {isPassword && (
        <ButtonEyes type="button" onClick={changeType}>
          {type === 'password' ? <Eyes /> : <EyesClosed />}
        </ButtonEyes>
      )}
      {errors && <ErrorMessage>{errors}</ErrorMessage>}
    </InputArea>
  );
}
