import styled from 'styled-components';

type BoxProps = {
    size: number;
};

export const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const Box = styled.div<BoxProps>`
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: ${({ size }) => size}px;
    width: 684px;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    //padding: 83px 0px;
    h1 {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 20px;
        font-weight: 600;
        width: 60%;
        text-align: center;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        & > button + button {
            margin-top: 1.85rem;
        }
    }

    .body {
        & > .checkbox + .checkbox {
            margin-top: 1.85rem;
        }
    }

`;