import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  margin-top: 300px;
  z-index: 1;

  .balloon {
    background-color: #fff;
    border: 2px solid #5768ff;
    border-radius: 10px;
    max-height: 140px;
    padding: 10px;
    width: 150px;
  }

  .balloon-title {
    color: #5768ff;
    font-family: 'Montserrat';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .balloon-content {
    color: #5768ff;
    font-family: 'Work Sans';
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
  }
`;
