import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    gap: 30px;
    
    @media(max-width: 728px) {
        padding: 0px;
    }
`;

export const PanelNoPodcasts = styled.div`
  width: 100%;
  height: calc(100vh - 235px);
  border-radius: 10px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @media only screen and (max-width: 768px) {
    border-radius: 0;
    height: calc(100vh - 220px);
    padding: 0 10px;
  }

  img {
    width: 80px;
    height: 80px;
  }

  h3 {
    color: var(--escala-de-cinza-70, #434343);
    text-align: center;

    /* Web/H2 */
    font-family: 'DM Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 125% */
    letter-spacing: 0.2px;
  }
`;

export const ContainerModal = styled.div`
    padding: 20px 5px 50px 5px;
    background-color: #f5f5f5;
    border-radius: 10px;
`;

export const Content = styled.section`
    width: 100%;
    display: flex;
    
    @media(max-width: 728px) {
        flex-direction: column;
    }
`;

export const AlbumArea = styled.div`
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 15px;
    width: 40%;
    height: 665px;
    overflow-y: auto;
            
    @media(max-width: 728px) {
        width: 100%;
    }
`;

export const Aside = styled.aside`
    width: 60%;
    margin-left: 20px;
    
    @media(max-width: 728px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 20px;
    }
`;

export const PlayerArea = styled.div`
    background-color: #FFFFFF;
    padding: 15px;
    width: 100%;
    
    @media(min-width: 480px) {
        height: 450px;
    }

    @media(max-width: 600px) {
        padding: 0px;
    }

    border-radius: 5px;
    overflow-x:hidden;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    svg {
        width: 25px;
    }

    .info {
        @media(max-width: 600px) {
            display: none;
        }
    }
`;

export const ModalTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 18px;
            margin-left: 10px;
        }

        svg {
            width: 20px;
        }
    }

    .info {
        margin-bottom: 10px;

        svg {
            width: 25px;
        }

        @media(min-width: 728px) {
            display: none;
        }
    }
`;