import React from 'react'

import { Button } from 'antd'
import PropTypes from 'prop-types'
import { ArrowRightOutlined } from '@ant-design/icons'
import { DownloadButtonComponent } from '../DownloadButtonComponent'

const propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  icon: PropTypes.object,
  content: PropTypes.object,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
}
function ClassRight({ sectionTitle, icon, content, buttonText, handleClick, handlePrintTest, loadingDownloadText }) {
  return (
    <div style={{ backgroundColor: 'white', padding: '22px 22px' }}>
      <h3>{sectionTitle}</h3>
      <div className="pdf-section" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>
        <div className='simulate-area'>
          {icon && <span className="pdf-icon">{icon}</span>}
          <span className="pdf-text-ct">{content}</span>
          {buttonText && (
            <Button type="primary" className="pdf-button" onClick={handleClick}>
              {buttonText}
              <ArrowRightOutlined />
            </Button>
          )}
        </div>
        {!!handlePrintTest && (
          <DownloadButtonComponent loading={loadingDownloadText} handleDownload={handlePrintTest} />
        )}
      </div>
    </div>
  )
}

ClassRight.propTypes = propTypes
export default ClassRight
