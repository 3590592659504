import React from 'react';

import './styles.scss';


type Props = {
    description: string;
};

export default function Title ({
    description
}: Props) {
    return (
        <h1 className="report-cfp-title">{description}</h1>
    );
};
