import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import moment from 'moment';
import locale from 'antd/lib/locale/pt_BR';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Row, Spin, Modal, Space, Button, Select, DatePicker, TimePicker } from 'antd';

import * as mentorshipSelectors from '../../redux/selectors/mentorship';
import { actions as mentorshipActions } from '../../redux/modules/mentorship';

const propTypes = {
  setMentorshipRequestModal: PropTypes.func.isRequired,
  getAvalibleClass: PropTypes.func.isRequired,
  askMentorship: PropTypes.func.isRequired,
  getMyMentorships: PropTypes.func.isRequired,
};

function MentorshipRequest(props) {
  const { getMyMentorships, setMentorshipRequestModal, getAvalibleClass, askMentorship } = props;

  const [data, setData] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [blockSubmit, setBlockSubmit] = useState(true);
  const [initialValues, setInitialValues] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ongoingMentorship, setOngoingMentorship] = useState([]);

  useEffect(() => {
    let aulas = [];
    getAvalibleClass((classAvalible) => {
      aulas = classAvalible.filter(
        (element, index, self) =>
          index === self.findIndex((aula) => aula.id_aula === element.aula.id_aula)
      );
      setInitialValues(aulas);
    });
    getMyMentorships((previousMentorship) => {
      setOngoingMentorship(previousMentorship.mentoriaaluno);
    });
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const dataLenght = Object.keys(data).length;
    if (dataLenght > 2) setBlockSubmit(false);
    if (dataLenght < 3) setBlockSubmit(true);
  }, [data]);

  const handleSubmit = () => {
    data.hora_fim = (+data.hora_inicio + 1).toString();
    data.id_dia = 1;
    askMentorship(data, () => setIsModalVisible(true));
  };

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const handleCancel = () => {
    setIsVisible(false);
    setTimeout(() => setMentorshipRequestModal(false), 500);
  };

  const handleData = (key, value) => {
    if (value !== 'Data inválida') {
      setData((prev) => {
        return { ...prev, [key]: value };
      });
    } else {
      delete data[key];
      setBlockSubmit(true);
    }
  };

  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  const format = 'HH';
  return (
    <>
      <Modal
        width={500}
        closable={true}
        footer={false}
        visible={isVisible}
        maskClosable={true}
        style={{ top: 30 }}
        destroyOnClose={true}
        onCancel={() => handleCancel()}
      >
        <Row className="row-modal">
          <Col>
            <p className="modal-title-mentoria">Mentorias</p>
            <br />
            <p className="modal-subTitle-mentoria">SOLICITAR MENTORIA</p>
            <br />
            <Col>
              <h2>Selecione a aula que você deseja rever com um professor: </h2>
              <Select
                name="id_aula"
                className="width-100"
                labelInValue
                onSelect={(e) => handleData('id_aula', e.key)}
              >
                {initialValues &&
                  initialValues.map((aula) => (
                    <Select.Option key={aula.aula.id_aula} value={aula.aula.id_aula}>
                      {aula.aula.titulo}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <br />
            <Col>
              <p>Selecione a data mais conveniente para você:</p>
              <Space direction="vertical">
                <DatePicker
                  disabledDate={disabledDate}
                  name="dia_mentoria"
                  placeholder="Data"
                  onChange={(e) => handleData('dia_mentoria', moment(e).format('YYYY-MM-DD'))}
                  locale={locale}
                  size="large"
                  format={dateFormatList}
                />
              </Space>
            </Col>
            <br />
            <Col>
              <p>Selecione a hora que você deseja:</p>
              <Space>
                <TimePicker
                  onChange={(e) => handleData('hora_inicio', moment(e).format('HH'))}
                  name="hora_inicio"
                  format={format}
                  placeholder="Horário"
                  showNow={false}
                  bordered={true}
                />
              </Space>
            </Col>
          </Col>
          <Col className="col-solicitar-mentoria">
            <Button onClick={() => handleCancel()}>CANCELAR</Button>
            <Button disabled={blockSubmit} type="primary" onClick={handleSubmit}>
              SOLICITAR MENTORIA
            </Button>
          </Col>
        </Row>
      </Modal>

      <Modal
        width={500}
        closable={true}
        footer={false}
        maskClosable={true}
        style={{ top: 30 }}
        destroyOnClose={true}
        visible={isModalVisible}
        onCancel={() => handleCancel()}
      >
        <Row className="row-modal-height">
          <Col className="button-title-mentorship">
            <p>Mentorias</p>
          </Col>
          {!ongoingMentorship ? (
            <Spin />
          ) : (
            <Col className="col-message">
              <CheckCircleOutlined style={{ color: '#12c735' }} />
              <p className="f-weight-800 p-message">Recebemos a sua solicitação! =)</p>
              <br /> <br />
              <p>Iremos informar quando seu horário for confirmado por nossos professores.</p>
              <p>Acompanhe o andamento da sua solicitação em</p>
              <p className="f-weight-500">Mentorias {'->'} Solicitações em Andamento.</p>
              <br />
              <p>
                Você receberá uma confirmação por email assim que sua solicitação for aceita por um
                professor.
              </p>
            </Col>
          )}
          <br />
          <Col className="button-done">
            <Button type="primary" onClick={handleCancel}>
              ENTENDI
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

MentorshipRequest.propTypes = propTypes;

const mapStateToProps = (state, props) => ({
  mentorship: mentorshipSelectors.mentorship(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  getMyMentorships: (next) => dispatch(mentorshipActions.getMyMentorships(next)),
  askMentorship: (formData, next) => dispatch(mentorshipActions.askForMentorship(formData, next)),
  getAvalibleClass: (next) => dispatch(mentorshipActions.getAllAvalibleClassToMentorship(next)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorshipRequest);
