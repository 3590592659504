import styled from 'styled-components';

export interface PercentProps {
    percent: number;
};


export const Container = styled.div`
    width: 100%;
    height: 13px;
    background: #EAEAEA;
`;

export const Progress = styled.div<PercentProps>`
    background-color: ${({ theme }) => theme.colors.warning};
    height: 95%;
    width: ${({ percent }) => percent}%;
    display: flex;
    flex-direction: flex-start;
    align-items: center;
`;

export const Percent = styled.div<PercentProps>`
    width: 100%;
    padding-left: ${({ percent }) => percent}%;
`;