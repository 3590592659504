import { format } from 'date-fns';

export const alphabeticalOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']

export function finishDateFormatted(data: string): string {
    if (!data) {
        return format(new Date(), 'dd/MM/yyyy');
    }

    const fixDate = new Date(`${data}`);

    const formattedDate = format(fixDate, 'dd/MM/yyyy');

    return formattedDate;
};

type QuestionType = {
    id_questao: number | string;
    answers: Array<{
        id_resposta: number;
    }>;
};

export function getQuestionsWithLetters(questions?: QuestionType[], answers?: any) {
    if (!questions || !answers) return [];

    const finishedAnswers = Object.keys(answers).map((questionId) => {
        const answer = answers[questionId];
        const currentQuestion = questions.find(question => {
            return parseInt(question.id_questao.toString()) === parseInt(questionId);
        });

        if (currentQuestion) {
            const currentIndexLetter = currentQuestion
                .answers
                .findIndex(item => {
                    return (parseInt(item.id_resposta.toString())) === parseInt(answers[questionId])
                });

            return {
                answer,
                questionId,
                letter: alphabeticalOrder[currentIndexLetter]
            };
        }
        return undefined;
    }).filter(item => (!!item));

    return finishedAnswers;
}
