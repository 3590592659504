import React, { ReactNode } from 'react';
import Modal from 'react-modal';
import { Button, ButtonArea, IconTitleArea, ModalArea, Title } from './styles';

export function ModalInformation({
  open,
  icon,
  title,
  primaryButtonAction,
  primaryButtonText,
  secondaryButtonAction,
  secondaryButtonText,
  onclose,
  iconPosition = 'center',
}: ModalInformationProps) {
  const closeModal = () => onclose?.();

  return (
    <Modal
      isOpen={open}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Modal Informations"
    >
      <ModalArea>
        <IconTitleArea iconPosition={iconPosition}>
          {icon}
          {typeof title === 'string' ? <Title>{title}</Title> : title}
        </IconTitleArea>
        <ButtonArea>
          {primaryButtonText && (
            <Button className="primary" onClick={primaryButtonAction}>
              {primaryButtonText}
            </Button>
          )}
          {secondaryButtonText && (
            <Button className="secondary" onClick={secondaryButtonAction}>
              {secondaryButtonText}
            </Button>
          )}
        </ButtonArea>
      </ModalArea>
    </Modal>
  );
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    background: 'transparent',
    zIndex: 1000,
  },
};

export type ModalInformationProps = {
  open: boolean;
  icon: ReactNode;
  iconPosition?: 'left' | 'center';
  title: string | ReactNode;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  onclose?: () => void;
};
