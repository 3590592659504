import styled, { css } from 'styled-components';


type Props = {
    size: number;
    color: string;
    fontColor: string;
    percent: number;
    doublePercent: number;
    responsive: boolean;
};

export const Container = styled.div<Props>`
    display: flex;
    zoom: ${({ size }) => size}%;

    ${({ responsive, theme }) => responsive && css`
        @media(max-width: ${theme.media.notebook}px) {
            zoom: 100%;
        }
        @media(max-width: ${theme.media.tablet}px) {
            zoom: 90%;
        }
    `};


    .graph {
        text-align: center;
        width: 150px;
        height: 150px;
        text-transform: uppercase;
        font-family: sans-serif;
        text-decoration: none;

        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size:  0.95vw;
            font-weight: 700;
            color: ${({ fontColor }) => fontColor};
        }
    }

    svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        fill: transparent;
        stroke-width: 8px;
        stroke: ${({ theme }) => theme.colors.gray_75};
    }

    .secondary {
        > svg {
            stroke: #FFF;
            stroke-width: 6px;
        }

        .secondary-cor {
            stroke: #9A9A9A;
            stroke-dasharray: 326;
            stroke-dashoffset: ${({doublePercent }) => 326 - doublePercent}; // 70% is fixed in this case
            transform: rotate(-90deg);
            transform-origin: center;
        }
    }

    .graph svg.cor {
        stroke: ${({ color }) => color};
        stroke-dasharray: 255;
        stroke-dashoffset: ${({ percent }) => 255 - percent};
        transform: rotate(-90deg);
        transform-origin: center;
    }

    .graph svg.cor-anim {
        stroke: ${({ color }) => color};
        stroke-dasharray: 255;
        stroke-dashoffset: ${({ percent }) => 255 - percent};
        animation: anim 2s;
    }

    .graph svg.cor-secondary-anim {
        stroke: #9A9A9A;
        stroke-dasharray: 326;
        stroke-dashoffset: ${({ doublePercent }) => 326 - doublePercent};
        animation: anim 2s;
    }

    @keyframes anim {
        0% {
            stroke-dashoffset: 255;
        }
    }

    @media only screen and (max-width: 768px) {
      .graph span {
        font-size: calc(12px + 0.95vw);
      }
    }
`;
