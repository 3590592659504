import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { GabaritoroProvider } from '../../../hooks/useGabaritoro';
import { chat } from '../../../shared/gabaritoro';
import Header from '../../NewCheckout/Header';
import Logo from '../LogoTwo';
import { Container, Wrapper, Top, Content } from './styles';

type Props = {
  children: React.ReactNode;
  padding?: boolean;
};

const Layout = ({ children, padding = true }: Props) => {
  useEffect(() => {
    chat();
  }, []);

  return (
    <>
      <Helmet>
        <title>Gabaritoro</title>
      </Helmet>
      <GabaritoroProvider>
        <Header />
        <Container>
          <Wrapper>
            <Top>
              <Logo />
            </Top>
            <Content padding={padding}>{children}</Content>
          </Wrapper>
        </Container>
      </GabaritoroProvider>
    </>
  );
};

export default Layout;
