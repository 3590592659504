import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  gap: 26px;

  width: 100%;
  min-height: 500px;

  background: #FFFFFF;
  box-shadow: inset -1px 0px 0px #EFEFEF;
  border-radius: 0px 0px 0px 5px;

  @media (max-width: 767px) {
    min-height: 282px;
  }

  .vish {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #252525;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .text {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #252525;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;

      br {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  min-width: 255px;
  height: 37px;

  background: #D81E5B;
  border: 1px solid #D81E5B;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 5px;

  @media (max-width: 767px) {
    padding: 5px 16px;
    min-width: 244px;
    height: 28px;
  }

  .text-button {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;

    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 18px;
    }
  }
`
