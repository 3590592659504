import React from 'react';

import { Modal } from 'antd';

import './styles.scss';


type Props = {
    isVisible: boolean;
    setVisible: (isVisible: boolean) => void;
    title: string;
    message?: string;
    zIndex?: number;
};

const ModalAlert = (props: Props) => {
    
    const handleCancel = () => {
        props.setVisible(false);
    };

    const handleClose = () => {
        props.setVisible(false);
    };

    return (
        <Modal
            zIndex={props.zIndex ?? 998}
            closable={true}
            visible={props.isVisible}
            destroyOnClose={true}
            maskClosable={false}
            className="container-therms-of-service"
            footer={false}
            onCancel={handleCancel}>
                <div className="container-therms-of-service-body">
                    <h1>
                        {props.title}
                    </h1>

                    {!!props?.message && (
                        <p>{props?.message}</p>
                    )}

                    <div className="container-therms-of-service-buttons"> 
                        <button className="button-warning" onClick={handleClose}>
                            Ok
                        </button>                        
                    </div>
                </div>
        </Modal>
    );
};

export default ModalAlert;