import { useState, useEffect } from 'react'

const useDocumentSize = () => {
  const getSize = () => {
    return {
      width: document.body.clientWidth,
      height: document.body.clientHeight
    }
  }

  const [documentSize, setDocumentSize] = useState(getSize)

  useEffect(() => {
    const timer = setTimeout(() => {
      setDocumentSize(getSize())
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setDocumentSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return documentSize
}

export default useDocumentSize