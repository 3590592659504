import React, { useContext } from 'react';

import { Modal } from 'antd';
import P from 'prop-types';
import { CheckoutContext } from '../shared/CheckoutProvider';
import './style/modalCPF.scss';


const propTypes = {
    isVisible: P.bool.isRequired,
    handleNewEmail: P.func.isRequired,
    setVisible: P.func.isRequired,
    next: P.func.isRequired
};

const ModalExistCPF = (props) => {

    const { values, setContinueSignIn } = useContext(CheckoutContext);

    const handleCancel = (cb = null) => {
        props.setVisible(false);
        setContinueSignIn(true);
    };

    const handleContinueTheSameEmail = () => {
        handleCancel();
        props.next();
    };

    const handleNewEmail = () => {
        handleCancel();
        props.handleNewEmail();
    };

    const makeDescriptionEmail = () => {
        try {
            const { email } = values;
            return email.replace(email.substr(8, email.length/3), '...');
        } catch (e) {
            return '...';
        }
    };

    return (
        <Modal
            zIndex="998"
            closable={true}
            visible={props.isVisible}
            destroyOnClose={true}
            maskClosable={false}
            className="container-exist-cpf"
            footer={false}
            onCancel={handleCancel}>
                <div className="container-exist-cpf-body">
                    <h1>
                        CPF já cadastrado
                    </h1>

                    <div className="container-exist-cpf-buttons"> 
                        <button className="button-warning" onClick={handleContinueTheSameEmail}>
                            Continuar com o email {makeDescriptionEmail()}
                        </button>
                        <button className="button-primary" onClick={handleNewEmail}>
                            Cadastrar um novo email
                        </button>
                    </div>
                </div>
        </Modal>
    );
};

ModalExistCPF.propTypes = propTypes;
export default ModalExistCPF;