import styled from "styled-components";

export const FooterPanel = styled.div`
  width: calc(100% + 48px);
  margin-left: -24px;
  padding-top: 12px;

  display: flex;
  padding: 14px var(--spacing-6, 24px) 0 var(--spacing-6, 24px);
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;

  border-top: 1px solid var(--escala-de-cinza-10, #efefef);

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const QuestionsInformationButtonsArea = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;
