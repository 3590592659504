import React from 'react';

import Modules from './List';
import Checkout from '..';

export default function CheckoutModular () { 
    return (
        <Checkout>
            <Modules />
        </Checkout>
    );
};