import styled from 'styled-components';

export const WrongCorrectInformation = styled.p`
  color: var(--escala-de-cinza-90, #101010);

  /* Web/H3 */
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  letter-spacing: 0.36px;

  > span {
    color: #7398f1;
  }
`;

export const ButtonPreviewNextQuestion = styled.button`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  z-index: 100;

  position: absolute;
  border: none;
  cursor: pointer;

  background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 58.5%, rgba(255, 255, 255, 0) 100%);

  &.preview {
    left: 0px;
    right: auto;
  }

  &.next {
    right: 20px;
    left: auto;
  }

  &.review {
    top: 38px;
  }

  @media screen and (max-width: 768px) {
    &.review {
      top: 63px;
    }
  }
`;

export const QuestionsSelectArea = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  position: relative;

  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
`;

export const QuestionsSelect = styled.label`
  > span {
    width: 35px;
    height: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    border-radius: 8px;
    border: 1px solid var(--escala-de-cinza-40, #a4a4a4);
    background: var(--escala-de-cinza-0, #fff);

    cursor: pointer;

    transition: all 0.5s ease;

    &:hover {
      filter: brightness(0.85);
    }

    &.correct {
      border: none;
      color: var(--escala-de-cinza-0, #fff);
      background: var(--Mensagens-Success, #62cf7a);
      box-shadow: none;
    }

    &.incorrect {
      border: none;
      color: var(--escala-de-cinza-0, #fff);
      background: var(--Mensagens-Danger, #ee3c3e);
    }
  }

  > input:checked + span:not(.correct):not(.incorrect) {
    border: none;
    color: var(--escala-de-cinza-0, #fff);
    background: var(--Primary-Purple, #5768ff);
    box-shadow: 0px 0px 10px 0px rgba(87, 104, 255, 0.64);
  }

  > input:checked + span.correct,
  input:checked + span.incorrect {
    border: 2px solid var(--Primary-Purple, #5768ff);
    box-shadow: 0px 0px 10px 0px rgba(87, 104, 255, 0.64);
  }
`;

export const SelectReviewItems = styled.select`
  width: fit-content;
  min-width: 230px;
  height: 36px;

  display: flex;
  padding: var(--spacing-2, 8px) var(--spacing-3, 12px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: var(--radius-md, 8px);
  border: 1px solid var(--escala-de-cinza-20, #d6d6d6);
  background: var(--color-background-button-outline-normal, #fff);

  box-shadow: 0px 1px 2px 0px rgba(18, 18, 23, 0.05);

  outline: none;
  cursor: pointer;

  margin: 10px;
`;
