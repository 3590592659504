import React from 'react';
import {
    Container,
    Progress,
    Percent
} from './styles';

type Props = {
    percent: number;
    showPercent?: boolean;
};

export default function BarChart ({ percent, showPercent=true }: Props) {
    return (
        <Container>
            <Progress percent={percent} />
            {
                showPercent && (
                    <Percent percent={percent}>{percent}%</Percent>
                )
            }
        </Container>
    );
};
