import React from 'react'

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Button } from 'antd';
import {
  ColCard,
  Card,
  BoxImg
} from './styles'

import config from '../../../config';
import arrowRightPink from '../../../img/svg/arrow-right-pink.svg';
import ExpireDateCourse from './expireDateCourse';

const propTypes = {
  course: PropTypes.object.isRequires,
}
function CourseCard(props) {
  let history = useHistory()

  function handleGoClasses(course) {
    history.push(`/courses/${course.id_curso}/${course.id_turma}`);
  };

  const { course } = props;

  return (
    <ColCard
      xs={{ span: 24}}
      sm={{ span: 8 }}
      md={{ span: 8 }}
      xl={{ span: 6 }}
      xxl={{ span: 4 }}
    >
      <Card className='card'>
        {
          !!course.img_capa ? (
            <BoxImg className='box-img'>
              <img
                src={config.s3Url + course.img_capa}
                alt="capa"
                />
              <span>{course?.turma && <p>Turma: {course.turma}</p>}</span>
              </BoxImg>
          ) : (
            <BoxImg className='box-img'>
              <div className='default-img'>
              <img
                src="/assets/img/toro-horizontal-white.svg"
                alt="Academia Rafael Toro logo"
              />
              <span>{course?.turma && <p>Turma: {course.turma}</p>}</span>
            </div>
            </BoxImg>

          )
        }

        <div className='card-course-body'>
          <div>
            <span>{course.curso}</span>
          </div>
            {course.expiration_date === 1 ?
              <ExpireDateCourse course={course} size={'12px'} />
            : ''}

          <Button className="btn-card-courses" onClick={() => handleGoClasses(course)}>
            <p> Estudar <img src={arrowRightPink} alt="icon warning circle" /></p>
          </Button>
        </div>
      </Card>
    </ColCard>
  )
}

CourseCard.propTypes = propTypes
export default CourseCard
