import { createSagaAction } from '../../shared/sagas';
import { createReducer } from '../../shared/reducers';

import { ICallback } from '../../shared/Models/ICallback';
import { ITransaction, ITransactions } from '../../shared/Models/cashback/ITransactions';
import { ICoupon } from '../../shared/Models/cashback/ICoupon';

export type CashbackStateType = {
    statements: Array<ITransaction>,
    balance: number;
    coupon?: ICoupon,
    formattedBalance: string;
    isCreatingCoupon: boolean;
    isCancelingCoupon: boolean;
    isFetchingCoupon: boolean;
    isLoading: boolean;
    error: boolean;
    createError: string;
};

export const constants = {
    CASHBACK_FETCH_TRANSACTIONS: createSagaAction('CASHBACK_FETCH_TRANSACTIONS'),
    CASHBACK_CREATE_COUPON: createSagaAction('CASHBACK_CREATE_CUPOM'),
    CASHBACK_CANCEL_COUPON: createSagaAction('CASHBACK_CANCEL_COUPON'),
    CASHBACK_FETCH_TRANSACTION_BY_ID: createSagaAction('CASHBACK_FETCH_TRANSACTION_BY_ID'),
    CASHBACK_NEW_COUPON: 'CASHBACK_NEW_COUPON',
};

export const actions = {
    fetchTransactions: (callback?: ICallback) => ({
        type: constants.CASHBACK_FETCH_TRANSACTIONS.ACTION,
        callback
    }),
    fetchTransactionById: (id: number, callback?: ICallback) => ({
        type: constants.CASHBACK_FETCH_TRANSACTION_BY_ID.ACTION,
        id,
        callback
    }),
    createCupom: (formData = {}, callback?: ICallback) => ({
        type: constants.CASHBACK_CREATE_COUPON.ACTION,
        formData,
        callback
    }),
    cancelCoupon: (couponId: number, callback?: ICallback) => ({
        type: constants.CASHBACK_CANCEL_COUPON.ACTION,
        couponId,
        callback
    }),
    newCoupon: () => ({
        type: constants.CASHBACK_NEW_COUPON
    })
    
};

const ACTION_HANDLERS = {
    //CASHBACK_FETCH_TRANSACTIONS
    [constants.CASHBACK_FETCH_TRANSACTIONS.ACTION]: (state: CashbackStateType) => {
        return {
            ...state, 
            error: false, 
            isLoading: true
        };
    },
    [constants.CASHBACK_FETCH_TRANSACTIONS.SUCCESS]: (state: CashbackStateType, action: {
        payload: ITransactions
    }) => {
        return {
            ...state, 
            error: false, 
            isLoading: false,
            balance: action.payload.balance,
            statements: action.payload.statements   
        };
    },
    [constants.CASHBACK_FETCH_TRANSACTIONS.FAILED]: (state: CashbackStateType) => {
        return {...state, error: true, isLoading: false};
    },
    //CASHBACK_FETCH_TRANSACTION
    [constants.CASHBACK_FETCH_TRANSACTION_BY_ID.ACTION]: (state: CashbackStateType) => {
        return {
            ...state, 
            error: false, 
            isFetchingCoupon: true
        };
    },
    [constants.CASHBACK_FETCH_TRANSACTION_BY_ID.SUCCESS]: (state: CashbackStateType, action: {
        payload: ICoupon
    }) => {
        return {
            ...state, 
            error: false, 
            isFetchingCoupon: false,
            coupon: {
                ...action.payload,
                loaded: true
            }
        };
    },
    [constants.CASHBACK_FETCH_TRANSACTION_BY_ID.FAILED]: (state: CashbackStateType) => {
        return {...state, error: true, isFetchingCoupon: false};
    },

    //CASHBACK_CREATE_CUPOM
    [constants.CASHBACK_CREATE_COUPON.ACTION]: (state: CashbackStateType) => {
        return { ...state, error: false, isCreatingCoupon: true };
    },
    [constants.CASHBACK_CREATE_COUPON.SUCCESS]: (state: CashbackStateType, action: {
        payload: {
            newCoupon: ICoupon
        }
    }) => {
        return { 
            ...state, 
            error: false, 
            isCreatingCoupon: false,
            coupon: action.payload.newCoupon
        };
    },
    [constants.CASHBACK_CREATE_COUPON.FAILED]: (state: CashbackStateType, action: {
        payload: {
            error: string
        }
    }) => {
        return { 
            ...state, 
            error: true, 
            isCreatingCoupon: false,
            createError: action?.payload?.error ?? ''
        };
    },

    // CASHBACK_CANCEL_COUPON
    [constants.CASHBACK_CANCEL_COUPON.ACTION]: (state: CashbackStateType) => {
        return { ...state, error: false, isCancelingCoupon: true };
    },
    [constants.CASHBACK_CANCEL_COUPON.SUCCESS]: (state: CashbackStateType) => {
        return { 
            ...state, 
            error: false,
            coupon: {
                ...state.coupon,
                canceled: true,
            },
            isCancelingCoupon: false,
        };
    },
    [constants.CASHBACK_CANCEL_COUPON.FAILED]: (state: CashbackStateType) => {
        return { ...state, error: true, isCancelingCoupon: false };
    },

    // CASHBACK_NEW_TRANSACTION
    [constants.CASHBACK_NEW_COUPON]: (state: CashbackStateType) => {
        return { 
            ...state, 
            coupon: undefined
        };
    },
};

const initialState: CashbackStateType = {
    statements: [],
    balance: 0,
    formattedBalance: '',
    isLoading: false,
    isCreatingCoupon: false,
    isCancelingCoupon: false,
    isFetchingCoupon: false,
    coupon: undefined,
    error: false,
    createError: '',
};

export default createReducer(initialState, (state: CashbackStateType, action: any) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : { ...state, isLoading: false }
});