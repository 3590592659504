import styled from "styled-components";

export const Container = styled.div`
    width: 37.5rem;
    @media (max-width: 720px) {
        width: 20rem;
    }


    img {
        width: 100%;
        height: 7.8rem;
        @media (max-width: 720px) {
            height: 6rem;
        }
    }
`;