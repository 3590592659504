import { createSagaAction } from '../../../shared/sagas'
import { createReducer } from '../../../shared/reducers'

// ------------------------------------
// Constants
// ------------------------------------
export const constants = {
  STUDENT_FETCH_BY_ID: createSagaAction('STUDENT_FETCH_BY_ID'),
  STUDENT_FETCH_SCORE_TORO: createSagaAction('STUDENT_FETCH_SCORE_TORO'),
  STUDENT_FETCH_REPORT_CFP: createSagaAction('STUDENT_FETCH_REPORT_CFP'),
  STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT: createSagaAction('STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT'),
  STUDENT_EDIT: createSagaAction('STUDENT_EDIT'),
  STUDENT_CHANGE_PASSWORD: createSagaAction('STUDENT_CHANGE_PASSWORD'),
  STUDENT_STORE_COMMUNICATIONS_CHANNELS: createSagaAction('STUDENT_STORE_COMMUNICATIONS_CHANNELS'),
  STUDENT_SET_CHANNELS: 'STUDENT_SET_CHANNELS',
  STUDENT_SCHEDULED_TEST: createSagaAction('STUDENT_SCHEDULED_TEST'),
  STUDENT_TRACK_DOWNLOAD_REPORT: createSagaAction('STUDENT_TRACK_DOWNLOAD_REPORT'),
  CFP_FETCH_REPORT_SIMULATED_PDF: createSagaAction('CFP_FETCH_REPORT_SIMULATED_PDF'),
  STUDENT_SCORE_INFORMATION_BY_TYPE: createSagaAction('STUDENT_SCORE_INFORMATION_BY_TYPE'),
}

// ------------------------------------
// Action creators
// ------------------------------------
export const actions = {
  fetchStudentsById: (id, next) => ({
    type: constants.STUDENT_FETCH_BY_ID.ACTION,
    id,
    next,
  }),
  fetchScoreToro: (params, success = null, error = null) => ({
    type: constants.STUDENT_FETCH_SCORE_TORO.ACTION,
    params,
    success,
    error
  }),
  fetchReportCFP: (courseClassId, courseId, next) => ({
    type: constants.STUDENT_FETCH_REPORT_CFP.ACTION,
    courseClassId,
    courseId,
    next
  }),
  fetchReportSimulatedFullResult: (courseClassId, courseId, classId, next) => ({
    type: constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.ACTION,
    courseClassId,
    courseId,
    classId,
    next
  }),
  editStudent: (formData, next) => ({
    type: constants.STUDENT_EDIT.ACTION,
    formData,
    next,
  }),
  changePassword: (formData, next) =>({
    type: constants.STUDENT_CHANGE_PASSWORD.ACTION,
    formData,
    next,
  }),
  setChannels: (channels) => ({
    type: constants.STUDENT_SET_CHANNELS,
    channels
  }),
  storeCommunicationsChannels: (formData, success, error) => ({
    type: constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.ACTION,
    formData,
    success,
    error
  }),
  saveScheduledTest: (formData, success, error) => ({
    type: constants.STUDENT_SCHEDULED_TEST.ACTION,
    formData,
    success,
    error
  }),
  trackDownloadReport: (formData) => ({
    type: constants.STUDENT_TRACK_DOWNLOAD_REPORT.ACTION,
    formData
  }),

  handleCfpSimulado: (userId, courseId, classId, media, start, end, kind, classes) => ({
    type: constants.CFP_FETCH_REPORT_SIMULATED_PDF.ACTION,
    userId,
    courseId,
    classId,
    media,
    start,
    end,
    kind,
    classes
  }),
  getScoreToroSugestionsByTypes: (categoryCourseId, typeReportId, categoryReportId) => ({
    type: constants.STUDENT_SCORE_INFORMATION_BY_TYPE.ACTION,
    categoryCourseId,
    typeReportId,
    categoryReportId
  })
}


// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // STUDENT_FETCH_BY_ID
  [constants.STUDENT_FETCH_BY_ID.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_FETCH_BY_ID.SUCCESS]: (state, action) => {
    return {
      ...state,
      user: { ...action.payload },
      isLoading: false,
    }
  },
  [constants.STUDENT_FETCH_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_FETCH_SCORE_TORO
  [constants.STUDENT_FETCH_SCORE_TORO.ACTION]: (state) => {
    return {
      ...state,
      error: false,
      isLoadingScoreToro: true
    }
  },
  [constants.STUDENT_FETCH_SCORE_TORO.SUCCESS]: (state, action) => {
    return {
      ...state,
      scoreToro: { ...action.payload },
      isLoadingScoreToro: false,
    }
  },
  [constants.STUDENT_FETCH_SCORE_TORO.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isLoadingScoreToro: false
    }
  },

  // STUDENT_FETCH_REPORT_CFP
  [constants.STUDENT_FETCH_REPORT_CFP.ACTION]: (state) => {
    return {
      ...state,
      error: false,
      isFetchingReportCFP: true,
      reportCFP: [],
    }
  },
  [constants.STUDENT_FETCH_REPORT_CFP.SUCCESS]: (state, action) => {
    return {
      ...state,
      isFetchingReportCFP: false,
      reportCFP: action.payload
    }
  },
  [constants.STUDENT_FETCH_REPORT_CFP.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isFetchingReportCFP: false
    }
  },

  // STUDENT_REPORT_SIMULATED_FULLRESULT
  [constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.ACTION]: (state) => {
    return {
      ...state,
      error: false,
      isFetchingReportSimulatedFullResult: true,
      reportSimulatedFullResult: [],
    }
  },
  [constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.SUCCESS]: (state, action) => {
    return {
      ...state,
      isFetchingReportSimulatedFullResult: false,
      reportSimulatedFullResult: action.payload
    }
  },
  [constants.STUDENT_FETCH_REPORT_SIMULATED_FULLRESULT.FAILED]: (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.errorMessage,
      isFetchingReportSimulatedFullResult: false,
      reportSimulatedFullResult: []
    }
  },

  // STUDENT_EDIT
  [constants.STUDENT_EDIT.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_EDIT.SUCCESS]: (state, action) => {
    return {
      ...state,
      ...action.userServiceReturn,
      isLoading: false,
    }
  },
  [constants.STUDENT_EDIT.FAILED]: (state, action) => {
    return { ...state, error: true, errorMessage: action.errorMessage, isLoading: false }
  },

  // STUDENT_CHANGE_PASSWORD
  [constants.STUDENT_CHANGE_PASSWORD.ACTION]: (state) => {
    return { ...state, error: false, isLoading: true }
  },
  [constants.STUDENT_CHANGE_PASSWORD.SUCCESS]: (state) => {
    return { ...state, isLoading: false }
  },
  [constants.STUDENT_CHANGE_PASSWORD.FAILED]: (state, action) => {
    return { ...state, isLoading: false, error: true, errorMessage: action.errorMessage }
  },

  // STUDENT_STORE_COMMUNICATIONS_CHANNELS
  [constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.ACTION]: (state) => {
    return { ...state, error: false, isLoadingSaveChannels: true }
  },
  [constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.SUCCESS]: (state) => {
    return { ...state, isLoadingSaveChannels: false }
  },
  [constants.STUDENT_STORE_COMMUNICATIONS_CHANNELS.FAILED]: (state, action) => {
    return { ...state, isLoadingSaveChannels: false, error: true, errorMessage: action.errorMessage }
  },
  // STUDENT_SET_CHANNELS
  [constants.STUDENT_SET_CHANNELS]: (state, action) => {
    return { ...state, channels: { ...action.channels } }
  },

  //STUDENT_SCHEDULED_TEST
  [constants.STUDENT_SCHEDULED_TEST.ACTION]: (state) => {
    return { ...state, error: false, isLoadingScheduledTest: true }
  },
  [constants.STUDENT_SCHEDULED_TEST.SUCCESS]: (state) => {
    return { ...state, isLoadingScheduledTest: false }
  },
  [constants.STUDENT_SCHEDULED_TEST.FAILED]: (state, action) => {
    return {
      ...state,
      isLoadingScheduledTest: false,
      error: true,
      errorMessage: action.errorMessage
    }
  },

  //STUDENT_TRACK_DOWNLOAD_REPORT
  [constants.STUDENT_TRACK_DOWNLOAD_REPORT.ACTION]: (state) => {
    return { ...state, error: false, isTrackingDownloadReport: true }
  },
  [constants.STUDENT_TRACK_DOWNLOAD_REPORT.SUCCESS]: (state) => {
    return { ...state, isTrackingDownloadReport: false }
  },
  [constants.STUDENT_SCHEDULED_TEST.FAILED]: (state) => {
    return { ...state, error: true, isTrackingDownloadReport: false }
  },

  //CFP_FETCH_REPORT_SIMULATED_PDF
  [constants.CFP_FETCH_REPORT_SIMULATED_PDF.ACTION]: (state) => {
    return { ...state, error: false, isFetchCfpsimulado: true }
  },
  [constants.CFP_FETCH_REPORT_SIMULATED_PDF.SUCCESS]: (state, action) => {
    return { ...state, reportSimulado: action.payload, isFetchCfpsimulado: false }
  },
  [constants.CFP_FETCH_REPORT_SIMULATED_PDF.FAILED]: (state) => {
    return { ...state, error: true, isFetchCfpsimulado: false }
  },

  // STUDENT_SCORE_INFORMATION_BY_TYPE
  [constants.STUDENT_SCORE_INFORMATION_BY_TYPE.ACTION]: (state) => {
    return { ...state, error: false }
  },
  [constants.STUDENT_SCORE_INFORMATION_BY_TYPE.SUCCESS]: (state, action) => {
    return { ...state, scoreToroInformation: action.payload, error: false  }
  },
  [constants.STUDENT_SCORE_INFORMATION_BY_TYPE.FAILED]: (state) => {
    return { ...state, error: true }
  },
}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: false,
  errorMessage: '',
  isLoading: false,
  isLoadingScoreToro: false,
  isLoadingSaveChannels: false,
  isLoadingScheduledTest: false,
  isFetchingReportCFP: false,
  isFetchingReportSimulatedFullResult: false,
  isTrackingDownloadReport: false,
  isFetchCfpsimulado: true,
  channels: {
    whatsapp: false,
    phone: false,
    email: false,
  },
  scoreToro: {
    score: 0,
    average: 0,
    simulated_average: 0,
    accomplish_classes: 0,
    total_classes: 0,
    progress: 0
  },
  scoreToroInformation: [],
  reportSimulatedFullResult: [],
  reportCFP: [],
  reportSimulado: {},
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})