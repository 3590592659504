import styled from 'styled-components';

export const Container = styled.div`
    padding: 226px 117px;
    @media(max-width: 720px) {
        width: 99%;
        padding: 5px;
    }
`;

export const Box = styled.div`
    padding: 92px 65px 92px 83px;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);
    border-radius: 10px;

    h1 {
        font-size: 2.25rem;
        @media(max-width: 720px) {
            font-size: 2rem;
        }
        font-weight: 700;
        color: ${({ theme }) => theme.colors.primary};
    }

    h2 {
        font-size: 1.3125rem;
        @media(max-width: 720px) {
            font-size: 1.0612rem;
        }
        font-weight: 400;
    }

    h3 {
        font-weight: 700;
        font-size: 1.2rem;
        @media(max-width: 720px) {
            font-size: 1rem;
        }
    }

    
    p {
        margin: 2rem 0;
        font-size: 1.1rem;
        @media(max-width: 720px) {
            font-size: 0.9rem;
        }
        font-weight: 500;
        line-height: 1.8;
    }

    a {
        display:block;
        color: ${({ theme }) => theme.colors.warning};
        font-size: 1.1rem;
        font-weight: 500;
    }

    strong {
        display: block;
        font-size: 1.2rem;
    }

    .first-paragraph {
        margin-top: 3rem;
    }

    .last-paragraph {
        text-decoration: underline;
    }
`;