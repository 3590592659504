import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const EditPen: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.7069 4.58563L11.9144 1.7925C11.8215 1.69962 11.7113 1.62594 11.5899 1.57567C11.4686 1.5254 11.3385 1.49953 11.2072 1.49953C11.0759 1.49953 10.9458 1.5254 10.8245 1.57567C10.7031 1.62594 10.5929 1.69962 10.5 1.7925L2.79313 9.5C2.69987 9.59252 2.62593 9.70266 2.5756 9.82401C2.52528 9.94536 2.49959 10.0755 2.50001 10.2069V13C2.50001 13.2652 2.60536 13.5196 2.7929 13.7071C2.98043 13.8946 3.23479 14 3.50001 14H6.29313C6.4245 14.0004 6.55464 13.9747 6.67599 13.9244C6.79735 13.8741 6.90748 13.8001 7.00001 13.7069L14.7069 6C14.7998 5.90714 14.8734 5.79689 14.9237 5.67555C14.974 5.55421 14.9999 5.42415 14.9999 5.29281C14.9999 5.16147 14.974 5.03142 14.9237 4.91008C14.8734 4.78874 14.7998 4.67849 14.7069 4.58563ZM6.29313 13H3.50001V10.2069L9.00001 4.70688L11.7931 7.5L6.29313 13ZM12.5 6.7925L9.70688 4L11.2069 2.5L14 5.2925L12.5 6.7925Z"
        fill={color || '#ffffff'}
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

EditPen.defaultProps = defaultProps;

export default EditPen;
