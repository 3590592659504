import React from 'react';

import './styles.scss';

type Props = {
    title: string;
    onRenderIcon?: () => any;
};

export default function Button({
    title,
    onRenderIcon
}: Props) {
    return (
        <button className="podcast-listen-button">
            {
                onRenderIcon && onRenderIcon()
            }
            {title}
        </button>
    );
};