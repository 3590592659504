import React from 'react';

import { Container } from './styles';

type Props = {
  title: string;
  content: string;
  isHovering: boolean;
};

export default function Balloon({ title, content, isHovering }: Props) {
  return (
    <Container>
      {isHovering ? (
        <div className="balloon">
          <div className="balloon-title">{title}</div>
          <div className="balloon-content">{content}</div>
        </div>
      ) : null}
    </Container>
  );
}
