import styled from 'styled-components';
import Modal from 'react-modal';

export const CustomModal = styled(Modal)`
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0px;
  background: none;

  @media (max-width: 768px) {
    z-index: 1000;
    padding: 0;
  }
`;

export const ModalInformationsContent = styled.div`
  width: 668px;
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  border-radius: 16px;
  background: var(--escala-de-cinza-0, #fff);

  animation: animationOnOpen 0.3s ease-in-out;

  @keyframes animationOnOpen {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 22px;
  gap: 8px;
  align-self: stretch;

  border-bottom: 1px solid var(--escala-de-cinza-10, #efefef);

  > button {
    border: 0;
    background: none;
    width: 40px;
    height: 40px;

    cursor: pointer;

    > img {
      width: 20px;
      height: 20px;
    }
  }

  > h3 {
    width: 100%;
    color: var(--escala-de-cinza-100, var(--color-surface-Dark-0, #000));
    text-align: center;

    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.48px;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 16px;
  padding: 0px 22px 22px 22px;

  align-self: stretch;

  > div:not(:first-child) {
    > * {
      color: var(--escala-de-cinza-60, #636363);

      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.48px;

      margin-bottom: 12px;
    }
  }

  > span {
    color: var(--escala-de-cinza-30, #bbb);

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
  }
`;

export const HeaderMessageModal = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-3, 12px);

  > img {
    width: 40px;
    height: 40px;
  }

  > h3 {
    color: var(--escala-de-cinza-80, #252525);

    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.48px;
  }
`;

export const ButtonFooter = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px 10px;
  gap: 6px;
  align-self: stretch;

  border-radius: 5px;
  border: 1px solid var(--Primrias-Lils, #5768ff);
  background: var(--Primrias-Lils, #5768ff);

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  > p {
    color: var(--escala-de-cinza-10, #efefef);
    text-align: center;

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.42px;
  }

  > img {
    width: 18px;
    height: 18px;
  }
`;

export const ImageNotificationArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 350px;

  > img {
    max-height: 350px;
  }
`;
