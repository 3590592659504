import styled from 'styled-components';
import { Link } from "react-router-dom";
import { SmileOutlined, FrownOutlined } from '@ant-design/icons';

export const Text = styled.p`
    margin-bottom: 1.875em;
    line-height: 1.375em;
    text-align: center;
    font-weight: 500;
    font-size: 1.125em;
    color: ${({ theme }) => theme.colors.gray_500};

    @media(max-width: 650px) {
        font-weight: 400;
        font-size: 1em;
    }
`;

export const Buttons = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 1.875em;

    @media(max-width: 650px) {
        display: block;
    }
`;

export const Button = styled(Link)`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 1.1525em;
    border: 1px solid ${({ theme }) => theme.colors.gray_800};
    border-radius: 0.3125em;
    background-color: ${({ theme }) => theme.colors.white};
    height: 10em;
    justify-content: center;
    align-items: center;
    line-height: 1.125em;
    font-size: 0.875em;
    color: ${({ theme }) => theme.colors.gray_500};

    @media(max-width: 650px) {
        flex-direction: row;
        margin-bottom: 0.9375em;
        height: 3.125em;
        gap: 0.9375em;
        justify-content: start;
        padding-left: 1.25em;
    }
`;

export const IconSmile = styled(SmileOutlined)`
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.gray_800};
`;

export const IconFrown = styled(FrownOutlined)`
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.gray_800};
`;