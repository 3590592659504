import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'
import { IGabaritoState } from '../../shared/Models/gabarito/IGabaritoState';
import { IGabarito } from '../../shared/Models/gabarito/IGabarito';
import { IGabaritoModule } from '../../shared/Models/gabarito/IGabaritoModule';
import { IGabaritoStudent } from '../../shared/Models/gabarito/IGabaritoStudent';
import { AnswerInput } from '../../hooks/useGabaritoro';
import { IModule } from '../../shared/Models/gabarito/IModule';
import { formattedResultGabaritos } from '../selectors/gabarito';


// Types
export type SuccessCallback = null | ((payload: unknown) => void);
export type ErrorCallback = (() => void ) | null;

export interface FormDataStoreLeadProps {
    name: string;
    phone: string;
    email: string;
    gabaritoId: number;
};

export type FormDataMakeTokenProps = {
    gabaritoId: number;
    userId: number;
    channels: {
        whatsapp: boolean;
        email: boolean;
        phone: boolean;
    },
    modulesId?: Array<number>;
};

export type FormDataUpdateByIdProps = {
    answer: AnswerInput
};

export type FormDataFillAnswersProps = {
    answers: AnswerInput[]
};

// Constants
export const constants = {
    GABARITO_FETCH_ALL: createSagaAction('GABARITO_FETCH_ALL'),
    GABARITO_STORE_LEAD: createSagaAction('GABARITO_STORE_LEAD'),
    GABARITO_GET_STUDENT_BY_TOKEN: createSagaAction('GABARITO_GET_STUDENT_BY_TOKEN'),
    GABARITO_GET_GABARITO_BY_TOKEN: createSagaAction('GABARITO_GET_RESULT_STUDENT_BY_TOKEN'),
    GABARITO_MAKE_TOKEN_EXISTS_USER: createSagaAction('GABARITO_MAKE_TOKEN_EXISTS_USER'),
    GABARITO_FILL_ANSWERS: createSagaAction('GABARITO_FILL_ANSWERS'),
    GABARITO_GET_MODULES_BY_ID: createSagaAction('GABARITO_GET_MODULES_BY_ID'),
    GABARITO_RESEND_RESULT: createSagaAction('GABARITO_RESEND_RESULT'),
};

export const actions = {
    fetchAll: (filters = {}, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_FETCH_ALL.ACTION,
        filters,
        success,
        error,
    }),
    storeLead: (formData: FormDataStoreLeadProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_STORE_LEAD.ACTION,
        formData,
        success,
        error
    }),
    getStudentByToken: (token: string, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_GET_STUDENT_BY_TOKEN.ACTION,
        token,
        success,
        error
    }),
    getGabaritoByToken: (token: string, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_GET_GABARITO_BY_TOKEN.ACTION,
        token,
        success,
        error
    }),
    makeTokenExistsStudent: (formData: FormDataMakeTokenProps, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_MAKE_TOKEN_EXISTS_USER.ACTION,
        formData,
        success,
        error,
    }),
    fillAnswers: (formData: FormDataFillAnswersProps, token: string, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_FILL_ANSWERS.ACTION,
        formData,
        token,
        success,
        error
    }),
    getModulesByIdGabarito: (gabaritoId: number, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_GET_MODULES_BY_ID.ACTION,
        gabaritoId,
        success,
        error
    }),
    resendResult:(gabaritoId: number, email: string, success: SuccessCallback = null, error: ErrorCallback = null) => ({
        type: constants.GABARITO_RESEND_RESULT.ACTION,
        gabaritoId,
        email,
        success,
        error
    }),
};

const ACTION_HANDLERS = {
    // FETCH_ALL
    [constants.GABARITO_FETCH_ALL.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: true }
    },
    [constants.GABARITO_FETCH_ALL.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: false }
    },
    [constants.GABARITO_FETCH_ALL.SUCCESS]: (state: IGabaritoState, action: { payload: IGabarito[] }) => {

        const formattedPayload = formattedResultGabaritos(action.payload);
        
        return {
            ...state,
            error: false,
            isLoading: false,
            gabaritos: formattedPayload
        }
    },

    // STORE_LEAD
    [constants.GABARITO_STORE_LEAD.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false, storeLeadLoading: true }
    },
    [constants.GABARITO_STORE_LEAD.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: false, storeLeadLoading: false }
    },
    [constants.GABARITO_STORE_LEAD.SUCCESS]: (state: IGabaritoState, action: { payload: { token: string } }) => {
        return {
            ...state,
            error: false,
            storeLeadLoading: false,
            token: action.payload.token
        }
    },

    // GET_LEAD_BY_TOKEN
    [constants.GABARITO_GET_STUDENT_BY_TOKEN.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: true }
    },
    [constants.GABARITO_GET_STUDENT_BY_TOKEN.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: false }
    },
    [constants.GABARITO_GET_STUDENT_BY_TOKEN.SUCCESS]: (state: IGabaritoState, action: { payload: IGabaritoStudent }) => {
        return {
            ...state,
            error: false,
            isLoading: false,
            student: { ...action.payload }
        }
    },

    // GET_GABARITO_BY_TOKEN
    [constants.GABARITO_GET_GABARITO_BY_TOKEN.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: true }
    },
    [constants.GABARITO_GET_GABARITO_BY_TOKEN.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: false }
    },
    [constants.GABARITO_GET_GABARITO_BY_TOKEN.SUCCESS]: (state: IGabaritoState, action: { payload: IGabarito }) => {
        return {
            ...state,
            error: false,
            isLoading: false,
            gabarito: { ...action.payload }
        }
    },

    // GABARITO_MAKE_TOKEN_EXISTS_USER
    [constants.GABARITO_MAKE_TOKEN_EXISTS_USER.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: true }
    },
    [constants.GABARITO_MAKE_TOKEN_EXISTS_USER.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: false, isLoading: false }
    },
    [constants.GABARITO_MAKE_TOKEN_EXISTS_USER.SUCCESS]: (state: IGabaritoState, action: { payload: { token: string } }) => {
        return {
            ...state,
            error: false,
            isLoading: false,
            token: action.payload.token
        }
    },
    
    // GABARITO_FILL_ANSwERS
    [constants.GABARITO_FILL_ANSWERS.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false, finishLoading: true }
    },
    [constants.GABARITO_FILL_ANSWERS.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: false, finishLoading: false }
    },
    [constants.GABARITO_FILL_ANSWERS.SUCCESS]: (state: IGabaritoState, action: { payload: IGabarito }) => {
        return {
            ...state,
            error: false,
            finishLoading: false,
            gabarito: { ...action.payload }
        }
    },

    // GABARITO_GET_MODULES_BY_ID_GABARITO
    [constants.GABARITO_GET_MODULES_BY_ID.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false }
    },
    [constants.GABARITO_GET_MODULES_BY_ID.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: true }
    },
    [constants.GABARITO_GET_MODULES_BY_ID.SUCCESS]: (state: IGabaritoState, action: { payload: IModule[] }) => {
        return {
            ...state,
            error: false,
            finishLoading: false,
            modules: [...action.payload]
        }
    },

    // GABARITO_RESEND_RESULT
    [constants.GABARITO_RESEND_RESULT.ACTION]: (state: IGabaritoState) => {
        return { ...state, error: false }
    },
    [constants.GABARITO_RESEND_RESULT.FAILED]: (state: IGabaritoState) => {
        return { ...state, error: true, }
    },
    [constants.GABARITO_RESEND_RESULT.SUCCESS]: (state: IGabaritoState, action: { payload: IModule[] }) => {
        return { ...state, error: false }
    }
};

export const initialState: IGabaritoState = {
    error: false,
    message: '',
    isLoading: false,
    gabaritos: [] as IGabarito[],
    modules: [] as IModule[],
    saveLoading: false,
    storeLeadLoading: false,
    sendEmailLoading: false,
    finishLoading: false,
    gabarito: {} as IGabaritoModule,
    student: {} as IGabaritoStudent,
    token: '',
};

export default createReducer(initialState, (state: IGabaritoState, action: any) => {

    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : { ...state, isLoading: false }
});