import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import { ModalContextProvider } from '../../../contexts/cfpModalContext';
//
// antd
// import { Button } from 'antd'
// import { FilePdfOutlined, ArrowRightOutlined } from '@ant-design/icons'

//
// general components
import PropTypes from 'prop-types';
import { Layout, Spin } from 'antd';
import { Redirect, useParams } from 'react-router-dom';
import ClassesList from '../components/ClassesList';
import MainLayout from '../../../components/MainLayout';
import ClassSidebar from '../components/class/ClassSidebar.component';
/*
  **Troquei o componente mobile pelo desk na linha 93 e inseri o ClassSidebar**
  import MobileClassContent from './components/class/mobile/MobileClassContent.component'
*/
import DesktopMockContent from '../components/class/desktop/DesktopMockContent.component';
import DesktopClassContent from '../components/class/desktop/DesktopClassContent.component';
import PerformanceButton from '../components/Performance/PerformanceButton';
//
// redux + selectors
import * as courseSelectors from '../../../redux/selectors/course';
import { actions as courseActions } from '../../../redux/modules/course';
import { actions as podcastActions } from '../../../redux/modules/podcast';

import ReportCFP from '../components/Performance/ReportCFP';
import Performance from '../components/Performance/Layout';

import {
  allowedScoreToro,
  allowedFeature,
  allowedReportDownload
} from '../../../helpers/permissions';
  import {
    MainClass,
    HeaderContent,
    Title,
    TagTitle
} from './styles';
import { getTestsToPrint } from '../../../redux/api/question';

// import ModalCFP from '../../../components/ModalCFP';

// Course context creation
export const CourseContext = React.createContext({});

const propTypes = {
  course: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  fetchPodcast: PropTypes.func.isRequired,
};

function CourseDetail({
  getCourseDetails,
  fetchPodcast,
  isLoading,
  course
}) {
  const [loadingDownloadText, setLoadingDownloadText] = useState(false);
  const [classStep, setClassStep] = useState('file');
  const [automaticDownloadPDF, setAutomaticDownloadPDF] = useState(false);
  const [selectedClass, setSelectedClass] = useState();
  const [reviewStarted, setReviewStarted] = useState(false);
  const [classStepNumber, setClassStepNumber] = useState(1);
  const [lectureIdToReview, setLectureIdToReview] = useState();
  const [blockByCourseClassDate, setBlockByCourseClassDate] = useState(false);
  //
  // helper to have the total wrong questions before reset the list with new ansers
  const [totalWrongQuestionsHelper, setTotalWrongQuestionsHelper] = useState();
  const { courseId, courseClassId } = useParams();
  const { course_category } = course;

  // const authState = useSelector((state) => state.auth);

  useEffect(() => {
    getCourseDetails(courseId, courseClassId, (payload) => {
      const { report } = payload;
      if (report && report.length && !report[0].on_progress) setBlockByCourseClassDate(true);
    });

    if (allowedFeature('podcast')) {
      fetchPodcast()
    }

    // eslint-disable-next-line
  }, []);

  const handlePrintTest = async (id) => {
    setLoadingDownloadText(true);
    const {filename} = await getTestsToPrint(id ? id : selectedClass?.id)

    if(filename !== "") {
      setLoadingDownloadText(false)
      window.open(filename, '_blank');
    }
  }

  if (blockByCourseClassDate) return <Redirect to={'/courses'} />;
  return (
    <MainLayout
      className="course"
      title={[{ label: 'Meus cursos' }, { label: course.title }]}
      course={course}
      setSelectedClass={setSelectedClass}
      >
      <CourseContext.Provider
        value={{
          course,
          classStep,
          setClassStep,
          reviewStarted,
          setReviewStarted,
          selectedClass,
          classStepNumber,
          setSelectedClass,
          lectureIdToReview,
          setClassStepNumber,
          setLectureIdToReview,
          totalWrongQuestionsHelper,
          setTotalWrongQuestionsHelper,
          automaticDownloadPDF,
          setAutomaticDownloadPDF,
          course_category,
          handlePrintTest,
          loadingDownloadText
        }}
      >
        {
          isLoading ? (
            <div className="courseDetail-loading">
              <Spin />
              <span>
                Carregando aulas...
              </span>
            </div>
          ) : (
            <>
                {/* Travando para o modal não aparecer nunca */}
                {/* <ModalContextProvider>
                  {<ModalCFP course={course.course_category} username={authState.user.name} />}
                </ ModalContextProvider> */}
              <PerformanceButton
                course={course}
                setSelectedClass={setSelectedClass}
              />
              <ClassesList />

                {selectedClass && selectedClass !== 'locked' && (
                  <>
                    <Layout.Content className={`course-content`}>
                      {selectedClass.simulado && (
                        <div style={{backgroundColor: 'white', padding: '25px'}}>
                          <TagTitle>Aula {selectedClass.order}</TagTitle>
                          <DesktopMockContent />
                        </div>
                      )}
                    </Layout.Content>

                    {!selectedClass.simulado && (
                      <MainClass>
                        <div className='header'>
                          <HeaderContent className='header-content'>
                            <TagTitle>Aula {selectedClass.order}</TagTitle>
                            <Title>{selectedClass.titulo}</Title>
                          </HeaderContent>
                        </div>
                        <div className='main'>
                          <DesktopClassContent />
                        </div>
                        <div className='aside'>
                          <ClassSidebar />
                        </div>
                      </MainClass>
                    )}
                  </>
                )}

                {selectedClass && selectedClass === 'locked' && <>Esta aula ainda não foi liberada</>}
                {!selectedClass && (
                  <>
                    {
                      allowedScoreToro(course.id_course_category) ? (
                        <Performance />
                      ) : allowedReportDownload(course.id_course_category) ? (
                        <ReportCFP />
                      ) : (
                        <div className="page">
                          <div className="course-content">
                            <div className="course-section-content">
                              <h2>
                                Curso <span>{course.title}</span>
                              </h2>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: course.descricao,
                                }}
                              ></p>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </>
                )}

            </>
          )
        }
      </CourseContext.Provider>
    </MainLayout>
  );
}

const mapStateToProps = (state, props) => ({
  course: courseSelectors.course(state, props),
  isLoading: state.course.isFetchingCourse,
});
const mapDispatchToProps = (dispatch) => ({
  getCourseDetails: (courseId, courseClassId, next) => dispatch(courseActions.getCourseDetails(courseId, courseClassId, next)),
  fetchPodcast: () => dispatch(podcastActions.fetchAll()),
});

CourseDetail.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(CourseDetail);
