import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const ErrorMessageIcon: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4604 13.6645C14.5127 13.7168 14.5541 13.7788 14.5824 13.8471C14.6107 13.9154 14.6253 13.9886 14.6253 14.0625C14.6253 14.1364 14.6107 14.2096 14.5824 14.2779C14.5541 14.3462 14.5127 14.4082 14.4604 14.4605C14.4082 14.5127 14.3461 14.5542 14.2778 14.5825C14.2095 14.6108 14.1364 14.6253 14.0624 14.6253C13.9885 14.6253 13.9154 14.6108 13.8471 14.5825C13.7788 14.5542 13.7167 14.5127 13.6645 14.4605L8.99995 9.79524L4.33542 14.4605C4.22987 14.566 4.08671 14.6253 3.93745 14.6253C3.78818 14.6253 3.64503 14.566 3.53948 14.4605C3.43393 14.3549 3.37463 14.2118 3.37463 14.0625C3.37463 13.9132 3.43393 13.7701 3.53948 13.6645L8.20471 9.00001L3.53948 4.33548C3.43393 4.22993 3.37463 4.08677 3.37463 3.93751C3.37463 3.78824 3.43393 3.64509 3.53948 3.53954C3.64503 3.43399 3.78818 3.37469 3.93745 3.37469C4.08671 3.37469 4.22987 3.43399 4.33542 3.53954L8.99995 8.20477L13.6645 3.53954C13.77 3.43399 13.9132 3.37469 14.0624 3.37469C14.2117 3.37469 14.3549 3.43399 14.4604 3.53954C14.566 3.64509 14.6253 3.78824 14.6253 3.93751C14.6253 4.08677 14.566 4.22993 14.4604 4.33548L9.79518 9.00001L14.4604 13.6645Z"
        fill={color ? color : '#d81e5b'}
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

ErrorMessageIcon.defaultProps = defaultProps;

export default ErrorMessageIcon;
