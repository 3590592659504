import React, { useContext } from 'react';

//
// context
import { CourseContext } from '../../ClassContent/CourseDetail.container';

import IconDownloadStep from '../../../../img/svg/iconDownload/iconDownloadStep/iconDownloadStep';
import IconVideoStep from '../../../../img/svg/iconVideo/iconVideoStep/iconVideoStep';
import IconVideoStepHover from '../../../../img/svg/iconVideo/iconVideoStep/iconVideoStepHover';
import IconVideoStepDone from '../../../../img/svg/iconVideo/iconVideoStep/iconVideoStepDone';
import IconVideoStepHorizontalDone from '../../../../img/svg/iconVideo/iconVideoStepHorizontal/iconVideoStepHorizontalDone.svg';
import IconVideoLastStep from '../../../../img/svg/iconVideo/iconVideoStep/IconVideoLastStep';
import IconVideoLastStepHover from '../../../../img/svg/iconVideo/iconVideoStep/IconVideoLastStepHover';
import IconVideoLastStepDone from '../../../../img/svg/iconVideo/iconVideoStep/IconVideoLastStepDone';
import IconVideoStepHorizontal from '../../../../img/svg/iconVideo/iconVideoStepHorizontal/iconVideoStepHorizontal.svg';
import IconVideoLastStepHorizontal from '../../../../img/svg/iconVideo/iconVideoStepHorizontal/iconVideoLastStepHorizontal.svg';
import IconVideoLastStepHorizontalDone from '../../../../img/svg/iconVideo/iconVideoStepHorizontal/iconVideoLastStepHorizontalDone.svg';

import IconPenLastStep from '../../../../img/svg/iconPen/iconPenStep/iconPenLastStep';
import IconPenLastStepHover from '../../../../img/svg/iconPen/iconPenStep/iconPenLastStepHover';
import IconPenLastStepDone from '../../../../img/svg/iconPen/iconPenStep/iconPenLastStepDone';
import IconPenStepHorizontal from '../../../../img/svg/iconPen/iconPenStepHorizontal/iconPenStepHorizontal.svg';
import IconPenLastStepHorizontal from '../../../../img/svg/iconPen/iconPenStepHorizontal/iconPenLastStepHorizontal.svg';
import IconPenLastStepHorizontalDone from '../../../../img/svg/iconPen/iconPenStepHorizontal/iconPenLastStepHorizontalDone.svg';

import IconDownloadStepHover from '../../../../img/svg/iconDownload/iconDownloadStep/IconDownloadStepHover';
import IconDownloadStepDone from '../../../../img/svg/iconDownload/iconDownloadStep/iconDownloadStepDone';
import IconDownloadLastStep from '../../../../img/svg/iconDownload/iconDownloadStep/iconDownloadLastStep';
import IconDownloadLastStepHover from '../../../../img/svg/iconDownload/iconDownloadStep/iconDownloadLastStepHover';
import IconDownloadLastStepDone from '../../../../img/svg/iconDownload/iconDownloadStep/iconDownloadLastStepDone';
import IconDownloadStepHorizontal from '../../../../img/svg/iconDownload/iconDowloadStepHorizonal/iconDownloadStepHorizonal.svg';
import IconDownloadStepHorizontalDone from '../../../../img/svg/iconDownload/iconDowloadStepHorizonal/iconDownloadStepHorizonalDone.svg';
import IconDownloadLastStepHorizontal from '../../../../img/svg/iconDownload/iconDowloadStepHorizonal/iconDownloadLastStepHorizontal.svg';
import IconDownloadLastStepHorizontalDone from '../../../../img/svg/iconDownload/iconDowloadStepHorizonal/iconDownloadLastStepHorizontalDone.svg';

function ClassSteps() {
  const { selectedClass } = useContext(CourseContext);

  const classSteps = [];

  // classSteps.push({
  //   id: 'intro',
  //   title: 'Introdução da aula.',
  //   buttonTitle: 'Ver',
  //   icon: <FilePdfOutlined />,
  //   step: 'intro',
  // })

  //
  // check file step
  if (selectedClass.pdf_file) {
    classSteps.push({
      id: 'file',
      title: 'Baixar material',
      buttonTitle: 'Baixar PDF',

      iconDesktop: <IconDownloadStep width={'66px'} />,
      iconHoverDesktop: <IconDownloadStepHover width={'66px'} />,
      iconDoneDesktop: <IconDownloadStepDone width={'66px'} />,

      lastIconDesktop: <IconDownloadLastStep width={'66px'} />,
      lastHoverIconDesktop: <IconDownloadLastStepHover width={'66px'} />,
      lastDoneIconDesktop: <IconDownloadLastStepDone width={'66px'} />,

      iconMobile: <img src={IconDownloadStepHorizontal} alt="icon" width={'66px'}/>,
      iconDoneMobile: <img src={IconDownloadStepHorizontalDone} alt="icon" width={'66px'} />,

      lastIconMobile: <img src={IconDownloadLastStepHorizontal} alt="icon" width={'66px'} />,
      lastIconDoneMobile: <img src={IconDownloadLastStepHorizontalDone} alt="icon" width={'66px'} />,

      step: 'file',
    });
  }

  const existVideo = Object.keys(selectedClass).find(element => element === 'aula_videos') &&
  selectedClass.video_embed  ?
    selectedClass.video_embed:
    selectedClass.aula_videos?.length > 0
      ? selectedClass.aula_videos[0].video_embed
      : ''

  // check video step
  if (existVideo) {
    classSteps.push({
      id: 'video',
      title: 'Assistir aula',
      buttonTitle: 'Ver Vídeo',

      iconDesktop: <IconVideoStep width={'66px'} />,
      iconHoverDesktop: <IconVideoStepHover width={'66px'} />,
      iconDoneDesktop: <IconVideoStepDone width={'66px'} />,

      lastIconDesktop: <IconVideoLastStep width={'66px'} />,
      lastHoverIconDesktop: <IconVideoLastStepHover width={'66px'} />,
      lastDoneIconDesktop: <IconVideoLastStepDone width={'66px'} />,

      iconMobile: <img src={IconVideoStepHorizontal} alt="icon" width={'66px'} />,
      iconDoneMobile: <img src={IconVideoStepHorizontalDone} alt="icon" width={'66px'} />,
      lastIconMobile: <img src={IconVideoLastStepHorizontal} alt="icon" width={'66px'} />,
      lastIconDoneMobile: <img src={IconVideoLastStepHorizontalDone} alt="icon" width={'66px'} />,

      step: 'video',
    });
  }

  //
  // check lecture step
  if (selectedClass.have_lecture) {
    classSteps.push({
      id: 'lecture',
      title: 'Fazer Prova',
      buttonTitle: 'Fazer Prova',

      iconDesktop: <IconPenLastStep width={'66px'} />,
      iconHoverDesktop: <IconPenLastStepHover width={'66px'} />,
      iconDoneDesktop: <IconPenLastStepDone width={'66px'} />,

      lastIconDesktop: <IconPenLastStep width={'66px'} />,
      lastHoverIconDesktop: <IconPenLastStepHover width={'66px'} />,
      lastDoneIconDesktop: <IconPenLastStepDone width={'66px'} />,

      iconMobile: <img src={IconPenStepHorizontal} alt="icon" width={'66px'} />,
      iconDoneMobile: <img src={IconPenLastStepHorizontalDone} alt="icon" width={'66px'} />,

      lastIconMobile: <img src={IconPenLastStepHorizontal} alt="icon" width={'66px'} />,
      lastIconDoneMobile: <img src={IconPenLastStepHorizontalDone} alt="icon" width={'66px'} />,

      step: 'lecture',
    });
  }

  //
  // TODO: same as above
  // classSteps.push({
  //   id: 'lectureRedo',
  //   title: 'Boa, agora, o que você acha de refazermos as questões que você errou?',
  //   buttonTitle: 'Refazer',
  //   icon: <FormOutlined />,
  //   step: 'lectureRedo',
  // })

  return classSteps;
}

export default ClassSteps;
