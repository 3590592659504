import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconBook: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg
      width="66"
      height="96"
      viewBox="0 0 66 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
    <rect y="15" width="66" height="66" rx="33" fill="#5768FF"/>
    <path
      d="M32.9999 60.1833H21.0166C19.1332 60.1833 17.5833 58.6333 17.5833 56.75V39.6166C17.5833 37.7333 19.1332 36.1833 21.0166 36.1833H22.5333"
      stroke={color || '#FFFFFF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.0001 36.2V60.1833C32.4834 59.35 30.9501 57.1333 27.9834 55.9167C26.6334 55.3667 25.3501 55.1834 24.3501 55.1334C23.4334 55.1001 22.7334 54.3334 22.7334 53.4334V34.45C22.7334 33.4833 23.5168 32.7001 24.4834 32.7334C25.4334 32.7667 26.5667 32.9166 27.7834 33.2833C30.3334 34.0333 32.0834 35.3833 33.0001 36.2Z"
      stroke={color || '#FFFFFF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.4167 39.6166V56.75C48.4167 58.6333 46.8667 60.1833 44.9833 60.1833H33V36.2H44.9833C46.8667 36.2 48.4167 37.7333 48.4167 39.6166Z"
      stroke={color || '#FFFFFF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.1167 41.2833H43.3"
      stroke={color || '#FFFFFF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.1167 48.1833H43.3"
      stroke={color || '#FFFFFF'}stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.1167 55.1H43.3"
      stroke={color || '#FFFFFF'}
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.5 81H34.5V96H31.5V81Z"
      fill="#5768FF"
    />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconBook.defaultProps = defaultProps;

export default IconBook;
