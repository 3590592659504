import request from '../../shared/request'

export async function login({ email, password, remember }) {
  const values = await request(`/login`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      password,
      remember,
      loggingTo: 'app-aluno'
    })
  })

  return values
}

export function forgot(email) {
  return request(`/v1/generate-password`, {
    method: 'POST',
    body: JSON.stringify({ email: email })
  })
}

export function selectOrganization({ organizationId, clientType, deviceUniqueId }) {
  return request(`/connect`, {
    method: 'POST',
    body: {
      organization: organizationId,
      device: `${clientType}:${deviceUniqueId}`
    }
  })
}
