import React from 'react'

import {  AlertOutlined,  CheckOutlined} from '@ant-design/icons'
import { Button, Popconfirm} from 'antd'
export function RenderSavebutton(handleFinishLecture, allQuestionsReady, backToFirstNotAnswered) { //definido 
  const popconfirmProps = allQuestionsReady
    ? {
        title: 'Você tem certeza que deseja FINALIZAR a prova?',
        onConfirm: () => handleFinishLecture(),
        okText: 'Sim',
        cancelText: 'Não',
        icon: <CheckOutlined style={{ color: 'green' }} />,
      }
    : {
        title: 'Você precisa responder todas as questões antes de finalizar a prova!',
        onConfirm: () => backToFirstNotAnswered(),
        onCancel: () => backToFirstNotAnswered(),
        cancelText: false,
        okText: 'Ir para a primeira questão em branco',
        icon: <AlertOutlined style={{ color: 'red' }} />,
        okButtonProps: { danger: true },
        cancelButtonProps: { disabled: true }
      }

  return (
    <Popconfirm {...popconfirmProps}>
      <Button className="btnFinishLecture">Salvar e ver resultado</Button>
    </Popconfirm>
  )
}
