import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 0 0;

  padding: 10px;

  gap: 30px;

  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const Content = styled.main`
    display: flex;
    width: 100%;
        
    @media(max-width: 980px) {
        flex-direction: column;
    }
        
    section {
        width: 35%;
        padding: 50px;
        padding-right: 30px;

        height: 638px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
        border-radius: 5px;

        @media(max-width: 1200px) {
            width: 45%;
        }

        @media(max-width: 980px) {
            width: 100%;
        }

        @media(max-width: 580px) {
            height: 100%;
        }

        & + section {
            width: 65%;
            margin-left: 25px;
            @media(max-width: 1200px) {
                width: 55%;
            }
            @media(max-width: 980px) {
                width: 100%;
                margin-left: 0px;
                margin-top: 25px;
            }
        }
    }

    .box-historic {
        h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 25px;
            line-height: 27px;

            color: #000533;
        }
    }
`;