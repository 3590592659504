import styled, {css} from 'styled-components';
import { AppstoreOutlined, ProfileOutlined } from '@ant-design/icons';

interface ButtonProps {
    active?: boolean;
};

export const Title = styled.h3`
    text-align: center;
    line-height: 1.9375em;
    font-weight: 600;
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.gray_500};
`;

export const Text = styled.p`
    margin-bottom: 1.875em;
    line-height: 1.375em;
    text-align: center;
    font-size: 1.125em;
    color: ${({ theme }) => theme.colors.gray_500};
`;

export const Buttons = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    gap: 1.875em;
    padding-bottom: 1.875em;

    @media(max-width: 650px) {
        display: block;
        padding-bottom: 0.9375em;
    }
`;

export const Button = styled.button<ButtonProps>`
    display: flex;
    flex: 1;
    gap: 0.625em;
    border: 1px solid ${({ theme }) => theme.colors.red_300};
    border-radius: 0.3125em;
    background-color: ${({ theme }) => theme.colors.white};
    height: 2.5em;
    justify-content: center;
    align-items: center;
    line-height: 1.3125em;
    cursor: pointer;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.red_300};

    ${({ active }) => (active && css`
        border-color: ${({ theme }) => theme.colors.lilac};
        background-color: ${({ theme }) => theme.colors.lilac};
        color: ${({ theme }) => theme.colors.white};
    `)}

    @media(max-width: 650px) {
        width: 100%;
        margin-bottom: 0.9375em;
    }
`;

export const IconModular = styled(AppstoreOutlined)<ButtonProps>`
    font-size: 1em;
    color: ${({ theme }) => theme.colors.red_300};

    ${({ active }) => (active && css`
        color: ${({ theme }) => theme.colors.white};
    `)}
`;

export const IconComplete = styled(ProfileOutlined)<ButtonProps>`
    font-size: 1em;
    color: ${({ theme }) => theme.colors.red_300};

    ${({ active }) => (active && css`
        color: ${({ theme }) => theme.colors.white};
    `)}
`;