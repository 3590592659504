import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//
// general components
import _ from 'lodash';
// import ScrollMenu from 'react-horizontal-scrolling-menu'
import ClassCard from './classesList/ClassCard';
import PerformanceCard from './classesList/PerformanceCard';
import PerformanceCardCFP from './classesList/PerformanceCardCFP';

import { parse, compareDesc, isEqual, endOfDay } from 'date-fns';

//
// context
import { CourseContext } from '../ClassContent/CourseDetail.container';

//
// selectors
import * as courseSelectors from '../../../redux/selectors/course';
import { allowedReportDownload, allowedScoreToro, checkIfIsModularOrNot } from '../../../helpers/permissions';

const propTypes = {
  courseClasses: PropTypes.array,
};
function ClassesList(props) {
  const { courseClasses } = props;

  const { selectedClass, setSelectedClass, course } = useContext(CourseContext);

  const list = useRef();
  const [scrolled, setScrolled] = useState(false);

  const checkLockedClass = (classItem) => {
    let status = false;
    if (classItem?.principal) {
      const { days } = classItem;
      if (!!days && days.length > 0) {
        const classDate = parse(_.get(days, '[0].date'), 'dd/MM/yy', new Date());
        status = compareDesc(new Date(), classDate);
      }
    }

    return status;
  };

  const scrollToCurrentClass = () => {
    const current = document.querySelector('.lessons-item.current.unlocked');
    if (current && selectedClass && selectedClass.titulo && !scrolled) {
      const position = current.offsetLeft;
      list.current.scrollLeft = position - list.current.clientWidth / 2 + current.clientWidth / 2;
      setScrolled(true);
    }
  };

  useEffect(() => {
    scrollToCurrentClass();
    // eslint-disable-next-line
  }, [selectedClass]);

  useEffect(() => {
    return () => setSelectedClass(undefined);
    // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    if(course?.classes?.length > 0 && !checkIfIsModularOrNot(course?.title))
      setSelectedClass(course?.classes[0])
    // eslint-disable-next-line
  },[])

  const handleCurrentClass = (classItem) => {
    const days = _.get(classItem, 'days');

    //
    // just check it for classes with date
    if (days && days.length > 0) {
      days.forEach((day) => {
        const classDate = parse(day.date, 'dd/MM/yy', new Date());
        if (isEqual(endOfDay(new Date()), endOfDay(classDate))) {
          setSelectedClass(classItem);
        }
      });
    }
  };

  const renderClassList = () => {
    //
    // aux vars
    let renderedMocks = [];
    let count = 0
    return courseClasses.map((classItem, i) => {
      const locked = checkLockedClass(classItem);

      //
      // workaround to set the current class
      // based just on the date
      if (!scrolled) {
        handleCurrentClass(classItem);
      }

      if (classItem.simulado) {
        //
        // 1. check if this mock was already rendered
        // 2. if not, render and add to the renderedMocks state array
        if (!renderedMocks.includes(classItem.id_aula_simulado_grupo)) {
          renderedMocks.push(classItem.id_aula_simulado_grupo);
          count++;
          return (
            <ClassCard
                key={i}
                classItem={classItem}
                locked={locked}
                simulated
                order={count}
            />
          );
        }
      } else {
        count++;
        return (
            <ClassCard
                key={i}
                classItem={classItem}
                locked={locked}
                order={count}
            />
        );
      }
      return false;
    });
  };

  return (
    <div ref={list} className="lessons-list">
      {allowedScoreToro(course?.id_course_category) && (
          <PerformanceCard key="CEA_CPA" />
      )}
      {allowedReportDownload(course?.id_course_category) && checkIfIsModularOrNot(course?.title) ? (
          <PerformanceCardCFP key="CFP" />
      ):null}
      {renderClassList()}
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  courseClasses: courseSelectors.courseClasses(state, props),
});
const mapDispatchToProps = () => ({
  // getClassesLists: (courseId, next) => dispatch(courseActions.getClassesLists(courseId, next)),
});

ClassesList.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(ClassesList);
