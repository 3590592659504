import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    height: 100%;
    padding: 20px;
    @media(max-width: 600px) {
        padding: 0px;
        padding-bottom: 10px;
    }



    .player {
        @media(min-width: 600px) {
            max-height: 100px !important;
        }

        @media(max-width: 600px) {
            margin-top: 20px;
        }

        display: flex;
        .pause, .play {
            svg {
                color: ${({ theme }) => theme.colors.warning} !important;
            }
        }

        .progress {
            > div {
                width: 95%;
                span {
                    color: ${({ theme }) => theme.colors.gray_600} !important;
                }

                .velocity {
                    width: 50px !important;
                    height: 20px !important;
                    padding: 20px !important;
                    background: ${({ theme }) => theme.colors.gray_700} !important;
                    color: white !important;
                }
            }
        }
    }

    > main {
        display: flex;    
        justify-content: space-between;
        @media(max-width: 600px) {
            height: 100px;
            background: #FFFFFF;
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
            padding: 10px;
        }   

        > .box-info {
            display: flex;
            > .box-img {
                width: 150px;
                @media(max-width: 600px) {
                    width: 100px;
                }
                
                img {
                    @media(max-width: 600px) {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            > .box-description {
                flex-basis: 70%;
                display: flex;
                flex-direction: column;
                margin-left: 15px;

                h3 {
                    color: ${({ theme }) => theme.colors.primary};
                    @media(max-width: 600px) {
                        font-size: 18px;
                    }
                }

                time {
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 13px;
                    @media(max-width: 600px) {
                        font-size: 12px;
                    }
                }

                p {
                    margin-top: 5px;
                }
            }
        }

        svg {
            width: 30px;
        }
        
    }
`;


export const BoxEmpty = styled.div`
    flex: 1;
    flex-direction: column;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 50px;
    }

    span {
        margin-top: 15px;
        font-size: 18px;
        font-weight: 700;
    }
    height: 100%;
`;