import styled, {css} from 'styled-components';
import { Formik } from 'formik';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';

interface ItemsProps {
    oneColumn: boolean;
};

export const Container = styled.div`
    border-top: 0.125em solid ${({ theme }) => theme.colors.gray_90};
    padding-top: 1.875em;
`; 

export const FormWrapper = styled(Formik)``;

export const Text = styled.p`
    margin-bottom: 1.875em;
    line-height: 1.375em;
    text-align: center;
    font-size: 1.125em;
    color: ${({ theme }) => theme.colors.gray_500};
`;

export const TextTwo = styled.p`
    margin-bottom: 1.875em;
    line-height: 1.25em;
    font-weight: 500;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.gray_500};
`;

export const Items = styled.div<ItemsProps>`
    display: flex;
    gap: 0 1.875em;

    ${({ oneColumn }) => (oneColumn ? 
    css`
        flex: 1;
        flex-direction: column;
    ` : 
    css`
        flex: 2;
        justify-content: space-between;
        flex-wrap: wrap;

        .ant-form-item {
            flex: calc(50% - 0.9375em);
            width: calc(50% - 0.9375em);
        }
    `)}

    .ant-form-item {
        display: flex;

        label {
            line-height: 1.3125em;
            font-weight: 500;
            font-size: 1em;
        }

        input {
            border-color: ${({ theme }) => theme.colors.blue_400};
        }

        .ant-select-selector {
            min-height: 2.8125em;
            height: auto !important;
        }
    }

    @media(max-width: 650px) {
        flex: 1;
        flex-direction: column;

        .ant-form-item {
            width: 100%;
        }
    }
`;

export const Checkboxes = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1.875em;
    
    .input-contract {
        font-size: 1em;
        font-weight: 500;

        padding: 0px;
        margin-left: -1px !important;
        & + .input-contract {
            margin-top: 5px;
        }

        .ant-checkbox-inner {
            border: 1px solid ${({ theme }) => theme.colors.primary};
            clip: rect(0 0 0 0);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a {
            color: ${({ theme }) => theme.colors.lilac};
            line-height: 1.0625rem;
        }
    }
`;

export const Button = styled.button`
    border: none;
    border-radius: 0.3125em;
    width: 100%;
    height: 2.5em;
    background: linear-gradient(96.64deg, ${({ theme }) => theme.colors.blue_600} 26.19%, ${({ theme }) => theme.colors.blue_400} 102.4%);
    box-shadow: 0 0.125em 0 rgba(0, 0, 0, 0.043);
    cursor: pointer;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconArrowRight = styled(ArrowRightOutlined)`
    font-size: 0.875em;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconLoading = styled(LoadingOutlined)`
    font-size: 1em;
    color: ${({ theme }) => theme.colors.white};
`;