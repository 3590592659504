import React, { useContext, useState, useEffect } from 'react'
import { useCourseDetail } from '../../../../../hooks/useCourseDetail'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Modal, Button } from 'antd'

import format from "date-fns/format";
import ptBR from 'date-fns/locale/pt-BR';

//
// context
import { CourseContext } from '../../../ClassContent/CourseDetail.container'

//
// redux + selectors
import * as classSelectors from '../../../../../redux/selectors/class'
import { actions as classActions } from '../../../../../redux/modules/class'
import { actions as studentActions } from '../../../../../redux/modules/student';

//
// assets
import trophy from '../../../../../img/trophy.svg';
import arrowRight from '../../../../../img/arrowRight.svg';

import './styles/LectureResult.scss'

const propTypes = {
  redo: PropTypes.bool,
  lectureResult: PropTypes.object,
  totalQuestions: PropTypes.number,
  lectureWrongQuestions: PropTypes.array,
  getLectureWrongQuestions: PropTypes.func.isRequired,
  fetchReportSimulatedFullResult: PropTypes.func.isRequired,
}

function LectureResult(props) {
  const [modalOpened, setModalOpened] = useState(true);
  const { course } = useCourseDetail();

  const {
    setClassStep,
    setReviewStarted,
    lectureIdToReview,
    totalWrongQuestionsHelper
  } = useContext(CourseContext)

  useEffect(() => {
    if(lectureIdToReview){
      const { getLectureWrongQuestions } = props
      getLectureWrongQuestions(lectureIdToReview)
    }
    // eslint-disable-next-line
  }, [])

  const handleCloseReview = () => {
    const { redo } = props
    setReviewStarted(true)
    //
    // change class content to review
    if (!redo) {
      setClassStep('lectureReview')
    } else {
      setClassStep('file')
    }
  }

  const formatTimeToString = (time) => {
    const data = time.split(':');

    return `${data[0]} horas ${data[1]} min e ${data[2]} seg`;
  }

  const finishDateToString = (dateUnformat) => {
    const date = new Date(dateUnformat);
    const formattedDate = format(date, "dd 'de' MMMM 'de' yyyy", { locale: ptBR });

    return formattedDate;
  }

  const { lectureResult, redo, lectureWrongQuestions, totalQuestions } = props

  return (
    <>
    <Modal
      // visible={reviewStarted}
      width={542}
      footer={false}
      maskClosable={false}
      visible={modalOpened}
      destroyOnClose={true}
      closable={redo ? true : false}
      wrapClassName='lectureResultModal'
      onCancel={() => setModalOpened(false)}
      zIndex={999}
    >
      <div className="resultContainer">
        <h2>{course.title} &bull; Resultado da prova</h2>
        <div className='icon'><img src={trophy} alt="Troféu"/></div>
        {!redo && (
          <>
            <p style={{ textAlign: 'center' }}>
              Você acertou <span>{totalQuestions - lectureWrongQuestions.length}</span> de <span>{totalQuestions}</span> questões em <span>{formatTimeToString(lectureResult.time)}</span> dia {finishDateToString(lectureResult.finishDate)}.
            </p>
            <Button
              type="primary"
              onClick={() => handleCloseReview()}
            >
              Ver a prova corrigida
              <img src={arrowRight} alt="Seta"/>
            </Button>
          </>
        )}
        {redo && (
          <>
            <p style={{ textAlign: 'center' }}>
              Nesta nova tentativa você acertou <span>{totalWrongQuestionsHelper - lectureWrongQuestions.length}</span> de <span>{totalWrongQuestionsHelper}</span> questões.
            </p>
          </>
        )}
      </div>
    </Modal>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  lectureResult: classSelectors.getLectureResult(state, props),
  lectureWrongQuestions: classSelectors.lectureWrongQuestions(state, props),
  totalQuestions: classSelectors.totalQuestions(state, props)
})

const mapDispatchToProps = dispatch => ({
  getLectureWrongQuestions: (lectureId, next) =>
    dispatch(classActions.getLectureWrongQuestions(lectureId, next)),

  fetchReportSimulatedFullResult: (courseClassId, courseId, next) => (
    dispatch(studentActions.fetchReportSimulatedFullResult(courseClassId, courseId, next))
  )
})

LectureResult.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(LectureResult)
