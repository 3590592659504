import React from 'react';
import { TextOne } from './styles';

const TextsHeader = (props) => {
    return (
        <TextOne>
            {props.children}
        </TextOne>
            
        
    );
};

export default TextsHeader;