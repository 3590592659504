import styled from 'styled-components';
import { Row, Col } from 'antd';


export const Container = styled.div`
  margin-top: 15vw;
`;
export const Content = styled(Row)`
    background: #FFFFFF;
    box-shadow: 0px 0px 1.408vw rgba(0, 0, 0, 0.05); //40
    border-radius: 0.704vw; //20
    padding: 2.112vw; //60
    height: 25.650vw; //728.48
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ColContent = styled(Col)`
    display: flex;
    justify-content: flex-start;
      height: 14.7vw;
      gap: 3vw;
      margin-left: 6vw;
`;


export const Middle = styled.div`
    position: absolute;
    width: 73.871vw;
    height: 0px;
    margin-top: 7.4vw;
    margin-right: 5.212vw;
    display: flex;
    p {
        position: absolute;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.408vw; //40
        line-height: 1.725vw; //49
        color: #A4A4A4;
        margin-top: -2vw;
    }
`;


export const LineDashed = styled.div`
    /* background: #C4C4C4; */
    border: 0.076vw dashed #252525; //5
    width: 100%;
    z-index: 0;
`;


export const ColM = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 97%;
    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.584vw; //45
        line-height: 1.936vw; //55
        color: #A4A4A4;
    }
`;

export const Line = styled.div`
    border: 0.0352vw solid #999999;
    width: 71.6vw;
    height: 0px;
    display: flex;
    justify-content: center;
    margin-left: 3.5vw;

`;

export const Wrapper = styled.div`
    width: 100%;
    background: #FFFFFF;
    border: 0.176vw solid #5768FF;//5
    box-sizing: border-box;
    border-radius: 0.704vw; //20
    margin-top: 4.225vw; //120
    padding: 3.873vw; //110
`;


export const LastTitle = styled.div`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.640vw; //75
    line-height: 3.204vw; //91
    color: #000000;
`;


export const Text = styled.div`
    p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.584vw; //45
        line-height: 2.464vw; //70
        text-align: justify;
        color: #434343;
        margin-top: 2.112vw;
    }
    p:last-child {
        margin-top: 0.112vw;
    }
    span {
        color: #D81E5B;
        font-weight: 600;
    }
`;

export const LineDashedTop = styled.div`
    position: absolute;
    border: 0.020vw dashed #999999; //0.5
    width: 91%;
    z-index: 0;
    margin-top: -7.4vw;
    margin-left: 5.9vw;
`;
