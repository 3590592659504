import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import arrowNext from '../../../../../img/arrowNext.svg';
import arrowPrev from '../../../../../img/arrowPrev.svg';

function Arrow({
  children,
  disabled,
  onClick,
  reverse,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  reverse: boolean;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
        border: 'none',
        textIndent: '-5000em',
        width: '33px',
        height: '33px',
        background: `url(${reverse ? arrowPrev : arrowNext}) no-repeat center`,
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow reverse={true} disabled={disabled} onClick={() => scrollPrev()}>
      Left
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(!visibleElements.length && isLastItemVisible);
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow reverse={false} disabled={disabled} onClick={() => scrollNext()}>
      Right
    </Arrow>
  );
}
