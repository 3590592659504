import styled from 'styled-components';
import { Row } from 'antd';

//base vw 2840px

type Props = {
    percent: number;
}

type PercetageModulo = {
    percentModulo: number;
}


type PropsRecomended = {
    recomended: number;
    displayRecomended: string;
}

type PropsResult = {
    result?: number | string;
}

type PropsResultP = {
    margin?: number;
}

export const Container = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 1.408vw rgba(0, 0, 0, 0.05); //40px
    border-radius: 0.704vw;
    /* margin: 0 3.521vw 3.063vw 3.521vw; //100px 87px 100px */
    padding: 2.112vw; //60px
    width: 100%;
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 1.2vw; //45px
        line-height: 2.288vw; //65px
        text-align: justify;
        color: #101010;
        margin-bottom: 1vw;
    }
`;

export const Line = styled.div`
    width: 100%;
    border: 1px solid #D6D6D6;
    margin: 1vw 0;
`;


/*GRAPH*/
export const Graph = styled.div`
    z-index: 1;
    position: relative;
    width: 100%;
    height: 2.8vw; //101px
    border-radius: 0.352vw; //10px
    background: #EFEFEF;
    display: flex;
    align-items: center;
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 1.3vw; //50
        line-height: 3.447vw; //61
        color: #FFFFFF;
        display: contents;
    }
`;

export const Yellow = styled.div<Props>`
    z-index: 3;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 2.8vw;

    width: ${({ percent }) => percent}%;
    background: #F89C3A;
    border-radius: 0.403vw 0px 0px 0.403vw; //10
    padding: 0 1.408vw;//40
    /* padding: 0px 1.408vw 0px 64px; */
`;

export const Blue = styled.div<Props>`
    height: 2.8vw;
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: ${({ percent }) => percent}%;
    border-radius: 0.403vw 0px 0px 0.403vw; //10
    background: #000A66;
    padding: 0 1.408vw;//40
`;

export const Transparent = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: ${({ percent }) => percent}%;
    p {
        color: #858585;
    }
    padding: 0 1.408vw;//40
`;

/*GRAPH*/

export const Legend = styled(Row)`
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.267vw; //36
        line-height: 1.549vw; //44
        color: #636363;
        display: contents;
    }
`;

export const Min = styled.div`
    display: flex;
    align-items: center;
    div {
        width: 2.640vw; //75
        height: 2.640vw; //75
        background: #F89C3A;
        border-radius: 50%;
        margin-right: 1.584vw; //45
    }
    margin-right: 3.873vw; //110
`;


export const Recomended = styled.div`
    display: flex;
    align-items: center;
    div {
        width: 2.640vw; //75
        height: 2.640vw; //75
        background: #000A66;
        border-radius: 50%;
        margin-right: 1.584vw; //45
    }
`;

export const ChartBarGranph = styled.div`
    width: 100%;
    display: flex;
    border-radius: 0.352vw; //10
    background: #EFEFEF;
    height: 2.922vw; //83
    padding: 0 0.704vw 0 0;
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 1.232vw; //35
        line-height: 1.514vw; //43
        color: #000000;
    }
`;

export const Modulo = styled.div<PercetageModulo>`
    width: ${({ percentModulo }) => percentModulo}%;
    height: 2.922vw; //83
    background: rgba(0, 10, 102, 0.37);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 0 0 0.704vw;
    border-radius: 0.403vw 0px 0px 0.403vw; //10
    position: absolute;

    p {
      position: absolute;
    }
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

export const ChartBarResume = styled.div`
    display: flex;
    height: 14.7vw;
    align-items: flex-end;
    z-index: 1;
`;

export const GraphBarResult = styled.div<PropsResult>`
    background: #F89C3A;
    border-radius:0.2vw 0.2vw 0 0;

    width: 4vw; //120
    height: ${({ result }) => result}%;
    display: flex;
    justify-content: center;
    padding: 1.056vw 0.352vw 0.352vw; //30px 10px 10px
    margin-right: 0.528vw; //15
    z-index: 1;
`;

export const GraphBarResultP = styled.div<PropsResultP>`
    /* margin-top: -${({ margin }) => margin}vw; */
    margin-top: -1vw;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.1vw; //40
    line-height: 1.725vw; //49
    text-align: center;
    color: #FFFFFF;
`;

export const GraphBarRecomended = styled.div<PropsRecomended>`
    background: #000A66;
    border-radius: 0.2vw 0.2vw 0 0;

    width: 4vw; //120
    height: ${({ recomended }) => recomended}%;
    display: ${({ displayRecomended }) => displayRecomended};
    justify-content: center;
    padding: 1.056vw 0.352vw 0.352vw;
    z-index: 1;
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 1.1vw; //40
        line-height: 1.725vw; //49
        text-align: center;
        color: #FFFFFF;
        margin-top: -1vw;
    }
`;


export const AvaliableDate = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 1.056vw;
        line-height: 1.232vw;
        text-align: end;
        color: #434343;
        justify-content: flex-end;
    }
`;
