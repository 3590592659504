import React from 'react';
import {
  TextDescription,
  TitleGraph
} from '../Weekly/PDF/Class/styles';

type Props = {
  type: 'graph' | 'description';
  children?: string | React.ReactNode;
  style: {[key: string]: string | number}
  dangerouslySetInnerHTML?: {
    __html: string;
  };
}

function ReportText({ type, children, style, dangerouslySetInnerHTML }: Props) {
  if (type === 'graph') {
    return (
      <TitleGraph style={style} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
        {children}
      </TitleGraph>
    )
  };
  if (type === 'description') {
    return (
      <TextDescription style={style} dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
        {children}
      </TextDescription>
    )
  };
  return null;
}

ReportText.defaultProps = {style: {}}
export default ReportText;