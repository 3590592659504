import React, { useContext } from 'react';
import P from 'prop-types';

import './style/topCard.scss';
import config from '../../../config';
import { numberFormat } from '../../../helpers';
import { Col, Row, Image} from 'antd';
import { CheckoutContext } from '../shared/CheckoutProvider';
import defaultImg from '../../../img/user-default.png';

import { CuponsArea } from './CuponsArea';

const propTypes = {
    current: P.number.isRequired,
    responseStatus: P.string.isRequired,
    showTotal: P.bool
};

const TopCard = ({ current, responseStatus, showTotal = true }) => {

    const { course, values, installments } = useContext(CheckoutContext);
    const currentInstallment = installments.find((installement) => installement.key === values.installments);

    return (
        <>
            {
                (!course || course?.id_error) ? (<></>) : (
                    <Row className={`card-price-xs-lg ${['DENIED', 'ERROR'].includes(responseStatus) ? 'card-price-xs-lg-error' : ''}`}>
                        <Col xs={12}>
                            <div className={current === 5 ? "failed-payment-xs-md" : ""}></div>
                            <div style={current === 5 ? { filter: 'grayscale(100%)' } : {}}>
                                {
                                    course.img_capa ? (
                                        <Image className="img-conatiner-card" src={config.s3Url + course.img_capa} />
                                    ) : (
                                        <Image className="img-conatiner-card" src={defaultImg} />
                                    )
                                }
                            </div>
                        </Col>
                        <Col xs={12}>
                            <h2 className="title-card-price-xs-lg">{course.curso}</h2>
                            <p className="course-card-price-xs-lg">
                                {course.curso} - {course.nome}
                            </p>

                            {
                                showTotal && (
                                    <>
                                        {
                                            currentInstallment ? (
                                                <div className={`number-card-price-xs-lg ${values.type === 'free' ? 'total-free' : ''}`}>
                                                    <span>{currentInstallment.key !== 1 ? 'x' : ''} R$ </span>
                                                    <p>{numberFormat(currentInstallment.key*currentInstallment.value, 2, ',', '.')}</p>
                                                </div>
                                            ) : (
                                                <span className="total-default">
                                                    R$ {numberFormat(course.valor_curso)}
                                                </span>
                                            )
                                        }
                                    </>
                                )
                            }
                        </Col>
                    </Row>
                )
            }
            <CuponsArea />
        </>
    );
};

TopCard.propTypes = propTypes;
export default TopCard;
