import React from 'react';

import { LoadingArea, LoadingImage } from './styles';

import LogoLoading from '../../img/svg/LogoLoading.svg';

export default function Loading() {
  return (
    <LoadingArea>
      <LoadingImage url={LogoLoading}>
        <div></div>
      </LoadingImage>
      <h1>Carregando</h1>
    </LoadingArea>
  );
}
