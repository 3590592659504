import React from 'react'
import AuthRoutes from './routes'

const propTypes = {}

function Auth() {
  return <AuthRoutes />
}

Auth.propTypes = propTypes
export default Auth
