import React from 'react';
import { Col, Row, Tag } from 'antd';

import { useCourseDetail } from '../../../../../hooks/useCourseDetail';

import { ReactComponent as DashboardIcon } from '../../../../../img/svg/dashboard.svg';

import { 
    Container, 
    Box,
    Title 
} from './styles';

export default function PerformanceCard () {
    const { setSelectedClass, selectedClass } = useCourseDetail();
    
    function handleHome() {
        setSelectedClass(undefined);
    };

    return (
        <Container className="lessons-item unlocked" onClick={handleHome} isHome={!selectedClass}>
            <span className="list-percent">
                <DashboardIcon />
            </span>

            <Row>
                <Box>
                    <Title className="list-title">
                        <DashboardIcon />
                        <span>Meu desempenho</span>
                    </Title>
                    <Row>
                        <Col>
                            <Tag>Página inicial</Tag>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span>&nbsp;</span>
                        </Col>
                    </Row>
                </Box>
            </Row>
        </Container>
    )
};