import styled, { css } from 'styled-components';
import { Row } from 'antd';

type WrapperCheckboxProps = {
    marked: boolean;
};

export const Container = styled(Row)`
    width: 100%;
`;

export const WrapperCheckbox = styled.div<WrapperCheckboxProps>`
    background: ${({ theme }) => theme.colors.gray_50};
    border: 1px solid ${({ theme }) => theme.colors.gray_100};
    box-sizing: border-box;
    
    border-radius: 4px;
    height: 40px;

    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        height: 90px;
    }      

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px;
    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        padding: 0 2px;
    }

    ${({ marked }) => marked && css`
        border: 2px solid ${({ theme }) => theme.colors.pink};
    `}

    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        label {
            display: flex;
            align-items: center;
            span {
                margin-left: 10px;
            }
        }
    }

    .checkbox-text {
        margin-left: 14px;
        color: ${({ theme }) => theme.colors.blue_500};
        font-size: 14px;
        font-weight: 500;
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            text-align: justify;    
            margin-left: 0px;
        }
    }
`;

export const Value = styled.span`
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 14px;
    color: ${({ theme }) => theme.colors.blue_500};
    font-size: 14px;
    font-weight: 500;
`;

export const Divider = styled.div`
    margin-top: 4%;
    border: 0.5px dashed ${({ theme }) => theme.colors.gray_300};
`;

export const TotalDescription = styled.div`
    margin-top: 2%;

    display: flex;
    justify-content: flex-end;

    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 44px;
        color: ${({ theme }) => theme.colors.primary};
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            font-size: 18px;
        }
    }
`;

export const WrapperButtons = styled.div`
    margin-top: 10%;
    display: flex;
    justify-content: space-between;

    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    button {
        display: flex;
        padding: 0 15px;

        .btn-text {
            flex: 3;
        }

        transition: filter 0.2s;
        &:hover {   
            filter: brightness(0.8);
        }

        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            width: 100%;
            & + button {
                margin-top: 10px;
            }
        }
    }

    .btn-outline-primary {
        svg {
            flex: 1;
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const BoxLoading = styled.div`
    flex: 1;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BoxError = styled.div`
    flex: 1;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-weight: 600px;
        font-size: 20px;
    }
`;

export const BoxEmpty = styled.div`
    font-size: 20px;
    display: flex;
    padding: 15px;
    justify-content: center;
    align-items: center;

    font-weight: bold;
`;