export const itemsMenu = [
  {
    label: 'Editar Perfil',
    item: 'profile',
  },
  {
    label: 'Alterar senha',
    item: 'password',
  },
];
