import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col, Input, Select, Spin } from 'antd';
import * as Yup from 'yup';
import { FormikValues } from 'formik';
import { MailOutlined, SendOutlined, LoadingOutlined } from '@ant-design/icons';

import Layout from '../../../components/Gabarito/Layout';

import { actions as gabaritoActions } from '../../../redux/modules/gabarito';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';

import { Container, FormWrapper } from '../Create/styles';
import { ButtonWrapper, Title } from './styles';


type StateProps = {
    gabarito: IGabaritoState;
};

type FormProps = FormikValues & {
    gabaritoId: number;
    email: string;
};

const ResendEmail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData] = useState<FormProps>({} as FormProps);
    const dispatch = useDispatch();
    const { gabaritos } = useSelector(
        (state: StateProps) => state.gabarito
    );

    const validationSchema = () => {
        return Yup.object().shape({
          email: Yup.string()
            .required('Por favor informe seu e-mail')
            .email('Por favor informe um email válido'),
          gabaritoId: Yup.number().required('Por favor selecione a prova'),
        });
    };

    useEffect(() => {
        dispatch(gabaritoActions.fetchAll({ pre: false }));
    }, [dispatch]);

    const handleSubmit = (_values: FormikValues, options: { resetForm: () => void }) => {
    
        const values = _values as FormProps;

        setIsLoading(true);
        dispatch(gabaritoActions.resendResult(
            values.gabaritoId, 
            values.email, 
            () => {
                options.resetForm();
                setIsLoading(false);
            }, 
            () => {
                options.resetForm();
                setIsLoading(false);
            }
        ));
    };
    
    return (
        <Layout>
            <Container>
                <Title>
                    <h2>
                       <MailOutlined/>&nbsp; Receba novamente o resultado por email
                    </h2>
                </Title>

                <FormWrapper
                    initialValues={formData}
                    enableReinitialize={true}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    >
                    {({ values, errors, handleSubmit, handleChange, setFieldValue }) => (
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item
                                    label="Seu email"
                                    required={false}
                                    hasFeedback
                                    validateStatus={errors.email && 'error'}
                                    help={errors.email}
                                >
                                    <Input
                                    size="large"
                                    name="email"
                                    className="ipt"
                                    placeholder="Seu email"
                                    value={values.email}
                                    onChange={handleChange}
                                    />
                                </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                <Form.Item
                                    label="Selecione a prova"
                                    required={false}
                                    hasFeedback
                                    validateStatus={errors.gabaritoId && 'error'}
                                    help={errors.gabaritoId}
                                >
                                    <Select
                                    size="large"
                                    showSearch
                                    className="ipt-select"
                                    optionFilterProp="children"
                                    placeholder="Selecione"
                                    value={values.gabaritoId}
                                    onChange={(e) => setFieldValue('gabaritoId', e)}
                                    >
                                    <Select.OptGroup>
                                        {gabaritos.map((item) => (
                                            <Select.Option key={item.id_gabarito} value={item.id_gabarito} title={item.name}>
                                                {item.summary ? item.summary : item.name}
                                            </Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                                </Col>
                            </Row>


                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <ButtonWrapper>
                                        <button className="warning" type={isLoading ? 'button': 'submit'} disabled={isLoading}>
                                            {
                                                isLoading ? (
                                                    <>
                                                        <Spin indicator={<LoadingOutlined spin />} />
                                                    </>
                                                ) : (
                                                    <>
                                                        <SendOutlined />
                                                        &nbsp;
                                                        Reenviar Resultado
                                                    </>
                                                )
                                            }
                                        </button>
                                    </ButtonWrapper>
                                </Col>
                            </Row>
                        </Form>
                    )}
                    </FormWrapper>
            </Container>
        </Layout>
    );
};

export default ResendEmail;