import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as Yup from 'yup';
import { Form, Row, Col, Input, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormikValues } from 'formik';

import Layout from '../../../../components/Gabarito/Layout';
import ModalAlert from '../../../../components/ModalAlert';
import StepsSubscriptionChannels from '../../StepsSubscripionChannels';

import { actions as gabaritoActions } from '../../../../redux/modules/gabarito';
import { actions as authActions } from '../../../../redux/modules/auth';

import { FormDataProps, StateProps, PayloadProps } from './types';

import { 
    Container, 
    FormWrapper, 
    Title 
} from './styles';
const { Item } = Form;

const Login = () => {
    const [openModalChannels, setOpenModalChannels] = useState(false); 
    const [pageMaterial, setPageMaterial] = useState('subscription');
    const [openModalAcceptTheTherms, setOpenModalAcceptTheTherms] = useState(false);
    const [formData] = useState<FormDataProps>({} as FormDataProps);

    const dispatch = useDispatch();
    const state = useSelector((state: StateProps) => state);
    const { token, user, isLoading } = state.auth;
    const { gabaritos, modules } = state.gabarito;
    const { channels } = state.student;

    const history = useHistory();

    useEffect(() => {
        dispatch(gabaritoActions.fetchAll({ pre: false }));
    }, [dispatch]);

    const validationSchema = () => {
        if (token) {
            return Yup.object().shape({
                gabaritoId: Yup.number().required('Por favor selecione a prova'),
                modulesId: Yup.array().required('Por favor selecione os módulos')
            });
        } 

        return Yup.object().shape({
          password: Yup.string().required('Por favor informe sua senha'),
          email: Yup.string()
            .required('Por favor informe seu e-mail')
            .email('Por favor informe um email válido'),
          gabaritoId: Yup.number().required('Por favor selecione a prova'),
          modulesId: Yup.array().required('Por favor selecione os módulos'),
        });
    };

    const handleChangeExam = (gabaritoId: number) => {
        dispatch(gabaritoActions.getModulesByIdGabarito(gabaritoId)); 
    };

    const handleNext = (values: FormikValues) => {
        if (token) {
            dispatch(gabaritoActions.makeTokenExistsStudent({
                gabaritoId: values.gabaritoId,
                userId: user.id,
                channels: {
                    ...channels
                },
                modulesId: values.modulesId
            }, (token: unknown) => {
                if (token) {
                    history.push(`/gabaritoro/respostas/${token}`);
                }
            }));
        } else {
            dispatch(authActions.login(values.email, values.password, false, (_payload: unknown) => {
                const payload = _payload as PayloadProps;
                if (payload && payload.id) {
                    dispatch(gabaritoActions.makeTokenExistsStudent({
                        gabaritoId: values.gabaritoId,
                        userId: payload.id,
                        channels: {
                            ...channels
                        },
                        modulesId: values.modulesId
                    }, (token: unknown) => {
                        if (token) {
                            history.push(`/gabaritoro/respostas/${token}`);
                        }
                    }));
                }
            }));
        }
    };

    const handleFinishSelectTheMaterial = () => {
        setOpenModalChannels(false);
        setPageMaterial('subscription');
    };

    return (
        <Layout>
            <ModalAlert
                isVisible={openModalAcceptTheTherms}
                setVisible={setOpenModalAcceptTheTherms}
                title="Aceite os termos de serviço, e a recepção do material para continuar"
            />

            <StepsSubscriptionChannels 
                isVisible={openModalChannels}
                setVisible={setOpenModalChannels}
                page={pageMaterial}
                setPage={setPageMaterial}
                onFinish={handleFinishSelectTheMaterial}
            />

            <Container>
                <Title>
                    <h2>
                        Confira o resultado da sua prova no gabarito.
                        <br/>
                        <span>&nbsp;Nós te avisamos quando a sua nota sair !</span>
                    </h2>
                </Title>
                
                <Title>
                    <h3>Esta ferramenta é exclusiva para a certificação CFP®</h3>
                </Title>

                <FormWrapper
                    initialValues={formData}
                    enableReinitialize={true}
                    onSubmit={handleNext}
                    validationSchema={validationSchema}
                >
                    {({ values, errors, handleSubmit, handleChange, setFieldValue }) => (
                        <Form layout="vertical" onFinish={handleSubmit}>
                            {
                                !token ? (
                                    <>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Item
                                                label="Email *"
                                                required={false}
                                                hasFeedback
                                                validateStatus={errors.email && 'error'}
                                                help={errors.email}
                                            >
                                                <Input
                                                    size="large"
                                                    name="email"
                                                    className="ipt"
                                                    placeholder="Seu email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Item
                                                label="Password *"
                                                required={false}
                                                hasFeedback
                                                validateStatus={errors.password && 'error'}
                                                help={errors.password}
                                            >
                                                <Input
                                                    type="password"
                                                    size="large"
                                                    name="password"
                                                    className="ipt"
                                                    placeholder="Seu password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>
                                    </>
                                ) : (
                                    <>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Item label="Email">
                                                <Input
                                                    disabled={true}
                                                    size="large"
                                                    name="email"
                                                    className="ipt"
                                                    placeholder="Seu email"
                                                    value={user.email}
                                                    style={{color: 'black'}}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Item label="Password">
                                                <Input
                                                    disabled={true}
                                                    type="password"
                                                    size="large"
                                                    name="password"
                                                    className="ipt"
                                                    placeholder="Seu password"
                                                    value={'NAO_UTILIZADA'}
                                                    style={{color: 'black'}}
                                                />
                                            </Item>
                                        </Col>
                                    </Row>
                                    </>
                                )
                            }

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Item
                                        label="Selecione a prova"
                                        required={false}
                                        hasFeedback
                                        validateStatus={errors.gabaritoId && 'error'}
                                        help={errors.gabaritoId}
                                    >
                                        <Select
                                            showSearch
                                            size="large"
                                            className="ipt-select"
                                            optionFilterProp="children"
                                            placeholder="Selecione"
                                            value={values.gabaritoId}
                                            onChange={(e) => {
                                                setFieldValue('gabaritoId', e);
                                                setFieldValue('modulesId', []);
                                                handleChangeExam(e);
                                            }}
                                        >
                                            <Select.OptGroup>
                                                {
                                                    gabaritos.map(item => (
                                                        <Select.Option key={item.id_gabarito} value={item.id_gabarito} title={item.name}>
                                                            {item.summary ? item.summary : item.name}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select.OptGroup>
                                        </Select>
                                    </Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                    <Item
                                        label="Selecione os modulos"
                                        required={false}
                                        hasFeedback
                                        validateStatus={errors.modulesId && 'error'}
                                        help={errors.modulesId}
                                    >
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            size="large"
                                            className="ipt-select"
                                            optionFilterProp="children"
                                            placeholder="Selecione"
                                            value={values.modulesId}
                                            onChange={(e) => setFieldValue('modulesId', e)}
                                        >
                                            <Select.OptGroup>
                                                {
                                                    modules.map(module => (
                                                        <Select.Option 
                                                            key={module.id_gabarito_module} 
                                                            value={module.id_gabarito_module} 
                                                            title={module.description}

                                                        >
                                                            Modulo {module.number}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select.OptGroup>
                                        </Select>
                                    </Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24} sm={24} md={24} lg={24}>
                                <div className="box-buttons">
                                    {
                                        isLoading ? (
                                            <button className="warning">
                                                <Spin indicator={<LoadingOutlined spin />} />
                                            </button>
                                        ) : (
                                            <button className="warning"
                                                type="submit"
                                                >
                                                Próximo
                                            </button>
                                        )
                                    }

                                    <button 
                                        type="button"
                                        className="transparent"
                                        onClick={() => history.push('/gabaritoro/login')}
                                    >
                                        Voltar
                                    </button>
                                </div>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </FormWrapper>
            </Container>
        </Layout>
    );
};

export default Login;