import '../style/style.scss';
import React, { useContext } from 'react';
import {Col, Input, Select, Form } from 'antd';
import MaskedInput from 'antd-mask-input';
import P from 'prop-types';

import VisaSvg from '../../../../img/svg/visa.svg';
import MasterSvg from '../../../../img/svg/master.svg';
import AmexaSvg from '../../../../img/svg/amex.svg';
import EloSvg from '../../../../img/svg/elo.svg';
import HiperSvg from '../../../../img/svg/hiper.svg';

import { validInputMask, validDataCard } from '../../shared/functions';
import { numberFormat } from '../../../../helpers';

import { CheckoutContext } from '../../shared/CheckoutProvider';
import useDocumentSize from '../../../../hooks/useDocumentSize';

const { Option, OptGroup } = Select;
const { Item } = Form;

const propTypes = {
  form: P.any.isRequired
};

const PaymentCreditOrDebit = ({ form }) => {
  const useSize = useDocumentSize();

  const {
    values,
    installments,
    setChangeValues,
    setCardValues,
  } = useContext(CheckoutContext);

  function handleChangeBrandCard (e) {
    if (e === 'Amex' || values?.card?.brand === 'Amex') {
      form.resetFields(['card_number', 'security_code']);
    }
    
    setCardValues(e, 'brand');
  };

  return (
    <>
      <Col className="gutter-row" xs={24} md={12}>
        <h5 className="title-form">Títular do cartão *</h5>
        <Item
          className="input-form"
          name="holder_name"
          rules={[{ required: true, message: 'Por favor digite o nome no cartão' }]}
        >
          <Input
            className="input-form"
            placeholder="Nome no cartão"
            value={values.card.holder_name}
            onChange={(e) => setCardValues(e.target.value, 'holder_name')}
          />
        </Item>
      </Col>
      {useSize.width <= 767 && (
        <Col className="gutter-row" xs={24} md={12}>
          <h5 className="title-form">CPF do titular *</h5>
          <Item
            className="input-form"
            name="holder_document"
            rules={[{ required: true, message: 'Por favor digite o CPF do titular.' }]}
          >
            <MaskedInput
              placeholder="123.456.789-00"
              value={values.card.holder_document}
              onChange={(e) => setCardValues(e.target.value, 'holder_document')}
              mask="111.111.111-11"
            />
          </Item>
        </Col>
      )}
      <Col className="gutter-row" xs={24} md={12}>
        <h5 className="title-form">Bandeira *</h5>
        <Item
          className="input-form"
          name="brand"
          rules={[{ required: true, message: 'Por favor selecione a bandeira' }]}
        >
          <Select
            placeholder="Bandeira do cartão"
            name="brand"
            value={values.card.brand}
            onChange={handleChangeBrandCard}
          >
            <OptGroup>
              <Option key="Visa" value="Visa">
                <div className="container-brand-option">
                  <p>Visa</p> <img src={VisaSvg} alt="Visa" />
                </div>
              </Option>
              <Option key="Mastercard" value="Mastercard">
                <div className="container-brand-option">
                  <p>Mastercard</p> <img src={MasterSvg} alt="Mastercard" />
                </div>
              </Option>
              <Option key="Amex" value="Amex">
                <div className="container-brand-option">
                  <p> Amex </p>
                  <img src={AmexaSvg} alt="Amex" />
                </div>
              </Option>
              <Option key="Elo" value="Elo">
                <div className="container-brand-option">
                  <p> Elo </p>
                  <img src={EloSvg} alt="Elo" />
                </div>
              </Option>
              <Option key="Hipercard" value="Hipercard">
                <div className="container-brand-option">
                  <p> Hipercard</p> <img src={HiperSvg} alt="Hipercard" />
                </div>
              </Option>
            </OptGroup>
          </Select>
        </Item>
      </Col>
      <Col className="gutter-row" xs={24} md={12}>
        <h5 className="title-form">Número do cartão *</h5>
        <Item
          className="input-form"
          name="card_number"
          rules={[
            { required: true, message: 'Por favor digite o número do cartão.' },
            () => validInputMask('cartão'),
          ]}
        >
          <MaskedInput
            className="placeholder-svg"
            placeholder="&#xf09d; 1234 5678 9876 4532"
            value={values.card.card_number}
            onChange={(e) => setCardValues(e.target.value, 'card_number')}
            mask={values?.card?.brand === 'Amex' ? "1111 111111 11111" : "1111 1111 1111 1111"}
          />
        </Item>
      </Col>
      {useSize.width >= 768 && (
        <Col className="gutter-row" xs={24} md={12}>
          <h5 className="title-form">CPF do titular *</h5>
          <Item
            className="input-form"
            name="holder_document"
            rules={[
              { required: true, message: 'Por favor digite o CPF do titular.' },
              () => validInputMask('CPF do títular'),
            ]}
          >
            <MaskedInput
              placeholder="123.456.789-00"
              value={values.card.holder_document}
              onChange={(e) => setCardValues(e.target.value, 'holder_document')}
              mask="111.111.111-11"
            />
          </Item>
        </Col>
      )}
      <Col className="gutter-row" xs={12} md={12}>
        <h5 className="title-form">Validade *</h5>
        <Item
          className="input-form"
          name="expiry"
          rules={[
            { required: true, message: 'Por favor digite a validade do cartão.' },
            () => validInputMask('validade', false),
            () => validDataCard(),
          ]}
        >
          <MaskedInput
            placeholder="Mês/Ano"
            value={values.expiry}
            onChange={(e) => setChangeValues(e.target.value, 'expiry')}
            mask="11/11"
          />
        </Item>
      </Col>
      <Col className="gutter-row" xs={12} md={12}>
        <h5 className="title-form">Cód. de segurança *</h5>
        <Item
          className="input-form"
          name="security_code"
          rules={[
            { required: true, message: 'Por favor digite o código de segurança.' },
            () => validInputMask('Cód. de segurança'),
          ]}
        >
          <MaskedInput
            placeholder={values?.card?.brand === 'Amex' ? "1234" : "123"}
            value={values.card.security_code}
            onChange={(e) => setCardValues(e.target.value, 'security_code')}
            mask={values?.card?.brand === 'Amex' ? "1111": "111"}
          />
        </Item>
      </Col>

      {values.type === 'credit' && (
        <Col className="gutter-row" xs={24} md={12}>
          <h5 className="title-form">Parcelas *</h5>
          <Item
            className="input-form"
            name="installments"
            rules={[{ required: true, message: 'Selecione a maneira como deseja parcelar.' }]}
          >
            <Select
              name="installments"
              placeholder="Selecione o parcelamento"
              value={values.installments}
              onChange={(e) => setChangeValues(e, 'installments')}
            >
              <OptGroup>
                {installments.map(({ key, value }) => (
                  <Option key={key} value={key}>
                    R$ {key} x {numberFormat(value, 2, ',', '.')}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Item>
        </Col>
      )}
    </>
  );
};

PaymentCreditOrDebit.propTypes = propTypes;
export default PaymentCreditOrDebit;
