import config from '../config';

const gtm = (path, data) => {
  (function(w,d,s,l,i){
    const dataLocal = JSON.parse(localStorage.getItem("persist:root") || "{}")
    const user = JSON.parse(dataLocal?.auth || "{}")?.user
    const userData = {
      user_id: user?.id || data?.id || "",
      clientFirstName: user?.firstName || data?.firstName || "",
      clientLastName: user?.firstName || data?.firstName || "",
      clientEmail: user?.email || data?.email || "",
      clientphone: user?.phoneNumber || data?.phoneNumber || "",
      pageTitle: document.title || data?.title || "",
    }

    w[l]=w[l]||[];

    w[l].push({
      'gtm.start':
      new Date().getTime(),event:'gtm.js'
    });

    let eventChangeRoute = {'event': 'changeRoute'}
    w[l].push(eventChangeRoute);

    if(userData.user_id !== ""){
      w[l].push(userData);
    }

    if(path === 'courses'){
      w[l].push({ ecommerce: null });
      w[l].push(data);
    }


  })(window,document,'script','dataLayer', config.GTM_ID);
};

export default gtm;
