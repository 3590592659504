import { COURSE_CATEGORY_CEA } from "../../../../../helpers/permissions";
import { IScorePerformanceInformation } from "../../../../../shared/Models/IScorePerformanceInformation";
import { IScoreToro } from "../../../../../shared/Models/IScoreToro";

export type StateProps = {
    auth: {
        user: {
            name: string;
            id_profile: number;
        };
    };
    student: {
        isLoadingScoreToro: boolean;
        scoreToro: IScoreToro;
    }
};

export type CourseType = {
    id: number;
    id_turma: number;
    data_inicio_aulas: string;
    data_fim_aulas: string;
    scorePerformanceInformation: Array<IScorePerformanceInformation>;
    id_course_category: number;
    id_main_course: number;
};

export type ContextProps = {
    course: CourseType;
    selectedClass: {}
};


type FilterProps = {
    date_end: string;
    students: number;
    id_team: number;
    id_curse: number;
    metrics?: Array<string>;
    date_start?: string;
};

export const getFiltersToFetchScoreToro = (filters: FilterProps, course: CourseType): FilterProps => {
    let formattedFilters: FilterProps = {
        ...filters, metrics: [
            'avarage',
            'score',
            'provided',
            'max'
        ],
        date_start: course.data_inicio_aulas
    };

    if (course?.id_main_course 
        && (course.id !== course?.id_main_course && course.id_course_category === COURSE_CATEGORY_CEA)
    ) {
        delete formattedFilters.date_start;
    }

    return formattedFilters;
}