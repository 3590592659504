import React from 'react';
import { InformationItemsArea } from './styles';

export function InformationsItems({ icon, title }: InformationsItemsProps) {
  return (
    <InformationItemsArea data-testid="information-items-area-main-area">
      <div className="img-area" data-testid="information-items-area-image-area">
        <img src={icon} alt="icon" data-testid="information-items-area-icon" />
      </div>
      <span data-testid="information-items-area-text">{title}</span>
    </InformationItemsArea>
  );
}

interface InformationsItemsProps {
  icon: string;
  title: string;
}
