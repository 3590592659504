import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { GabaritoroProvider } from '../../../hooks/useGabaritoro';
import Footer from '../../NewCheckout/Footer';
import Header from '../../NewCheckout/Header';
import Logo from '../Logo';
import { Container, Wrapper } from './styles';

import { chat } from '../../../shared/gabaritoro';

type Props = {
    children: React.ReactNode;
    layoutTheme?: 'light' | 'dark';
    logo?: boolean;
};


const Layout = ({ children, layoutTheme, logo = true }: Props) => {

    useEffect(() => {
        chat();
    }, []);

    return (
        <>
            <Helmet>
                <title>Gabaritoro</title>
            </Helmet>
            <GabaritoroProvider>
                <Header />
                    <Container>
                        <Wrapper layoutTheme={layoutTheme}>
                            {
                                logo && (
                                    <Logo layoutTheme={layoutTheme} />
                                )
                            }
                            {children}
                        </Wrapper>
                    </Container>
                <Footer/>
            </GabaritoroProvider>
        </>
    );
};

export default Layout;