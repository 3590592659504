import React, { useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'

import useDocumentSize from '../../../../hooks/useDocumentSize'
import { checkPodcastEpisodePermission } from '../../../../helpers/podcast'
import { allowedFeature } from '../../../../helpers/permissions'

import Player from '../../../../components/Podcast/Player'
// import Footer from '../../../../components/Podcast/Footer'
import PodcastButton from '../../../../components/Podcast/Button'
import { ReactComponent as HeadSetSVG } from '../../../../img/podcast/Headset.svg'

//
// context
import { CourseContext } from '../../ClassContent/CourseDetail.container'

//
// redux + selectors
import * as authSelectors from '../../../../redux/selectors/auth'
import { actions as classActions } from '../../../../redux/modules/class'
import { actions as courseActions } from '../../../../redux/modules/course'
import { actions as podcastActions } from '../../../../redux/modules/podcast'

import './styles/class.scss';
import { IntroClassesArea } from './IntroClassesArea'
import { getClassesById } from '../../../../redux/api/class'

const propTypes = {
  user: PropTypes.object,
  registerPdfDownload: PropTypes.func.isRequired,
  setClassProperties: PropTypes.func.isRequired,
  onCloseFooterPlay: PropTypes.func.isRequired,
  onOpenFooterPlay: PropTypes.func.isRequired,
  fetchEpisodePodcastByClass: PropTypes.func.isRequired,
  availableClassesEpisodes: PropTypes.array.isRequired,
  currentPlay: PropTypes.object.isRequired,
  currentPodcast: PropTypes.object.isRequired,
  trackPodcast: PropTypes.func.isRequired,
  lastCurrentTimeTrack: PropTypes.number.isRequired
};
function Intro({
  fetchEpisodePodcastByClass,
  onCloseFooterPlay,
  onOpenFooterPlay,
  availableClassesEpisodes,
  currentPlay,
  currentPodcast,
  trackPodcast,
  lastCurrentTimeTrack
}) {
  const [showPodcast, setShowPodcast] = useState(false);
  const [foundPodcast, setFoundPodcast] = useState(false);
  const [myClassSelected, setMyClassSelected] = useState(null);
  const { selectedClass, setClassStep, course_category } = useContext(CourseContext);
  const { width } = useDocumentSize();
  const { courseId } = useParams();

  const { pdf_file } = selectedClass

  const existVideo = Object.keys(selectedClass).find(element => element === 'aula_videos') &&
  selectedClass.video_embed  ?
    selectedClass.video_embed:
    selectedClass.aula_videos?.length > 0
      ? selectedClass.aula_videos[0].video_embed
      : ''
    function handleOpenClassPodcast () {
    setShowPodcast(true);
    fetchEpisodePodcastByClass(Number(courseId), selectedClass.cod_aula, _ => {
      onCloseFooterPlay();
    });
  };

  function handleLeaveEpisodePodcast () {
    const currentTime = currentPlay.currentTime;
    const duration = currentPlay.duration;

    const percentFinish = Math.floor(currentTime / duration);
    const closePodcast = () => setShowPodcast(false);

    trackPodcast({
      ...currentPodcast,
      finished: (percentFinish > 90),
      currentTime,
      lastCurrentTimeTrack
    }, closePodcast, closePodcast);
  };

  useEffect(() => {
    setShowPodcast(false);
    const allowed = checkPodcastEpisodePermission(availableClassesEpisodes, selectedClass.cod_aula);
    if (allowed) {
      setFoundPodcast(true);
    } else {
      setFoundPodcast(false);
    }
    // eslint-disable-next-line
  }, [selectedClass.cod_aula, availableClassesEpisodes]);

  useEffect(()=>{
    (async ()=>{
      if(selectedClass.id !== undefined && selectedClass.order === 1){
        const dataMyClassSelected = await getClassesById(selectedClass.id)
        setMyClassSelected(dataMyClassSelected)
      }
    })()
    // eslint-disable-next-line
  },[selectedClass.id])

  if(selectedClass.order === 1 && myClassSelected !== null && myClassSelected.class_type_id === 5) {
    return <IntroClassesArea selectedClass={myClassSelected} courseCategory={course_category} />
  }

  return (
    <>
      {
        showPodcast ? (
          <>
          <div className="box-podcast-listen-button" onClick={handleLeaveEpisodePodcast}>
            <PodcastButton
              title="Voltar"
              onRenderIcon={() => <ArrowLeftOutlined />}
            />
          </div>
          <div className="box-see-podcast">
            <div className="box-title">
              <h3>Ouça o Podcast</h3>
            </div>
            <div className="box-player">
              <Player />
            </div>
            {/* <div className="box-footer">
              <Footer />
            </div> */}
          </div>
          </>
        ) : (
          <>
            <div className="box-podcast-listen-button" onClick={handleOpenClassPodcast}>
              {
                (allowedFeature('podcast') && foundPodcast) && (
                  <PodcastButton
                    title="Ouça o áudio do dia"
                    onRenderIcon={
                      () => (
                        <HeadSetSVG
                          fill={width <= 600 ? '#FFFFFF' : '#F89C3A'}
                          width="25"
                          height="25"
                        />
                      )
                    }
                  />
                )
              }
            </div>
            <div className="pdf-section-content no-pdf-section-content">
              <h2>Sobre <span>a aula</span></h2>
              <p dangerouslySetInnerHTML={{
                  __html: selectedClass.texto}}></p>
              <Button
                type="primary"
                className="ant-btn ant-btn-primary pdf-button"
                onClick={() => {
                  if(pdf_file){
                    setClassStep('file')
                  } else if(existVideo){
                    setClassStep('video')
                  } else if(selectedClass.have_lecture) {
                    setClassStep('lecture')
                  }
                }}
              >
                Iniciar aula
                <ArrowRightOutlined />
              </Button>
            </div>
          </>
        )
      }
    </>
  )
}

const mapStateToProps = (state, props) => ({
  user: authSelectors.getUser(state, props),
  availableClassesEpisodes: state.podcast.availableClassesEpisodes,
  currentPlay: state.podcast.currentPlay,
  lastCurrentTimeTrack: state.podcast.lastCurrentTimeTrack,
  currentPodcast: state.podcast.currentPodcast
})

const mapDispatchToProps = (dispatch) => ({
  registerPdfDownload: (userId, courseTag, classId, next) =>
    dispatch(classActions.registerPdfDownload(userId, courseTag, classId, next)),
  setClassProperties: (selectedClass, step, next) =>
    dispatch(courseActions.setClassProperties(selectedClass, step, next)),
  onCloseFooterPlay: () => dispatch(podcastActions.closePlayerFooter()),
  onOpenFooterPlay:() => dispatch(podcastActions.openPlayerFooter()),
  fetchEpisodePodcastByClass: (courseId, tags, callback) => dispatch(podcastActions.fetchByClass(courseId, tags, callback)),
  trackPodcast: (formData, callbackSuccess, callbackError) => dispatch(podcastActions.track(formData, callbackSuccess, callbackError))
})

Intro.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(Intro)
