import React, { useState, useContext, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import "react-horizontal-scrolling-menu/dist/styles.css"
import { LeftArrow, RightArrow } from "./Arrows"
import PaginationItem from './PaginationItem'

//
// general components
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlayCircleOutlined,
  CalculatorOutlined,
  AlignLeftOutlined,
} from '@ant-design/icons'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { differenceInMilliseconds, format } from 'date-fns'
import LectureVideoQuestion from './LectureVideoQuestion.component'
import LectureTextQuestion from './LectureTextQuestion.component'
import { Button, Radio, Skeleton, notification } from 'antd'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

import useDocumentSize from '../../../../../hooks/useDocumentSize'
import { TimerContext } from '../../../../../hooks/useTimer'
import { msToTime, stringTimetoMillisseconds, sumAllQuestionTimes } from '../../../../../helpers'
import { sanitizeOngoingPayload, sanitizeFinalPayload } from './helpers'
import { RenderSavebutton } from './components/questions'
import FormulaMatematica from './components/formula'

//
// context
import { CourseContext } from '../../../ClassContent/CourseDetail.container'

//
// redux + selectors
import * as authSelectors from '../../../../../redux/selectors/auth'
import * as classSelectors from '../../../../../redux/selectors/class'
import { actions as classActions } from '../../../../../redux/modules/class'
import { actions as courseActions } from '../../../../../redux/modules/course'
import VideoTypeModal from './components/VideoTypeModal'

import Unanswered from './components/Unanswered'
import UnansweredMobile from './components/UnansweredMobile'

import { getQuestionsWithLetters } from '../common'
import { 
  Container, 
  BoxAnswer, 
  Box, 
  BoxTwo,
  Header, 
  TitleQuestionArea, 
  QuestionIdentification, 
  QuestionStatement,
  RadioStyled,
  QuestionFooter,
  QuestionPagination,
  QuestionProgress,
} from './styles/Lecture.component'

const propTypes = {
  redo: PropTypes.bool,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  ongoingLecture: PropTypes.object,
  studentQuestions: PropTypes.array,
  getLectureDetails: PropTypes.func,
  persistOngoingLecture: PropTypes.func,
  lectureWrongQuestions: PropTypes.array,
  finishLecture: PropTypes.func.isRequired,
  lectureStarted: PropTypes.bool.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  getOngoingLecture: PropTypes.func.isRequired,
  currentQuestion: PropTypes.object.isRequired,
  setLectureStarted: PropTypes.func.isRequired,
  setClassProperties: PropTypes.func.isRequired,
  getFinishedLectures: PropTypes.func.isRequired,
  getLectureWrongQuestions: PropTypes.func.isRequired,
}

const defaultProps = {
  redo: false,
}

function Lecture(props) {
  const {
    user,
    redo,
    isLoading,
    finishLecture,
    lectureStarted,
    studentQuestions,
    setLectureStarted,
    getOngoingLecture,
    getLectureDetails,
    setClassProperties,
    getFinishedLectures,
    lectureWrongQuestions,
    persistOngoingLecture,
    getLectureWrongQuestions,
    studentQuestions: allQuestions,
  } = props

  //
  // course context
  const {
    course,
    setClassStep,
    selectedClass,
    setSelectedClass,
    setLectureIdToReview,
    setTotalWrongQuestionsHelper,
  } = useContext(CourseContext)

  const { time, running, setRunning, setTime } = useContext(TimerContext)

  //
  // component state
  const [answers, setAnswers] = useState({})
  const [answersWithLetters, setAnswersWithLetters] = useState([])

  const [redoAnswers, setRedoAnswers] = useState({})
  const [questionTime, setQuestionTime] = useState({})
  const [formulaVisible, setFormulaVisible] = useState(false)
  const [questionStartDate, setQuestionStartDate] = useState({})
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [questionVideoStart, setQuestionVideoStart] = useState(false)
  const [questionTextShow, setQuestionTextShow] = useState(false)
  const [modalVideoOption, setModalVideoOption] = useState(false)
  const [LSBVideo, setLSBVideo] = useState(false)
  const [visibleUnanswered, setVisibleUnanswered] = useState(false)
  const [percentAnswered, setPercentAnswered] = useState(0)
  const [questionsViewed, setQuestionsViewed] = useState([])
  const size = useDocumentSize()
  const paginationRef = useRef({});
  const questions = redo ? lectureWrongQuestions : studentQuestions

  const isSimulated = questions[0]?.class_type === 2

  //
  // get lecture details
  useEffect(() => {
    if (lectureStarted) {
      if (redo) {
        //
        // 1. get existing finished lectures
        // 2. get wrong questions from older finished lecture
        getFinishedLectures(selectedClass.id, (lectures) => {
          if (lectures.length > 0) {
            const firstLecture = lectures[lectures.length - 1]
            if (firstLecture.id_prova) {
              getLectureWrongQuestions(firstLecture.id_prova)
            }
          } else {
            //
            // if no finished lectures, ask user to do a lecture
            notification['error']({
              message: `Não encontramos provas finalizadas, por favor realize a prova antes de tentar fazer a revisão`,
            })
          }
        })
      } else {
        getLectureDetails(selectedClass.id, () => {
          getOngoingLecture(user.id, selectedClass.id, course.tag, (lectureReturn) => {
            const lecture = JSON.parse(_.get(lectureReturn, '[0].prova', {}))
            setAnswers(lecture.question)
            setQuestionTime(lecture.question_time)
          })
        })
      }
    }
    // eslint-disable-next-line
  }, [selectedClass, lectureStarted])

  useEffect(() => {
    if (JSON.stringify(answers) !== '{}' && questions && questions.length > 0) {
      const questionsWithAnswersAndLetters = getQuestionsWithLetters(questions, answers)
      setAnswersWithLetters(questionsWithAnswersAndLetters)
    }
    // eslint-disable-next-line
    setPercentAnswered(parseInt(((currentQuestionIndex + 1) * 100) / questions.length));
  }, [answers, questions, currentQuestionIndex])

  //
  // start lecture time count
  useEffect(() => {
    if (lectureStarted) {
      setQuestionStartDate(new Date())

      if (isSimulated) {
        setTime(0)
        setRunning(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lectureStarted, isSimulated])

  useEffect(() => {
    if (LSBVideo) {
      setQuestionVideoStart(true)
    }
  }, [LSBVideo])

  const handleCloseLecture = () => {
    setAnswers({})
    setAnswersWithLetters([])

    setTime(0)
    setRunning(false)

    setCurrentQuestionIndex(0)
    setLectureStarted(false)
    setVisibleUnanswered(false)
  }

  //
  // Handler for the question change and ongoing state persist
  const handleQuestionChange = async (direction = false) => {
    //
    // generate the question time
    // 1. check existing time
    // 2. convert exiting time to milisseconds
    // 3. sum with ongoing question time
    // 4. assign to questionTime state var
    let questionTimeTotal = 0

    let existingTime = _.get(questionTime, `[${currentQuestion.id_questao}]`, '00:00:00')

    // little workaround to avoid existing calc problems
    if (existingTime === 'NaN:NaN:NaN') existingTime = '00:00:00'

    const existingTimeInMs = stringTimetoMillisseconds(existingTime)

    questionTimeTotal += existingTimeInMs
    questionTimeTotal += differenceInMilliseconds(new Date(), questionStartDate)

    const newQuestionTime = Object.assign(
      { ...questionTime },
      { [currentQuestion.id_questao]: msToTime(questionTimeTotal) }
    )

    setQuestionTime(newQuestionTime)

    //
    // persist the question answer
    const ongoingPayload = {
      contador: sumAllQuestionTimes(newQuestionTime),
      curso: course.tag,
      aula: selectedClass.id,
      question_time: newQuestionTime,
      question: Object.assign({ ...answers }, { ...redoAnswers }),
    }

    const sanitizedOngoingPayload = sanitizeOngoingPayload({ ongoingPayload })

    persistOngoingLecture(sanitizedOngoingPayload, user.id, selectedClass.id)

    //
    // reset question timer
    setQuestionStartDate(new Date())

    //
    // make navigation
    if (direction) {
      const newIndex = direction === 'next' ? currentQuestionIndex + 1 : currentQuestionIndex - 1;
      
      setCurrentQuestionIndex(newIndex);
      setQuestionsViewed([...questionsViewed, newIndex]);
      
      paginationRef.current.scrollToItem(
        paginationRef.current.getItemById(`question-${newIndex}`),
        "smooth", 
        "center", 
        "nearest"
      );
    }
  }
  const alphabeticalOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M']
  let alphabeticalOrderIndex = 0
  //
  // handler for set answers used to persist the ongoing state
  const setQuestionAnswer = (answer) => {
    if (redo) {
      setRedoAnswers(Object.assign({ ...redoAnswers }, { [currentQuestion.id_questao]: answer }))
    } else {
      setAnswers(Object.assign({ ...answers }, { [currentQuestion.id_questao]: answer }))
    }
  }

  //
  // finish the lecture
  const handleFinishLecture = async () => {
    setVisibleUnanswered(false)

    setTime(0)
    setRunning(false)

    //
    // false parameter for not change currentQuestionIndex
    await handleQuestionChange(false)
    const finalPayload = {
      prova: {
        contador: sumAllQuestionTimes(questionTime),
        curso: course.tag,
        aula: selectedClass.id,
        question: Object.assign({ ...answers }, { ...redoAnswers }),
        answersWithLetters,
        data_prova: format(new Date(), 'yyyy/MM/dd HH:mm'),
      },
    }

    const sanitizedFinalPayload = sanitizeFinalPayload({ finalPayload })

    finishLecture(sanitizedFinalPayload, user.id, selectedClass.id, (lecture) => {
      setLectureStarted(false)
      setLectureIdToReview(_.get(lecture, 'finishedLecture.id_prova'))
      if (redo) {
        setTotalWrongQuestionsHelper(lectureWrongQuestions.length)
        setClassStep('lectureRedoResult')

        setClassProperties(selectedClass, { second_test: true }, (newSelectedClass) => {
          setSelectedClass({ ...newSelectedClass, second_test: true })
        })
      } else {
        setClassStep('lectureResult')

        setClassProperties(selectedClass, { first_test: true }, (newSelectedClass) => {
          setSelectedClass({ ...newSelectedClass, first_test: true })
        })
      }

      setAnswers({})
      setAnswersWithLetters([])
      setCurrentQuestionIndex(0)
      setQuestionTime({})
    })
  }

  const formulaMtematica = () => {
    return (
      <Button onClick={() => setFormulaVisible(true)}>
        <CalculatorOutlined /> Dica da questão
      </Button>
    )
  }

  const handlePlayVideo = () => {
    if (course?.is_libras && currentQuestion?.video_embed_libras) {
      //setModalVideoOption(true)
      setLSBVideo(true)
      setQuestionVideoStart(true)
    } else {
      setQuestionVideoStart(true)
    }
  }

  const handleQuestionByIndex = (index) => {
    handleQuestionChange()
    return setCurrentQuestionIndex(index)
  }

  //
  // Lecture modal footer render
  const renderFooter = () => {
    const questions = redo ? lectureWrongQuestions : allQuestions
    const answersNumber = allQuestions.filter((question) => getAnswerValue(question)).length //numero de questões respondidas
    const isLastQuestion = questions.length === currentQuestionIndex + 1 //retorna se true se for a ultima questao da prova
    const allQuestionsReady = allQuestions.length - answersNumber === 0 //retorna true se todas questoes da prova foram responsidas

    const backToFirstNotAnswered = () => {
      setVisibleUnanswered(true)
      handleQuestionChange()

      const index = allQuestions.findIndex((question) => !getAnswerValue(question))
      return setCurrentQuestionIndex(index)
    }
    //retorna a primeira ...

    return !questions || questions.length === 0 ? (
      <Skeleton.Button />
    ) : (
      <QuestionFooter>
        <div className="lecture-modal-footer">
          {currentQuestion.formula || currentQuestion.formula_imagem ? formulaMtematica() : ''}
            <Button
              disabled={currentQuestionIndex === 0}
              onClick={() => handleQuestionChange('prev')}
              className='button-prev-question'
            >
                <ArrowLeftOutlined />
                <span style={{textTransform: "none"}}> Anterior</span>
            </Button>
              
            {currentQuestion.questao_comentada && !isSimulated && (
              <div className="commented-question">
                <Button onClick={() => setQuestionTextShow(true)}>
                  <AlignLeftOutlined style={{ fontSize: 20 }} /> Ler a resolução
                </Button>
              </div>
            )}

            {(currentQuestion.video_embed_libras || currentQuestion.video_embed) && !isSimulated && (
              <div className="commented-question">
                <Button onClick={handlePlayVideo}>
                  <PlayCircleOutlined style={{ fontSize: 20 }} /> Assistir a resolução
                </Button>
              </div>
            )}
  
            {!isLastQuestion && <Button className='button-next-question-success' disabled={isLastQuestion} onClick={() => handleQuestionChange('next')}>
              <span style={{textTransform: "none"}}>Próximo </span>
              <ArrowRightOutlined />
            </Button>}

          {(allQuestionsReady || isLastQuestion) &&
            RenderSavebutton(handleFinishLecture, allQuestionsReady, backToFirstNotAnswered)}
        </div>
        {visibleUnanswered && size.width <= 768 && (
          <UnansweredMobile
            questions={allQuestions}
            answers={answers}
            redoAnswers={redoAnswers}
            handleQuestionByIndex={handleQuestionByIndex}
            currentQuestion={questions[currentQuestionIndex]}
          />
        )}
      </QuestionFooter>
    )
  }

  const getAnswerValue = (currentQuestion) => {
    if (currentQuestion) {
      if (redo) {
        return redoAnswers[currentQuestion.id_questao]
      } else {
        return answers[currentQuestion.id_questao]
      }
    } else {
      return null
    }
  }

  //
  // styles for radio button
  // used on question answers
  const radioStyle = {
    display: 'flex',
    marginBottom: 15,
    lineHeight: '30px',
    whiteSpace: 'break-spaces',
    alignItems: 'baseline',
  }

  const currentQuestion = questions[currentQuestionIndex]

  const renderImg = (img) => {
    if (img) {
      const domain = 'https://arquivos.academiarafaeltoro.com.br'
      const path = 'uploads/'
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img
      return (
        <div className='input-box'>
          <InnerImageZoom
            src={`${domain}/${imgWithPath}`}
            fullscreenOnMobile={true}
            hideCloseButton={true}
          />
        </div>
      )
    }
    return ''
  }

  const handleItemClick = (itemId, index) => ({
    getItemById,
    scrollToItem
  }) => {
    setQuestionsViewed([...questionsViewed, index])
    setCurrentQuestionIndex(index)
    scrollToItem(getItemById(itemId), "smooth", "center", "nearest");
  };

  return (
    <>
      {lectureStarted && (
        <>
          <Container
            visible={lectureStarted}
            closable={true}
            onCancel={handleCloseLecture}
            destroyOnClose={true}
            footer={questions ? renderFooter() : null}
            maskClosable={false}
            wrapClassName="lectureContainer"
            title={redo ? 'Refazedo questões erradas' : null}
            zIndex={999}
          >
            <Box visibleUnanswered={visibleUnanswered} wideVersion={size.width > 768}>
              <Header>
                  {isLoading || questions.length === 0 ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <TitleQuestionArea>
                        <h1 className={!isSimulated && 'onlyTitle'}>{course.title} &bull; Questão {currentQuestionIndex + 1} de {questions.length}</h1>
                        {isSimulated && (
                        <div>
                          <time>
                            {('0' + parseInt(String((time / 3600) % 3600))).slice(-2)}:
                            {('0' + parseInt(String((time / 60) % 60))).slice(-2)}:
                            {('0' + (time % 60)).slice(-2)}
                          </time>
                          <button className={!running ? 'stopped' : ''} type='button' onClick={() => setRunning(!running)}>
                            <span></span>
                            {running ? 'Pausar': 'Play'}
                          </button>
                        </div>
                        )}
                      </TitleQuestionArea>
                    </>
                  )}
                </Header>
              <BoxTwo>
              {visibleUnanswered && size.width > 768 && (
                <Unanswered
                  questions={allQuestions}
                  answers={answers}
                  redoAnswers={redoAnswers}
                  handleQuestionByIndex={handleQuestionByIndex}
                  currentQuestion={questions[currentQuestionIndex]}
                  firstQuestion={
                    allQuestions.find((question) => !getAnswerValue(question))?.pivot?.ordem - 1 ??
                    0
                  }
                  paginationRef={paginationRef}
                />
              )}
              <BoxAnswer visibleUnanswered={visibleUnanswered} wideVersion={size.width > 768}>
                {modalVideoOption && (
                  <VideoTypeModal
                    modalVideoOption={modalVideoOption}
                    setModalVideoOption={setModalVideoOption}
                    setLSBVideo={setLSBVideo}
                    setQuestionVideoStart={setQuestionVideoStart}
                  />
                )}
                <LectureTextQuestion
                  questionTextShow={questionTextShow}
                  setQuestionTextShow={setQuestionTextShow}
                  text={currentQuestion?.questao_comentada}
                />
                <LectureVideoQuestion
                  questionVideoStart={questionVideoStart}
                  setQuestionVideoStart={setQuestionVideoStart}
                  videoEmbed={
                    currentQuestion &&
                    (LSBVideo ? currentQuestion.video_embed_libras : currentQuestion.video_embed)
                  }
                  setModalVideoOption={setModalVideoOption}
                  setLSBVideo={setLSBVideo}
                />
                <QuestionPagination>
                  <div>
                  <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    apiRef={paginationRef}
                  >
                    {questions.map((_question, index) => (
                        <PaginationItem 
                          current={currentQuestionIndex === index} 
                          answered={getAnswerValue(_question)}
                          viewed={questionsViewed.includes(index)}
                          key={`question-${index}`} 
                          itemId={`question-${index}`}
                          onClick={handleItemClick(`question-${index}`, index)}
                        >
                          {index + 1}
                        </PaginationItem>
                    ))}
                    </ScrollMenu>
                  </div>
                </QuestionPagination>
                <QuestionProgress percent={percentAnswered}>
                  {percentAnswered > 0 && <p>Progresso: <strong>{percentAnswered}%</strong></p>}
                </QuestionProgress>
                <div className="questionAnswersContainer">
                  {isLoading || questions.length === 0 ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <QuestionIdentification>Questão {currentQuestionIndex + 1} <span>({currentQuestion.codigo})</span></QuestionIdentification>
                      <QuestionStatement>
                        {currentQuestion.enunciado}
                        {renderImg(currentQuestion.img_enunciado)}
                      </QuestionStatement>
                        <Radio.Group
                          onChange={(e) => setQuestionAnswer(e.target.value)}
                          value={getAnswerValue(currentQuestion)}
                          style={{
                            width: '100%',
                            paddingRight: '10px'
                          }}
                        >
                          {currentQuestion.answers
                            .filter((answer) => answer.resposta)
                            .map((answer, index) => {
                              alphabeticalOrderIndex = index
                              return (
                                <RadioStyled
                                  key={`answer-${index}`}
                                  style={radioStyle}
                                  value={answer.id_resposta}
                                  checked={getAnswerValue(currentQuestion) === answer.id_resposta}
                                >
                                  {`${alphabeticalOrder[index]}) ${answer.resposta}`}
                                </RadioStyled>
                              )
                            })}
                          <RadioStyled 
                            style={{...radioStyle, background: '#FFEFDE', borderColor: '#FFEFDE'}} 
                            value="idk"
                            checked={getAnswerValue(currentQuestion) === 'idk'}
                          >
                            {`${alphabeticalOrder[alphabeticalOrderIndex + 1]}) Não sei.`}
                          </RadioStyled>
                          </Radio.Group>
                    </>
                  )}
                </div>
              </BoxAnswer>
              </BoxTwo>
            </Box>
          </Container>

          {formulaVisible && (
            <FormulaMatematica
              formulaVisible={formulaVisible}
              currentQuestion={currentQuestion}
              setFormulaVisible={setFormulaVisible}
            />
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  studentQuestions: classSelectors.getStudentQuestions(state, props),
  isLoading: classSelectors.isLoading(state, props),
  user: authSelectors.getUser(state, props),
  ongoingLecture: classSelectors.getOngoingLecture(state, props),
  lectureWrongQuestions: classSelectors.lectureWrongQuestions(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  getLectureDetails: (classId, next) => dispatch(classActions.getLectureDetails(classId, next)),
  persistOngoingLecture: (ongoingPayload, userId, classId, next) =>
    dispatch(classActions.persistOngoingLecture(ongoingPayload, userId, classId, next)),
  getOngoingLecture: (userId, classId, courseTag, next) =>
    dispatch(classActions.getOngoingLecture(userId, classId, courseTag, next)),
  finishLecture: (finalPayload, userId, classId, next) =>
    dispatch(classActions.finishLecture(finalPayload, userId, classId, next)),
  getCourseDetails: (courseId, next) => dispatch(courseActions.getCourseDetails(courseId, next)),
  getLectureWrongQuestions: (lectureId, next) =>
    dispatch(classActions.getLectureWrongQuestions(lectureId, next)),
  getFinishedLectures: (classId, next) => dispatch(classActions.getFinishedLectures(classId, next)),
  setClassProperties: (courseId, step, next) =>
    dispatch(courseActions.setClassProperties(courseId, step, next)),
})

Lecture.propTypes = propTypes
Lecture.defaultProps = defaultProps
export default connect(mapStateToProps, mapDispatchToProps)(Lecture)
