import React, { useContext, useEffect, useState } from 'react'
import { Row, Button } from 'antd'
import { PlaySquareOutlined } from '@ant-design/icons'
import { DiscussionEmbed } from 'disqus-react'

import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import config from '../../../../config';
//
// redux + selectors
import { actions as classActions } from '../../../../redux/modules/class'
import * as authSelectors from '../../../../redux/selectors/auth'
import { actions as courseActions } from '../../../../redux/modules/course'

//
// context
import { CourseContext } from '../../ClassContent/CourseDetail.container'
import RenderVideo from '../../../../components/RenderVideo.component'

const propTypes = {
  user: PropTypes.object,
  registerVideoPlay: PropTypes.func.isRequired,
  setClassProperties: PropTypes.func.isRequired,
  videoEmbed: PropTypes.string,
}
function ClassVideo(props) {
  const { course, selectedClass, setSelectedClass } = useContext(CourseContext)
  const handlePlayVideo = () => {
    const { registerVideoPlay, user, setClassProperties } = props


    const videoId = currentVideo?.id_video ?? 0;

    registerVideoPlay(user.id, course.tag, selectedClass.id, videoId, (payload) => {

      const video_played = payload?.video_played ?? 0;

      setClassProperties(selectedClass, { video_played }, (newSelectedClass) => {
        setSelectedClass({...newSelectedClass, video_played })
      })
    })
  }

  useEffect(() => {
    handlePlayVideo();
    // eslint-disable-next-line
  },[])

  const [currentVideo, setCurrentVideo] = useState()

  return (
    <>
      <h3>Assista ao vídeo da aula:</h3>
      <div className="video-class">
        <Row className="video-classRow">
          {selectedClass.aula_videos && selectedClass.aula_videos.length > 1 ? (
            <>
              {selectedClass.aula_videos.map((value, i) => {
                return value.video_embed ? (
                  <>
                    <Button
                      icon={<PlaySquareOutlined />}
                      type="primary"
                      className={
                        'pdf-button' +
                        (currentVideo && value.id_video === currentVideo.id_video ? ' current' : '')
                      }
                      onClick={() => setCurrentVideo(value)}
                      xs={{ span: 8, offset: 4 }}
                      style={{ marginBottom: '4%', cursor: 'pointer' }}
                    >
                      Vídeo {i + 1}
                    </Button>
                  </>
                ) : (
                  ''
                )
              })}
              <div style={{ width: '100%' }}>
                {currentVideo ? <RenderVideo videoEmbed={currentVideo.video_embed} handlePlayVideo={handlePlayVideo} /> : ''}
              </div>
            </>
          ) : selectedClass.aula_videos ? (
            selectedClass.aula_videos.map((value) =>
              value.video_embed ? (
                <div style={{ width: '100%' }}>
                  <RenderVideo videoEmbed={value.video_embed} handlePlayVideo={handlePlayVideo} />
                </div>
              ) : (
                'Não temos vídeo para esta aula no momento'
              )
            )
          ) : (
            ''
          )}
        </Row>
      </div>
      {/\(LIVE\)$/i.test(selectedClass.cod_aula) && <DiscussionEmbed
          shortname={config.DISQUS_SHORTNAME}
          config={
              {
                  url: `${window.location.href}?aula=${selectedClass.id}`,
                  identifier: `${course.id_turma}/${selectedClass.id}`,
                  title: `${course.title} ${selectedClass.titulo} ${course.id_turma}`,
                  language: 'pt_BR'
              }
          }
        />}
    </>
  )
}

const mapStateToProps = (state, props) => ({
  user: authSelectors.getUser(state, props),
})

const mapDispatchToProps = (dispatch) => ({
  registerVideoPlay: (userId, courseTag, classId, videoId, next) =>
    dispatch(classActions.registerVideoPlay(userId, courseTag, classId, videoId, next)),
  setClassProperties: (courseId, step, next) =>
    dispatch(courseActions.setClassProperties(courseId, step, next)),
})

ClassVideo.propTypes = propTypes
export default connect(mapStateToProps, mapDispatchToProps)(ClassVideo)
