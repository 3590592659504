import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowLeftOutlined, LeftOutlined } from '@ant-design/icons';
import { getQuestionByCode } from '../../redux/api/question';
import MainLayout from '../../components/MainLayout';
import Question from '../../components/SearchQuestion/Question';
import { Container, Panel, ButtonsStyled, ButtonStyled, ButtonStyledTwo } from './styles';

function QuestionPage() {
  const history = useHistory();
  const { questionCode } = useParams<{ questionCode: string }>();
  const [data, setData] = useState({});

  const fetchData = useCallback(async (questionCode) => {
    const questionData = await getQuestionByCode(questionCode);
    setData(questionData);
  }, []);

  useEffect(() => {
    if (questionCode) {
      fetchData(questionCode).catch(console.error);
    }
  }, [questionCode, fetchData]);

  return (
    <MainLayout title="Questão" className="home">
      <Container>
        <ButtonsStyled>
          <ButtonStyled type="default" onClick={() => history.push('/questions')}>
            <ArrowLeftOutlined /> Voltar para Questões
          </ButtonStyled>
          <ButtonStyledTwo type="default" onClick={() => history.push('/questions')}>
            <LeftOutlined style={{ fontSize: '24px' }} />
          </ButtonStyledTwo>
        </ButtonsStyled>
        <Panel>
          <Question data={data} resolved />
        </Panel>
      </Container>
    </MainLayout>
  );
}

export default QuestionPage;
