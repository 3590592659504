import React from 'react';
import { Content } from '../../../components/NewCheckout';
import Checkout from '..';

export default function New () {
    return (
        <Checkout>
            <Content />
        </Checkout>
    );
};