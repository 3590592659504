import React from 'react';

import './styles.scss';

type Props = {
    description: string;
};

export default function Subtitle({
    description
}: Props) {
    return <h3 className="report-cfp-subtitle">{description}</h3>
};
