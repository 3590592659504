import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { actions as podcastActions, PodcastState } from '../../../redux/modules/podcast';

import { ReactComponent as BanSVG } from '../../../img/podcast/Ban.svg';
import TableItem from '../Table/Item';
import TableHeader from '../Table/Header';

import { IEpisode } from '../../../shared/Models/podcast/IEpisode';
import { IPodcast } from '../../../shared/Models/podcast/IPodcast';
import { 
    Container,
    Header,
    Content,
    BoxLoading,
    BoxEmpty,
    BoxArrows
} from './styles';

export default function Album () {
    const [current, setCurrent] = useState(0);
    const dispatch = useDispatch();
    const { 
        currentPodcast,
        currentPlay,
        lastCurrentTimeTrack,
        historical, 
        podcasts, 
        isLoading 
    } = useSelector((state: { podcast: PodcastState }) => state.podcast);
    
    function handleSelectedPodcast (episode: IEpisode, podcast: IPodcast) {
        const historicPlay = historical.find(item => String(item.id) === String(currentPlay.id));
        if (currentPodcast?.id && currentPlay.currentTime !== historicPlay?.currentTime) {
            const olderPlay = currentPlay;
            const olderEpisode = currentPodcast;    
            dispatch(podcastActions.track({
                ...olderEpisode,
                finished: false,
                currentTime: olderPlay.currentTime,
                lastCurrentTimeTrack,
            }));   
        }

        dispatch(podcastActions.selectedItem(episode, podcast));
    };

    return (
        <Container>
            {
                isLoading ? (
                    <BoxLoading>
                        <Spin />
                    </BoxLoading>    
                ) : !podcasts.length ? (
                    <BoxEmpty>
                        <BanSVG />
                        <span>Ops! Você ainda não possui nenhum podcast disponível.</span>
                    </BoxEmpty>
                ): (
                    <>
                    {
                        (podcasts.length > 1) && (
                            <BoxArrows>
                                {
                                    (current > 0) ? (
                                        <button onClick={() => setCurrent(current - 1)}>
                                            <LeftOutlined />
                                        </button>
                                    ) : <span />
                                }
                                {
                                    ((current+1) < podcasts.length) ? (
                                        <button onClick={() => setCurrent(current + 1)}>
                                            <RightOutlined />
                                        </button>
                                    ) : <span />
                                }
                            </BoxArrows>
                        )
                    }
                    <Header>
                        <div className="box-img">
                            <img src={podcasts[current].img} alt="Podcast Imagem"/>
                        </div>
                        <div className="description">
                            <span>{podcasts[current].title}</span>
                            <h2>{podcasts[current].description}</h2>
                        </div>
                    </Header>

                    <Content>
                        <div className="body">
                            <TableHeader />
                            {
                                podcasts[current].episodes
                                    .filter(item => item.published)
                                    .map(item => (
                                        <TableItem 
                                            key={item.id}
                                            onClick={handleSelectedPodcast}
                                            episode={item}
                                            podcast={podcasts[current]}
                                            selected={item.id === currentPodcast.id}
                                        />
                                    ))
                            }
                        </div>
                    </Content>
                    </>
                )
            }
        </Container>
    );
};