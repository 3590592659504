import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Row, Col, Button, Spin } from 'antd';
import { actions as mentorshipActions } from '../../redux/modules/mentorship';

const propTypes = {
  setMentorshipRealizedModal: PropTypes.func.isRequired,
  getMadeMentorship: PropTypes.func.isRequired,
};

function MentorshipRealized({ setMentorshipRealizedModal, getMadeMentorship }) {
  const [isVisible, setIsVisible] = useState(true);
  const [initialData, setInitialData] = useState();

  useEffect(() => {
    getMadeMentorship((mentors) => {
      setInitialData(mentors);
    });
    // eslint-disable-next-line
  }, [isVisible]);

  const handleCancel = () => {
    setIsVisible(false);
    setMentorshipRealizedModal(false);
  };

  return (
    <>
      <Modal
        width={500}
        closable={true}
        footer={false}
        maskClosable={true}
        style={{ top: 30 }}
        visible={isVisible}
        destroyOnClose={true}
        onCancel={() => handleCancel()}
      >
        <Row className="row-modal">
          <Col className="col-header">
            <p className="modal-title-mentoria">Mentorias</p>
            <br />
            <p className="modal-subTitle-mentoria">MENTORIAS REALIZADAS</p>
            {!initialData ? (
              <Spin />
            ) : (
              initialData?.map((mentor) =>
                mentor.id_professor ? (
                  <Row className="row-box-realized">
                    <Col key={mentor?.id_mentoria} xs={20}>
                      <h5 className="font-bold">{`${mentor?.professor?.nome} ${mentor?.professor?.sobre_nome}`}</h5>
                    </Col>
                    <Col key={mentor?.id_mentoria} xs={20}>
                      <br />
                      <p className="font-bold">{mentor?.aula.titulo}</p>
                      <p>{`${mentor?.hora_inicio} - ${mentor?.hora_fim}`}</p>
                      <p>{moment(mentor?.dia_mentoria).format('LL')}</p>
                      <br />
                    </Col>
                  </Row>
                ) : (
                  <Row className="row-box-realized">
                    <Col>
                      <p className="title-mentoship-realized">Mentoria aguardando confirmação</p>
                    </Col>
                    <Col key={mentor?.id_mentoria} xs={20}>
                      <br />
                      <p className="font-bold">{mentor?.aula.titulo}</p>
                      <p>{`${mentor?.hora_inicio} - ${mentor?.hora_fim}`}</p>
                      <p>{moment(mentor?.dia_mentoria).format('LL')}</p>
                      <br />
                    </Col>
                  </Row>
                )
              )
            )}
          </Col>
          <Col xs={24} className="botao-voltar">
            <Button onClick={handleCancel}>Voltar</Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

MentorshipRealized.propTypes = propTypes;

const mapDispatchToProps = (dispatch) => ({
  getMadeMentorship: (next) => dispatch(mentorshipActions.getMyMadeMentorships(next)),
});

export default connect(null, mapDispatchToProps)(MentorshipRealized);
