import styled from 'styled-components';

export const Container = styled.div`

    display: flex;
    justify-content: space-between;

    padding: 0 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray_150};

    span {
        color: ${({ theme }) => theme.colors.gray_600};
        font-size: 14px;
        flex-basis: 15%;
        font-weight: 500;   
    }

    .description {
        flex-basis: 55%;
    }

    .time {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;