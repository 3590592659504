import styled from 'styled-components';
import { Modal } from 'antd';


type ContainerProps = {
    size: number;
}

export const Container = styled(Modal)<ContainerProps>`
    .ant-modal-content {
        border-radius: 10px;
        height: ${({ size }) => size}px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
`;