import React from 'react';

import {
    Text

} from './styles' 

const TextSuggestion = (props) => {
    return (
        <Text>
            <p>{props.children}</p>
        </Text>
    );
};

export default TextSuggestion;