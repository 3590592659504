import styled from "styled-components";

type TButton = {
  dropdownDisable: boolean;
}

type TDropdown = {
  dropdownDisable: boolean
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
`;

export const SelectAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  width: 100%;
`

export const SelectField = styled.select`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;

  width: 100%;

  border: 1px solid #000A66;
  border-radius: 5px;

  @media (min-width: 1366px) {
    min-width: 245px;
  }
  @media (min-width: 767px) and (max-width: 1365px) {
    min-width: 200px
  }
  @media (max-width: 767px) {
    min-width: 180px;
  }

  &:hover {
    border: 1px solid #A1A5CB;
    color: #A1A5CB;
  }

  &:focus {
    border: 1px solid #5768FF;
    color: #252525;
  }

  &:disabled {
    border: 1px solid #BBBBBB;
    color: #BBBBBB;
  }
`
export const Option = styled.option`
  width: 100%;
  font-family: 'Work Sans';
  color: #D6D6D6;
`
export const Text = styled.div<TDropdown>`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  margin-bottom: 0 !important;

  color: ${({dropdownDisable}) => dropdownDisable ? '#BBBBBB;' : '#000A66'};
`
export const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;

  margin: 0 10px;
  gap: 8px;

  background: #f09c30;

  border: 1px solid #f09c30;
  border-radius: 5px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  color: #FFFFFF;

  @media (min-width: 1366px) {
    min-width: 150px;
  }
  @media (min-width: 767px) and (max-width: 1365px) {
    min-width: 125px;
  }
  @media (max-width: 767px) {
    min-width: 100px;
    font-size: 14px;
  }

  &:hover {
    background: #D07514;
    border: 1px solid #D07514;
  }

  &:disabled {
    background: #EFEFEF;
    border: 1px solid #EFEFEF;
    color: #BBBBBB;
  }
`
