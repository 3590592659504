import React from 'react';
import { Container } from './styles';

import Notification from './Notification';
import Subscription from './Subscription';

type Props = {
    isVisible: boolean;
    setVisible: (isVisible: boolean) => void;
    onFinish: () => void;
    setPage: (page: string) => void;
    page: string;
};

const StepsSubscriptionChannels = ({ 
    isVisible, 
    setVisible,
    onFinish,
    setPage,
    page
}: Props) => {
    const renderStep = () => {
        switch (page) {
            case 'subscription':
                return (
                    <Subscription 
                        setPage={setPage} 
                        setSubscriptionIsVisiable={setVisible}
                    />
                );
            case 'notification':
                return (
                    <Notification onFinish={onFinish} />
                );
            default:
                return (
                    <Subscription 
                        setPage={setPage} 
                        setSubscriptionIsVisiable={setVisible}
                    />
                );
        }
    };

    const finish = () => {
        if (page === 'notification') {
            onFinish();
        } else {
            setVisible(false);
        }
    };

    return (
        <Container
            size={page === 'subscription' ? 639 : 548}
            zIndex={998}
            closable={true}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            footer={false}
            onCancel={finish}>
                {renderStep()}
        </Container>      
    );
};

export default StepsSubscriptionChannels;