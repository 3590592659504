import squarefour from '../../../img/squarefour.svg';
import commomCheck from '../../../img/commom-check.svg';
import trophySilver from '../../../img/trophy-silver.svg';

export const informationsItemsArray = [
  {
    icon: squarefour,
    title: '%%questionsQuantities%% questões',
  },
  {
    icon: commomCheck,
    title: 'Escolha apenas uma opção',
  },
  {
    icon: trophySilver,
    title: 'Acerte no mínimo 70% para passar',
  },
];

export const itemsSelectOnReview = [
  {
    title: 'Todas questões',
    value: 'all',
  },
  {
    title: 'Somente questões corretas',
    value: 'correct',
  },
  {
    title: 'Somente questões erradas',
    value: 'wrong',
  },
];

export type ItemSelectOnReview = {
  title: string;
  value: 'all' | 'correct' | 'wrong';
};