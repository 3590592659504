import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconDownloadStepHover: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M31.5 0H34.5V15H31.5V0Z" fill="#F0F2FF"/>
    <rect y="15" width="66" height="66" rx="33" fill="#F0F2FF"/>
    <g clip-path="url(#clip0_1580_10830)">
    <path d="M49.1668 58.2V58.2089L49.1671 58.2177C49.221 59.7378 48.6704 61.2174 47.6358 62.3324C46.6034 63.4451 45.173 64.1044 43.6567 64.1667H22.3442C20.8292 64.1003 19.4011 63.4397 18.3694 62.3278C17.3354 61.2134 16.7833 59.7358 16.8332 58.2164L16.8335 58.2082V58.2V48C16.8335 47.8011 16.9125 47.6103 17.0531 47.4697C17.1938 47.329 17.3845 47.25 17.5835 47.25H23.0661C23.222 47.7856 23.5126 48.2751 23.9132 48.6702L24.9605 49.7341L24.9604 49.7341L24.9632 49.7369L27.2927 52.0663C28.022 52.8287 28.7692 53.5926 29.5338 54.3411C30.1249 55.0363 30.8268 55.6294 31.611 56.0963L31.638 56.1123L31.6667 56.1249C31.8553 56.2072 32.0509 56.2725 32.2512 56.3198C32.9095 56.4772 33.6008 56.4218 34.2257 56.1616L34.242 56.1548L34.2578 56.1468C34.7902 55.8795 35.2703 55.5188 35.6753 55.0821L36.3996 54.3743L36.4056 54.3684L36.4114 54.3623L36.7605 53.9973L36.7941 53.9684C36.826 53.9407 36.8695 53.9024 36.9139 53.8615C36.9565 53.8223 37.0075 53.7737 37.0507 53.7271C37.0713 53.7049 37.0998 53.6728 37.1262 53.636C37.1299 53.6307 37.1343 53.6245 37.1391 53.6174L38.1864 52.5542C38.9558 51.8014 39.7078 51.0327 40.4554 50.2684C40.4555 50.2684 40.4556 50.2683 40.4557 50.2682L40.7037 50.0202L40.7059 50.018L42.0201 48.6871C42.4208 48.2921 42.7115 47.8024 42.8675 47.2667H48.3501H48.3635L48.3768 47.266C48.4778 47.2606 48.5788 47.2756 48.6738 47.3103C48.7688 47.3449 48.8558 47.3984 48.9296 47.4675C49.0034 47.5366 49.0624 47.62 49.1032 47.7125C49.1436 47.8043 49.1653 47.9032 49.1668 48.0034V58.2Z" fill="#5768FF" stroke="#5768FF"/>
    <path d="M33 32.5835V53.0002" stroke={color || '#5768FF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M33 53.0002L39.6667 46.3335" stroke={color || '#5768FF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M33.0007 53.0002L26.334 46.3335" stroke={color || '#5768FF'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <path d="M31.5 81H34.5V96H31.5V81Z" fill="#F0F2FF"/>
    <defs>
    <clipPath id="clip0_1580_10830">
    <rect width="40" height="40" fill="white" transform="translate(13 28)"/>
    </clipPath>
    </defs>
    </svg>

  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconDownloadStepHover.defaultProps = defaultProps;

export default IconDownloadStepHover;
