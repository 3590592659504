import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconVideoLastStepHover: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.5 0H34.5V15H31.5V0Z" fill="#F0F2FF" />
      <rect y="15" width="66" height="66" rx="33" fill="#F0F2FF" />
      <path d="M36.6277 41.0605L35.5375 41.6523L36.7334 41.9819C38.695 42.5225 40.2731 43.9757 41.0002 45.8476L41.0001 45.8476L41.0041 45.8574C41.2947 46.5609 41.4502 47.3306 41.4663 48.1547V54.1118C41.4502 54.9358 41.2947 55.7055 41.0041 56.409L41.0041 56.409L41.0006 56.4178C40.0567 58.8316 37.6878 60.5499 34.9329 60.5499H23.3663C19.7765 60.5499 16.8496 57.6245 16.8496 54.0166V48.2499C16.8496 45.262 18.8462 42.7546 21.5696 41.9809L22.6938 41.6615L21.6865 41.0689C21.1605 40.7595 20.6943 40.3804 20.34 39.9375L20.333 39.9287L20.3255 39.9202C19.5738 39.0633 19.1329 37.9144 19.1329 36.6832C19.1329 33.9074 21.3777 31.6499 24.1496 31.6499C26.1645 31.6499 27.8925 32.8305 28.6969 34.5456L29.1496 35.5107L29.6023 34.5456C30.4067 32.8305 32.1348 31.6499 34.1496 31.6499C36.9235 31.6499 39.1829 33.9094 39.1829 36.6832C39.1829 37.9144 38.7421 39.0633 37.9904 39.9202L37.9903 39.9201L37.9822 39.9298C37.6002 40.3881 37.148 40.778 36.6277 41.0605Z" fill="#5768FF" stroke={color || '#5768FF'} />
      <path d="M45.5591 44.6678L45.5591 44.6678L45.5605 44.6673C46.3708 44.36 47.2987 44.4696 48.0327 44.9615C48.7497 45.4698 49.1832 46.3004 49.1832 47.1665V55.0998C49.1832 55.9689 48.7473 56.7978 48.0336 57.2875L48.0336 57.2875L48.0306 57.2896C47.5686 57.6116 47.0361 57.7665 46.4999 57.7665C46.178 57.7665 45.8698 57.7111 45.5565 57.5975L43.6709 56.8887C43.8924 56.2189 44.0414 55.513 44.0984 54.7715L44.0984 54.7715L44.0986 54.7688L44.0991 54.7621C44.1155 54.5327 44.1332 54.2844 44.1332 54.0165V48.2498C44.1332 47.9819 44.1155 47.7335 44.0991 47.504L44.0986 47.4975L44.0986 47.4975L44.0984 47.4948C44.0414 46.7533 43.8924 46.0474 43.6709 45.3776L45.5591 44.6678Z" fill="#5768FF" stroke={color || '#5768FF'} />
    </svg>

  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconVideoLastStepHover.defaultProps = defaultProps;

export default IconVideoLastStepHover;
