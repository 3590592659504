import React from 'react';
import Navigation from '../Navigation';
import { Container } from './styles';

function Sidebar() {
  return (
    <Container>
      <Navigation />
    </Container>
  );
}

export default Sidebar;
