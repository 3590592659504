import React, { useContext } from 'react';

import { CheckoutContext } from '../shared/CheckoutProvider';

import { Button, Col, Row, Input, Spin, Form } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { useParams } from 'react-router-dom';

const { Item } = Form;

export function CuponsArea() {
  const { values, applyCupom, removeCupom, applyCupomLoading, setChangeValues } =
    useContext(CheckoutContext);
  const { id_turma } = useParams();

  if (id_turma !== '531')
    return (
      <Col className="gutter-row" style={{ width: '100%' }}>
        <h5 className="title-form margin-cupons">Cupom de desconto</h5>
        <Item className="input-form" name="cupom">
          <Row className="cupom-container">
            <Col>
              <Input
                className="cupom-form cupons-mobile-input"
                placeholder="Digite seu cupom "
                value={values.cupom}
                onChange={(e) => setChangeValues(e.target.value, 'cupom')}
                disabled={values?.detailsCupom?.valid}
              />
            </Col>
            <Col>
              {values.detailsCupom?.cashValue || values?.detailsCupom.value ? (
                <Button className="btn-button btn-cupom" onClick={removeCupom}>
                  Remover
                </Button>
              ) : (
                <Button className="btn-button btn-cupom" onClick={applyCupom}>
                  {!applyCupomLoading ? 'Aplicar' : <Spin indicator={<LoadingOutlined spin />} />}
                </Button>
              )}
            </Col>
          </Row>
        </Item>
      </Col>
    );
  else return null;
}
