import React from 'react';

import SmileSadPNG from '../../../../img/smile-sad.png';
import './styles.scss';

export default function BoxError() {
    return (
        <div className="new-checkout-box-error">
            <img src={SmileSadPNG} alt="triste" />
            <h1>Desculpe.</h1>
            <h3>
                O curso que você deseja não tem mais vagas, ou não está mais disponível.
            </h3>
        </div>
    );
};