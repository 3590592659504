import React from 'react';
import Layout from '../../../components/Gabarito/LayoutTwo';
import { Text, Buttons, Button, IconSmile, IconFrown } from './styles';

const Home = () => {
  return (
    <Layout>
      <Text>
        Confira o resultado da sua prova. <br />
        Avisaremos quando a sua nota sair!
      </Text>
      <Buttons>
        <Button to="/gabaritoro/login">
          <IconSmile />
          Sou aluno
        </Button>
        <Button to="/gabaritoro/create">
          <IconFrown />
          Não sou aluno
        </Button>
      </Buttons>
    </Layout>
  );
};

export default Home;
