import React from 'react'
import PropsTypes from 'prop-types'
import { Modal, Row, Col, Button} from 'antd'

const propTypes = {
  formulaVisible: PropsTypes.bool.isRequired,
  currentQuestion: PropsTypes.array.isRequired,
  setFormulaVisible: PropsTypes.func.isRequired,
}
function FormulaMatematica (props) {

  const renderImgFormula = (img) => {
    if (img) {
      const domain = 'https://arquivos.academiarafaeltoro.com.br'
      const path = 'uploads/'
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img
      return <img src={`${domain}/${imgWithPath}`} alt="" style={{ marginTop: 10 }} />
    }
    return ''
  }

  const { currentQuestion, formulaVisible, setFormulaVisible } = props
  
  return (
    <>
      <Modal
        width={700}
        zIndex="1003"
        closable={true}
        style={{top: 180}}
        maskClosable={false}
        destroyOnClose={true}
        title="Dica da questão"
        visible={formulaVisible}
        wrapClassName="formulaContainer"
        onOk={() => setFormulaVisible(false)}
        onCancel={() => setFormulaVisible(false)}
        footer={[
          <Button key="back" type="sucsses" onClick={() => setFormulaVisible(false)}>
            Ok
          </Button>
        ]}
      >
        <Row>
          <Col xs={{ span: 20, offset: 2}}>
            {currentQuestion?.formula}
          </Col>
          <Col xs={{ span: 20, offset: 2}}>
            {renderImgFormula(currentQuestion.formula_imagem)}
          </Col>
        </Row>
      </Modal>
    </>
  )
}

FormulaMatematica.propTypes = propTypes

export default FormulaMatematica