import React from 'react';
import { connect } from 'react-redux';
import * as authSelectors from '../../redux/selectors/auth';
import config from '../../config';
import logoCompact from '../../img/logo-2.svg';
import { Container, Content, NameLetterIcon, UserContent } from './styles';
import { useHistory } from 'react-router-dom';

type TProps = {
  name: string;
  avatar: string;
};

function Top({ name, avatar }: TProps) {
  const history = useHistory();

  const handleGoToProfile = () => {
    history.push('/user');
  };

  return (
    <Container>
      <Content>
        <img src={logoCompact} alt="ART" className="logo" />
        <form>
          <fieldset>
            <legend>Formulário de pesquisa</legend>
            <label htmlFor="searchTerm">Termo</label>
            <input type="text" name="searchTerm" id="searchTerm" placeholder="Pesquisar..." />
          </fieldset>
        </form>
      </Content>
      <UserContent onClick={handleGoToProfile}>
        {avatar ? (
          <img src={config.s3Url + avatar} alt={name} width={41} height={41} />
        ) : (
          <NameLetterIcon>{name?.slice(0, 1)}</NameLetterIcon>
        )}
      </UserContent>
    </Container>
  );
}

const mapStateToProps = (state: any) => {
  const { name } = authSelectors.getUser(state);
  return {
    name,
    avatar: state?.auth?.avatar,
  };
};

export default connect(mapStateToProps)(Top);
