import { createSagaAction } from '../../shared/sagas';
import { createReducer } from '../../shared/reducers';

export const constants = {
  GET_ALL_AVALIBLE_CLASS: createSagaAction('GET_ALL_AVALIBLE_CLASS'),
  ASK_FOR_MENTORSHIP: createSagaAction('ASK_FOR_MENTORSHIP'),
  GET_MY_MENTORSHIPS: createSagaAction('GET_MY_MENTORSHIPS'),
  GET_MY_MADE_MENTORSHIPS: createSagaAction('GET_MY_MADE_MENTORSHIPS'),
  CANCEL_MY_MENTORSHIP: createSagaAction('CANCEL_MY_MENTORSHIP'),
};

export const actions = {
  getAllAvalibleClassToMentorship(next) {
    return {
      type: constants.GET_ALL_AVALIBLE_CLASS.ACTION,
      next,
    };
  },
  askForMentorship(formData, next) {
    return {
      type: constants.ASK_FOR_MENTORSHIP.ACTION,
      formData,
      next,
    };
  },
  getMyMentorships(next) {
    return {
      type: constants.GET_MY_MENTORSHIPS.ACTION,
      next,
    };
  },
  getMyMadeMentorships(next) {
    return {
      type: constants.GET_MY_MADE_MENTORSHIPS.ACTION,
      next,
    };
  },
  cancelMyMentorship(id, next) {
    return {
      type: constants.CANCEL_MY_MENTORSHIP.ACTION,
      id,
      next,
    };
  },
};

const ACTION_HANDLERS = {
  [constants.GET_ALL_AVALIBLE_CLASS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.GET_ALL_AVALIBLE_CLASS.SUCCESS]: (state, action) => {
    return { ...state, avalibleClass: action.payload, isLoading: false };
  },
  [constants.GET_ALL_AVALIBLE_CLASS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.ASK_FOR_MENTORSHIP.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.ASK_FOR_MENTORSHIP.SUCCESS]: (state, action) => {
    return { ...state, mentorships: action.payload, isLoading: false };
  },
  [constants.ASK_FOR_MENTORSHIP.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.GET_MY_MENTORSHIPS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.GET_MY_MENTORSHIPS.SUCCESS]: (state, action) => {
    return { ...state, mentorships: action.payload, isLoading: false };
  },
  [constants.GET_MY_MENTORSHIPS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.GET_MY_MADE_MENTORSHIPS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.GET_MY_MADE_MENTORSHIPS.SUCCESS]: (state, action) => {
    return { ...state, mentorships: action.payload, isLoading: false };
  },
  [constants.GET_MY_MADE_MENTORSHIPS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },

  [constants.CANCEL_MY_MENTORSHIP.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true };
  },
  [constants.CANCEL_MY_MENTORSHIP.SUCCESS]: (state, action) => {
    return { ...state, isLoading: false };
  },
  [constants.CANCEL_MY_MENTORSHIP.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false };
  },
};

export const initialState = {
  mentorships: [],
};

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : { ...state, isLoading: false };
});
