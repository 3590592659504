import styled from 'styled-components';

export const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.blue_500};
    height: 130px;
    width: 100%;
    padding: 0;
    position: sticky;
    bottom: 0px;
    left: 0px;
    margin-top: 3px;

    display: flex;
    justify-content: space-between;
    padding: 25px;
    @media (max-width: 728px) {
        align-items: center;
        padding: 10px 15px;
        padding-right: 10px;
        bottom: 50px;
    }

    .description {
        width: 15%;
        @media(max-width: 728px) {
            width:75%;   
        }
        
        display: flex;
        flex-direction: column;
        .topic {
            font-weight: bold;
            color: #eee;
            margin-bottom: 5px;
            width: 200px;
        }

        .subtopic {
            font-weight: 500;
            color: #eee;
            line-height: 20px;
            margin-top:3px;
            width: 200px;
        }
    }

    .actions {
        height: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .controls-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        height: 100%;
        svg {
            cursor: pointer;
        }
    }
`;