import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as studentActions } from '../../../../redux/modules/student';

import Header from '../../../../components/ReportCFP/Header';
import Title from '../../../../components/ReportCFP/Title';
import Subtitle from '../../../../components/ReportCFP/Subtitle';

import CircleChart from '../../../../components/CircleChart';
import BarChart from '../../../../components/BarChart';
import GraphBar from '../../../../components/ReportCFP/GraphBar';
import LegendBarChart from '../../../../components/ReportCFP/LegendBarChart';

import { getPercentFormatted } from '../../../../shared/utils/common';

import {
    getDefaultParams,
    getFormatedModulesPerformanceReport,
    StudentStateType
} from '../../common';

import './styles.scss';

export default function WeeklyPNG () {
    const {
        reportSimulado: report
    } = useSelector((state: StudentStateType) => state.student);

    const moduleFormattedReport = getFormatedModulesPerformanceReport(report?.module_performance);
    const dispatch = useDispatch();

    const { report: typeReport } = getDefaultParams(window.location.search);

    useEffect(() => {
        const { userId, courseId, classId, media, start, end, kind, classes } = getDefaultParams(window.location.search);
        dispatch(studentActions.handleCfpSimulado(
            userId,
            courseId,
            classId,
            media,
            start,
            end,
            kind,
            classes
        ));
        // eslint-disable-next-line
    }, []);

    const classDone = report?.test?.done?.[0]?.prova ? report?.test?.done?.[0]?.prova : 0;
    const classToBeDone = report?.test?.to_be_done?.[0]?.prova ?? 0;
    const totalClassesToBeDone = `${classToBeDone}*`;
    const percentDone = classToBeDone === 0 ? 0 : (classDone * 100) / classToBeDone;

    return (
        <div className="weekly-png">
            <div className="weekly-png__header">
                <Header />
            </div>

            <main className="weekly-png__main">
                <div>
                <section className="weekly-png__section-1">
                    <div className="weekly-png__section-1__title">
                        <Title
                            description="Média Geral"
                        />
                        <Title
                            description="Provas Feitas"
                        />
                        <Title
                            description="Score Toro"
                        />
                    </div>

                    <div className="weekly-png__section-1__graphs">
                        <div className="weekly-png__section-1__box">
                            <div className="weekly-png__section-1__box-circle-chart">
                                <CircleChart
                                    percent={getPercentFormatted(report?.average?.acertos_avg)}
                                    size={300}
                                    animation={false}
                                    fontColor={'#000A66'}
                                    responsive={false}
                                />
                            </div>
                        </div>
                        <div className="weekly-png__section-1__box">
                            <div className="progress-bar">
                                <h3>{classDone}/{totalClassesToBeDone}</h3>
                                <BarChart
                                    percent={percentDone}
                                    showPercent={false}
                                />
                                {typeReport === '30d' ? (
                                    <p className="weekly-png__section-1__box-description">
                                        *Número de Provas do curso
                                        <br/>
                                        CFP® 30 dias.
                                    </p>
                                ): typeReport === '60d' ?(
                                    <p className="weekly-png__section-1__box-description">
                                        *Número de Provas da 1ª etapa do curso
                                        <br/>
                                        CFP® 60 dias.
                                    </p>
                                ): null}

                                {(percentDone <= 0) ? <p data-test-id="initial-text" className="weekly-png__section-1__box-init">
                                    Para iniciar as aulas,
                                    <br/>
                                    entre em contato com seu professor.
                                </p> : null}
                            </div>
                        </div>



                        <div className="weekly-png__section-1__box">
                            <div className="weekly-png__section-1__box-circle-chart">
                                <CircleChart
                                    percent={getPercentFormatted(report?.score?.score)}
                                    size={300}
                                    animation={false}
                                    fontColor={'#000A66'}
                                    responsive={false}
                                />
                            </div>
                        </div>
                    </div>
                </section>
                </div>

                <section className="weekly-png__section-2">
                    <Title
                        description="Desempenho em Aulas"
                    />
                    <Subtitle
                        description="Questões acertadas"
                    />
                    <main>
                        {
                            (report?.class_performance ?? []).map((item, index) => {
                                const formattedTitle = ((item.titulo.length > 60) && getPercentFormatted(item.total) >= 40 && getPercentFormatted(item.total) <= 60)
                                    ? `${item.titulo.substr(0, 60)}...`
                                    : item.titulo

                                return (
                                    <div key={item.module}>
                                        <LegendBarChart
                                            description={formattedTitle}
                                            color={(index+1) % 2 === 0 ? '#000a6640' : '#000a6666'}
                                            percent={getPercentFormatted(item.total)}
                                        />
                                    </div>
                                );
                            })
                        }
                    </main>
                </section>

                <section className="weekly-png__section-3">
                    <Title
                        description="Desempenho por módulo"
                    />
                    <Subtitle
                        description="Média de acertos por módulos"
                    />

                    <div className="weekly-png__section-3__graph">
                        <div className="lines">
                            <span className="line"></span>
                            <span className="line">
                                <span>
                                    70%
                                </span>
                            </span>
                            <span className="line">
                                <span>
                                    30%
                                </span>
                            </span>
                            <span className="line"></span>
                        </div>
                        <main>
                            {
                                (moduleFormattedReport ?? []).map(item => (
                                    <div className="weekly-png__section-3__main-box" key={item.module}>
                                        <GraphBar percent={getPercentFormatted(item.total)} />
                                    </div>
                                ))
                            }
                        </main>
                        <footer>
                            {
                                (moduleFormattedReport ?? []).map(item => (
                                    <div className="weekly-png__section-3__footer-module" key={item.module}>
                                        <h1>{item.module}</h1>
                                    </div>
                                ))
                            }
                        </footer>
                    </div>
                </section>
            </main>
        </div>
    );
};
