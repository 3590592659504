import styled from 'styled-components';


type Props = {
    approved: boolean;
};

export const Container = styled.span<Props>`
    width: 240px;
    justify-content: space-evenly;
    @media(max-width: 560px) {
        justify-content: center;
    }
    align-items: center;
    display: flex;
    
    svg, h1 {
        font-weight: 700;
        font-size: 2.1rem;
        @media(max-width: 560px) {
            font-size: 1.2rem;
        }
        color: ${({ approved }) => approved ? '#2EC76B' : '#D81E5B'}; 
    }

    h1 {
        @media(max-width: 560px) {
            margin-left: 5px;
        }
    }
`;