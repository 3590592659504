import React from 'react';
import { useSelector } from 'react-redux';

import { useGabaritoro } from '../../../hooks/useGabaritoro';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';

import { Props, InputEvent } from './types';

import { Container } from './styles';
import { IAnswer } from '../../../shared/Models/gabarito/IAnswer';

const Answer = ({ answer, corrrectAnswer, numberQuestion }: Props) => {
    const { gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito);
    const { handleMarkAnswer, onValidation, userAnswers } = useGabaritoro();
    function handleSelectLetter(e: InputEvent) { 
        const answer = {
            id_gabarito_question: Number(e.target.name),
            id_gabarito_question_answer: Number(e.target.value)
        };

        handleMarkAnswer(answer);
        onValidation(true, numberQuestion);
    };

    function checkedQuestion (answer: IAnswer) {
        const markedAnswer = userAnswers.find(_answer => _answer.id_gabarito_question === answer.id_gabarito_question && _answer.id_gabarito_question_answer === answer.id_gabarito_question_answer);
        
        return !!markedAnswer;
    };

    return (
        <Container correct={(gabarito.filled && gabarito.student_filled) && (corrrectAnswer === answer.id_gabarito_question_answer)}>
            {
                (gabarito.filled && gabarito.student_filled) ? (
                    <input type="radio"
                        name={answer.id_gabarito_question.toString()}  
                        value={answer.id_gabarito_question_answer}
                        checked={checkedQuestion(answer)}
                        onChange={handleSelectLetter}
                    />
                ) : (
                    <input type="radio"
                        name={answer.id_gabarito_question.toString()}  
                        value={answer.id_gabarito_question_answer}
                        onChange={handleSelectLetter}
                        checked={checkedQuestion(answer)}
                    />
                )
            }
        </Container>
    );
};

export default Answer;