import academiaRafaelToroLogo from './img/logo-clean.png';
import academiaRafaelToroLogoDark from './img/logo.png'
import artCursosLogo from './img/logoArtConcursosLight.svg';
import artCursosLogoDark from './img/logoArtConcursosDark.svg';

require('dotenv').config();

const config = {
  //eslint-disable-next-line
  API_BASE: process.env.REACT_APP_API_URL ?? 'https://dev.api.academiarafaeltoro.com.br',
  DOWNLOAD_URL: process.env.DOWNLOAD_URL ?? 'https://s3.sa-east-1.amazonaws.com/arquivos.academiarafaeltoro.com.br/',
  s3Url: 'https://arquivos.academiarafaeltoro.com.br/',
  API_BASE_AVG: process.env.REACT_APP_API_BASE_AVG ?? 'https://dev.api.academiarafaeltoro.com.br/v1/report/course/cfp',
  API_BASE_FRONT: 'https://dev.app.academiarafaeltoro.com.br',
  REACT_APP_URL: process.env.REACT_APP_URL ?? 'https://dev.app.academiarafaeltoro.com.br',
  REACT_APP_SUPPORT_URL: process.env.REACT_APP_SUPPORT_URL ?? 'https://www.academiarafaeltoro.com.br/whatsapp',
  REACT_APP_FAQ_URL: process.env.REACT_APP_FAQ_URL ?? 'https://www.academiarafaeltoro.com.br/faq',
  // API_GENERATE: 'http://ec2-18-231-174-203.sa-east-1.compute.amazonaws.com:8007/api/generate/pdf',
  servicesUris: {},
  //eslint-disable-next-line
  PLATFORM: process.env.PLATFORM ?? 'Web',
  apiKey: '@newCheckout',
  GTM_ID: process.env.REACT_APP_GTM,
  G4_ID: process.env.REACT_APP_G4_ID,
  hiddenMenu: [''],
  DISQUS_SHORTNAME: process.env.REACT_APP_DISQUS_SHORTNAME || 'teste-toro',
  REACT_APP_DEL_ACCOUNT_URL: process.env.REACT_APP_DEL_ACCOUNT_URL,
  imagesByDomain: {
    'academiarafaeltoro.com.br': {
      logo: academiaRafaelToroLogo,
      logoDark: academiaRafaelToroLogoDark
    },
    'artconcursos.com.br': {
      logo: artCursosLogo,
      logoDark: artCursosLogoDark
    },
    'default': {
      logo: academiaRafaelToroLogo,
      logoDark: academiaRafaelToroLogoDark
    }
  }
};

export default config;