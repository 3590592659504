import React, { useState, useEffect, useContext } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Button, Col, Modal, Row, Select, Skeleton } from 'antd';
import LectureVideoQuestion from './LectureVideoQuestion.component';
import { CloseCircleOutlined, CheckCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

//
// context
import { CourseContext } from '../../../ClassContent/CourseDetail.container';

//
// redux + selectors
import * as authSelectors from '../../../../../redux/selectors/auth';
import * as classSelectors from '../../../../../redux/selectors/class';
import { actions as classActions } from '../../../../../redux/modules/class';
import VideoTypeModal from './components/VideoTypeModal';

const propTypes = {
  user: PropTypes.object,
  getWrongQuestions: PropTypes.func,
  getLectureWrongQuestions: PropTypes.func.isRequired,
  lectureWrongQuestions: PropTypes.array,
  allAnsweredQuestions: PropTypes.array,
  totalQuestions: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};
function LectureReview(props) {
  const { getLectureWrongQuestions } = props;
  //
  // course context
  const { course, setClassStep, reviewStarted, selectedClass, setReviewStarted, lectureIdToReview } =
    useContext(CourseContext);

  //
  // component state
  const [filter, setFilter] = useState(null);
  const [videoToShow, setVideoToShow] = useState({ videoEmbed: '', videoEmbedLibras: '' });
  const [questionVideoStart, setQuestionVideoStart] = useState(false);
  const [modalVideoOption, setModalVideoOption] = useState(false);
  const [LSBVideo, setLSBVideo] = useState(false);
  //
  // get lecture details
  useEffect(() => {
    if (lectureIdToReview) {
      getLectureWrongQuestions(lectureIdToReview);
    }
    // eslint-disable-next-line
  }, [selectedClass, reviewStarted]);

  const handleCloseReview = () => {
    setClassStep('lecture');
    setReviewStarted(false);
  };

  const handleStartQuestionVideo = (question) => {
    if (course?.is_libras && question.video_embed_libras) {
      /**
      setVideoToShow({
        videoEmbed: question.video_embed,
        videoEmbedLibras: question.video_embed_libras,
      });
      setModalVideoOption(true);
      */

      setVideoToShow({ ...videoToShow, videoEmbed: question.video_embed_libras });
      setQuestionVideoStart(true);
    } else {
      setVideoToShow({ ...videoToShow, videoEmbed: question.video_embed });
      setQuestionVideoStart(true);
    }
  };

  const renderImg = (img) => {
    if (img) {
      const domain = 'https://arquivos.academiarafaeltoro.com.br';
      const path = 'uploads/';
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img;
      return <img src={`${domain}/${imgWithPath}`} alt="" style={{ marginTop: 10 }} />;
    }
    return '';
  };

  const renderAnswerIcon = (answer) => {
    if ((answer.marcada && answer.correta === 1) || answer.correta === 1) {
      return <CheckCircleOutlined style={{ fontSize: 36, marginRight: 10, marginLeft: 10 }} />;
    } else if (answer.correta !== 1 && answer.marcada) {
      return (
        <CloseCircleOutlined
          style={{ fontSize: 36, color: '#DE0000', marginRight: 10, marginLeft: 10 }}
        />
      );
    }
  };

  const applyFilter = () => {
    const { allAnsweredQuestions } = props;
    let answers = [];
    if (filter === 'wrong') {
      answers = allAnsweredQuestions.filter((answer) => answer.wrong);
    } else if (filter === 'correct') {
      answers = allAnsweredQuestions.filter((answer) => !answer.wrong);
    } else {
      answers = allAnsweredQuestions;
    }

    return answers;
  };

  const { lectureWrongQuestions, totalQuestions } = props;
  const filteredWrongQuestions = applyFilter();
  //
  // workaround due problems styling unordered list bullet with flexbox
  const alphabeticalOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
  return (
    <>
      <Modal
        width={932}
        footer={false}
        zIndex={10000}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        visible={reviewStarted}
        title="Revisão da prova"
        onCancel={handleCloseReview}
        wrapClassName="lectureReviewContainer"
      >
        <div className="modal-body">
          <VideoTypeModal
            modalVideoOption={modalVideoOption}
            setLSBVideo={setLSBVideo}
            setModalVideoOption={setModalVideoOption}
            setQuestionVideoStart={setQuestionVideoStart}
          />
          <LectureVideoQuestion
            questionVideoStart={
              questionVideoStart && (videoToShow.videoEmbed || videoToShow.videoEmbedLibras)
            }
            setQuestionVideoStart={setQuestionVideoStart}
            setLSBVideo={setLSBVideo}
            setModalVideoOption={setModalVideoOption}
            videoEmbed={LSBVideo ? videoToShow.videoEmbedLibras : videoToShow.videoEmbed}
          />
          <header>
            <Row style={{ width: '100%' }} justify="space-between" gutter={[16, 16]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                {props.isLoading ? (
                  <Skeleton active />
                ) : (
                  <>
                    <div className="titleWrongQuestions">
                      Você acertou
                      <span>{+totalQuestions - +lectureWrongQuestions.length}</span> de
                      <span>{totalQuestions}</span> questões
                    </div>
                  </>
                )}
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }} className="WrongQuestionsFilter">
                {props.isLoading ? (
                  <Skeleton active />
                ) : (
                  <>
                    Filtro de questões:
                    <Select
                      value={filter ?? null}
                      onChange={(value) => setFilter(value)}
                      style={{ margin: '0 0 0 10px', width: 300 }}
                    >
                      <Select.Option value={null}>Todas</Select.Option>
                      <Select.Option value="wrong">Somente questões erradas</Select.Option>
                      <Select.Option value="correct">Somente questões certas</Select.Option>
                    </Select>
                  </>
                )}
              </Col>
            </Row>
          </header>
          {props.isLoading ? (
            <>
              <div key={`question-${0}`}>
                <div className="questionDescription">
                  <h1>
                    <Skeleton active />
                  </h1>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    <Skeleton active />
                  </p>
                </div>
                <div className="questionVideoContainer">
                  <Skeleton.Button active />
                </div>
                <div className="questionAnswersContainer">
                  <Skeleton active />
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          {filteredWrongQuestions.map((questionItem, index) => {
            const question = questionItem;
            const answered = question?.answers.find((answer) => answer.marcada === true);
            return (
              <div key={`question-${index}`}>
                <div className="questionDescription">
                  <h1>Questão {questionItem.order}</h1>
                  <p style={{ whiteSpace: 'pre-wrap' }}>
                    ({question.codigo}) {question.enunciado} {renderImg(question.img_enunciado)}
                  </p>
                </div>
                <div className="questionVideoContainer">
                  <Button onClick={() => handleStartQuestionVideo(question)}>
                    <PlayCircleOutlined style={{ fontSize: 20 }} />
                    Ver questão comentada
                  </Button>
                </div>
                <div className="questionAnswersContainer">
                  <ul>
                    {question.answers.map((answer, index) => {
                      return (
                        <li
                          key={`answer-${index}`}
                          className={
                            answer.correta === 1 ? 'correta' : answer.marcada ? 'selected' : ''
                          }
                        >
                          {`${alphabeticalOrder[index]}. `}
                          {answer.resposta}
                          {renderAnswerIcon(answer)}
                        </li>
                      );
                    })}
                    <li className={answered ?? 'selected'}>
                      E. Não sei
                      {!answered && (
                        <CloseCircleOutlined
                          style={{
                            fontSize: 36,
                            color: '#DE0000',
                            marginRight: 10,
                            marginLeft: 10,
                          }}
                        />
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state, props) => ({
  getWrongQuestions: classSelectors.getWrongQuestions(state, props),
  user: authSelectors.getUser(state, props),
  studentQuestions: classSelectors.getStudentQuestions(state, props),
  ongoingLecture: classSelectors.getOngoingLecture(state, props),
  lectureWrongQuestions: classSelectors.lectureWrongQuestions(state, props),
  allAnsweredQuestions: classSelectors.allAnsweredQuestions(state, props),
  totalQuestions: classSelectors.totalQuestions(state, props),
  isLoading: classSelectors.isLoading(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  getLectureDetails: (classId, next) => dispatch(classActions.getLectureDetails(classId, next)),
  getOngoingLecture: (userId, classId, next) =>
    dispatch(classActions.getOngoingLecture(userId, classId, next)),
  getLectureWrongQuestions: (lectureId, next) =>
    dispatch(classActions.getLectureWrongQuestions(lectureId, next)),
});

LectureReview.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(LectureReview);
