import { IAllowEpisode } from "../shared/Models/podcast/IAllowEpisode";

export function checkPodcastEpisodePermission(
    episodes: IAllowEpisode[], 
    classTag: string
) {
    if (!episodes || !episodes.length) return false;

    const allowed = episodes.some(classEpisode => {
        return classTag.trim() === classEpisode.allowedEpisode.trim();
    });

    return allowed;
};