import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { FileDoneOutlined } from '@ant-design/icons';

import Layout from '../../../components/Gabarito/Layout';

import { Container } from './styles';
import { RouteParams } from './types';

import { actions as gabaritoActions } from '../../../redux/modules/gabarito';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';
import { Spin } from 'antd';


const AvailableResult = () => {
    const history = useHistory();

    const { token } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const { student, isLoading } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito)

    useEffect(() => {
        dispatch(gabaritoActions.getStudentByToken(token));
    }, [dispatch, token]);

    const handleResult = () => {
        history.push(`/gabaritoro/respostas/${token}`);
    };

    return (
        <Layout>
            <Container>
                {
                    isLoading ? (
                        <Spin />
                    ) : (                        
                        <>
                            <FileDoneOutlined />

                            <h1>Oi {student.nome} seu resultado está disponível </h1>
                            <h2>Boa sorte</h2>

                            <button onClick={handleResult}>Ver resultado</button>
                        </>
                    )
                }

            </Container>
        </Layout>
    );
};

export default AvailableResult;