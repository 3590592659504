import React, { useState, useContext, useEffect, useRef } from 'react'

import { connect } from 'react-redux'

import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { LeftArrow, RightArrow } from "./Arrows"
import PaginationItem from './PaginationItem'

//
// general components
import {
  LeftOutlined,
  RightOutlined,
  PlayCircleOutlined,
  CalculatorOutlined,
  AlignLeftOutlined,
} from '@ant-design/icons'
import PropTypes from 'prop-types'
import LectureVideoQuestion from './LectureVideoQuestion.component'
import LectureTextQuestion from './LectureTextQuestion.component'
import { Button, Skeleton, Select } from 'antd'
import InnerImageZoom from 'react-inner-image-zoom'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'

import FormulaMatematica from './components/formula'

//
// context
import { CourseContext } from '../../../ClassContent/CourseDetail.container'

//
// redux + selectors
import * as authSelectors from '../../../../../redux/selectors/auth'
import * as classSelectors from '../../../../../redux/selectors/class'
import { actions as classActions } from '../../../../../redux/modules/class'
import VideoTypeModal from './components/VideoTypeModal'

import { 
  Container, 
  BoxAnswer, 
  Box, 
  BoxTwo,
  Header, 
  TitleQuestionArea, 
  QuestionIdentification, 
  QuestionStatement,
  QuestionFooter,
  QuestionPagination,
  QuestionResult,
  QuestionAnsewers,
  QuestionAnsewersItem,
  ButtonStyled,
  ButtonsContainer,
} from './styles/Lecture.component'
import unchecked from '../../../../../img/radioEmpty.svg';
import checkedGreen from '../../../../../img/checkmarkGreen.svg';
import checkedRed from '../../../../../img/checkmarkRed.svg';

const propTypes = {
  user: PropTypes.object,
  getWrongQuestions: PropTypes.func,
  getLectureWrongQuestions: PropTypes.func.isRequired,
  lectureWrongQuestions: PropTypes.array,
  allAnsweredQuestions: PropTypes.array,
  totalQuestions: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
};

function LectureReview(props) {
  const { getLectureWrongQuestions, isLoading } = props;
  //
  // course context
  const { course, setClassStep, reviewStarted, selectedClass, setReviewStarted, lectureIdToReview } =
    useContext(CourseContext);
  //
  // component state
  const [filter, setFilter] = useState(null);
  const [questionVideoStart, setQuestionVideoStart] = useState(false);
  const [questionTextShow, setQuestionTextShow] = useState(false);
  const [modalVideoOption, setModalVideoOption] = useState(false);
  const [LSBVideo, setLSBVideo] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [formulaVisible, setFormulaVisible] = useState(false)
  const [answers] = useState({})
  const paginationRef = useRef({});

  //
  // get lecture details
  useEffect(() => {
    if (lectureIdToReview) {
      getLectureWrongQuestions(lectureIdToReview);
    }
    // eslint-disable-next-line
  }, [selectedClass, reviewStarted]);

  const handleCloseReview = () => {
    setClassStep('lecture');
    setReviewStarted(false);
  };

  const { allAnsweredQuestions } = props;
  const questions = allAnsweredQuestions;

  const applyFilter = () => {
    let answers = [];
    if (filter === 'wrong') {
      answers = allAnsweredQuestions.filter((answer) => answer.wrong);
    } else if (filter === 'correct') {
      answers = allAnsweredQuestions.filter((answer) => !answer.wrong);
    } else {
      answers = allAnsweredQuestions;
    }

    return answers;
  };

  const { lectureWrongQuestions, totalQuestions } = props;
  const filteredWrongQuestions = applyFilter();
  //
  // workaround due problems styling unordered list bullet with flexbox
  const alphabeticalOrder = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
  let alphabeticalOrderIndex = 0

  const handleQuestionChange = async (direction = false) => {
    if (direction) {
      const newIndex = direction === 'next' ? currentQuestionIndex + 1 : currentQuestionIndex - 1;
      
      setCurrentQuestionIndex(newIndex);
      
      paginationRef.current.scrollToItem(
        paginationRef.current.getItemById(`question-${newIndex}`),
        "smooth", 
        "center", 
        "nearest"
      );
    }
  }

  const formulaMtematica = () => {
    return (
      <Button onClick={() => setFormulaVisible(true)}>
        <CalculatorOutlined /> Dica da questão
      </Button>
    )
  }

  const handlePlayVideo = () => {
    if (course?.is_libras && currentQuestion?.video_embed_libras) {
      //setModalVideoOption(true)
      setLSBVideo(true)
      setQuestionVideoStart(true)
    } else {
      setQuestionVideoStart(true)
    }
  }

  const handleFilter = (value) => {
    setCurrentQuestionIndex(0);
    setFilter(value);
  };

  //
  // Lecture modal footer render
  const renderFooter = () => {
    const questions = filteredWrongQuestions
    const isLastQuestion = questions.length === currentQuestionIndex + 1 //retorna se true se for a ultima questao da prova

    return !questions || questions.length === 0 ? (
      <Skeleton.Button />
    ) : (
      <QuestionFooter>
        <div className="lecture-modal-footer">
          {currentQuestion.formula || currentQuestion.formula_imagem ? formulaMtematica() : ''}
          <Button
            disabled={currentQuestionIndex === 0}
            onClick={() => handleQuestionChange('prev')}
          >
            <LeftOutlined />
            <span className="text-desktop"> Anterior</span>
          </Button>
          {lectureWrongQuestions.length !== 0 && lectureWrongQuestions.length < totalQuestions && <Select
            value={filter ?? null}
            onChange={(value) => handleFilter(value)}
            style={{ width: 300 }}
          >
            <Select.Option value={null}>Todas</Select.Option>
            <Select.Option value="wrong">Somente questões erradas</Select.Option>
            <Select.Option value="correct">Somente questões certas</Select.Option>
          </Select>}
          {!isLastQuestion && <Button disabled={isLastQuestion} onClick={() => handleQuestionChange('next')}>
            <span className="text-desktop">Próximo </span>
            <RightOutlined />
          </Button>}
        </div>
      </QuestionFooter>
    )
  }

  const getAnswerValue = (currentQuestion) => {
    if (currentQuestion) {
        return answers[currentQuestion.id_questao]
    } else {
      return null
    }
  }

  const getAnswerIndex = (id_questao) => allAnsweredQuestions.map(q => q.id_questao).indexOf(id_questao) + 1;

  const currentQuestion = filteredWrongQuestions[currentQuestionIndex]
  
  const renderImg = (img) => {
    if (img) {
      const domain = 'https://arquivos.academiarafaeltoro.com.br'
      const path = 'uploads/'
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img
      return (
        <div className='input-box'>
          <InnerImageZoom
            src={`${domain}/${imgWithPath}`}
            fullscreenOnMobile={true}
            hideCloseButton={true}
          />
        </div>
      )
    }
    return ''
  }

  const handleItemClick = (itemId, index) => ({
    getItemById,
    scrollToItem
  }) => {
    setCurrentQuestionIndex(index);
    scrollToItem(getItemById(itemId), "smooth", "center", "nearest");
  };

  return (
    
        <>
          <Container
            visible={reviewStarted}
            closable={true}
            onCancel={handleCloseReview}
            destroyOnClose={true}
            footer={renderFooter()}
            maskClosable={false}
            wrapClassName="lectureContainer"
            zIndex={999}
          >
            <Box>
              <Header>
                  {isLoading || questions.length === 0 ? (
                    <Skeleton active />
                  ) : (
                    <TitleQuestionArea>
                      <h1>{course.title} &bull; Revisão da prova</h1>
                    </TitleQuestionArea>
                  )}
                </Header>
              <BoxTwo withButton={currentQuestion && (currentQuestion.video_embed_libras || currentQuestion.video_embed)}>
              
              <BoxAnswer>
                {modalVideoOption && (
                  <VideoTypeModal
                    modalVideoOption={modalVideoOption}
                    setModalVideoOption={setModalVideoOption}
                    setLSBVideo={setLSBVideo}
                    setQuestionVideoStart={setQuestionVideoStart}
                  />
                )}
                <LectureVideoQuestion
                  questionVideoStart={questionVideoStart}
                  setQuestionVideoStart={setQuestionVideoStart}
                  videoEmbed={
                    currentQuestion &&
                    (LSBVideo ? currentQuestion.video_embed_libras : currentQuestion.video_embed)
                  }
                  setModalVideoOption={setModalVideoOption}
                  setLSBVideo={setLSBVideo}
                />
                <LectureTextQuestion
                  questionTextShow={questionTextShow}
                  setQuestionTextShow={setQuestionTextShow}
                  text={currentQuestion?.questao_comentada}
                />
                <QuestionResult>Você acertou <strong>{+totalQuestions - +lectureWrongQuestions.length}</strong> de <strong>{totalQuestions}</strong> questões </QuestionResult>
                <QuestionPagination border={true}>
                  <div>
                  <ScrollMenu
                    LeftArrow={LeftArrow}
                    RightArrow={RightArrow}
                    apiRef={paginationRef}
                  >
                    {filteredWrongQuestions.map((_question, index) => (
                        <PaginationItem 
                          current={currentQuestionIndex === index} 
                          answered={getAnswerValue(_question)}
                          wrong={_question.wrong}
                          key={`question-${index}`} 
                          itemId={`question-${index}`}
                          onClick={handleItemClick(`question-${index}`, index)}
                        >
                          {getAnswerIndex(_question.id_questao)}
                        </PaginationItem>
                    ))}
                    </ScrollMenu>
                  </div>
                </QuestionPagination>
                
                <div className="questionAnswersContainer review">
                  {isLoading || questions.length === 0 ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <QuestionIdentification>Questão {getAnswerIndex(currentQuestion.id_questao)} <span>({currentQuestion.codigo})</span></QuestionIdentification>
                      <QuestionStatement>
                        {currentQuestion.enunciado}
                        {renderImg(currentQuestion.img_enunciado)}
                      </QuestionStatement>
                        <QuestionAnsewers>
                          {currentQuestion.answers
                            .filter((answer) => answer.resposta)
                            .map((answer, index) => {
                              alphabeticalOrderIndex = index
                              return (
                                <QuestionAnsewersItem checked={answer.marcada} wrong={currentQuestion.wrong} correct={answer.correta}>
                                  {answer.marcada ? 
                                    (currentQuestion.wrong ? 
                                    <img src={checkedRed} alt="Errado"/> : <img src={checkedGreen} alt="Correto"/>) : 
                                    (answer.correta ? <img src={checkedGreen} alt="Correto"/> :
                                    <img src={unchecked} alt="Desmarcado"/>)
                                  }
                                  {`${alphabeticalOrder[index]}) ${answer.resposta}`}
                                </QuestionAnsewersItem>
                              )
                            })}
                          <QuestionAnsewersItem>
                            <img src={unchecked} alt="Desmarcado"/>
                            {`${alphabeticalOrder[alphabeticalOrderIndex + 1]}) Não sei.`}
                          </QuestionAnsewersItem>
                        </QuestionAnsewers>
                        <ButtonsContainer>
                          {currentQuestion.questao_comentada && (
                            <ButtonStyled onClick={() => setQuestionTextShow(true)}>
                              <AlignLeftOutlined style={{ fontSize: 20 }} /> Ler resolução
                            </ButtonStyled>
                          )}
                          {(currentQuestion.video_embed_libras || currentQuestion.video_embed) && (
                            <ButtonStyled onClick={handlePlayVideo}>
                              <PlayCircleOutlined style={{ fontSize: 20 }} /> Assistir resolução
                            </ButtonStyled>
                          )}
                        </ButtonsContainer>
                    </>
                  )}
                </div>
              </BoxAnswer>
              </BoxTwo>
            </Box>
          </Container>

          {formulaVisible && (
            <FormulaMatematica
              formulaVisible={formulaVisible}
              currentQuestion={currentQuestion}
              setFormulaVisible={setFormulaVisible}
            />
          )}
        </>
      
  )
}

const mapStateToProps = (state, props) => ({
  getWrongQuestions: classSelectors.getWrongQuestions(state, props),
  user: authSelectors.getUser(state, props),
  studentQuestions: classSelectors.getStudentQuestions(state, props),
  ongoingLecture: classSelectors.getOngoingLecture(state, props),
  lectureWrongQuestions: classSelectors.lectureWrongQuestions(state, props),
  allAnsweredQuestions: classSelectors.allAnsweredQuestions(state, props),
  totalQuestions: classSelectors.totalQuestions(state, props),
  isLoading: classSelectors.isLoading(state, props),
});

const mapDispatchToProps = (dispatch) => ({
  getOngoingLecture: (userId, classId, next) =>
    dispatch(classActions.getOngoingLecture(userId, classId, next)),
  getLectureWrongQuestions: (lectureId, next) =>
    dispatch(classActions.getLectureWrongQuestions(lectureId, next)),
});

LectureReview.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(LectureReview);
