import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 150px;
    padding: 30px;
    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        padding: 30px 0px;
        margin-top: 50px;
        width: 100%;
    }

    border: 4px solid ${({ theme }) => theme.colors.warning};
    border-radius: 10px;
    .box-title {
        @media(max-width: ${({ theme }) => theme.media.tablet}px) { 
            margin-left: 10px;
        }
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
            margin-left: 10px;
        }
    }

    p {
        margin-top: 25px;
        @media(max-width: ${({ theme }) => theme.media.tablet}px) {
            margin-left: 3px;
            font-weight: 700;
            color: #999999;
        }
    }

    .box-calendar {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        .box-calendar-content {
            width: 80%;
            @media(max-width: ${({ theme }) => theme.media.tablet}px) {
                width: 100%;
            }

            background-color: white;
            border: 2px solid #eeeeee;
            box-sizing: border-box;
            box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
            border-radius: 5px;

            padding: 10px;

            .react-calendar {
                border: none;
                padding: 0;
                width: 100%;

                .react-calendar__navigation {
                    width: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .react-calendar__navigation__prev2-button {
                        display: none;
                    }

                    .react-calendar__navigation__next2-button {
                        display: none;
                    }
                    
                    .react-calendar__navigation__prev-button {
                        color: ${({ theme }) => theme.colors.warning};
                        font-size: 32px;
                        cursor: pointer;
                    }

                    .react-calendar__navigation__next-button {
                        color: ${({ theme }) => theme.colors.warning};
                        font-size: 32px;
                        cursor: pointer;
                    }

                    .react-calendar__navigation__label > span {
                        font-weight: 300;
                        font-size: 16px;
                        color: #666;

                        cursor: pointer;

                        transition: filter 0.2s;
                        
                        &:hover {
                            filter: brightness(0.8);
                        }
                    }
                }

                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
            
                .react-calendar__month-view__weekdays {
                    text-align: center;
                    margin-top: 20px;
                    font-weight: bold;
                    color: #ccc;
                    text-transform: uppercase;
                    font-size: 13px;

                    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
                        font-size: 10px;
                    }
                }

                .react-calendar__tile {
                    margin-top: 20px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                } 

                .react-calendar__tile--active {
                    > abbr {
                        color: ${({ theme }) => theme.colors.warning};
                        font-weight: bold;
                    }
                }

                button {
                    background-color: white;
                    border: none;
                }
            }
        }
    }
    
    .box-footer {
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;

        > button {
            + button {
                margin-top: 5px;
            }
        }
    }

    .box-scheduled-test {
        display: flex;
        flex-direction: column;
        
        h1 {
            color: ${({ theme }) => theme.colors.warning};
            
            font-size: 24px;
            margin-top: 20px;
        }

        .box-scheduled-content {
            display: flex;
            justify-content: center;
            

            .box-scheduled-test-information {
                width: 400px;
                @media(max-width: ${({ theme }) => theme.media.tablet}px) {
                    width: 95%;
                }

                display: flex;
                flex-direction: column;
                align-items: center;

                padding: 40px;
                background: #FFFFFF;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
                border-radius: 10px;
                svg {
                    margin-bottom: 15px;
                }
                
                span {
                    margin-top: 5px;
                    font-weight: 500;
                    font-size: 20px;
                    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
                        font-size: 17px;
                    }
                }

                .date {
                    font-weight: 600;
                    font-size: 24px;
                    color: black;
                    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
                        font-size: 19px;
                    }
                }

                margin: 30px 0px;
            }
        }

        .box-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;  
