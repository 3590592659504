import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Tag } from 'antd';

import { actions as studentActions } from '../../../../../redux/modules/student';

import { ReactComponent as GraphicSVG } from '../../../../../img/performance/graphic.svg';
import { ReactComponent as TransparentGraphicSVG } from '../../../../../img/performance/transparent-graphic.svg';

import { useCourseDetail } from '../../../../../hooks/useCourseDetail';

import { 
    Container, 
    Box,
    Title 
} from './styles';

export default function PerformanceCardCFP () {
    const { setSelectedClass, selectedClass, course } = useCourseDetail();
    const dispatch = useDispatch();

    function handleHome() {
        setSelectedClass(undefined);
    };

    useEffect(() => {
        if (course.id_turma && course.id) {
          dispatch(studentActions.fetchReportCFP(course.id_turma, course.id));
        }
        // eslint-disable-next-line
    }, [course.id_turma, course.id]);

    return (
        <Container className="lessons-item unlocked" onClick={handleHome} isHome={!selectedClass}>
            <span className="list-percent">
                <GraphicSVG 
                    width="23"
                    height="23"
                />
            </span>

            <Row>
                <Box>
                    <Title className="list-title">
                        <div>
                            <TransparentGraphicSVG 
                                width="20"
                                height="20"
                            />
                            <span>Meu desempenho</span>
                        </div>
                    </Title>
                    <main>
                        <Tag>Página inicial</Tag>
                        <span className="info">Baixar relatório</span>
                    </main>
                </Box>
            </Row>
        </Container>
    )
};