import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { useSelector } from 'react-redux';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';

import Answer from '../Answer';

import { Container, Number, Result, HelpError } from './styles';
import { Props } from './types';

const Question = ({ question }: Props) => {

    const { gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito);
    
    return (
        <>
            <Container color={question.number % 2 === 0 ? 'light' : 'dark'} error={question.error ?? false}>
                {
                    (!!question.nulled && gabarito.filled && gabarito.student_filled) && (
                        <div className="nulled">
                            <span>Questão anulada</span>
                        </div>
                    )
                }
                <Number>{question.number}</Number>
                {
                    question.answers.map(answer => (
                        <Answer 
                            key={answer.id_gabarito_question_answer} 
                            answer={answer}
                            markedAnswer={question.id_gabarito_marked_answer}
                            corrrectAnswer={question.id_gabarito_correct_answer}
                            numberQuestion={question.number}
                        />
                    ))
                }
                {
                    (gabarito.student_filled && gabarito.filled) && (
                        <Result>
                            {
                                !question.correct ? (
                                    <CloseCircleOutlined className="fail"/>      
                                ): (
                                    <CheckCircleOutlined  className="success" />
                                )
                            }
                        </Result>
                    )
                }
            </Container>
            {
                question.error && (
                    <HelpError>Por favor selecione uma resposta para a questão.</HelpError>
                )
            }
        </>
    )
};

export default Question;