import React from 'react';
import Icon from '@ant-design/icons';

// import { Container } from './styles';

function FacebookIcon(props) {
  const facebookSvg = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.63925 12.9993V25.4628C2.64125 25.5478 2.67592 25.6288 2.73607 25.689C2.79622 25.7491 2.87723 25.7838 2.96227 25.7858H7.59471C7.67975 25.7838 7.76076 25.7491 7.82091 25.689C7.88106 25.6288 7.91573 25.5478 7.91773 25.4628V12.7944H11.2739C11.3556 12.7946 11.4344 12.7639 11.4944 12.7083C11.5543 12.6528 11.5909 12.5765 11.5969 12.495L11.9199 8.68193C11.9232 8.63718 11.9172 8.59226 11.9025 8.54987C11.8878 8.50749 11.8647 8.46853 11.8345 8.43537C11.8043 8.40221 11.7676 8.37554 11.7268 8.35698C11.686 8.33842 11.6418 8.32836 11.5969 8.3274H7.91773V5.61726C7.91773 5.31219 8.03893 5.01962 8.25464 4.80391C8.47035 4.5882 8.76292 4.46701 9.06799 4.46701H11.6521C11.7378 4.46701 11.8199 4.43298 11.8805 4.37241C11.9411 4.31183 11.9751 4.22968 11.9751 4.14401V0.323015C11.9751 0.237347 11.9411 0.155185 11.8805 0.0946083C11.8199 0.0340318 11.7378 0 11.6521 0H7.27959C6.04889 0 4.8686 0.488893 3.99837 1.35913C3.12813 2.22936 2.63925 3.40964 2.63925 4.64034V8.31952H0.323015C0.23663 8.32158 0.154464 8.35734 0.0941036 8.41918C0.0337433 8.48101 -2.44826e-05 8.56401 1.33181e-08 8.65042V12.4635C1.33181e-08 12.5492 0.0340319 12.6314 0.0946085 12.6919C0.155185 12.7525 0.237347 12.7865 0.323015 12.7865H2.63925V12.9993Z"
      />
    </svg>
  );

  return <Icon component={facebookSvg} {...props} />;
}

export default FacebookIcon;
