import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const OneIconBookStepRight: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="96" height="66" viewBox="0 0 96 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="15" width="66" height="66" rx="33" fill="#5768FF" />
      <path d="M48.0007 45.1831H36.0173C34.134 45.1831 32.584 43.6332 32.584 41.7498V24.6164C32.584 22.7331 34.134 21.1831 36.0173 21.1831H37.534" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M47.9991 21.2V45.1834C47.4824 44.3501 45.9491 42.1334 42.9824 40.9167C41.6324 40.3667 40.3491 40.1835 39.3491 40.1335C38.4324 40.1001 37.7324 39.3334 37.7324 38.4334V19.4501C37.7324 18.4834 38.5158 17.7001 39.4825 17.7334C40.4325 17.7668 41.5658 17.9167 42.7824 18.2834C45.3324 19.0334 47.0824 20.3834 47.9991 21.2Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M63.4167 24.6168V41.7502C63.4167 43.6336 61.8667 45.1836 59.9833 45.1836H48V21.2002H59.9833C61.8667 21.2002 63.4167 22.7335 63.4167 24.6168Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M53.1172 26.2832H58.3005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M53.1172 33.1831H58.3005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M53.1172 40.1001H58.3005" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

OneIconBookStepRight.defaultProps = defaultProps;

export default OneIconBookStepRight;
