import React from 'react';
import {
  ButtonPreviewNextQuestion,
  QuestionsSelect,
  QuestionsSelectArea,
  SelectReviewItems,
  WrongCorrectInformation,
} from './styles';

import arrowPrev from 'img/arrowPrev.svg';
import arrowNext from 'img/arrowNext.svg';

import { LectureType, QuestionType } from 'pages/lectures/utils/types';
import { PanelHeader } from 'pages/lectures/components/commons/styles';

export function LecturesHeaderPanel({
  countQuantityCountCorrectQuestions,
  currentLecture,
  handleChangeItemReview,
  handleSelectQuestion,
  handleViewNextsQuestions,
  handleViewPreviewQuestions,
  isReview,
  itemsSelectOnReview,
  lecture,
  lectureAnswers,
  questionSelected,
  reviewType,
  testReviewTime,
  widthComponentQuestionsSelectArea,
}: LecturesHeaderPanelProps) {
  return (
    <PanelHeader data-testid="lecturesheaderpanel-panelheader">
      {isReview && (
        <WrongCorrectInformation data-testid="lecturesheaderpanel-incorrectcorrect-information">
          Você acertou{' '}
          <span data-testid="lecturesheaderpanel-correct-questions">
            {countQuantityCountCorrectQuestions().correctQuestions} de {lecture?.questions?.length}{' '}
            questões
          </span>{' '}
          em <span data-testid="lecturesheaderpanel-review-time">{testReviewTime}</span>!
        </WrongCorrectInformation>
      )}
      {widthComponentQuestionsSelectArea.scrollLeft > 0 && (
        <ButtonPreviewNextQuestion
          onClick={handleViewPreviewQuestions}
          className={`preview ${isReview ? 'review' : 'lecture'}`}
          data-testid="lecturesheaderpanel-preview-button"
        >
          <img src={arrowPrev} alt="Anterior" data-testid="lecturesheaderpanel-arrowprev" />
        </ButtonPreviewNextQuestion>
      )}
      <QuestionsSelectArea
        id="select-questions-area"
        data-testid="lecturesheaderpanel-questionsselect-area"
      >
        {currentLecture?.map((question: QuestionType) => {
          let questionClass = '';

          if (isReview) {
            const getAnswerCorrect = question.answers.find((answer) => answer.correta === 1);
            const getAnswerAnswered = question.answers.find((answer) => answer.marcada === true);

            if (getAnswerCorrect?.id_resposta === getAnswerAnswered?.id_resposta) {
              questionClass = 'correct';
            } else {
              questionClass = 'incorrect';
            }
          } else {
            const hasLecturesAnswers = Object.keys(lectureAnswers).length > 0;

            if (hasLecturesAnswers && lectureAnswers.question[question.id_questao]) {
              questionClass = 'correct';
            }
          }

          return (
            <QuestionsSelect
              key={`question-${question.id_questao}-${Math.random()}`}
              htmlFor={`question-${question.id_questao}`}
              data-testid={`lecturesheaderpanel-question-select-${question.id_questao}`}
            >
              <input
                checked={questionSelected.id_questao === question.id_questao}
                type="radio"
                id={`question-${question.id_questao}`}
                name="question"
                value={question.id_questao}
                onChange={(e) => handleSelectQuestion(e.target.value)}
                hidden
                data-testid="lecturesheaderpanel-question-select-radio"
              />
              <span className={questionClass} data-testid="lecturesheaderpanel-question-class">
                {question.pivot?.ordem}
              </span>
            </QuestionsSelect>
          );
        })}
      </QuestionsSelectArea>
      {widthComponentQuestionsSelectArea.clientWidth +
        widthComponentQuestionsSelectArea.scrollLeft <
        widthComponentQuestionsSelectArea.scrollWidth && (
        <ButtonPreviewNextQuestion
          onClick={handleViewNextsQuestions}
          className={`next ${isReview ? 'review' : 'lecture'}`}
          data-testid="lecturesheaderpanel-next-button"
        >
          <img src={arrowNext} alt="Próximo" data-testid="lecturesheaderpanel-arrownext" />
        </ButtonPreviewNextQuestion>
      )}
      {isReview && (
        <SelectReviewItems
          onChange={handleChangeItemReview}
          value={reviewType.title}
          name="reviewType"
          data-testid="lecturesheaderpanel-select-review-items"
        >
          {itemsSelectOnReview.map((item: any) => (
            <option
              key={item.title}
              value={item.title}
              data-testid={`lecturesheaderpanel-select-review-item-${item.title}`}
            >
              {item.title}
            </option>
          ))}
        </SelectReviewItems>
      )}
    </PanelHeader>
  );
}

export type LecturesHeaderPanelProps = {
  isReview: boolean;
  countQuantityCountCorrectQuestions: () => { correctQuestions: number; totalQuestions: number };
  lecture: LectureType;
  testReviewTime: string;
  widthComponentQuestionsSelectArea: any;
  handleViewPreviewQuestions: () => void;
  currentLecture: QuestionType[];
  handleSelectQuestion: (id: string) => void;
  questionSelected: QuestionType;
  lectureAnswers: any;
  handleViewNextsQuestions: () => void;
  itemsSelectOnReview: any;
  reviewType: any;
  handleChangeItemReview: (e: any) => void;
};
