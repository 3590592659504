import styled, { css } from 'styled-components';
import { Layout } from 'antd'

type Props = {
    isRelative?: boolean;
    minHeight?: number;
};

export const Content = styled(Layout.Content)<Props>`
    ${({ isRelative }) => isRelative && css`
        position: relative;
    `}

    ${({ minHeight }) => minHeight && css`
        min-height: ${minHeight}px;
    `}

    @media(max-width: 720px) {
        padding: 0px;
    }

    @media(max-width: 768px) { 
        &.page {
            padding-bottom: 50px;
        }
    }
`;