import React from 'react';
import { Container, Title, Subtitle } from './styles';

export default function HeaderInside({ title, subtitle }: { title: string; subtitle?: string }) {
  return (
    <Container>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Container>
  );
}
