import styled from 'styled-components';


type Props = {
  radius: number;
  textColor: string;
};

export const Container = styled.div<Props>`
  display: flex;
  position: relative;

  .graph {
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
  }


  .text {
    position: absolute;
    color: ${({textColor}) => textColor ?? '#000000'};
    font-weight: 500;

    display: flex;
    align-items: center;
    text-align: center;

    font-family: 'Work Sans';

    @media (min-width: 1366px) {
      font-size: 18px;
    }
    @media (min-width: 767px) and (max-width: 1365px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      font-size: 14px;
    }
  }
`
