//eslint-disable-next-line
import React, { useEffect, useRef, useState } from 'react';

import { connect, useDispatch, useSelector } from 'react-redux';

import { actions as studentActions } from '../../redux/modules/student';

import MainLayout from '../../components/MainLayout';
import UploadInput from '../../components/upload';
import Loading from '../../components/Loading';
import HeaderInside from '../../components/HeaderInside';

import config from '../../config';

import { FormRegister, UserDataType } from './components/forms/FormRegister';
import { FormPassword, UserPassWordDataType } from './components/forms/FormPassword';

import ArrowRight from '../../img/svg/arrowRight';
import EditPen from '../../img/svg/EditPen';

import { itemsMenu } from './utils';

import {
  AvatarArea,
  ButtonDeleteMenuArea,
  ButtonDeleteAccount,
  Container,
  EditAvatarItem,
  LeftBar,
  MenuItems,
  NameLetterIcon,
  Panel,
  RightArea,
  TitleItem,
  ButtonDeleteFooterArea,
} from './styles';

function User() {
  const linkRedirectWhatsappDeleteAccount = config.REACT_APP_DEL_ACCOUNT_URL;
  const firstLoading = useRef(true);
  const [imgProfile, setImgProfile] = useState([]);
  const [screen, setScreen] = useState(itemsMenu[0].item);

  const dispatch = useDispatch();

  const isLastItemMenu = (item: string) => item === itemsMenu[itemsMenu.length - 1].item;
  const {
    student: { user, isLoading },
  } = useSelector((state: any) => state);
  const { token } = useSelector((state: any) => state.auth);

  useEffect(() => {
    const dataLocal = JSON.parse(localStorage.getItem('persist:root') || '{}');
    if (dataLocal) {
      const {
        user: { id },
      } = JSON.parse(dataLocal.auth || '{}');

      dispatch(studentActions.fetchStudentsById(id));
    }
  }, [imgProfile, token, dispatch]);

  const handleSaveUserData = (values: UserDataType) => {
    const { foto, ...newValues } = values;
    const valuesSend = { ...newValues, id: user?.id_usuario };
    dispatch(
      studentActions.editStudent(valuesSend, () => studentActions.fetchStudentsById(valuesSend.id))
    );
  };

  const handleSavePasswordUser = (values: UserPassWordDataType) => {
    const valuesSend = {
      ...values,
      userId: user?.id_usuario,
    };
    dispatch(
      studentActions.changePassword(valuesSend, () =>
        studentActions.fetchStudentsById(user?.id_usuario)
      )
    );
  };

  if (isLoading && firstLoading.current) {
    firstLoading.current = false;
    return <Loading />;
  }

  const RenderAvatar = () => (
    <AvatarArea>
      {user?.foto ? (
        <img src={config.s3Url + user?.foto} alt="avatar" />
      ) : (
        <NameLetterIcon>{user?.nome?.slice(0, 1)}</NameLetterIcon>
      )}
      <EditAvatarItem>
        <EditPen width={16} />
      </EditAvatarItem>
    </AvatarArea>
  );

  return (
    <MainLayout title="Meu perfil" className="home">
      <Container>
        <HeaderInside title="Meu perfil" subtitle="Configure sua conta" />
        <Panel>
          <LeftBar>
            {itemsMenu.map(({ item, label }, index: number) => (
              <MenuItems
                key={item}
                isLastItem={isLastItemMenu(item)}
                isFirstItem={index === 0}
                isSelected={item === screen}
                onClick={() => setScreen(item)}
              >
                <p>{label}</p>
                <ArrowRight width={28} color="#3f4cbd" />
              </MenuItems>
            ))}
            <ButtonDeleteMenuArea>
              <ButtonDeleteAccount href={linkRedirectWhatsappDeleteAccount} target="_blank">
                Deletar minha conta
              </ButtonDeleteAccount>
            </ButtonDeleteMenuArea>
          </LeftBar>
          <RightArea>
            {screen === 'profile' ? (
              <>
                <TitleItem>Dados pessoais</TitleItem>
                <UploadInput
                  allowedExtentions={['image/jpeg', 'image/png']}
                  typeUpload="services/s3/student"
                  onChange={(fileList: any) => setImgProfile(fileList)}
                  initialFileList={imgProfile}
                  imageUrl={user?.foto}
                  listType="picture-card"
                  type="avatar"
                  ImageAvatar={<RenderAvatar />}
                />
                {user?.id_usuario ? (
                  <FormRegister handleSaveUserData={handleSaveUserData} user={user} />
                ) : null}
              </>
            ) : screen === 'password' ? (
              <>
                <TitleItem>Senha</TitleItem>
                <FormPassword
                  dataPassword={{ nova_senha: '', reNewPassword: '', senha_atual: '' }}
                  handleSavePasswordUser={handleSavePasswordUser}
                />
              </>
            ) : null}
          </RightArea>
        </Panel>
      </Container>
      <ButtonDeleteFooterArea>
        <ButtonDeleteAccount href={linkRedirectWhatsappDeleteAccount} target="_blank">
          Deletar minha conta
        </ButtonDeleteAccount>
      </ButtonDeleteFooterArea>
    </MainLayout>
  );
}

export default connect()(User);
