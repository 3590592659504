import styled from 'styled-components';

export interface ButtonSubmitProps {
  margin?: string;
}

export const ButtonSubmitComponent = styled.button<ButtonSubmitProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 8px 26px;
  gap: 6px;

  border-radius: 5px;
  border: 1px solid #5768ff;
  background: #5768ff;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  color: #efefef;
  text-align: center;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.48px;

  cursor: pointer;

  margin: ${({ margin }) => margin || '0px'};

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
