import React, { useState, useEffect } from 'react';

import PrintTest from '../../../../img/svg/print-tests.svg';

import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

interface DownloadButtonComponentProps {
  loading: boolean;
  handleDownload: () => void;
  current?: boolean;
}

export function DownloadButtonComponent({
  loading,
  current = false,
  handleDownload,
}: DownloadButtonComponentProps) {
  const antIcon = <LoadingOutlined style={{ fontSize: 14, color: '#5768FF' }} spin />;
  const [thisButton, setThisButton] = useState(current);

  useEffect(() => {
    if (!loading) {
      setThisButton(false);
    }
  }, [loading]);

  return (
    <button
      className="print-button"
      onClick={() => {
        handleDownload();
        setThisButton(true);
      }}
      disabled={loading && thisButton}
    >
      Imprimir
      {loading && thisButton ? (
        <Spin size="small" indicator={antIcon} data-testid="loading-icon" />
      ) : (
        <img src={PrintTest} alt="print-test" data-testid="print-test" />
      )}
    </button>
  );
}
