import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import CourseListContainer from '../CourseList.container'
import CourseDetailContainer from '../ClassContent/CourseDetail.container'

function CourseRoutes() {
  return (
    <Switch>
      <Route exact path="/courses" component={CourseListContainer} />
      <Route exact path="/courses/:courseId" component={CourseDetailContainer} />
      <Redirect to="/" />
    </Switch>
  )
}

export default CourseRoutes
