import auth from './auth'
import course from './course'
import lecture from './class'
import student from './student'
import question from './question'
import mentorship from './mentorship'
import checkout from './checkout'
import gabarito from './gabarito'
import podcast from './podcast'
import cashback from './cashback'

export default {
  auth,
  course,
  lecture,
  student,
  question,
  mentorship,
  checkout,
  gabarito,
  podcast,
  cashback
}
