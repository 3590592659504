import React from 'react';

import {
    ChartBarGranph,
    Modulo,
    Right

} from './styles'

type Props = {
    percentModulo: number;
    modulo: number;
    total: string;
}

const ChartBarPercent = ({ percentModulo, modulo, total } : Props) => {

    return (
        <ChartBarGranph>
            <Modulo percentModulo={percentModulo}><p>Módulo{modulo}</p></Modulo>
            <Right><p>{total}</p></Right>
        </ChartBarGranph>
    );
};

export default ChartBarPercent;
