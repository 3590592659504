import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const SuccessMessageIcon: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.1479 5.46048L7.14792 14.4605C7.09567 14.5128 7.03364 14.5543 6.96535 14.5826C6.89706 14.6109 6.82387 14.6255 6.74995 14.6255C6.67603 14.6255 6.60283 14.6109 6.53454 14.5826C6.46626 14.5543 6.40422 14.5128 6.35198 14.4605L2.41448 10.523C2.30893 10.4174 2.24963 10.2743 2.24963 10.125C2.24963 9.97574 2.30893 9.83259 2.41448 9.72704C2.52003 9.62149 2.66318 9.56219 2.81245 9.56219C2.96171 9.56219 3.10487 9.62149 3.21042 9.72704L6.74995 13.2673L15.352 4.66454C15.4575 4.55899 15.6007 4.49969 15.7499 4.49969C15.8992 4.49969 16.0424 4.55899 16.1479 4.66454C16.2535 4.77009 16.3128 4.91324 16.3128 5.06251C16.3128 5.21177 16.2535 5.35493 16.1479 5.46048Z"
        fill="#62CF7A"
      />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

SuccessMessageIcon.defaultProps = defaultProps;

export default SuccessMessageIcon;
