import styled from 'styled-components';
import { Col } from 'antd';

type ContainerProps = {
    isHome: boolean;
};

export const Container = styled.div<ContainerProps>`
    padding-bottom: 1%;
    margin-right: 20px;
    border: 4px solid ${({ theme, isHome }) => isHome && theme.colors.warning};
    .list-percent {
        svg {
            color: ${({ theme }) => theme.colors.warning};
        }
    }
`;

export const Box = styled(Col)`
    width: 100%;
`;

export const Title = styled.span`
    svg {
        color: ${({ theme }) => theme.colors.warning};
        font-size: 18px;
    }

    span {
        margin-left: 3%;
        font-weight: 400;
        font-size: 17px;
    }
`;
