import React, { ChangeEvent, SelectHTMLAttributes } from 'react';
import { SelectArea, SelectComponent, SelectLabel } from './styles';
import ArrowDown from '../../img/svg/ArrowDown';

interface SelectProps extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'onChange'> {
  label: string;
  target: string;
  options: {
    value: string | number;
    label: string;
  }[];
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
}

export function Select({ label, options, target, onChange, value }: SelectProps) {
  return (
    <SelectArea>
      <SelectLabel>{label}</SelectLabel>
      <SelectComponent
        id={target}
        onChange={onChange}
        name={target}
        defaultValue={value}
        value={value}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </SelectComponent>
      <ArrowDown />
    </SelectArea>
  );
}
