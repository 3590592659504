import React, { useContext } from 'react';
import Score from '../Score';

import { CourseContext } from '../../../ClassContent/CourseDetail.container';
import Calendar from '../Calendar';


import { Container, Box } from './styles';

type ContextProps = {
    course: {
        title: string;
    }
};

export default function Layout () {
    const { course } = useContext(CourseContext) as ContextProps;

    return (
        <Container>
            <div className="course-content">
                <Box>
                    <div className="content">
                        <div className="box-calendar">
                            <div className="box-title">
                                <h2>
                                    CURSO
                                </h2>
                                <h3>{course.title}</h3>
                            </div>
                            <Calendar />
                        </div>

                        <div className="box-score">
                            <Score />
                        </div>
                    </div>
                </Box>
            </div>
        </Container>
    );
}
