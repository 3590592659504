import request from '../../shared/request'

export function getCoursesByUserId() {
  return request(`/v1/services/aluno/findCoursesByStudent`, {
    method: 'GET',
  })
}

export function getCourseDetails(courseId, courseClassId=null) {
  const route = courseClassId 
    ? `/v1/services/student/statistics/${courseId}/${courseClassId}` 
    : `/v1/services/student/statistics/${courseId}`;
  
  return request(route, {
    method: 'GET',
  })
}

export function getCourseDetailsByTag(courseTag) {
  return request(`/v1/services/course/${courseTag}`, {
    method: 'GET',
  });
}
