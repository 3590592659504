import React from 'react'

import { ReactComponent as SmileySad } from '../../img/svg/smiley-sad.svg';
import { ReactComponent as ArrowRight } from '../../img/svg/arrow-right.svg';

import { Container, Button } from './styles';

export default function PerformanceCFPNotExist() {
  return (
    <Container>
      <SmileySad />
      <span className='vish'>Vish!</span>
      <span className='text'>
        Você não realizou nenhuma prova até o momento.
        <br />
        Fale com seu professor para iniciar as suas aulas.
      </span>
      <a
        href='https://api.whatsapp.com/send?phone=5551983219951&text=Ol%C3%A1!%20Gostaria%20de%20entrar%20em%20contato'
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button>
          <span className='text-button'>Falar com meu professor</span>
          <ArrowRight />
        </Button>
      </a>
    </Container>
  )
}
