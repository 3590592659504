import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import Button from '../../../../components/Button';
import ModalConfirm from '../../../../components/ModalConfirm';
import { actions as studentActions } from '../../../../redux/modules/student';


import { Props, HistoryProps, FormProps } from './types';

import { WrapperCheckbox } from '../../../../components/Gabarito/Layout/styles';
import { Container } from './styles';

const Unsubscription = ({ setPage }: Props) => {
    const [confirm, setConfirm] = useState(false);
    const [form, setForm] = useState<FormProps>({ phone: false, email: false, whatsapp: false });
    
    const dispatch = useDispatch();
    const { isLoadingSaveChannels } = useSelector((state: { student: { isLoadingSaveChannels: boolean } }) => state.student);
    
    const history: HistoryProps = useHistory();
    const { token } = useParams<{token: string}>();

    const onConfirm = () => {
        dispatch(studentActions.storeCommunicationsChannels({
            ...form,
            type: 'disable',
            token 
        }, () => setPage('notification')));
    };

    const handleConfirm = () => {
        if (form.phone || form.email || form.whatsapp) {
            setConfirm(true);
        }
    };

    const handleCancel = () => {
        history.push('/gabaritoro');
    };

    const handleChange = (type: string, e: CheckboxChangeEvent) => {
        setForm({
            ...form,
            [type]: e.target.checked
        });
    };
    
    return (
        <>
            <ModalConfirm 
                isVisible={confirm} 
                setVisible={setConfirm} 
                title="Tem certeza que deseja descadastrar ?"
                onConfirm={onConfirm}
                isLoading={isLoadingSaveChannels}
            />

            <Container>
                <h1>De qual comunicação você gostaria de se descadastrar ?</h1>
                <div className="body">
                    <WrapperCheckbox className="checkbox" marked={form.phone}>
                        <Checkbox onChange={(e) => handleChange('phone', e)}>
                            <span className="checkbox-text">
                                Contato telefônico
                            </span>
                        </Checkbox>
                    </WrapperCheckbox>

                    <WrapperCheckbox className="checkbox" marked={form.email}>
                        <Checkbox onChange={(e) => handleChange('email', e)}>
                            <span className="checkbox-text">
                                Email
                            </span>
                        </Checkbox>
                    </WrapperCheckbox>

                    <WrapperCheckbox className="checkbox" marked={form.whatsapp}>
                        <Checkbox onChange={(e) => handleChange('whatsapp', e)}>
                            <span className="checkbox-text">
                                WhatsApp
                            </span>
                        </Checkbox>
                    </WrapperCheckbox>
                </div>

                <div className="buttons">
                    <Button 
                        onClick={handleConfirm}
                        layout={(form.email || form.phone || form.whatsapp) ? 'warning' : 'default'}
                        disabled={(!form.email && !form.phone && !form.whatsapp)}
                    >
                        Confirmar
                    </Button>

                    <Button layout="outline-primary" onClick={handleCancel}>
                        Cancelar
                    </Button>
                </div> 
            </Container>
        </>
    )
};

export default Unsubscription;