import React, { useEffect, useState } from 'react';

import { Formik } from 'formik';

import { FormaArea, GridArea } from './styles';

import { Input } from '../../../../../components/Input';
import { ButtonSubmit } from '../../../../../components/ButtonSubmit';
import { Select } from '../../../../../components/Select';

import { getDataFinancialInstitions } from '../../../../../redux/api/student';

import useUserForms from '../hooks/useUserForms';

interface optionsSelectType {
  value: string;
  label: string;
}

export function FormRegister({ user, handleSaveUserData }: FormRegisterProps) {
  const [optionsSelect, setOptionsSelect] = useState<optionsSelectType[]>([]);
  const { formRegisterValidate, getItemsRegisterForm } = useUserForms();

  useEffect(() => {
    (async () => {
      const response = (await getDataFinancialInstitions()) as Array<optionsSelectType>;

      setOptionsSelect([
        { value: '', label: 'Não trabalho em nenhuma' },
        ...response.map((item: optionsSelectType) => ({ value: item.value, label: item.label })),
        { value: 'other', label: 'Outra' },
      ]);
    })();
  }, []);

  const handleSubmit = (data: UserDataType) => {
    handleSaveUserData(data);
  };

  return (
    <Formik
      initialValues={user as unknown as UserDataType}
      validate={formRegisterValidate}
      onSubmit={(values) => handleSubmit(values)}
      validateOnMount={true}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => {
        const itemsForm = getItemsRegisterForm(values, setFieldValue);

        return (
          <FormaArea onSubmit={handleSubmit}>
            {itemsForm.map((items, index) => (
              <GridArea items={items.length} key={index}>
                {items.map(({ label, target, type, onChange, value, maxLength }) =>
                  type === 'select' ? (
                    <Select
                      target={target}
                      onChange={onChange}
                      label={label}
                      key={target}
                      options={optionsSelect}
                      value={value !== null ? value : 'Não trabalho em nenhuma'}
                    />
                  ) : (
                    <Input
                      lang="pt-BR"
                      key={target}
                      errors={errors[target as keyof UserDataType]}
                      label={label}
                      type={type}
                      name={target}
                      placeholder={label}
                      value={value ? value : values[target as keyof UserDataType] || ''}
                      onChange={onChange ? onChange : handleChange}
                      onBlur={handleBlur}
                      maxLength={maxLength ? maxLength : undefined}
                      disabled={target === 'email' || target === 'documento'}
                    />
                  )
                )}
              </GridArea>
            ))}
            <ButtonSubmit
              margin="auto 0 0 auto"
              disable={isSubmitting}
              label="Salvar"
              type="submit"
            />
          </FormaArea>
        );
      }}
    </Formik>
  );
}

export interface UserDataType {
  id_usuario: number;
  nome: string | null;
  sobre_nome: string | null;
  email: string | null;
  telefone: string | null;
  documento: string | null;
  uf: string | null;
  cidade: string | null;
  bairro: string | null;
  rua: string | null;
  numero: string | null;
  complemento: string | null;
  cep: string | null;
  dt_nascimento: string | null;
  instituicao_financeira: string | null;
  foto: string | null;
}

interface FormRegisterProps {
  user: UserDataType;
  handleSaveUserData: (values: UserDataType) => void;
}

export interface ItemsFormProps {
  label: string;
  target: string;
  type: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  value?: string;
  maxLength?: number;
}
