import React from 'react';

interface ArrowDownProps {
  color?: string;
}

export default function ArrowDown({ color }: ArrowDownProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0672 11.6922L10.4422 17.3172C10.3841 17.3753 10.3152 17.4214 10.2393 17.4529C10.1634 17.4843 10.0821 17.5005 9.99998 17.5005C9.91785 17.5005 9.83652 17.4843 9.76064 17.4529C9.68477 17.4214 9.61584 17.3753 9.55779 17.3172L3.93279 11.6922C3.81552 11.5749 3.74963 11.4159 3.74963 11.25C3.74963 11.0841 3.81552 10.9251 3.93279 10.8078C4.05007 10.6905 4.20913 10.6247 4.37498 10.6247C4.54083 10.6247 4.69989 10.6905 4.81717 10.8078L9.37498 15.3664V3.125C9.37498 2.95924 9.44083 2.80027 9.55804 2.68306C9.67525 2.56585 9.83422 2.5 9.99998 2.5C10.1657 2.5 10.3247 2.56585 10.4419 2.68306C10.5591 2.80027 10.625 2.95924 10.625 3.125V15.3664L15.1828 10.8078C15.3001 10.6905 15.4591 10.6247 15.625 10.6247C15.7908 10.6247 15.9499 10.6905 16.0672 10.8078C16.1844 10.9251 16.2503 11.0841 16.2503 11.25C16.2503 11.4159 16.1844 11.5749 16.0672 11.6922Z"
        fill={color ? color : '#040404'}
      />
    </svg>
  );
}
