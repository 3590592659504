import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import MaskedInput from 'antd-mask-input';
import { FormikValues } from 'formik';
import { Form, Row, Col, Input, Select, Checkbox, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import ModalAlert from '../../../../components/ModalAlert';
import Layout from '../../../../components/Gabarito/Layout';
import StepsSubscriptionChannels from '../../StepsSubscripionChannels';

import { actions as gabaritoActions } from '../../../../redux/modules/gabarito';
import { actions as studentActions } from '../../../../redux/modules/student';

import { FormDataProps, HistoryProps, StateProps } from './types';

import { 
  Container, 
  FormWrapper, 
  CheckboxesWrapper, 
  Title, 
} from './styles';

const { Item } = Form;

const Create = () => {
  const [openModalChannels, setOpenModalChannels] = useState(false);
  const [pageMaterial, setPageMaterial] = useState('subscription');
  const [openModalAcceptTheTherms, setOpenModalAcceptTheTherms] = useState(false);
  const [acceptContract, setAcceptContract] = useState(false);
  const [acceptMaterial, setAcceptMaterial] = useState(false);
  const [formData] = useState<FormDataProps>({} as FormDataProps);
  

  const dispatch = useDispatch();
  const { gabaritos, storeLeadLoading, modules } = useSelector(
    (state: StateProps) => state.gabarito
  );

  const { channels } = useSelector(
    (state: StateProps) => state.student
  );

  const history: HistoryProps = useHistory();

  useEffect(() => {
    dispatch(gabaritoActions.fetchAll({ pre: false }));
  }, [dispatch]);

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required('Por favor informe seu nome'),
      
      phone: Yup.string()
        .required('Por favor informe seu telefone')
        .test('completePhone', 'Por favor finalize o telefone', (value) => {
          if (!value) return true;
          return !value.match(/[_]/);
        }),

      email: Yup.string()
        .required('Por favor informe seu e-mail')
        .email('Por favor informe um email válido'),
      gabaritoId: Yup.number().required('Por favor selecione a prova'),
      modulesId: Yup.array().required('Por favor selecione os módulos')
    });
  };

  const handleChangeExam = (gabaritoId: number) => {
    dispatch(gabaritoActions.getModulesByIdGabarito(gabaritoId)); 
  };

  const handleNext = (values: FormikValues) => { 
    if (acceptMaterial && acceptContract) {
      const formData = {
        ...values,
        channels: {
          ...channels
        }
      };

      dispatch(
        gabaritoActions.storeLead(formData as FormDataProps, (payload: any) => {
          if (payload) {
            history.push(`/gabaritoro/respostas/${payload?.token}`);
          }
        })
      );
    } else {
      setOpenModalAcceptTheTherms(true);
    }
  };

  const handleAcceptTheMaterial = () => {
    setAcceptMaterial(!acceptMaterial);
    dispatch(studentActions.setChannels({
      phone: !acceptMaterial,
      email: !acceptMaterial,
      whatsapp: !acceptMaterial
    }));
  };

  const handleShowTheMaterial = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setOpenModalChannels(true);
  };

  const handleFinishSelectTheMaterial= () => {
    setOpenModalChannels(false);
    setPageMaterial('subscription');
    setAcceptMaterial(true);
  };

  return (
    <Layout>
      <Container>
        <ModalAlert
          isVisible={openModalAcceptTheTherms}
          setVisible={setOpenModalAcceptTheTherms}
          title="Aceite os termos de serviço, e a recepção do material para continuar"
        />

        <StepsSubscriptionChannels 
          isVisible={openModalChannels}
          setVisible={setOpenModalChannels}
          page={pageMaterial}
          setPage={setPageMaterial}
          onFinish={handleFinishSelectTheMaterial}
        />

        <Title>
          <h2>
            Confira o resultado da sua prova no gabarito.
            <br />
            <span>&nbsp;Nós te avisamos quando a sua nota sair !</span>
          </h2>
        </Title>

        <Title>
            <h3>Esta ferramenta é exclusiva para a certificação CFP®</h3>
        </Title>

        <FormWrapper
          initialValues={formData}
          enableReinitialize={true}
          onSubmit={handleNext}
          validationSchema={validationSchema}
        >
          {({ values, errors, handleSubmit, handleChange, setFieldValue }) => (
            <Form layout="vertical" onFinish={handleSubmit}>
              <Row gutter={20}>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item
                    label="Nome completo *"
                    required={false}
                    hasFeedback
                    validateStatus={errors.name && 'error'}
                    help={errors.name}
                  >
                    <Input
                      size="large"
                      name="name"
                      placeholder="Nome completo"
                      className="ipt"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <Item
                    label="Telefone *"
                    required={false}
                    hasFeedback
                    validateStatus={errors.phone && 'error'}
                    help={errors.phone}
                  >
                    <MaskedInput
                      size="large"
                      name="phone"
                      placeholder="(00) 00000-0000"
                      className="ipt"
                      value={values.phone}
                      onChange={handleChange}
                      mask="(11) 11111-1111"
                    />
                  </Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item
                    label="Seu email *"
                    required={false}
                    hasFeedback
                    validateStatus={errors.email && 'error'}
                    help={errors.email}
                  >
                    <Input
                      size="large"
                      name="email"
                      className="ipt"
                      placeholder="Seu email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Item
                    label="Selecione a prova"
                    required={false}
                    hasFeedback
                    validateStatus={errors.gabaritoId && 'error'}
                    help={errors.gabaritoId}
                  >
                    <Select
                      size="large"
                      showSearch
                      className="ipt-select"
                      optionFilterProp="children"
                      placeholder="Selecione"
                      value={values.gabaritoId}
                      onChange={(e) => {
                        setFieldValue('gabaritoId', e);
                        setFieldValue('modulesId', []);
                        handleChangeExam(e);
                      }}
                    >
                      <Select.OptGroup>
                        {gabaritos.map((item) => (
                          <Select.Option key={item.id_gabarito} value={item.id_gabarito} title={item.name}>
                            {item.summary ? item.summary : item.name}
                          </Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Item>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                    <Item
                        label="Selecione os modulos"
                        required={false}
                        hasFeedback
                        validateStatus={errors.modulesId && 'error'}
                        help={errors.modulesId}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            size="large"
                            className="ipt-select"
                            optionFilterProp="children"
                            placeholder="Selecione"
                            value={values.modulesId}
                            onChange={(e) => setFieldValue('modulesId', e)}
                        >
                            <Select.OptGroup>
                                {
                                    modules.map(module => (
                                        <Select.Option 
                                            key={module.id_gabarito_module} 
                                            value={module.id_gabarito_module} 
                                            title={module.description}

                                        >
                                            Modulo {module.number}
                                        </Select.Option>
                                    ))
                                }
                            </Select.OptGroup>
                        </Select>
                    </Item>
                </Col>
            </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <CheckboxesWrapper>
                    <Checkbox
                      className="input-contract"
                      value={acceptContract}
                      onChange={() => setAcceptContract(!acceptContract)}
                      checked={acceptContract}
                    >
                      Li e aceito a &nbsp;
                      <a
                        href="https://www.academiarafaeltoro.com.br/politica-de-privacidade/"
                        className="btn-accept-the-contract"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Política de Privacidade
                      </a>
                    </Checkbox>

                    <Checkbox
                      className="input-contract"
                      value={formData.material}
                      onChange={handleAcceptTheMaterial}
                      checked={acceptMaterial}
                    >
                      Aceito receber <span  className="material" onClick={handleShowTheMaterial}>a newsletter</span>
                    </Checkbox>
                  </CheckboxesWrapper>
                </Col>
              </Row>

              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div className="box-buttons">
                    {storeLeadLoading ? (
                      <button className="warning">
                        <Spin indicator={<LoadingOutlined spin />} />
                      </button>
                    ) : (
                      <button className="warning" type="submit">
                        Próximo
                      </button>
                    )}

                    <button type="button" className="transparent" onClick={() => history.push('/gabaritoro')}>
                      Voltar
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </FormWrapper>
      </Container>
    </Layout>
  );
};

export default Create;
