import styled, { css } from 'styled-components';
interface ContainerProps {
    color: 'dark' | 'light';
    error: boolean;
};

export const Container = styled.div<ContainerProps>`
    background-color: ${({ color }) => color === 'dark' ? '#eee' : '#fff'};
    height: 57px;

    ${({ error }) => error && css`
        border: 1px solid red;
    `}

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .nulled {
        position: absolute;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 1.2rem;
        @media(max-width: 768px) {
            font-size: 0.7rem;
        }

        background: #ddd;
        opacity: 0.5;

        span {
            text-decoration: line-through;
        }
    }
`;

export const Number = styled.span`
    flex: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.25rem;
    font-weight: 500;
    z-index: 9;
`;


export const Result = styled.span`
    flex: 1;
    display: flex;

    justify-content: center;
    align-items: center;

    .success {
        color: #2EB85C;
        opacity: 1;
        z-index: 9;
    }

    .fail {
        color: red;
    }

    svg {
        font-size: 23px;
        
    }
`;

export const HelpError = styled.span`
    color: red;
`;