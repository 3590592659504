import _ from 'lodash'

export const pagination = (state) => _.get(state, 'student.pagination', {})
export const getStudent = (state) => _.get(state, 'student.user', {})
export const isLoading = (state) => _.get(state, 'student.isLoading', false)
export const error = (state) => _.get(state, 'student.error', false)
export const errorMessage = (state) => _.get(state, 'student.errorMessage', '')
export const getCfpSimulado = (state) => _.get(state, 'student.handleCfpSimulado')
export const isFetchCfpsimulado = (state) => _.get(state, 'student.isFetchCfpsimulado')


export const formattedScoreTodo = (resp) => {
    const score = resp[1]?.score ? Math.round(resp[1]?.score * 100) : 0;
    const average = resp[3]?.max_acertos_avg[0] ? Math.round(resp[3]?.max_acertos_avg[0] * 100) : 0;

    const totalClasses = resp[2]?.to_be_done?.prova[0] ?? 0;
    const exams = resp[2]?.to_be_done?.prova[1] ?? 0;
    const simulates = resp[2]?.to_be_done?.prova[2] ?? 0;

    const accomplishClasses = resp[2]?.done?.prova[0] ?? 0;
    const accomplishExams = resp[2]?.done?.prova[1] ?? 0;
    const accomplishSimulates = resp[2]?.done?.prova[2] ?? 0;

    let progress = ((accomplishClasses+accomplishExams+accomplishSimulates) * 100) / (totalClasses+exams+simulates);
    progress = isNaN(progress) ? 0 : Math.round(progress);

    
    return {
        score,
        average,
        accomplishClasses,
        totalClasses,
        progress
    };
};
