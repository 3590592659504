import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types'
import Top from '../Top';
import Sidebar from '../Sidebar';

import { actions as podcastActions } from '../../redux/modules/podcast';
import { allowedFeature } from '../../helpers/permissions';

import PodcastPlayer from '../Podcast/GlobalPlayer';

import { Content } from './styles';

const propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any,
  className: PropTypes.any,
  course: PropTypes.object,
  setSelectedClass: PropTypes.func,
  icon: PropTypes.any
};

function MainLayout({ 
  children, 
  className,
}) {
  const { currentPlay } = useSelector(state => state.podcast);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/podcast') {
      dispatch(podcastActions.closePlayerFooter());
    } else {
      if (currentPlay.isFooterPlayerOpen) {
        dispatch(podcastActions.openPlayerFooter());
      }
    }
    // eslint-disable-next-line
  }, [location?.pathname]);

  return (
    <>
      <Helmet>
        <title>Área do Aluno</title>
      </Helmet>
      <Layout className="main-layout">
        <Top />
        <Content>
          <Layout hasSider={true}>
            <Sidebar />
            <Content className={`page ${className} main-layout-padding`} isRelative>
              <Layout>
                <Content>
                  {children}
                </Content>
              </Layout>
              {
                (allowedFeature('podcast') 
                && currentPlay?.isFooterPlayerOpen 
                && currentPlay?.source 
                && (location.pathname !== '/podcast')) && (
                  <PodcastPlayer />
                ) 
              } 
            </Content>
          </Layout>
        </Content>
      </Layout>
    </>
  )
}

MainLayout.propTypes = propTypes
export default MainLayout
