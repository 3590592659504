import React from 'react';
import Icon from '@ant-design/icons'

function ConnectionIcon(props) {
  const connectionIcon = () => (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.9375 0.8125V2.0625H8.05625L0.8125 9.30625L1.69375 10.1875L8.9375 2.94375V7.0625H10.1875V0.8125H3.9375Z" fill="#595E83"/>
    </svg>

  );

  return (
    <Icon component={connectionIcon} {...props}/>  
  )
}

export default ConnectionIcon;