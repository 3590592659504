import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as api from '../api/cashback';

import { ICallback } from '../../shared/Models/ICallback';
import { ITransactions, ITransaction } from '../../shared/Models/cashback/ITransactions';
import { constants } from '../modules/cashback';


function* fetchTransactions(action: {
    type: string;
    callback?: ICallback;
}) {
    try {
        const payload: ITransactions = yield call(api.fetchTransactions);
        yield put({
            type: constants.CASHBACK_FETCH_TRANSACTIONS.SUCCESS,
            payload
        });

        action.callback && action.callback({
            type: 'SUCCESS',
            payload
        });
    } catch (e: any) {
        yield put({
            type: constants.CASHBACK_FETCH_TRANSACTIONS.FAILED
        });

        action.callback && action.callback({
            type: 'ERROR',
            payload: e,
        });
    }
};

function* fetchTransactionById(action: {
    type: string;
    id: number;
    callback?: ICallback;
}) {    
    try {
        const payload: ITransaction = yield call(api.fetchTransactionById, action.id);
        yield put({
            type: constants.CASHBACK_FETCH_TRANSACTION_BY_ID.SUCCESS,
            payload
        });

        action.callback && action.callback({
            type: 'SUCCESS',
            payload
        });
    } catch (e: any) {
        yield put({
            type: constants.CASHBACK_FETCH_TRANSACTION_BY_ID.FAILED
        });

        action.callback && action.callback({
            type: 'ERROR',
            payload: e,
        });
    }
};

function* createCoupon(action: {
    type: string;
    formData: {},
    callback?: ICallback;
}) {
    try {  
        const payload: ITransaction = yield call(api.createCoupon, action.formData);   
        yield put({
            type: constants.CASHBACK_CREATE_COUPON.SUCCESS,
            payload
        });

        action.callback && action.callback({
            type: 'SUCCESS',
            payload
        });
    } catch (e: any) {
        yield put({
            type: constants.CASHBACK_CREATE_COUPON.FAILED,
            payload: {
                error: e.message.replace('Error: ', '')
            }
        });

        action.callback && action.callback({
            type: 'ERROR',
            payload: e,
        });
    }
};

function* cancelCoupon(action: {
    type: string,
    couponId: number,
    callback?: ICallback
})  {
    try {
        const payload: ITransaction = yield call(api.cancelCoupon, action.couponId);
        
        yield put({
            type: constants.CASHBACK_CANCEL_COUPON.SUCCESS,
            payload
        });

        action.callback && action.callback({
            type: 'SUCCESS',
            payload
        });
    } catch (e) {
        yield put({
            type: constants.CASHBACK_CANCEL_COUPON.FAILED
        });

        action.callback && action.callback({
            type: 'ERROR',
            payload: e,
        });
    }
}

function* watchFetchTransactions() {
    yield takeLatest(constants.CASHBACK_FETCH_TRANSACTIONS.ACTION, fetchTransactions);
};

function* watchFetchTransactionById() {
    yield takeLatest(constants.CASHBACK_FETCH_TRANSACTION_BY_ID.ACTION, fetchTransactionById);
};

function* watchCreateCoupon() {
    yield takeLatest(constants.CASHBACK_CREATE_COUPON.ACTION, createCoupon);
};

function* watchCancelCoupon() {
    yield takeLatest(constants.CASHBACK_CANCEL_COUPON.ACTION, cancelCoupon);
};

export function* rootSaga() {
    yield all([
        fork(watchFetchTransactions),
        fork(watchCreateCoupon),
        fork(watchCancelCoupon),
        fork(watchFetchTransactionById)
    ]);
};