import React, { useState } from 'react';

import Alert from '../../../../../components/ModalAlert';
import Calendar from '../../../../../pages/course/components/Performance/Calendar';

import { ContextClassProps } from '../../../../../hooks/useCourseDetail';

import { allowedReportDownload, allowedScoreToro, checkIfIsModularOrNot } from '../../../../../helpers/permissions';
import {ReactComponent as IconEducation} from '../../../../../img/education.svg'
import {ReactComponent as EducationIconBlack} from '../../../../../img/educationIconBlack.svg'

import { MyPerformanceButton, PerformanceModal, Container, TitleHeader, ContainerHeader } from './styles';
import  ExpireDateCourse  from '../../expireDateCourse';
import Arrow from '../../../../../img/svg/arrow-right-lilas.svg'

type Props = {
    course: {
        title: string;
        id_course_category?: number;
        expiration_date?: number;
        scheduledTest?: {
            date_exam: string;
        }
    },
    icon: any,
    setSelectedClass: (className?: ContextClassProps) => void;
  };

export default function PerformanceButton({
    course,
    setSelectedClass,
}: Props) {
    const [error, setError] = useState(false);
    const [visible, setVisible] = useState(true);


    return (
      <ContainerHeader>
        <h1 className="header-title">
          <div className='header-title-course'>
            <div className='color-icon-light'>
              <IconEducation />
            </div>
            <div className='color-icon-dark'>
              <EducationIconBlack />
            </div>
            <TitleHeader>
              <p>Cursos / <span>{course.title}</span></p>
            </TitleHeader>
            {course.expiration_date === 1 ?
              <div className='expire'>
                <ExpireDateCourse course={course} size={'14px'} />
              </div>
            : ''}
          </div>

          {(allowedScoreToro(course.id_course_category) || allowedReportDownload(course.id_course_category)) && checkIfIsModularOrNot(course?.title) && (
              <MyPerformanceButton onClick={() => setSelectedClass(undefined)}>
                Meu desempenho
                <img src={Arrow} alt="icon arrow- left" />
              </MyPerformanceButton>
            )}
        </h1>
        <Container>
          {(allowedScoreToro(course.id_course_category) && !course.scheduledTest) && (
            <>
              <Alert
                isVisible={error}
                setVisible={setError}
                title="É obrigatório selecionar a data da sua prova."
                zIndex={999}
                />
              <PerformanceModal
                  visible={visible}
                  onCancel={() => setVisible(false)}
                  zIndex={998}
                  footer={false}
                  >
                  <div className="box-calendar">
                      <Calendar/>
                  </div>
              </PerformanceModal>
            </>
          )}
        </Container>
      </ContainerHeader>
    );
}
