import styled from 'styled-components';

//base converssao vw 2840px

type Props = {
    percent?: number | string;
    colorbk1?: string;
    x?: string;
    positionPercent?: string;
    zIndex?: number;
}
type PropsContainer = {
    widthGraph?: string;
}

type PropsSecundary = {
    percentTwo?: number | string;
    colorbk2?: string;
}
type PropsTitle = {
    displayTitle?: string;
    displayAvaliable?: string;
    displayLegend?: string;
    colorbk0?: string;
    colorText?: string;
    height?: string;
    fontSize?: string;
    displaytextPercent?: string;
}
type PropsDescription = {
    description?: string;
}
/*GRAPH*/
export const Graph = styled.div<PropsTitle>`
    z-index: 1;
    position: relative;
    width: 100%;
    height: ${({ height }) => height} !important;
    height: 5.629vw;
    border-radius: 0.352vw; //10px
    background: ${({ colorbk0 }) => colorbk0} !important;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    P {
        display: contents;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 1.760vw; //50
        line-height: 3.447vw; //61
        color: #FFFFFF;
    }
`;
export const Text = styled.div<PropsTitle>`
    display: contents;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: ${({ fontSize }) => fontSize} !important;
    font-size: 1.760vw; //50
    line-height: 3.447vw; //61
    color: ${({ colorText }) => colorText} !important;
    display: ${({ displaytextPercent }) => displaytextPercent} !important;

    color: #FFFFFF;
`;
export const Title = styled.div<PropsTitle>`
    display: ${({ displayTitle }) => displayTitle};
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.760vw; //50
    line-height: 3.447vw; //61
    color: #434343;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 1vw;
`;
export const Color = styled.div<Props>`
    z-index: ${({ zIndex }) => zIndex};
    /* position: absolute; */
    display: flex;
    align-items: center;

    justify-content: ${({ positionPercent }) => positionPercent} !important;
    justify-content: flex-end;
    height: 100%;

    width: ${({ percent }) => percent}%;
    background: ${({ colorbk1 }) => colorbk1};
    border-radius: 0.3vw; //10
    /* padding: 0 1.408vw;//40 */
    padding: 0px 0.4vw 0 1.9vw;
    margin-left: -0.1%;
`;
export const ColorSecondary = styled.div<PropsSecundary>`
    z-index: 2;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;

    width: ${({ percentTwo }) => percentTwo};
    background: ${({ colorbk2 }) => colorbk2};
    border-radius: 0.403vw 0px 0px 0.403vw;
    padding: 0 1.408vw;

    /* ::after {
        content: '60%';
        margin-top: 10vw;
        position: absolute;
        margin-left: 5vw;
        width: 4.959vw;
        background-color: #000a66;
        color: white;
        height: 2.379vw;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 41vw;
        font-size: 1.6vw;
        font-weight: 600;
    }

    ::before {
        content: '';
    } */
`;

export const Total = styled.div<Props>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    width: ${({ percent }) => percent}%;
    padding: 0 1.408vw;//40
    height: 100%;
    z-index: 0;
`;
export const TextTotal = styled.div<PropsTitle>`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: ${({ fontSize }) => fontSize} !important;
    font-size: 1.760vw; //50
    line-height: 3.447vw; //61
    color: ${({ colorText }) => colorText} !important;
    color: #858585;
    z-index: 9;
`;
export const AvaliableDate = styled.div<PropsTitle>`
    width: 80vw;
    display: ${({ displayAvaliable }) => displayAvaliable};
    margin-top: 2vw;
    justify-content: flex-end;
    p {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 1.056vw;
        line-height: 1.232vw;
        text-align: end;
        color: #434343;
        justify-content: flex-end;
        margin-bottom: 0px;
    }
`;

export const Description = styled.div<PropsDescription>`

    display: ${({ description }) => description};
    margin: 1vw 0;
    p {
        opacity: 0.8;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 1.411vw;
        line-height: 1.653vw;
        color: #000000;
    }
`;

export const ContainerGraph = styled.div<PropsContainer>`
    width: 100%;
    width: ${({ widthGraph }) => widthGraph};
`;
