import styled from 'styled-components';

export const ModalArea = styled.div`
  max-width: 450px;
  display: flex;
  padding: 26px 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;

  border-radius: 5px;
  background: var(--Conditional-pop-over, #fff);
  box-shadow: 0px 3px 20px -4px rgba(0, 0, 0, 0.07), 0px 6px 20px 0px rgba(0, 0, 0, 0.04),
    0px 9px 40px 8px rgba(0, 0, 0, 0.05);
`;

export const IconTitleArea = styled.div<{ iconPosition: 'left' | 'center' }>`
  display: flex;
  flex-direction: ${({ iconPosition }) => (iconPosition === 'left' ? 'row' : 'column')};
  gap: 16px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`;

export const Title = styled.h2`
  color: var(--character-primary-85, rgba(0, 0, 0, 0.85));

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.36px;
`;

export const ButtonArea = styled.div`
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
`;

export const Button = styled.button`
  display: flex;
  padding: var(--spacing-2, 8px) var(--spacing-3, 12px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1, 4px);
  align-self: stretch;
  border-radius: var(--radius-md, 8px);

  border: none;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.42px;

  cursor: pointer;

  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &.primary {
    color: var(--escala-de-cinza-0, #fff);

    background: var(--Primary-Purple, #5768ff);
  }

  &.secondary {
    color: var(--escala-de-cinza-90, #101010);
    background: var(--Conditional-pop-over, #fff);
  }
`;
