import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import MaskedInput from 'antd-mask-input';
import { FormikValues } from 'formik';
import { Form, Input, Select, Checkbox, Spin } from 'antd';
import { actions as gabaritoActions } from '../../../redux/modules/gabarito';
import { actions as studentActions } from '../../../redux/modules/student';
import ModalAlert from '../../../components/ModalAlert';
import StepsSubscriptionChannels from '../../../pages/gabarito/StepsSubscripionChannels';
import { FormDataProps, HistoryProps, StateProps, UserProps } from './types';
import {
  Container,
  FormWrapper,
  Text,
  TextTwo,
  Items,
  Checkboxes,
  Button,
  IconArrowRight,
  IconLoading,
} from './styles';

interface Props {
  isModular: boolean;
}

const { Item } = Form;

const Register = ({ isModular }: Props) => {
  const [openModalChannels, setOpenModalChannels] = useState(false);
  const [pageMaterial, setPageMaterial] = useState('subscription');
  const [openModalAcceptTheTherms, setOpenModalAcceptTheTherms] = useState(false);
  const [acceptContract, setAcceptContract] = useState(false);
  const [acceptMaterial, setAcceptMaterial] = useState(false);
  const [formData] = useState<FormDataProps>({} as FormDataProps);

  const dispatch = useDispatch();
  const { gabaritos, storeLeadLoading, modules } = useSelector(
    (state: StateProps) => state.gabarito
  );
  const state = useSelector((state: UserProps) => state);
  const { token, user } = state.auth;
  const { channels } = useSelector((state: StateProps) => state.student);

  const history: HistoryProps = useHistory();

  useEffect(() => {
    dispatch(gabaritoActions.fetchAll());
  }, [dispatch]);

  const validationSchema = () => {
    return Yup.object().shape({
      gabaritoId: Yup.number().required('Por favor selecione a prova'),
      ...(!token && { name: Yup.string().required('Por favor informe seu nome') }),
      ...(!token && {
        phone: Yup.string()
          .required('Por favor informe seu telefone')
          .test('completePhone', 'Por favor finalize o telefone', (value) => {
            if (!value) return true;
            return !value.match(/[_]/);
          }),
      }),
      ...(!token && {
        email: Yup.string()
          .required('Por favor informe seu email')
          .email('Por favor informe um email válido'),
      }),
      ...(isModular && { modulesId: Yup.array().required('Por favor selecione os módulos') }),
    });
  };

  const handleChangeExam = (gabaritoId: number) => {
    dispatch(gabaritoActions.getModulesByIdGabarito(gabaritoId));
  };

  const handleNext = (values: FormikValues) => {
    if (acceptMaterial && acceptContract) {
      if (token) {
        const formData = {
          gabaritoId: values.gabaritoId,
          userId: user.id,
          channels: {
            ...channels,
          },
          ...(isModular && { modulesId: values.modulesId }),
        };

        dispatch(
          gabaritoActions.makeTokenExistsStudent(formData, (token: unknown) => {
            if (token) {
              history.push(`/gabaritoro/respostas/${token}`);
            }
          })
        );
      } else {
        const formData = {
          ...values,
          channels: {
            ...channels,
          },
        };

        dispatch(
          gabaritoActions.storeLead(formData as FormDataProps, (payload: any) => {
            if (payload) {
              history.push(`/gabaritoro/respostas/${payload?.token}`);
            }
          })
        );
      }
    } else {
      setOpenModalAcceptTheTherms(true);
    }
  };

  const handleAcceptTheMaterial = () => {
    setAcceptMaterial(!acceptMaterial);
    dispatch(
      studentActions.setChannels({
        phone: !acceptMaterial,
        email: !acceptMaterial,
        whatsapp: !acceptMaterial,
      })
    );
  };

  const handleFinishSelectTheMaterial = () => {
    setOpenModalChannels(false);
    setPageMaterial('subscription');
    setAcceptMaterial(true);
  };

  return (
    <Container>
      <ModalAlert
        isVisible={openModalAcceptTheTherms}
        setVisible={setOpenModalAcceptTheTherms}
        title="Aceite os termos de serviço e a recepção do material para continuar"
      />
      <StepsSubscriptionChannels
        isVisible={openModalChannels}
        setVisible={setOpenModalChannels}
        page={pageMaterial}
        setPage={setPageMaterial}
        onFinish={handleFinishSelectTheMaterial}
      />
      <FormWrapper
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={handleNext}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleSubmit, handleChange, setFieldValue }) => (
          <Form className="form" layout="vertical" onFinish={handleSubmit}>
            {!token && <Text>Não é aluno? Basta preencher os campos abaixo :)</Text>}
            <Items oneColumn={!!token}>
              {!token && (
                <>
                  <Item
                    label="Nome completo"
                    required
                    hasFeedback
                    validateStatus={errors.name && 'error'}
                    help={errors.name}
                  >
                    <Input
                      size="large"
                      name="name"
                      placeholder="Nome completo"
                      className="ipt"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </Item>
                  <Item
                    label="Telefone"
                    required
                    hasFeedback
                    validateStatus={errors.phone && 'error'}
                    help={errors.phone}
                  >
                    <MaskedInput
                      size="large"
                      name="phone"
                      placeholder="(00) 00000-0000"
                      className="ipt"
                      value={values.phone}
                      onChange={handleChange}
                      mask="(11) 11111-1111"
                    />
                  </Item>
                  <Item
                    label="Seu email"
                    required
                    hasFeedback
                    validateStatus={errors.email && 'error'}
                    help={errors.email}
                  >
                    <Input
                      size="large"
                      name="email"
                      className="ipt"
                      placeholder="Seu email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </Item>
                </>
              )}
              <Item
                label="Selecione o tipo de prova"
                required
                hasFeedback
                validateStatus={errors.gabaritoId && 'error'}
                help={errors.gabaritoId}
              >
                <Select
                  size="large"
                  showSearch
                  className="ipt-select"
                  optionFilterProp="children"
                  placeholder="Selecione"
                  value={values.gabaritoId}
                  onChange={(e) => {
                    setFieldValue('gabaritoId', e);
                    handleChangeExam(e);
                    if (isModular) {
                      setFieldValue('modulesId', []);
                    }
                  }}
                >
                  <Select.OptGroup>
                    {gabaritos.map((item) => (
                      <Select.Option
                        key={item.id_gabarito}
                        value={item.id_gabarito}
                        title={item.name}
                      >
                        {item.summary ? item.summary : item.name}
                      </Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </Item>
              {isModular && (
                <Item
                  label="Selecione os módulos"
                  required
                  hasFeedback
                  validateStatus={errors.modulesId && 'error'}
                  help={errors.modulesId}
                >
                  <Select
                    mode="multiple"
                    showSearch
                    size="large"
                    className="ipt-select"
                    optionFilterProp="children"
                    placeholder="Selecione"
                    value={values.modulesId}
                    onChange={(e) => setFieldValue('modulesId', e)}
                  >
                    <Select.OptGroup>
                      {modules.map((module) => (
                        <Select.Option
                          key={module.id_gabarito_module}
                          value={module.id_gabarito_module}
                          title={module.description}
                        >
                          Módulo {module.number}
                        </Select.Option>
                      ))}
                    </Select.OptGroup>
                  </Select>
                </Item>
              )}
            </Items>
            <TextTwo>Esta ferramenta é exclusiva para a certificação CFP®.</TextTwo>
            <Checkboxes>
              <Checkbox
                className="input-contract"
                value={formData.material}
                onChange={handleAcceptTheMaterial}
                checked={acceptMaterial}
              >
                Aceito receber comunicações institucionais e promoções.
              </Checkbox>
              <Checkbox
                className="input-contract"
                value={acceptContract}
                onChange={() => setAcceptContract(!acceptContract)}
                checked={acceptContract}
              >
                Li e aceito a{' '}
                <a
                  href="https://www.academiarafaeltoro.com.br/politica-de-privacidade/"
                  className="btn-accept-the-contract"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  política de privacidade
                </a>
                .
              </Checkbox>
            </Checkboxes>
            {storeLeadLoading ? (
              <Button>
                <Spin indicator={<IconLoading spin />} />
              </Button>
            ) : (
              <Button type="submit">
                Próximo <IconArrowRight />
              </Button>
            )}
          </Form>
        )}
      </FormWrapper>
    </Container>
  );
};

export default Register;
