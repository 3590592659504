import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'antd';
import { ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons';

const { Item } = Form;

export const Title = styled.h3`
    margin-bottom: 0.625em;
    text-align: center;
    line-height: 1.9375em;
    font-weight: 600;
    font-size: 1.5em;
    color: ${({ theme }) => theme.colors.gray_500};
`;

export const FormWrapper = styled(Formik)``;

export const Items = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0 1.875em;
    margin-bottom: 0.625em;
`;

export const ItemStyled = styled(Item)`
    display: flex;

    label {
        line-height: 1.3125em;
        font-weight: 500;
        font-size: 1em;
    }

    input {
        border-color: ${({ theme }) => theme.colors.blue_400};
    }
`;

export const Button = styled.button`
    border: none;
    border-radius: 0.3125em;
    width: 100%;
    height: 2.5em;
    background: linear-gradient(96.64deg, ${({ theme }) => theme.colors.blue_600} 26.19%, ${({ theme }) => theme.colors.blue_400} 102.4%);
    box-shadow: 0 0.125em 0 rgba(0, 0, 0, 0.043);
    cursor: pointer;
    font-size: 1em;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconArrowRight = styled(ArrowRightOutlined)`
    font-size: 0.875em;
    color: ${({ theme }) => theme.colors.white};
`;

export const IconLoading = styled(LoadingOutlined)`
    font-size: 1em;
    color: ${({ theme }) => theme.colors.white};
`;