import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Input, Spin } from 'antd';
import { FormikValues } from 'formik';
import { actions as gabaritoActions } from '../../../redux/modules/gabarito';
import { actions as authActions } from '../../../redux/modules/auth';
import Layout from '../../../components/Gabarito/LayoutTwo';
import { FormDataProps, StateProps, PayloadProps } from './types';
import {
  Title,
  FormWrapper,
  Items,
  ItemStyled,
  Button,
  IconArrowRight,
  IconLoading,
} from './styles';

const Login = () => {
  const [formData] = useState<FormDataProps>({} as FormDataProps);

  const dispatch = useDispatch();
  const state = useSelector((state: StateProps) => state);
  const { isLoading } = state.auth;

  const history = useHistory();

  useEffect(() => {
    dispatch(gabaritoActions.fetchAll());
  }, [dispatch]);

  const validationSchema = () => {
    return Yup.object().shape({
      password: Yup.string().required('Por favor informe sua senha'),
      email: Yup.string()
        .required('Por favor informe seu email')
        .email('Por favor informe um email válido'),
    });
  };

  const handleNext = (values: FormikValues) => {
    dispatch(
      authActions.login(values.email, values.password, false, (_payload: unknown) => {
        const payload = _payload as PayloadProps;
        if (payload && payload.id) {
          history.push(`/gabaritoro/create`);
        }
      })
    );
  };

  return (
    <Layout>
      <Title>Faça login :)</Title>
      <FormWrapper
        initialValues={formData}
        enableReinitialize={true}
        onSubmit={handleNext}
        validationSchema={validationSchema}
      >
        {({ values, errors, handleSubmit, handleChange, setFieldValue }) => (
          <Form layout="vertical" onFinish={handleSubmit}>
            <Items>
              <ItemStyled
                label="Email"
                required
                hasFeedback
                validateStatus={errors.email && 'error'}
                help={errors.email}
              >
                <Input
                  size="large"
                  name="email"
                  className="ipt"
                  placeholder="Seu email"
                  value={values.email}
                  onChange={handleChange}
                />
              </ItemStyled>
              <ItemStyled
                label="Password"
                required
                hasFeedback
                validateStatus={errors.password && 'error'}
                help={errors.password}
              >
                <Input
                  type="password"
                  size="large"
                  name="password"
                  className="ipt"
                  placeholder="Seu password"
                  value={values.password}
                  onChange={handleChange}
                />
              </ItemStyled>
            </Items>
            {isLoading ? (
              <Button>
                <Spin indicator={<IconLoading spin />} />
              </Button>
            ) : (
              <Button type="submit">
                Próximo <IconArrowRight />
              </Button>
            )}
          </Form>
        )}
      </FormWrapper>
    </Layout>
  );
};

export default Login;
