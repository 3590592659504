import React from 'react';

import { Container } from './styles';

type Props = {
    percent: number;
};

export default function GraphBar ({ percent }: Props) {
    return (
        <>
        <Container
            percent={percent}
        >
            <span>{percent}%</span>

        </Container>
        </>
    )
};
