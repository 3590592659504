import React, { useContext } from 'react';

//
// general components
import ClassSteps from './ClassSteps';
import { checkStepFinished } from '../../../../helpers';

//
// context
import { CourseContext } from '../../ClassContent/CourseDetail.container';

import  {
  Aside,
  TitleAside,
  ItemStep
} from './stylesClassSidebar'

import IconBook from '../../../../img/svg/iconBookStep';
import OneIconBook from '../../../../img/svg/oneIconBook';
import IconBookRight from '../../../../img/svg/iconBookStepRight';
import OneIconBookStepRight from '../../../../img/svg/oneIconBookStepRight';

import ArrowRightLilas from '../../../../img/svg/arrowRight.svg';
import { isEmpty } from 'lodash';

function scrollBottom (next = null) {
  window.scrollTo({
    top: window.innerHeight,
    behavior: 'smooth'
  });

  next && next();
};

function ClassSidebar() {
  const { setClassStep, selectedClass, setAutomaticDownloadPDF } = useContext(CourseContext);

  const classSteps = ClassSteps();

  function handleStep (step) {
    if (step === 'file') {
      scrollBottom(() => {
        setAutomaticDownloadPDF(true);
        setClassStep(step);
      });
    } else {
      setClassStep(step);
    }
  };

  const stepLength = classSteps.length-1;

  const getIconDesktop = (last, selectedClass, item, isHover) => {
    const finishedStep = checkStepFinished(selectedClass, item.step);

    if(finishedStep && last) return  item.lastDoneIconDesktop;
    if(finishedStep) return item.iconDoneDesktop;
    if(last) return isHover ? item.lastHoverIconDesktop : item.lastIconDesktop;
    return isHover ? item.iconHoverDesktop : item.iconDesktop;
  }

  const getIconMobile = (last, selectedClass, item) => {
    const finishedStep = checkStepFinished(selectedClass, item.step);

    if(finishedStep && last) return  item.lastIconDoneMobile;
    if(finishedStep) return item.iconDoneMobile;
    if(last) return item.lastIconMobile;
    return item.iconMobile;
  }

  return (
    <Aside className='aside'>
      <h3>Realize as etapas: </h3>

      <ItemStep key={0}  onClick={() => setClassStep('intro')}>
        <div className='icon-desktop first-icon'>
          {isEmpty(classSteps) ? (<OneIconBook width={'66px'}/>): (<IconBook width={'66px'}/>)}
        </div>
        <div className='icon-mobile'>
          {isEmpty(classSteps) ? (<OneIconBookStepRight width={'66px'}/>): (<IconBookRight width={'66px'}/>)}
        </div>
        <TitleAside clasName="title-aside">Introdução</TitleAside>
      </ItemStep>

      {classSteps.map((item, i) => {
        const finishedStep = checkStepFinished(selectedClass, item.step);
        return (
          <ItemStep
            className='item-step'
            key={i}
            onClick={() => handleStep(item.step)}
          >
            <div className={`icon-desktop ${finishedStep ? 'icons-done' : 'icons'}`}>
              <div className={finishedStep ? 'icon-item-done' : 'icon-item'}>
                {getIconDesktop(stepLength === i, selectedClass, item, false)}
              </div>
              <div className='icon-item-hover'>
                {getIconDesktop(stepLength === i, selectedClass, item, true)}
              </div>

              <TitleAside clasName="title-aside">{item.title}</TitleAside>
              <img src={ArrowRightLilas} alt="icon arrow" className='img-arrow' />
            </div>

            <div className='icon-mobile'>
              {getIconMobile(stepLength === i, selectedClass, item)}
              <TitleAside clasName="title-aside">{item.title}</TitleAside>
            </div>
          </ItemStep>
        );
      })}
    </Aside>
  );
}

export default ClassSidebar;
