import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// Constants
export const constants = {
  CLASS_GET_LECTURE_DETAILS: createSagaAction('CLASS_GET_LECTURE_DETAILS'),
  CLASS_PERSIST_ONGOING_LECTURE: createSagaAction('CLASS_PERSIST_ONGOING_LECTURE'),
  CLASS_GET_ONGOING_LECTURE: createSagaAction('CLASS_GET_ONGOING_LECTURE'),
  CLASS_FINISH_LECTURE: createSagaAction('CLASS_FINISH_LECTURE'),
  CLASS_PDF_DOWNLOAD: createSagaAction('CLASS_PDF_DOWNLOAD'),
  CLASS_VIDEO_PLAY: createSagaAction('CLASS_VIDEO_PLAY'),
  CLASS_GET_FINISHED_LECTURES: createSagaAction('CLASS_GET_FINISHED_LECTURES'),
  CLASS_GET_LECTURE_WRONG_QUESTIONS: createSagaAction('CLASS_GET_LECTURE_WRONG_QUESTIONS'),
  CLASS_GET_FINISHED_MOCKS: createSagaAction('CLASS_GET_FINISHED_MOCKS'),
}

export const actions = {
  getLectureDetails: (classId, next) => {
    return {
      type: constants.CLASS_GET_LECTURE_DETAILS.ACTION,
      classId,
      next
    }
  },
  persistOngoingLecture: (ongoingPayload, userId, classId, next) => {
    return {
      type: constants.CLASS_PERSIST_ONGOING_LECTURE.ACTION,
      ongoingPayload,
      userId,
      classId,
      next
    }
  },
  getOngoingLecture: (userId, classId, courseTag, next) => {
    return {
      type: constants.CLASS_GET_ONGOING_LECTURE.ACTION,
      userId,
      classId,
      courseTag,
      next
    }
  },
  finishLecture: (finalPayload, userId, classId, next) => {
    return {
      type: constants.CLASS_FINISH_LECTURE.ACTION,
      finalPayload,
      userId,
      classId,
      next
    }
  },
  registerPdfDownload: (userId, courseTag, classId, next) => {
    return {
      type: constants.CLASS_PDF_DOWNLOAD.ACTION,
      userId,
      courseTag,
      classId,
      next
    }
  },
  registerVideoPlay: (userId, courseTag, classId, videoId, next) => {
    return {
      type: constants.CLASS_VIDEO_PLAY.ACTION,
      userId,
      courseTag,
      classId,
      videoId,
      next
    }
  },
  getFinishedLectures: (classId, next) => {
    return {
      type: constants.CLASS_GET_FINISHED_LECTURES.ACTION,
      classId,
      next
    }
  },
  getLectureWrongQuestions: (lectureId, next) => {
    return {
      type: constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.ACTION,
      lectureId,
      next
    }
  },
  getFinishedMocks: (mocks, next) => {
    return {
      type: constants.CLASS_GET_FINISHED_MOCKS.ACTION,
      mocks,
      next
    }
  },
}

const ACTION_HANDLERS = {
  // CLASS_GET_LECTURE_DETAILS.ACTION
  [constants.CLASS_GET_LECTURE_DETAILS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_GET_LECTURE_DETAILS.SUCCESS]: (state, action) => {
    return { ...state, studentQuestions: action.payload.questions, isLoading: false }
  },
  [constants.CLASS_GET_LECTURE_DETAILS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // CLASS_PERSIST_ONGOING_LECTURE.ACTION
  [constants.CLASS_PERSIST_ONGOING_LECTURE.ACTION]: (state) => {
    return { ...state, error: null, isLoadingOngoing: true }
  },
  [constants.CLASS_PERSIST_ONGOING_LECTURE.SUCCESS]: (state) => {
    return { ...state, isLoadingOngoing: false }
  },
  [constants.CLASS_PERSIST_ONGOING_LECTURE.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoadingOngoing: false }
  },

  // CLASS_GET_ONGOING_LECTURE.ACTION
  [constants.CLASS_GET_ONGOING_LECTURE.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_GET_ONGOING_LECTURE.SUCCESS]: (state, action) => {
    return { ...state, ongoingLecture: action.ongoingLecture, isLoading: false }
  },
  [constants.CLASS_GET_ONGOING_LECTURE.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // CLASS_FINISH_LECTURE.ACTION
  [constants.CLASS_FINISH_LECTURE.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_FINISH_LECTURE.SUCCESS]: (state, action) => {
    return { ...state, lectureResult: action.lectureResult, isLoading: false }
  },
  [constants.CLASS_FINISH_LECTURE.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // CLASS_PDF_DOWNLOAD.ACTION
  [constants.CLASS_PDF_DOWNLOAD.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_PDF_DOWNLOAD.SUCCESS]: (state) => {
    return { ...state, isLoading: false }
  },
  [constants.CLASS_PDF_DOWNLOAD.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // CLASS_GET_FINISHED_LECTURES.ACTION
  [constants.CLASS_GET_FINISHED_LECTURES.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_GET_FINISHED_LECTURES.SUCCESS]: (state, action) => {
    return { ...state, finishedLectures: action.payload, isLoading: false }
  },
  [constants.CLASS_GET_FINISHED_LECTURES.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // CLASS_GET_LECTURE_WRONG_QUESTIONS.ACTION
  [constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.SUCCESS]: (state, action) => {
    return {
      ...state,
      lectureWrongQuestions: action.wrongQuestions,
      totalQuestions: action.totalQuestions,
      isLoading: false,
      allAnsweredQuestions: action.allQuestions
    }
  },
  [constants.CLASS_GET_LECTURE_WRONG_QUESTIONS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // CLASS_GET_FINISHED_MOCKS.ACTION
  [constants.CLASS_GET_FINISHED_MOCKS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.CLASS_GET_FINISHED_MOCKS.SUCCESS]: (state, action) => {
    return { ...state, mockStructure: action.mockStructure, isLoading: false }
  },
  [constants.CLASS_GET_FINISHED_MOCKS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },
}

export const initialState = {
  studentQuestions: [],
  lectureWrongQuestions: [],
  mockStructure: []
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : { ...state, isLoading: false }
})
