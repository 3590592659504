import React from 'react';

import {
    ChartBarResume,
    GraphBarResult,
    GraphBarRecomended,
    GraphBarResultP,

} from './styles'

type Props = {
    result?: any;
    recomended?: number | any;
    margin?: number;
    displayRecomended?: any;
}

const ChartBarTotal = ({ result, recomended, margin, displayRecomended } : Props) => {


    return (
        <ChartBarResume>
            <GraphBarResult result={result}>
                <GraphBarResultP margin={margin}> {result? result: 0}% </GraphBarResultP>
            </GraphBarResult>
            <GraphBarRecomended
                displayRecomended={displayRecomended}
                recomended={recomended}><p>{recomended}%</p>
            </GraphBarRecomended>
        </ChartBarResume>
    );
};

export default ChartBarTotal;
