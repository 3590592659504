import React, { useState } from 'react';
import { Select, notification } from 'antd';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from '../../Button';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';
import request, { makeURLToken } from '../../../shared/request';

import { Container } from './styles';

export type ParamProps = {
    token: string;
    exam: string;
};

const PreGabarito = () => {
    const [saveLoading, setSaveLoading] = useState(false);
    const [validating, setValidating] = useState(false);

    const [gabaritoId, setGabaritoId] = useState<number | undefined>(undefined);
    const history = useHistory();
    const { gabaritos, gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito);

    const handleSave = () => {
        setValidating(false);
        if (!gabaritoId) {
            return setValidating(true);
        };

        setSaveLoading(true);
        request('/v1/gabaritoro/update-gabarito-student', {
            method: 'POST',
            body: JSON.stringify({
                id_gabarito: gabaritoId,
                id_student: gabarito.id_usuario,
                old_id_gabarito: gabarito.id_gabarito
            }) 
        }, {'x-api-key': makeURLToken()}).then(resp => {
            setSaveLoading(false);
            history.push(`/gabaritoro/respostas/${resp}/1`);
        }).catch(err => {
            setSaveLoading(false);
            
            notification.error({
                message: 'Houve um erro inesperado'
            });
        })
    }

    return (
        <Container>
            <div className="box">
                <label>
                    <b>Escolha uma prova </b>
                </label>
                <Select
                    showSearch
                    size="large"
                    className="ipt-select"
                    optionFilterProp="children"
                    placeholder="Selecione"
                    value={gabaritoId}
                    onChange={setGabaritoId}
                >
                    <Select.OptGroup>
                       {
                            gabaritos.map(item => (
                                <Select.Option key={item.id_gabarito} value={item.id_gabarito} title={item.name}>
                                    {item.summary ? item.summary : item.name}
                                </Select.Option>
                            ))
                        }
                    </Select.OptGroup>
                </Select>
                {
                    validating && (
                        <span className="error">Selecione uma prova</span>
                    )
                }
                <br/>
                <Button  
                    onClick={handleSave} 
                    layout='warning' 
                    disabled={saveLoading} 
                    isLoading={saveLoading}
                >
                    Salvar
                </Button>
            </div>
        </Container>
    );
};

export default PreGabarito;