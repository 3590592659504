import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { actions as podcastActions, PodcastState } from '../../../redux/modules/podcast';

import AudioPlayer from '../AudioPlayer';
import { ReactComponent as CloseSVG } from '../../../img/podcast/Close.svg';
import { ReactComponent as FullSVG } from '../../../img/podcast/Full.svg';

import { formattedBigText } from '../../../helpers/renderFormatted';
import useDocumentSize from '../../../hooks/useDocumentSize';

import { Container } from './styles';

export default function GlobalPlayer () {    
    const { currentPodcast } = useSelector((state: { podcast: PodcastState }) => state.podcast);
    const dispatch = useDispatch();
    const history = useHistory();
    const size = useDocumentSize();
    

    function handleClosePodcast () {
        dispatch(podcastActions.closePlayerFooter());
    };

    function handleOpenPodcast () {
        history.push('/podcast');
    };

    const title = formattedBigText(currentPodcast.title);

    return (
        <Container>
            <div className="description">
                <span className="topic">
                    {title} ({currentPodcast.classDescription})
                </span>
                <span className="subtopic">
                    {currentPodcast.professor}
                </span>
            </div>

            {
                size.width > 728 ? (
                    <>
                        <AudioPlayer controls={size.width > 728} />
                        <div className="controls-actions">
                            <CloseSVG onClick={handleClosePodcast}/>
                            <FullSVG onClick={handleOpenPodcast}/>
                        </div>
                    </>
                ) : (
                    <div className="actions">
                        <AudioPlayer controls={size.width > 728} />
                        <div className="controls-actions">
                            <CloseSVG onClick={handleClosePodcast}/>
                            <FullSVG onClick={handleOpenPodcast}/>
                        </div>
                    </div>
                )
            }
        </Container>
    );
};