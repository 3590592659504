import styled from 'styled-components';

export const IntroClassContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ContentItems = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 185px 13px;
  margin-top: 225px;

  &.social-network {
    margin-top: 70px;
    gap: 70px 13px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const Items = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 90px 30px 30px;
  gap: 5px;

  position: relative;

  background: #efefef;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 10px;

  font-family: 'Work Sans';
  font-style: normal;

  p {
    font-weight: 600;
    font-size: 18px;

    color: #252525;

    &.booksbiblies {
      margin-top: 25px;
    }
  }

  span {
    font-weight: 400;
    font-size: 16px;
    height: 12px;

    color: #252525;

    margin-bottom: 15px;
  }

  &.social-network {
    padding-top: 60px;
  }
`;

interface ItemsHeaderProps {
  background: string;
}

const ItemsHeaderBase = styled.div<ItemsHeaderProps>`
  position: absolute;

  background: url(${({ background }) => background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  box-shadow: 15px 10px 64px -10px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
`;

export const ItemsHeader = styled(ItemsHeaderBase)`
  width: 150px;
  height: 210px;

  left: 30px;
  top: -145px;

  > p.books {
    width: fit-content;

    position: absolute;
    top: 40px;
    left: 8px;

    font-size: 22px;
  }

  > p.bible {
    width: 100%;

    position: absolute;
    top: 80px;
    left: 0;

    font-size: 30px;
    text-align: center;

    color: #f6e3aa;
  }
`;

export const ItemsHeaderSocial = styled(ItemsHeaderBase)`
  width: 85px;
  height: 75px;

  left: 30px;
  top: -35px;
`;

export const Button = styled.a`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  width: 100%;
  height: 40px;

  background: #5768ff;
  border: 1px solid #5768ff;

  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 5px;

  cursor: pointer;

  margin-top: 15px;

  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #ffffff;

  transition: filter 0.5s;

  &:hover {
    color: #ffffff;
    filter: brightness(0.8);
  }

  > span {
    margin: 0;

    svg {
      color: #fff;
      font-size: 11px;
    }
  }
`;
