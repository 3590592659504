import {
    ICheckoutStart,
    ICheckoutAddress,
    ICheckoutPayment,
    ICheckoutConfirmPayment,
    InsertType,
    DataLayerType
} from './types';

class CheckoutG4 {

    private window?: DataLayerType;

    constructor(window?: DataLayerType) {
        this.window = window;
    }

    clear () {
        if (this.window?.dataLayer) {
            try {
                this.window.dataLayer.push({ ecommerce: null });
            } catch (e) {
                // avoiding any crash
            }
        }
    }
    insert(data: InsertType) {
        if (this.window?.dataLayer) {
            try {
                this.window.dataLayer.push(data);
            } catch (e) {
                // avoiding any crash
            }
        }
    }
    start (data: ICheckoutStart) {
        this.clear();
        Object.assign(data, {
            event: 'begin_checkout'
        }); 
        this.insert(data);
    }
    address (data: ICheckoutAddress) {
        Object.assign(data, {
            event: 'add_adress_info'
        });    
        this.insert(data);
    }
    payment (data: ICheckoutPayment) {
        Object.assign(data, {
            event: 'add_payment_info'
        }); 
        this.insert(data);
    }
    confirmPayment (data: ICheckoutConfirmPayment, status = 'DENIED') {
        if (status === 'APPROVED') {
            if (data?.ecommerce?.items[0]?.index) {
                delete data.ecommerce.items[0].index;
            }
            
            Object.assign(data, {
                event: 'purchase'
            });

            this.insert(data);
        }
    }
}

export default CheckoutG4;