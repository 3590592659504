import * as React from 'react';

import {
  Button,
  ContentItems,
  IntroClassContainer,
  Items,
  ItemsHeader,
  ItemsHeaderSocial,
} from './styles';

import whatsapp from './imgs/socialnetwork/whatsapp.svg';
import telegram from './imgs/socialnetwork/telegram.svg';
import calendar from './imgs/socialnetwork/calendar.svg';

import handout from './imgs/handoutbible/handout.png';
import bible from './imgs/handoutbible/bible.png';

import { ArrowRightOutlined } from '@ant-design/icons';

import { BooksBiblesImagesArrayProps, SocialNetworksArrayProps } from './utils';

const socialNetworks: SocialNetworksArrayProps = {
  1: {
    title: 'Whatsapp',
    image: whatsapp,
  },
  2: {
    title: 'Telegram',
    image: telegram,
  },
  3: {
    title: 'Calendário',
    image: calendar,
  },
};

const booksBibles: BooksBiblesImagesArrayProps = {
  1: {
    title: 'Apostila',
    image: handout,
  },
  2: {
    title: 'Bíblia',
    image: bible,
  },
};

export function IntroClassesArea({ selectedClass, courseCategory }: any) {
  const DOWNLOAD_URL = 'https://s3.sa-east-1.amazonaws.com/arquivos.academiarafaeltoro.com.br/';
  const { books, grupo_sociais, texto } = selectedClass;

  function renderBooksBibles() {
    return books?.map(({ pdf, id_tipo_books, title }: any, index: number) => (
      <Items className="social-network" key={index}>
        <ItemsHeader background={booksBibles[id_tipo_books]?.image}>
          <p className={id_tipo_books === 1 ? 'books' : 'bible'}>{courseCategory}</p>
        </ItemsHeader>
        <p className="booksbiblies">{booksBibles[id_tipo_books]?.title}</p>
        <span>{title}</span>
        <Button
          target="_blank"
          href={DOWNLOAD_URL + pdf}
          download={true}
          data-testid="button-books"
        >
          {`Baixar ${booksBibles[id_tipo_books]?.title}`}
          <ArrowRightOutlined />
        </Button>
      </Items>
    ));
  }

  function renderItemsSocialNetworkArea() {
    return grupo_sociais?.map(({ link, pdf, id_tipo_grupos }: any, index: number) => (
      <Items className="social-network" key={index}>
        <ItemsHeaderSocial background={socialNetworks[id_tipo_grupos]?.image} />
        <p>{socialNetworks[id_tipo_grupos]?.title}</p>
        <Button
          href={id_tipo_grupos !== 3 ? link : DOWNLOAD_URL + pdf}
          target="_blank"
          download={id_tipo_grupos !== 3 ? false : true}
          data-testid="button-social-network"
        >
          {socialNetworks[id_tipo_grupos]?.title === 'Calendário' ? 'Baixar' : 'Entrar no Grupo'}
          <ArrowRightOutlined />
        </Button>
      </Items>
    ));
  }

  return (
    <IntroClassContainer>
      <div dangerouslySetInnerHTML={{ __html: texto }} />
      <ContentItems className="social-network">{renderItemsSocialNetworkArea()}</ContentItems>
      <ContentItems>{renderBooksBibles()}</ContentItems>
    </IntroClassContainer>
  );
}
