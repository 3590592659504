import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Col, Divider, Typography, Popover, Spin } from 'antd';
import PropTypes from 'prop-types';

import MentorshipRequest from './MentorshipRequest';
import OngoingMentorship from './MentorshipOngoing';
import MentorshipRealized from './MentorshipRealized';

import { actions as mentorshipActions } from '../../redux/modules/mentorship';

const propTypes = {
  setMentorshipModal: PropTypes.func.isRequired,
};

function Mentroship({ setMentorshipModal }) {
  const [visible, setVisible] = useState(true);
  const [studentMentorship, setStudentMentorship] = useState([]);
  const [showMentorshipRequestModal, setMentorshipRequestModal] = useState(false);
  const [showMentorshipOngoingModal, setMentorshipOngoingtModal] = useState(false);
  const [showMentorshipRealizedModal, setMentorshipRealizedModal] = useState(false);

  const { isLoading, mentorships } = useSelector((state) => state.mentorship);
  const dispatch = useDispatch();

  const alreadyHasMentorship = mentorships.mentoriaaluno;

  useEffect(() => {
    dispatch(mentorshipActions.getMyMentorships());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setStudentMentorship(alreadyHasMentorship);
    //eslint-disable-next-line
  }, [alreadyHasMentorship]);

  const { Title } = Typography;
  //precisa haver o onCancel para que o closable da modal funcione
  const handleCancel = () => {
    setVisible(false);
    setMentorshipModal(false);
  };

  const content = (
    <div>
      <p>Você já possui uma mentoria</p>
      <p>Verificar nas mentorias em andamento</p>
    </div>
  );

  return (
    <>
      <Modal
        width={500}
        footer={false}
        closable={true}
        visible={visible}
        maskClosable={true}
        style={{ top: 30 }}
        destroyOnClose={true}
        onCancel={() => handleCancel()}
      >
        <div className="row-modal-height">
          <Col className="button-title-mentorship">
            <p>Mentorias</p>
            <br />
          </Col>
          {isLoading ? (
            <Spin />
          ) : studentMentorship?.length !== 0 ? (
            <Popover content={content}>
              <span>
                <Button disabled block className="m-bottom-20">
                  SOLICITAR MENTORIA
                </Button>
              </span>
            </Popover>
          ) : (
            <span>
              <Button
                block
                type="primary"
                className="m-bottom-20"
                onClick={() => setMentorshipRequestModal(true)}
              >
                SOLICITAR MENTORIA
              </Button>
            </span>
          )}
          <Button block className="m-bottom-20" onClick={() => setMentorshipOngoingtModal(true)}>
            SOLICITAÇÕES EM ANDAMENTO
          </Button>
          <Button block className="m-bottom-20" onClick={() => setMentorshipRealizedModal(true)}>
            MENTORIAS REALIZADAS
          </Button>
          <Divider />
          <Title level={5}>Você tem direito a uma mentoria de cortesia!</Title>
          <p>
            Basta clicar acima em Solicitar Mentoria, escolher a aula, hora e data em que você quer
            estudar, e pronto! Um professor vai entrar em contato com você. É grátis
          </p>
          <br />
          <Title level={5}>Escolha bem!</Title>
          <p>
            Neste momento, estamos limitanto a uma mentoria por aluno, então aproveite para escolher
            aquela aula que você mais precisa de uma ajudinha.
          </p>
          <br />
          <Title level={5}>Mas atenção:</Title>
          <p>
            Se precisar cancelar ou reagendar sua mentoria, você deve fazer isso com 24h de
            antecedência ou vai perder sua chance.
          </p>
        </div>
      </Modal>
      {showMentorshipRequestModal && (
        <MentorshipRequest setMentorshipRequestModal={setMentorshipRequestModal} />
      )}
      {showMentorshipOngoingModal && (
        <OngoingMentorship setMentorshipOngoingtModal={setMentorshipOngoingtModal} />
      )}
      {showMentorshipRealizedModal && (
        <MentorshipRealized setMentorshipRealizedModal={setMentorshipRealizedModal} />
      )}
    </>
  );
}

Mentroship.propTypes = propTypes;

export default Mentroship;
