export function moneyBR(value: number) {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(value);
};

export function dateBR(date: string) {
    const fixDate = date.trim().replace(' ', 'T');
    return new Date(fixDate).toLocaleDateString('pt-BR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    });
};


export function dateMonthDayBR(date: string) {
    const fixDate = date.trim().replace(' ', 'T');
    return new Date(fixDate).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: 'short',
    });
};

export function getNameAndLastName(name?: string): Array<string> {
    if (!name) return ['', ''];

    const [firstName, ...rest] = name.trim().split(' ')

    return [firstName, rest.join(' ')];
};

export const getPercentFormatted = (total?: number) => {
    if (!total) return 0;
    if ((total * 100) > 100) return 100;

    const formatted = (total * 100).toFixed(1);

    if (isNaN(parseFloat(formatted))) return 0;

    return parseFloat(formatted);
};
