import React from 'react';
import Icon from '@ant-design/icons';

function StudentFilledIcon(props) {
  const studentFilledIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="school_24px">
        <path
          id="icon/social/school_24px"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3L1 9L5 11.18V17.18L12 21L19 17.18V11.18L21 10.09V17H23V9L12 3ZM18.82 9L12 12.72L5.18 9L12 5.28L18.82 9ZM12 18.72L17 15.99V12.27L12 15L7 12.27V15.99L12 18.72Z"
          fill="currentColor"
          fillOpacity="0.54"
        />
      </g>
    </svg>
  );

  return <Icon component={studentFilledIcon} {...props} />;
}

export default StudentFilledIcon;
