import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 35px 10px;

  @media(max-width: ${({theme}) => theme.media.tablet}px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const ContainerHeader = styled.div`

  .header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: calc(100% + 38px);
    padding: 25px;
    margin-left: -19px;
    margin-top: -19px;
    svg {
      margin-right: 10px;
    };
    font-weight: bold;
    .ant-breadcrumb-link {
      font-size: 17px;
    }
  }
  .header-title-course {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .expire {
    margin-left: 20px;
  }
  .expire p, .expire strong {
    font-size: 14px !important;
  }

  @media only screen and (min-width: 769px) {
    .color-icon-light {
      display: flex;
      align-items: center;
    }
    .color-icon-dark {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    .header-title {
      align-items: baseline;
      flex-direction: column;
      background-color: #fff0;
      padding-bottom: 0;
    }
    .expire {
      margin: 15px 0 !important;
    }
    .color-icon-light {
      display: none;
    }
    .color-icon-dark {
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 720px) {
    margin-top: 70px;
  }
`;
export const MyPerformanceButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px 16px;

    font-weight: 600;
    font-size: 18px;
    color: #5768FF;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.016));
    border: 1px solid #5768FF;
    transition: 0.3s;
    img {
      display: none;
    }

    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
      padding: 4px 10px;
      gap: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;

      img {
        display: block;
      }
    }

    &:hover {
      border: 1px solid #3F4CBD;
      color: #3F4CBD;
      transition: 0.3s;
    }
`;

export const PerformanceModal = styled(Modal)`
    width: 600px !important;
    .box-calendar {
        padding: 25px;
    }
`;

export const TitleHeader = styled.div`
  p, span {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #858585;
  }
  span {
    font-weight: 500;
    color: #5768FF;
  }
  @media only screen and (max-width: 768px) {
    p, span {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #000000;
    }
    span {
    font-weight: 500;
    color: #5768FF;
  }
  }
`;
