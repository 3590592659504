import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spin } from 'antd';

import { EditFilled, EyeOutlined } from '@ant-design/icons';
import { CashbackStateType, actions as cashbackActions } from '../../../redux/modules/cashback';
import { dateMonthDayBR, moneyBR } from '../../../shared/utils/common';

import { ITransaction } from '../../../shared/Models/cashback/ITransactions';
import './styles.scss'

export default function Statament () {
    const { 
        statements, 
        isLoading,
        coupon 
    } = useSelector((state: { cashback: CashbackStateType }) => state.cashback);
    const dispatch = useDispatch();

    const formattedStatements = statements.map(item => {
        let description = item.details.replace('%value%', moneyBR(Math.abs(item.value)));
        if (item?.order?.curso) {
            description = description.replace('%course%', item.order.curso);
        }
        if (item?.coupon?.cupom) {
            description = description.replace('%coupon%', item.coupon.cupom);
        }

        const used = !!item.coupon?.orders || !!item.coupon?.deleted_at;

        return {
            ...item,
            formattedCreatedAt: dateMonthDayBR(item.created_at).toUpperCase(),
            description,
            editable: item.coupon?.id_transaction_coupon,
            used
        };
    });

    function handleSelectStatement (item: ITransaction) {
        if (item.coupon?.id_transaction_coupon) {
            dispatch(cashbackActions.fetchTransactionById(item.coupon.id_transaction_coupon));
        }
    };

    return (
        <div className="box-statement">
            {
                isLoading ? (
                    <div className="box-extract-loading">
                        <Spin />
                    </div>
                ) : !formattedStatements?.length ? (
                    <div className="box-extract-empty">
                        <span>Nenhuma transação encontrada.</span>
                    </div>
                ) : (
                    <div className="box-extract">
                        {
                            formattedStatements?.map(item => (
                                <div key={item.id_student_transaction} className="box-extract__out-item">
                                    {
                                        item.editable && (
                                            <button 
                                                onClick={() => handleSelectStatement(item)}
                                                className={`box-extract__button ${(item.coupon?.id_transaction_coupon === coupon?.id_transaction_coupon) && coupon?.loaded ? 'box-extract__button-edit' : ''}`}>
                                                {
                                                    (item.editable && item.used) ? (
                                                        <EyeOutlined />
                                                    ) : (
                                                        <EditFilled />
                                                    )
                                                }
                                            </button>
                                        )
                                    }
                                    <span className="box-extract__item-point"></span>
                                    <div className="box-extract__item" key={item.id_student_transaction}>
                                        <div>
                                            <span className="item-date">{item.formattedCreatedAt}</span>
                                            <span className="item-description">
                                                {item.description}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};