import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconPenLastStepDone: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.5 0H34.5V15H31.5V0Z" fill="#5768FF" />
      <rect y="15" width="66" height="66" rx="33" fill="#5768FF" />
      <path d="M23.7673 63.4002L48.4173 38.7502L42.2507 32.5835L17.6006 57.2335L17.584 63.4168L23.7673 63.4002Z" stroke={color || 'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M38.584 38.2168L42.784 42.4168" stroke={color || 'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconPenLastStepDone.defaultProps = defaultProps;

export default IconPenLastStepDone;
