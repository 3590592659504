import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import copy from 'copy-to-clipboard';
import { Spin } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import Confirm from '../../../components/ModalConfirm';

import { actions as cashbackActions, CashbackStateType } from '../../../redux/modules/cashback';

import { moneyBR } from '../../../shared/utils/common';
import './styles.scss';

type FormType = {
    value: string;
};

export default function FormCoupon() {
    const [form] = useState<FormType>({
        value: ''
    });
    const [confirm, setConfirm] = useState(false);

    const dispatch = useDispatch();
    
    const { 
        isCreatingCoupon, 
        isCancelingCoupon,
        coupon,
        error,
        createError 
    } = useSelector((state: { cashback: CashbackStateType }) => state.cashback);

    function validationSchema() {
        return Yup.object().shape({
            value: Yup.string()
                .test('money', 'O campo deve conter apenas números', (value) => {
                    
                    if (value) {
                        const match = value.match(/[\D]/gi)?.length ?? 0;
                        return !(match > 0);
                    }

                    return true;
                }),
        });
    };

    function handleChange(
        event: { target: { value: string } }, 
        setFieldValue: (field: string, value: string) => void 
    ) {
        const formattedValue = `${event.target.value}`
            .replace('R$ ', '')
            .trim();
            
        setFieldValue('value', formattedValue);
    };

    function handleCopyCoupon() {
        if (coupon?.cupom) {
            copy(coupon?.cupom);
        }
    };

    function handleSubmit(formData: FormType, { resetForm }: {
        resetForm: () => void
    }) { 
        if (formData.value.trim() && !isCreatingCoupon) {
            dispatch(cashbackActions.createCupom(formData, (payload) => {
                if (payload.type === 'SUCCESS') {
                    dispatch(cashbackActions.fetchTransactions()); 
                    
                }

                resetForm();
            }));
        }
    };

    function handleCancelCoupon(e: {
        preventDefault: () => void;
    }) {
        e.preventDefault();
        setConfirm(true);
    };

    function handleConfirmCancel() {
        if (!isCancelingCoupon) {
            setConfirm(false); 
            if (coupon?.id_desconto) {
                dispatch(cashbackActions.cancelCoupon(coupon?.id_desconto, () => {
                    dispatch(cashbackActions.fetchTransactions());
                }));
            }
        }
    };

    function handleNewTransaction() {
        dispatch(cashbackActions.newCoupon());
    };

    return (
        <>
            {
                confirm && (
                    <Confirm 
                        title={`Deseja realmente cancelar o cupom ${coupon?.cupom} ?`}
                        isVisible={confirm}
                        setVisible={setConfirm}
                        onConfirm={handleConfirmCancel}
                    />
                )
            }

            <section className="box-form">
                <div className="box-form__title">
                    <h2>Gerar desconto</h2>
                    {
                        !!coupon && (
                            <button onClick={handleNewTransaction}>
                                <PlusOutlined />
                                Criar cupom
                            </button>
                        )
                    }
                </div>

                <div className="box-value">
                    {
                        coupon?.loaded ? (
                            <span className="form-label">Valor gerado anteriormente</span>
                        ) : (
                            <span className="form-label">Quanto deseja resgatar ?</span>
                        )   
                    }
                    <Formik
                        initialValues={form}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={handleSubmit}>
                        {({ handleSubmit, setFieldValue, values, errors }) => {
                            return (
                                <>
                                    <div className="form-actions">
                                        {
                                            coupon?.loaded ? (
                                                <input 
                                                    value={moneyBR(coupon.valor_cupom)}
                                                    disabled
                                                    className="input-disabled"
                                                    alt="input-disabled"
                                                />
                                            ) : (
                                                <input 
                                                    placeholder="R$ 0,00"
                                                    onChange={(event) => handleChange(event, setFieldValue)}
                                                    value={!values.value ? '' : `R$ ${values.value}`}
                                                />
                                            )
                                        }

                                        {
                                            !coupon?.loaded && (
                                                <button 
                                                    onClick={() => handleSubmit(values as any)} 
                                                    className={(!!!values.value || !!errors.value) ? 'btn-make-dicount disabled' : 'btn-make-dicount'}
                                                    disabled={(!!!values.value || !!errors.value || isCreatingCoupon)}
                                                >
                                                    {
                                                        isCreatingCoupon ? (
                                                            <Spin indicator={<LoadingOutlined spin />} />
                                                        ) : (
                                                            <>Gerar desconto</>
                                                        )
                                                    }
                                                </button>
                                            )    
                                        }
                                    </div>
                                    {
                                        (!!coupon && !coupon.canceled && !coupon.loaded) && 
                                        <div className="form-feedback">
                                            <span >Você gerou um cupom de desconto de {moneyBR(coupon.valor_cupom)}!</span>
                                        </div>
                                    }

                                    {
                                        error && (
                                            <div className="form-feedback form-feedback-error">
                                                <span>{createError}</span>
                                            </div>
                                        )
                                    }

                                    {
                                        !!errors.value && (
                                            <div className="form-feedback form-feedback-error">
                                                <span>{errors.value}</span>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }}
                        </Formik>
                </div>

                <div className="box-cupom">
                    <div className="cupom-header">
                        <span>Cupom</span>
                    </div>

                    <div className="cupom-body">
                        {
                            (!!coupon?.cupom && !coupon.canceled) ? (
                                <div className="cupom">
                                    {coupon.cupom}
                                </div>
                            ) : (
                                <div className="cupom cupom-canceled">
                                </div>
                            )
                        }

                        {
                            (!!coupon && !coupon.canceled) && (
                                <span className="cupom-copy" onClick={handleCopyCoupon}>
                                    <span>Copiar código</span>
                                </span>
                            )
                        }
                    </div>
                    {
                        !!coupon && (
                            <>
                                {
                                    (!coupon.canceled && !(!!coupon.deleted_at || !!coupon.orders)) ? (
                                        <div className="cupom-footer">
                                            <a href="/" className="cupom-cancel" onClick={handleCancelCoupon}>
                                                Cancelar Cupom
                                            </a>
                                        </div>
                                    ) : (
                                        <>
                                        {
                                            (!!coupon.deleted_at || coupon.canceled) ? (
                                                <div className="cupom-cancel-feedback">
                                                    <span>Seu cupom foi cancelado! O valor dele voltou para sua carteira</span>
                                                </div>
                                            ): (
                                                <div className="cupom-feedback">
                                                    <span>Seu cupom foi utilizado</span>
                                                </div>
                                            )
                                        }
                                        </>
                                        
                                    )
                                }
                            </>
                        )
                    }
                </div> 
            </section>
        </>
    );
};