import { cookieParams, rsaPub, TRANSACTION_TYPE_FULL, TRANSACTION_TYPE_INSTALL_NO_INTEREST } from './constants';
import { JSEncrypt } from 'jsencrypt';
import { numberFormat } from '../../../helpers';
import config from '../../../config';

import Cookies from 'universal-cookie';

export const makeURLToken = () => {
    const crypt = new JSEncrypt();
    crypt.setPublicKey(rsaPub);

    return crypt.encrypt(config.apiKey);
};

export const setCookie = (affiliate) => {
    const cookie = new Cookies();
    const key = cookieParams.key;
    cookie.remove(key);

    const expires = new Date((Date.now() + (cookieParams.milliSecondsOneDay * 10)));

    cookie.set(key, affiliate, {
        path: cookieParams.path,
        expires
    });
};

export const getCookie = () => {
    const cookie = new Cookies();
    const key = cookieParams.key;
    const affiliate = cookie.get(key);

    return affiliate ?? '';
};

export const encryptCard = (card) => {
    const jsonCard = JSON.stringify({
        ...card,
        card_number: unformat(card.card_number),
        holder_document: unformat(card.holder_document)
    });

    const crypt = new JSEncrypt();
    crypt.setPublicKey(rsaPub);
    const chunks = strSplit(jsonCard, 100).map((chunk) => crypt.encrypt(chunk));

    return JSON.stringify(chunks);
}

export const makeInstallments = (cashValue, installmentAmount, method, totalInstallments = 12) =>  {
    let installments = [];
    if (cashValue || installmentAmount || cashValue === 0 || installmentAmount === 0) {
        installments = Array(totalInstallments).fill().map((_, index) => {
            const installment = (index + 1);
            if (installment === 1) {
                if(method !== "credit")
                    return {
                        key: 1,
                        value: cashValue ?? installmentAmount
                    };
                else
                    return {
                        key: 1,
                        value: (installmentAmount / installment)
                    };
            }

            return {
                key: installment,
                value: (installmentAmount / installment)
            };
        });
    }

    return installments;
}

export const makeCourseValue = (course, cupom) => {

    let courseValue = 0;
    let installments = 0;
    let _cupom = false;

    if (cupom?.id_desconto) {
        _cupom = true;
        if (cupom.pode_parcelar) {
            courseValue = cupom.valor_cupom;
            installments = 12;
        } else {
            courseValue = cupom.valor_cupom_a_vista;
            installments = 1;
        }
    } else {
        courseValue = course.valor_curso;
        installments = 12;
    }

    return {
        courseValue: numberFormat(courseValue/installments, 2, ',', '.'),
        installments,
        cupom: _cupom
    };
}

export const unformat = (value) => {
    if (value) return value.replace(/[-_. ]/g, '');
    return '';
};

export const formatBirthDate = (birthDate) => {
    const [day, month, year] = birthDate.split('/');
    return `${year}-${month}-${day}`;
};

export const formatExpirionNumber = (expirion) => {
    const [expiration_month, expiration_year] = expirion.split('/');

    return {
        expiration_month,
        expiration_year
    };
};

export const formatCardCreditType = (installments, type) => {

    if (type === 'credit') {
        return installments === 1 ? TRANSACTION_TYPE_FULL : TRANSACTION_TYPE_INSTALL_NO_INTEREST;
    }

    return '';
};

export const validInputMask = (field, finishMasculine = true) => ({
    validator(_, value) {
        if (!value) {
            return Promise.resolve()
        }

        if (value.includes('_')) {
            return Promise.reject(new Error(`Aguardando ${field} válid${finishMasculine ? 'o' : 'a'}`));
        }

            return Promise.resolve();
        }

});

export const validCpf = (field, finishMasculine = true) => ({
    validator(_, value) {

    if(value === undefined || value === null)
        return Promise.reject();

    value = value.replace(/[^\d]+/g,'');

    if(value === '')
    return Promise.reject(new Error(`Aguardando ${field} válid${finishMasculine ? 'o' : 'a'}`));
    // Elimina values invalidos conhecidos
    if (value.length !== 11 ||
        value === "00000000000" ||
        value === "11111111111" ||
        value === "22222222222" ||
        value === "33333333333" ||
        value === "44444444444" ||
        value === "55555555555" ||
        value === "66666666666" ||
        value === "77777777777" ||
        value === "88888888888" ||
        value === "99999999999")
        return Promise.reject(new Error(`Aguardando ${field} válid${finishMasculine ? 'o' : 'a'}`));
    // Valida 1o digito
    var add = 0;
    var i=0;
    for (i=0; i < 9; i ++)
        add += parseInt(value.charAt(i)) * (10 - i);
        var rev = 11 - (add % 11);
        if (rev === 10 || rev === 11)
            rev = 0;
        if (rev !== parseInt(value.charAt(9)))
            return Promise.reject(new Error(`Aguardando ${field} válid${finishMasculine ? 'o' : 'a'}`));
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i ++)
        add += parseInt(value.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(value.charAt(10)))
        return Promise.reject(new Error(`Aguardando ${field} válid${finishMasculine ? 'o' : 'a'}`));
    return Promise.resolve();
}});

export const validDate = () => ({
    validator(_, value) {
        if (!value || value.includes('_')) {
            return Promise.resolve();
        }

        const regex = /(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d{2}/;
        if (!value.match(regex)) {
            return Promise.reject(new Error('A data informada é inválida'));
        }

        return Promise.resolve();
    }
});

export const validDataCard = () => ({
    validator (_, value) {
        if (!value || value.includes('_')) {
            return Promise.resolve();
        }

        const regex = /(0[1-9]|1[012])\/(2|3|4|5|6|7|8|9)\d{1}/;

        if (!value.match(regex)) {
            return Promise.reject(new Error('A data do cartão de crédito é inválida'));
        }

        return Promise.resolve();
    }
});

function strSplit (string, splitLength) {
    if (splitLength === null) {
      splitLength = 1
    }
    if (string === null || splitLength < 1) {
      return false
    }
    string += ''
    const chunks = []
    let pos = 0
    const len = string.length
    while (pos < len) {
      chunks.push(string.slice(pos, pos += splitLength))
    }
    return chunks
}

export const linkWhatsApp = (error) => {
    return `https://wa.me/5551997449565${error ? '?text=' + error : ''}`;
};

export const makeURLGA = (curso, id_turma, step, afiliado = null) => {
    if (afiliado && !['dados', 'endereco', 'pagamento', 'confirmacao', 'erro'].includes(afiliado)) {
      window.history.pushState({}, '', `/newCheckout/${curso}/${id_turma}/${afiliado}/${step}`);
    } else {
      window.history.pushState({}, '', `/newCheckout/${curso}/${id_turma}/${step}`);
    }
};

export const getFullCurrentValue = (installments, current) => {
    const currentInstallment = installments.find((installement) => installement?.key === current);

    if (currentInstallment) {
        return {
            total: (currentInstallment.value * currentInstallment.key),
            value: currentInstallment.value,
            installmentPurchases: currentInstallment.key
        };
    }

    return {
        total: null,
        value: null,
        installmentPurchases: null,
    };
}
