export const TRANSACTION_TYPE_FULL = 'FULL';
export const TRANSACTION_TYPE_INSTALL_NO_INTEREST = 'INSTALL_NO_INTEREST';
export const TRANSACTION_TYPE_INSTALL_WITH_INTEREST = 'INSTALL_WITH_INTEREST';

export const noCourse = `
    Não é possível continuar com o cadastro. Não há vagas ou o período de matrícula encerrou para este curso.
`;

export const rsaPub = `
-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC5+/4ltzmA6H7CaBsmaixUiFLq
5kr3ZqreOd80IECMXiFJ46TSf/T17MU3n40ZGIlS54UbkST0e6JEApKVgMh7tFjq
5aU78nKEKKx76oNUWrkHarh551Vpvc46O1MasP32PiucWXs8FqaEK3aZQc+pxOyD
qhbzzkjPjKAAKs6hDwIDAQAB
-----END PUBLIC KEY-----
`;

export const countPix = 180000;

export const cookieParams = {
    milliSecondsOneDay: 86400000,
    key: '@afilliate',
    path: '/'
};

export const whatsappSupport = 'https://wa.me/555180632020';



