import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as podcastActions, PodcastState } from '../../../../redux/modules/podcast';

import MainLayout from '../../../../components/MainLayout';
import HeaderInside from '../../../../components/HeaderInside';
import Player from '../../../../components/Podcast/Player';
import Album from '../../../../components/Podcast/Album';
import { ReactComponent as BlueInfo } from '../../../../img/podcast/BlueInfo.svg';
import noPodcasts from '../../../../img/smiley-sad.svg';

import { Container, PanelNoPodcasts, Content, AlbumArea, Aside, PlayerArea } from '../styles';

export default function Podcast() {
  const dispatch = useDispatch();
  const { currentPodcast, currentPlay, podcasts } = useSelector(
    (state: { podcast: PodcastState }) => state.podcast
  );

  useEffect(() => {
    dispatch(podcastActions.fetchAll());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentPodcast.id && currentPlay.isPlaying) {
      dispatch(podcastActions.openPlayerFooter());
    } else {
      dispatch(podcastActions.closePlayerFooter());
    }
    // eslint-disable-next-line
  }, [currentPodcast.id, currentPlay.isPlaying]);

  return (
    <MainLayout>
      <Container>
        <HeaderInside
          title="Podcast"
          subtitle="Ouça e aprenda com especialistas sobre diversos temas, desde investimentos imobiliários até lavagem de dinheiro."
        />
        {podcasts.length ? (
          <Content>
            <AlbumArea>
              <Album />
            </AlbumArea>
            <Aside>
              <PlayerArea>
                <Player />
                <BlueInfo className="info" />
              </PlayerArea>
            </Aside>
          </Content>
        ) : (
          <PanelNoPodcasts>
            <img src={noPodcasts} alt="Sem podcasts" />
            <h3>O curso que você adquiriu não possui nenhum podcast disponível.</h3>
          </PanelNoPodcasts>
        )}
      </Container>
    </MainLayout>
  );
};