import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IAuth } from '../../../shared/Models/IAuth';
import Layout from '../../../components/Gabarito/LayoutTwo';
import Register from '../../../components/Gabarito/Register';
import { Title, Text, Buttons, Button, IconModular, IconComplete } from './styles';

export type UserProps = {
  auth: IAuth;
};

const Create = () => {
  const [examType, setExamType] = useState<'modular' | 'complete' | undefined>();

  const state = useSelector((state: UserProps) => state);
  const {
    user: { name },
  } = state.auth;

  return (
    <Layout>
      <Title>{name ? `Oi, ${name} :-)` : 'Oi :-)'}</Title>
      <Text>Que tipo de prova você fez?</Text>
      <Buttons>
        <Button active={examType === 'modular'} onClick={() => setExamType('modular')}>
          <IconModular active={examType === 'modular'} />
          Modular
        </Button>
        <Button active={examType === 'complete'} onClick={() => setExamType('complete')}>
          <IconComplete active={examType === 'complete'} />
          Completa
        </Button>
      </Buttons>
      {examType && <Register isModular={examType === 'modular'} />}
    </Layout>
  );
};

export default Create;
