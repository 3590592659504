import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  center;


    svg {
        font-size: 70px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.warning};
    }

    h1 {
        margin-top: 44px;
        font-size: 40px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.warning};
    }

    button {
        margin-top: 1rem;
    }
`;