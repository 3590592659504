import styled from 'styled-components';

export const InputComponent = styled.input`
  position: relative;
  display: flex;
  height: 40px;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  align-self: stretch;

  outline: none;

  border-radius: 5px;
  border: 1px solid #252525;

  color: #252525;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  transition: all 0.2s ease-in-out;

  &[type='date']::-webkit-search-cancel-button,
  ::-webkit-clear-button {
    position: absolute;
    cursor: pointer;
    right: 12px;
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    position: absolute;
    cursor: pointer;
    right: 12px;
  }

  &[type='date']::-webkit-calendar-picker-indicator:hover {
    -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04);
  }

  &.is-password {
    padding-right: 40px;
  }

  &:disabled {
    background: #f9f9f9;
    color: #b1b1b1;
    border: 1px solid #a19f9f;
  }
`;

export const InputLabel = styled.p`
  color: '#252525';

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.48px;

  transition: all 0.2s ease-in-out;
`;

export const InputArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  &:focus-within {
    ${InputComponent} {
      border-radius: 5px;
      border: 1px solid #5768ff;
      box-shadow: 0px 0px 4px 0px rgba(114, 123, 210, 0.7);
    }

    ${InputLabel} {
      color: #5768ff;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: #d81e5b;

  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.36px;

  margin: -8px 0 -22px 0px;
`;

export const ButtonEyes = styled.button`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  right: 12px;
  top: 45px;

  background: none;
  border: none;

  cursor: pointer;

  padding: 0;
  margin: 0;
  z-index: 1;
`;
