import request from '../../shared/request'

export function getQuestionByCode(questionCode) {
  return request(`/v1/services/questions/explained/${questionCode}`, {
    method: 'GET',
  })
}

export function getQuestionById(questionId) {
  return request(`/v1/services/questions/explainedById/${questionId}`, {
    method: 'GET',
  })
}

export function getQuestionsByStudent() {
  return request(`/v1/services/questions/find/studentV2`, {
    method: 'GET',
  })
}

export function getQuestions(params = {}) {
  const queryString = new URLSearchParams(params).toString();
  const url = `/v1/services/questions/find/studentV3${
    queryString ? `?${queryString}` : ''
  }`;

  return request(url, {
    method: 'GET'
  });
}

export function getFamilies() {
  return request(`/v1/services/questions/find/families`, {
    method: 'GET'
  })
}

export function getModulesbyFamilies(familie_id) {
  return request(`/v1/modules/all?families=${familie_id}`, {
    method: 'GET'
  })
}

export function getTestsToPrint(classId) {
  return request(`/v1/services/questions/print/${classId}`, {
    method: 'GET',
  })
}
