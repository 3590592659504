import styled, { css } from 'styled-components';


type StatusProps = {
    approved: boolean;
    module?: number;
};

type DescriptionProps = {
    number: number;
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export const ModuleDescription = styled.h1`
    text-align: center;
    font-size: 1.75rem;
    @media(max-width: 720px) {
        font-size: 1.3rem;
        margin-top: 1%;
    }
    font-weight: 600;
`;

export const Divider = styled.div`
    border-bottom: 2px solid #D8DBE0;

    height: 0.5px;
    width: 100%;
    margin-top: 1rem;
`;

export const BoxTable = styled.div`
    padding: 1rem 0;
    padding-top: 1.5rem;   
    flex: 1;
    width: 100%; 
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .question {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 1.25rem;
        @media(max-width: 720px) {
            font-size: 1rem;
        }
    }

    .letter {
        flex: 1;
        display: flex;
        justify-content: center;
        justify-content: center;
        padding: 1rem 0;
        font-weight: 700;
        font-size: 1.25rem;
        @media(max-width: 720px) {
            font-size: 1rem;
        }
    }
`;

export const WrapperStatus = styled.div<StatusProps>`
    width: 34.8125rem;
    @media (max-width: 720px) {
        width: 98%;
    }

    height: 16.375rem;
    background-color: #FFF;

    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);

    margin-bottom: 50px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
    justify-content: space-evenly;

    &>.status {

        width: 230px;
        justify-content: space-evenly;
        align-items: center;

        display: flex;
        
        svg, h1 {
            font-weight: 700;
            font-size: 2.1rem;
            color: ${({ approved }) => approved ? '#2EC76B' : '#D81E5B'}; 
        }
    }

    span {
        font-size: 24px;
        font-weight: 600;
    }
    
`;

export const Status = styled.div<StatusProps>`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 10px;

    svg, h1 {
        font-weight: 600;
        font-size: 1.6rem;
        color: ${({ approved }) => approved ? '#2EC76B' : '#D81E5B'};
    }
    
    ${({ module }) => (
        module !== 1 && css`
            margin-top: 10rem;
        `
    )}

    margin-top: 10rem;
`;

export const DescriptionPercent = styled.span<DescriptionProps>`
    font-weight: 600;
    font-size: 1.2rem;
    @media (max-width: 720px) {
        font-size: 0.9rem;
    }

    ${({ number }) => (number > 1) && css`
        margin-top: 6rem;
    `}

    text-align: center;
`;