import React from 'react';
import LegendChart from '../SubtitleChart';
import {
    Graph,
    Color,
    ColorSecondary,
    Total,
    Text,
    TextTotal,
    AvaliableDate,
    Description,
    ContainerGraph
} from './styles';

type Props = {
    classNumber?: number;
    percent?: number | string;
    percentText?: number | string;
    colorText?: string;
    colorbk0?: string;
    colorbk1?: string;
    colorbk2?: string
    percentTwo?: number | string;
    ColorSecondary?: string;
    total?: any;
    displayAvaliable?: string;
    textAvaliableDate?: string;

    displaySubtitle?: string,
    subtitleMin?: string
    subtitleRecommended?: string,
    positionSubtitle?: string;

    description?: string;
    textdescription?: string;
    positionPercent?: string;
    height?: string;
    fontSize?: string;
    widthGraph?: string;
    displaytextPercent?: string;
    zIndex?: number;
}

function BarchartH({
    percent,
    percentText,
    percentTwo,
    colorText,
    colorbk0,
    colorbk1,
    colorbk2,
    total,
    displayAvaliable,
    textAvaliableDate,
    displaySubtitle,
    subtitleMin,
    subtitleRecommended,
    positionSubtitle,
    positionPercent,
    description,
    textdescription,
    height,
    fontSize,
    widthGraph,
    displaytextPercent,
    zIndex
}: Props) {
    return (
        <ContainerGraph widthGraph={widthGraph}>
            {/* <Content> */}
            <Graph colorbk0={colorbk0} height={height}>
                <Color percent={percent} zIndex={zIndex} colorbk1={colorbk1} positionPercent={positionPercent}>
                    <Text fontSize={fontSize} colorText={colorText} displaytextPercent={displaytextPercent}>{percentText}</Text>
                </Color>
                <ColorSecondary percentTwo={percentTwo} colorbk2={colorbk2}>
                    <p>{percentTwo}</p>
                </ColorSecondary>
                <Total percent={100}>
                    <TextTotal fontSize={fontSize} colorText={colorText}>{total}</TextTotal>
                </Total>
            </Graph>
            <Description description={description}>
                <p>{textdescription}</p>
            </Description>
            <AvaliableDate displayAvaliable={displayAvaliable}>
                <p>{textAvaliableDate}</p>
            </AvaliableDate>

            <LegendChart
                displaySubtitle={displaySubtitle}
                subtitleMin={subtitleMin}
                subtitleRecommended={subtitleRecommended}
                positionSubtitle={positionSubtitle}
            />

            {/* </Content> */}
        </ContainerGraph>
    );
};

export default BarchartH;
