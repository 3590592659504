import { createSagaAction } from '../../shared/sagas'
import gtm from "../../shared/gtm";
import { createReducer } from '../../shared/reducers'
import { REHYDRATE } from 'redux-persist/lib/constants'
import _ from 'lodash'

// Constants
export const constants = {
  AUTH_LOGOUT: 'AUTH_LOGOUT',
  VERIFY_TOKEN_ACTION: 'VERIFY_TOKEN_ACTION',
  AUTH_LOGIN: createSagaAction('AUTH_LOGIN'),
  AUTH_FORGOT_PASSWORD: createSagaAction('AUTH_FORGOT_PASSWORD'),
  AUTH_UPDATE_PHOTO: createSagaAction('AUTH_UPDATE_PHOTO')
}

// ------------------------------------
// Constants
// ------------------------------------
export const actions = {
  logout: () => ({
    type: constants.AUTH_LOGOUT
  }),
  login: (email, password, remember, success, error) => ({
    type: constants.AUTH_LOGIN.ACTION,
    email,
    password,
    remember,
    success,
    error
  }),
  forgot: (email, next) => ({
    type: constants.AUTH_FORGOT_PASSWORD.ACTION,
    email,
    next
  }),
  updatePhoto: (foto) => ({
    type: constants.AUTH_UPDATE_PHOTO.ACTION,
    foto
  })
}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // REHYDRATE
  [REHYDRATE]: (state, action) => {
    // const persisted = action.payload ? action.payload.a : {}
    const persisted = _.get(action, 'payload.auth', {})
    return {
      ...state,
      avatar: persisted.avatar || initialState.avatar,
      user: persisted.user || initialState.user,
      token: persisted.token || initialState.token,
      token_id: persisted.token_id || initialState.token_id
    }
  },

  // AUTH_LOGOUT
  [constants.AUTH_LOGOUT]: () => initialState,

  // AUTH_LOGIN.ACTION
  [constants.AUTH_LOGIN.ACTION]: state => {
    return { ...state, error: null, isLoading: true }
  },

  [constants.AUTH_LOGIN.SUCCESS]: (state, action) => {
    const { token, email, id, name, foto, profile_id, firstName, lastName, phoneNumber, token_id} = action.payload;
    gtm("/auth/login", { email, id, name, foto, profile_id, firstName, lastName, phoneNumber })
    return {
      ...state,
      token,
      token_id,
      user: {
        email,
        id,
        id_profile: profile_id,
        name,
        foto,
        firstName,
        lastName,
        phoneNumber
      },
      avatar: foto,
      isLoading: false
    }
  },

  // AUTH_LOGIN.FAILED
  [constants.AUTH_LOGIN.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // FORGOT_PASSWORD.ACTION
  [constants.AUTH_FORGOT_PASSWORD.ACTION]: state => {
    return { ...state, error: null, isLoading: true }
  },

  [constants.AUTH_FORGOT_PASSWORD.SUCCESS]: (state, action) => {
    const { email } = action.payload;
    return { ...state, user: { email }, isLoading: false }
  },

  // FORGOT_PASSWORD.FAILED
  [constants.AUTH_FORGOT_PASSWORD.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  //PHOTO URL
  // PHOTO.ACTION
  [constants.AUTH_UPDATE_PHOTO.ACTION]: state => {
    return { ...state, error: null, isLoading: true }
  },

  [constants.AUTH_UPDATE_PHOTO.SUCCESS]: (state, action) => {
    // const user = {...state.user, foto: action.foto}

    return { ...state, avatar: action.foto, isLoading: false }
  },

  // PHOTO.FAILED
  [constants.AUTH_UPDATE_PHOTO.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  }


}

// ------------------------------------
// Reducer
// ------------------------------------
export const initialState = {
  error: null,
  isLoading: false,
  token: null,
  avatar: null,
  token_id: null,
  user: {}
}

export default createReducer(initialState, (state, action) => {
  const handler = ACTION_HANDLERS[action.type]

  return handler ? handler(state, action) : { ...state, isLoading: false }
})
