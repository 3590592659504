import React, { useContext } from 'react';
import { Col, Radio, Form } from 'antd';
import P from 'prop-types';

import '../style/formPayment.scss';

// IMGS
import RectangleSvg from '../../../../img/svg/rectangle.svg'
import RectanglePinkSvg from '../../../../img/svg/rectangle-pink.svg'
import CredCardPinkSvg from '../../../../img/svg/cred-card-pink.svg'
import CredCardSvg from '../../../../img/svg/cred-card.svg'

import { CheckoutContext } from '../../shared/CheckoutProvider';
import useDocumentSize from '../../../../hooks/useDocumentSize';

const { Item } = Form;

const propTypes = {
    form: P.any.isRequired
};

const FormOfPayment = ({ form }) => {

    const useSize = useDocumentSize();

    const { values, setChangeTypeCredit, setMethod } = useContext(CheckoutContext);

    return (
        values.type !== 'free' ? (
            <>
            <Col> 
                <h5 className="title-form">Forma de pagamento</h5>
            </Col>
            <Radio.Group onChange={(e) => {
                setChangeTypeCredit(e.target.value);
                form.resetFields(['installments']);
            }} value={values.type} className="radio-group-form">
                <Col className="gutter-row" xs={8} sm={8} md={8}>
                    {useSize.width <= 767 &&
                    <Col className={`col-cred-mobile`}>
                        <Radio.Button disabled={values.type === 'free'} value="credit" className="button-radio-xs-lg col-pix-xs type-payment-infos" onClick={()=>setMethod("credit")}> 
                        {values.type === 'credit' ? 
                            (
                                <img src={CredCardPinkSvg} alt="Cartão de crédito selecionado" /> 
                            ):(
                                <img src={CredCardSvg} alt="Cartão de crédito" /> 
                              )   
                        }
                        </Radio.Button>
                        <p className="title-radio-xs-lg">Crédito</p>
                    </Col>
                    }

                    {useSize.width >= 768 &&
                    <Item  className={`input-form radio-gropup-item ${values.type === 'credit' ? 'input-form-payment-selected' : ''}`}>
                        <Radio value="credit" className="radio-group-item-checbox type-payment-infos" onClick={()=>setMethod("credit")}>
                        <span className="cred-card">
                            Crédito
                        </span>
                        </Radio>
                    </Item>
                    }
                </Col>

                <Col className="gutter-row" xs={8} sm={8} md={8}>
                    {useSize.width <= 767 && (
                    <Col className="col-debit-mobile">
                        <Radio.Button disabled={values.type === 'free'} value="debit" className="button-radio-xs-lg col-pix-xs type-payment-infos" onClick={()=>setMethod("debit")}> 
                        {values.type === 'debit' ?
                            (
                                <img src={CredCardPinkSvg} alt="Cartão de crédito selecionado" /> 
                            ):( 
                                <img src={CredCardSvg} alt="Cartão de crédito" /> 
                              )
                        }
                        </Radio.Button>
                        <p className="title-radio-xs-lg">Débito</p>
                    </Col>
                    )}
                    {useSize.width >= 768 && (
                        <Item className={`input-form radio-gropup-item ${values.type === 'debit' ? 'input-form-payment-selected' : ''}`}>
                            <Radio  value="debit" className="radio-group-item-checbox type-payment-infos" onClick={()=>setMethod("debit")}>
                                <span className="cred-card">
                                    Débito
                                </span>
                            </Radio>
                        </Item>
                    )
                    }
                </Col>
                <Col className="gutter-row" xs={8} sm={8} md={8}>
                {useSize.width <= 767 &&
                    <Col className="col-pix-mobile">
                    <Radio.Button disabled={values.type === 'free'} value="pix" className="button-radio-xs-lg type-payment-infos"  onClick={()=>setMethod("pix")}> 
                        {values.type === "pix" ? 
                            (
                            <img src={RectanglePinkSvg} alt="Retangulo selecionado" /> 
                            ):(
                                <img src={RectangleSvg} alt="Retangulo" /> 
                              ) 
                        }
                    </Radio.Button>
                    <p className="title-radio-xs-lg">Pix</p>
                    </Col>
                }
                    {useSize.width >= 768 &&
                    <Item className={`input-form radio-gropup-item ${values.type === 'pix' ? 'input-form-payment-selected' : ''}`}>
                        <Radio disabled={values.type === 'free'} value="pix" className="radio-group-item-checbox type-payment-infos" onClick={()=>setMethod("pix")}> Pix </Radio>
                    </Item>
                    }
                </Col>
            </Radio.Group>         
            </>
        ) : false       
    );
};

FormOfPayment.propTypes = propTypes;

export default FormOfPayment;