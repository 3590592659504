import styled from 'styled-components';

interface GridAreaProps {
  items: number;
}

export const FormaArea = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 30px;
`;

export const GridArea = styled.div<GridAreaProps>`
  display: grid;
  grid-template-columns: repeat(${({ items }) => items}, 1fr);
  grid-gap: 24px;

  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;