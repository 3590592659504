import React from 'react';

import { Container, Progress, Percent } from './styles';

type Props = {
  percent: number;
  showPercent?: boolean;
};

export default function BarChartReportCfp({ percent = 0, showPercent = true }: Props) {
  return (
    <Container data-testid="barchart-test-id">
      <Progress percent={percent} data-testid="percent" />
      {showPercent && <Percent percent={percent}>{percent}%</Percent>}
    </Container>
  );
}
