import React from "react";
import { format, parseISO }  from 'date-fns';
import { ptBR } from 'date-fns/locale';
import warningCircle from '../../../../img/svg/warningCircle.svg';
import dangerTriangle from '../../../../img/svg/dangerTriangle.svg';
import { ContainerExpiteDate } from './styles'

function ExpireDateCourse({ course, size }) {
  const curso = course ?? {};

  const dataFinalShort = curso.data_fim_aulas && format(parseISO(curso.data_fim_aulas), 'dd MMM yyyy', { locale: ptBR });

  const dateFinal = new Date(curso.data_fim_aulas); //data origin
  const dateFinalFormattedBR = dateFinal.toLocaleDateString("pt-br"); //data formatted br

  const partsFinal = dateFinalFormattedBR.split('/')
  const dateEnd = new Date(partsFinal[2], partsFinal[1] - 1, partsFinal[0])

  const today = new Date()

  const finalTime = Math.abs(dateEnd - today);
  const endFormattedDays = Math.ceil(finalTime / (1000 * 60 * 60 * 24));

  return (
    <ContainerExpiteDate>
      {endFormattedDays <= 30 ? (
        <div className='date-course-danger'>
          <img src={dangerTriangle} alt="icon danger triangle" />
          <p className='date-course' style={{ fontSize: size }}>Você tem </p>
          <strong> {endFormattedDays}</strong>
          <p style={{ fontSize: size }}> {endFormattedDays === 1 ? 'dia' : 'dias'} até expirar </p>
        </div>
        ) : (
        <div className='date-course-warning'>
          <img src={warningCircle} alt="icon warning circle" />
          <p style={{ fontSize: size }}>Disponível até </p>
          <strong style={{ fontSize: size }}> {dataFinalShort}</strong>
        </div>
        )
      }
    </ContainerExpiteDate>
  )
}

export default ExpireDateCourse;
