import { createSagaAction } from '../../shared/sagas'
import { createReducer } from '../../shared/reducers'

// Constants
export const constants = {
    QUESTION_GET_QUESTION_BY_CODE: createSagaAction('QUESTION_GET_QUESTION_BY_CODE'),
    QUESTION_BY_STUDENT: createSagaAction('QUESTION_BY_STUDENT'),
    QUESTION_BY_ID: createSagaAction('QUESTION_BY_ID'),
    QUESTIONS: createSagaAction('QUESTIONS'),
    FAMILIES: createSagaAction('FAMILIES'),
}

export const actions = {
    getQuestionByCode: (questionCode, next) => {
        return {
          type: constants.QUESTION_GET_QUESTION_BY_CODE.ACTION,
          questionCode,
          next
        }
    },
    getQuestionById: (questionId, next) => {
      return {
        type: constants.QUESTION_BY_ID.ACTION,
        questionId,
        next
      }
    },
    getQuestionsByStudent: (next) => {
      return {
        type: constants.QUESTION_BY_STUDENT.ACTION,
        next
      }
    },
    getQuestions: (params, next) => {
      return {
        type: constants.QUESTIONS.ACTION,
        params,
        next
      }
    },
    getFamilies: (next) => {
      return {
        type: constants.FAMILIES.ACTION,
        next
      }
    },
}

const ACTION_HANDLERS = {
    // QUESTION_GET_QUESTION_BY_CODE.ACTION
  [constants.QUESTION_GET_QUESTION_BY_CODE.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.QUESTION_GET_QUESTION_BY_CODE.SUCCESS]: (state, action) => {
    return { ...state, byCode:{...action.payload}, isLoading: false }
  },
  [constants.QUESTION_GET_QUESTION_BY_CODE.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // QUESTION_BY_ID.ACTION
  [constants.QUESTION_BY_ID.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.QUESTION_BY_ID.SUCCESS]: (state, action) => {
    return { ...state, question:{...action.payload}, isLoading: false }
  },
  [constants.QUESTION_BY_ID.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },


  // QUESTION_BY_STUDENT
  [constants.QUESTION_BY_STUDENT.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.QUESTION_BY_STUDENT.SUCCESS]: (state, action) => {
    return { ...state, data_2:{...action.payload}, isLoading: false }
  },
  [constants.QUESTION_BY_STUDENT.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // QUESTIONS
  [constants.QUESTIONS.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.QUESTIONS.SUCCESS]: (state, action) => {
    return { ...state, data_2:{...action.payload}, isLoading: false }
  },
  [constants.QUESTIONS.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },

  // FAMILIES
  [constants.FAMILIES.ACTION]: (state) => {
    return { ...state, error: null, isLoading: true }
  },
  [constants.FAMILIES.SUCCESS]: (state, action) => {
    return { ...state, data_2:{...action.payload}, isLoading: false }
  },
  [constants.FAMILIES.FAILED]: (state, action) => {
    return { ...state, error: action.message, isLoading: false }
  },
}

export const initialState = {
    question: {},
    byCode: {}
}

export default createReducer(initialState, (state, action) => {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : { ...state, isLoading: false }
})
