import React, { HTMLAttributes, ReactNode } from 'react';

import { Spin } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './styles';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  antIcon?: () => void;
  layout?: 'warning' | 'primary' | 'outline-primary' | 'waiting' | 'default';
  isLoading?: boolean;
  disabled?: boolean;
}

function Button({ children, antIcon, layout, isLoading, disabled, ...rest }: ButtonProps) {
  return (
    <Container
      data-testid="button-container-test"
      {...rest}
      layout={layout ?? 'default'}
      icon={antIcon ? true : false}
      disabled={disabled}
      className={`btn-${layout}`}
    >
      {isLoading ? (
        <Spin data-testid="button-spin-test" indicator={<LoadingOutlined spin />} />
      ) : (
        <>
          {antIcon && antIcon()}
          {children}
        </>
      )}
    </Container>
  );
}

export default Button;
