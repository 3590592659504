import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { ArrowRightOutlined, PlayCircleOutlined, AlignLeftOutlined } from '@ant-design/icons';
import config from '../../config';
import VideoTypeModal from 'pages/course/components/class/lecture/components/VideoTypeModal.js';
import LectureVideoQuestion from 'pages/course/components/class/lecture/LectureVideoQuestion.component.js';
import LectureTextQuestion from 'pages/course/components/class/lecture/LectureTextQuestion.component.js';
import unchecked from '../../img/UncheckCircle.svg';
import checked from '../../img/CheckCircle.svg';
import checkedCorrect from '../../img/CheckCircleCorrect.svg';
import checkedIncorrect from '../../img/CheckedCircleIncorrect.svg';
import { 
  CodeStyled, 
  ContentStyled, 
  QuestionHeaderStyled, 
  AnswersStyled, 
  AnswerStyled, 
  QuestionFooterStyled, 
  ButtonStyled,
  ButtonStyledTwo,
  RowStyledTwo, 
} from './styles';

function Question({data, resolved}) {
  const [questionAnswer, setQuestionAnswer] = useState();
  const [questionAnswerCorrect, setQuestionAnswerCorrect] = useState();
  const [questionVideoStart, setQuestionVideoStart] = useState(false);
  const [questionTextShow, setQuestionTextShow] = useState(false);
  const [modalVideoOption, setModalVideoOption] = useState(false);
  const [LSBVideo, setLSBVideo] = useState(false);

  useEffect(() => {
    if(resolved && data.awnsers) {
      const correctAnswer = data.awnsers.find(answer => answer.correta === 1).id_resposta;
      
      setQuestionAnswerCorrect(correctAnswer);
      setQuestionAnswer(correctAnswer);
    }
  }, [resolved, data.awnsers]);

  function renderImg(img) {
    if (img) {
      const domain = config.s3Url;
      const path = 'uploads/';
      const imgWithPath = img.indexOf(path) >= 0 ? img : path + img;
      
      return <img src={`${domain}${imgWithPath}`} alt="" style={{ marginTop: 10 }} />;
    }
  }

  const handlePlayVideo = () => {
    if (data?.video_embed_libras) {
      setLSBVideo(true);
      setQuestionVideoStart(true);
    } else {
      setQuestionVideoStart(true);
    }
  };

  function checkAnswer() {
    const correctAnswer = data.awnsers.find(answer => answer.correta === 1).id_resposta;
    
    setQuestionAnswerCorrect(correctAnswer);
  }

  function selectAnswer(answer) {
    setQuestionAnswerCorrect(undefined);
    setQuestionAnswer(answer);
  }

  const checkIcon = (answerId, answerCorrect) => {
    if(answerId === questionAnswer) {
      if(questionAnswer === questionAnswerCorrect) {
        return <img src={checkedCorrect} alt="correto"/>;
      } else if(questionAnswerCorrect && questionAnswer !== questionAnswerCorrect) {
        return <img src={checkedIncorrect} alt="incorreto"/>;
      } else {
        return <img src={checked} alt="marcado"/>;
      }
    } else if(questionAnswerCorrect && answerCorrect) {
      return <img src={checkedCorrect} alt="correto"/>;
    } else {
      return <img src={unchecked} alt="desmarcado"/>;
    }
  }

  return (
    <div style={{width: '100%'}}>
      <Row gutter={[4, 20]} align="middle">
        <Col span={24} style={{paddingLeft: '24px'}}>
          <CodeStyled>{data.codigo ? <><span>Questão </span>{data.codigo}</> : 'Carregando...'}</CodeStyled>
        </Col>
      </Row>
      {data.codigo && (
        <>
          <RowStyledTwo>
            <Col span={24}>
              <ContentStyled>
                <QuestionHeaderStyled>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{data.enunciado}</p>
                  {renderImg(data.img_enunciado)}
                </QuestionHeaderStyled>
                <AnswersStyled>
                  {data.awnsers?.filter((answer) => answer.resposta)?.map((answer) => (
                    <AnswerStyled 
                      key={answer.id_resposta}
                      checked={answer.id_resposta === questionAnswer} 
                      incorrect={questionAnswerCorrect && answer.id_resposta !== questionAnswerCorrect} 
                      correct={questionAnswerCorrect === answer.id_resposta} 
                      onClick={() => selectAnswer(answer.id_resposta)}
                    >
                      {checkIcon(answer.id_resposta, answer.correta)} {answer.resposta}
                    </AnswerStyled>
                  ))}
                </AnswersStyled>
              </ContentStyled>
            </Col>
          </RowStyledTwo>
          <Row style={{ marginTop: '20px' }}>
            <Col span={24}>
              <QuestionFooterStyled>
                {data.questao_comentada && <ButtonStyledTwo type='default' active={questionTextShow} onClick={() => setQuestionTextShow(true)}><AlignLeftOutlined /> Ler a resolução</ButtonStyledTwo>}
                {data.video_embed && <ButtonStyledTwo type='default' active={questionVideoStart} onClick={handlePlayVideo}><PlayCircleOutlined /> Assistir a resolução</ButtonStyledTwo>}
                {!resolved && <ButtonStyled onClick={checkAnswer} disabled={!questionAnswer} className='seeResult' type='primary'>Salvar e ver resultado <ArrowRightOutlined /></ButtonStyled>}
              </QuestionFooterStyled>
            </Col>
          </Row>
          {modalVideoOption && (
            <VideoTypeModal
              modalVideoOption={modalVideoOption}
              setModalVideoOption={setModalVideoOption}
              setLSBVideo={setLSBVideo}
              setQuestionVideoStart={setQuestionVideoStart}
            />
          )}
          <LectureVideoQuestion
            questionVideoStart={questionVideoStart}
            setQuestionVideoStart={setQuestionVideoStart}
            videoEmbed={
              data &&
              (LSBVideo ? data.video_embed_libras : data.video_embed)
            }
            setModalVideoOption={setModalVideoOption}
            setLSBVideo={setLSBVideo}
          />
          <LectureTextQuestion
            questionTextShow={questionTextShow}
            setQuestionTextShow={setQuestionTextShow}
            text={data?.questao_comentada}
          />
        </>
      )}
    </div>
  );
}

export default Question;
