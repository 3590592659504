import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { IGabaritoModule } from '../shared/Models/gabarito/IGabaritoModule';
import { IGabaritoState } from '../shared/Models/gabarito/IGabaritoState';

type GabaritoroProviderProps = {
    children: ReactNode
};

export type AnswerInput = {
    id_gabarito_question: number;
    id_gabarito_question_answer: number;
};

type Channels = {
    phone: boolean;
    email: boolean;
    whatsapp: boolean;
};


export type GabaritoroContextProps = {
    userAnswers: Array<AnswerInput>;
    handleMarkAnswer: (answer: AnswerInput) => void;
    startMarkedAnswers: (gabarito: IGabaritoModule) => void;
    startEmptyAnswers: () => void;
    totalQuestions: () => number | null;
    validation: boolean;
    onValidation: (isValidation: boolean, started: number) => void;
    startedValidationQuestion: number;
    setStartedValidationQuestion: (started: number) => void;
    channels: Channels;
    onSetChannel(type: string, e: CheckboxChangeEvent): void;
};

export const GabaritoroContext = createContext({} as GabaritoroContextProps);

export const GabaritoroProvider = ({ children }: GabaritoroProviderProps) => {
    const [startedValidationQuestion, setStartedValidationQuestion] = useState(0);
    const [validation, setValidation]   = useState(false);
    const [userAnswers, setUserAnswers] = useState<AnswerInput[]>([]);

    const [channels, setChannels] = useState({phone: false, email: false, whatsapp: false});
    
    const { gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito)

    const totalQuestions = (): number | null => {
        if (gabarito?.modules?.length) {
            const numberQuestions = gabarito.modules.map(module => module.questions.length);

            const total = numberQuestions.reduce((acc: number, numberQuestions: number) => {
                return acc + numberQuestions;
            }, 0)

            return total;
        }

        return null;
    };

    const handleMarkAnswer = (answer: AnswerInput) => { 
        const has = userAnswers.find(item => item.id_gabarito_question === answer.id_gabarito_question);
        if (has) {
            const updatedAnswers = userAnswers.map(item => {
                if (item.id_gabarito_question === answer.id_gabarito_question) {
                    return {
                        ...answer
                    };
                }

                return {
                    ...item
                };
            });

            setUserAnswers([...updatedAnswers]);
        } else {
            setUserAnswers([...userAnswers, answer]);
        }
    };

    const startMarkedAnswers = (gabarito: IGabaritoModule) => {
        const markedAnswers: AnswerInput[] = [] as AnswerInput[];

        gabarito.modules.forEach(module => {
            module.questions.forEach(question => {                
                markedAnswers.push({
                    id_gabarito_question: question.id_gabarito_question,
                    id_gabarito_question_answer: question.id_gabarito_marked_answer!
                });
            });
        });

        setUserAnswers([ ...markedAnswers ]);
    };

    const startEmptyAnswers = () => {
        setUserAnswers([]);
    };

    const onValidation = (isValidation:boolean, started: number = 0) => {
        setStartedValidationQuestion(started);
        setValidation(isValidation);
    };

    const onSetChannel = (type: string, e:CheckboxChangeEvent) => {
        setChannels({
            ...channels,
            [type]: e.target.checked
        });
    }

    return (
        <GabaritoroContext.Provider value={{
            userAnswers,
            handleMarkAnswer,
            startMarkedAnswers,
            startEmptyAnswers,
            totalQuestions,
            validation,
            onValidation,
            startedValidationQuestion,
            setStartedValidationQuestion,
            channels,
            onSetChannel
        }}>
            { children }
        </GabaritoroContext.Provider>
    );
};

export function useGabaritoro() {
    return useContext(GabaritoroContext);
};