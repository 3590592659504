import React from 'react';
import { GraphBar } from '../Simulado/styles';


type Props = {
    progress: number;
}

const ChartBar = ({progress}: Props) => {
    return <GraphBar progress={progress}><p>{progress}%</p></GraphBar>

};

export default ChartBar;
