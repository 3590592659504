import { createSagaAction } from '../../shared/sagas'
import { REHYDRATE } from 'redux-persist/lib/constants'

import { createReducer } from '../../shared/reducers';

import { IEpisode } from '../../shared/Models/podcast/IEpisode';
import { IPlayer } from '../../shared/Models/podcast/IPlayer';
import { IPodcast } from '../../shared/Models/podcast/IPodcast';
import { IHistoric } from '../../shared/Models/podcast/IHistoric';
import { IAllowEpisode } from '../../shared/Models/podcast/IAllowEpisode';

export type PodcastState = {
    currentPodcast: IEpisode;
    currentPlay: IPlayer;
    historical: Array<IHistoric>;
    podcasts: Array<IPodcast>;
    isLoading: boolean;
    isTracking: boolean;
    lastCurrentTimeTrack: number;
    error: boolean;
    fullScreen: boolean;
    availableClassesEpisodes: Array<IAllowEpisode>;
};

export type PodcastStateAudioPlayerSelector = {
    currentPlay: IPlayer;
    currentPodcast: IEpisode;
    historical: Array<IHistoric>;
    lastCurrentTimeTrack: number;
}

type TrackFormDataType = IEpisode & {
    finished: boolean;
    currentTime: number;
    lastCurrentTimeTrack: number;
};

export const constants = {
    PODCAST_FETCH_ALL: createSagaAction('PODCAST_FETCH_ALL'),
    PODCAST_FETCH_BY_CLASS: createSagaAction('PODCAST_FIND_BY_CLASS'),
    PODCAST_TRACK: createSagaAction('PODCAST_TRACK'),
    PODCAST_SELECTED_ITEM: 'SELECTED_ITEM',
    PODCAST_OPEN_PLAYER_FOOTER: 'PODCAST_OPEN_PLAYER_FOOTER',
    PODCAST_CLOSE_PLAYER_FOOTER: 'PODCAST_CLOSE_PLAYER_FOOTER',
    PODCAST_PLAY: 'PODCAST_PLAY',
    PODCAST_PAUSE: 'PODCAST_PAUSE',
    PODCAST_SET_DURATION: 'PODCAST_SET_DURATION',
    PODCAST_SET_CURRENT_TIME: 'PODCAST_SET_CURRENT_TIME',
    PODCAST_SET_VELOCITY: 'PODCAST_SET_VELOCITY',
    PODCAST_FULLSCREEN: 'PODCAST_FULLSCREEN',
};

export const actions = {
    fetchAll: (success?: (payload: IPodcast) => void, error?: () => void) => ({
        type: constants.PODCAST_FETCH_ALL.ACTION,
        success,
        error
    }),
    fetchByClass: (courseId: number, tags: string, callback?: (payload?: IEpisode) => void) => ({
        type: constants.PODCAST_FETCH_BY_CLASS.ACTION,
        courseId,
        tags,
        callback
    }),
    track: (
        formData: TrackFormDataType, 
        callbackSuccess?: () => void, 
        callbackError?: () => void,    
    ) => ({
        type: constants.PODCAST_TRACK.ACTION,
        formData,
        callbackSuccess,
        callbackError,
    }),
    selectedItem: (episode: IEpisode, podcast: IPodcast) => ({
        type: constants.PODCAST_SELECTED_ITEM,
        episode,
        podcast
    }),
    openPlayerFooter: () => ({
        type: constants.PODCAST_OPEN_PLAYER_FOOTER
    }),
    closePlayerFooter: () => ({
        type: constants.PODCAST_CLOSE_PLAYER_FOOTER
    }),
    play: (callback?: () => void) => ({
        type: constants.PODCAST_PLAY,
        callback,
    }),
    pause: (callback?: () => void) => ({
        type: constants.PODCAST_PAUSE,
        callback,
    }),
    setDuration: (duration: number) => ({
        type: constants.PODCAST_SET_DURATION,
        duration
    }),
    setCurrentTime: (currentTime: number) => ({
        type: constants.PODCAST_SET_CURRENT_TIME,
        currentTime
    }),
    setVelocity: (velocity: number) => ({
        type: constants.PODCAST_SET_VELOCITY,
        velocity
    }),
    toggleFullScreen: (open: boolean) => ({
        type: constants.PODCAST_FULLSCREEN,
        open,
    })
};


const ACTION_HANDLERS = {
    // STUDENT_SET_CHANNELS
    [REHYDRATE]: (_: PodcastState, action: { payload: {podcast: PodcastState}}) => {
        const persisted = action.payload?.podcast ?? initialState;
        return {
            ...persisted,
            currentPlay: {
                ...persisted.currentPlay
            },
            currentPodcast: {
                ...persisted.currentPodcast
            }
        };
    },
    
    // FETCH_ALL
    [constants.PODCAST_FETCH_ALL.ACTION]: (state: PodcastState) => {
        return { ...state, error: false, isLoading: true }
    },
    [constants.PODCAST_FETCH_ALL.FAILED]: (state: PodcastState) => {
        return { ...state, error: false, isLoading: false }
    },
    [constants.PODCAST_FETCH_ALL.SUCCESS]: (state: PodcastState, action: { payload: IPodcast[] }) => {
        const availableClassesEpisodes: IAllowEpisode[] = [];
        let updateHistorical: IHistoric[] = [];
        
        action.payload.forEach(podcast => {
            podcast.episodes.forEach(episode => {
                if (episode.tags && availableClassesEpisodes) {
                    episode.tags.forEach(tag => {
                        const exist = availableClassesEpisodes.some(allowedClass => allowedClass.allowedEpisode === tag && allowedClass.allowedPodcast === podcast.tag);
                        if (!exist) {
                            availableClassesEpisodes.push({
                                allowedPodcast: podcast.tag,
                                allowedEpisode: tag,
                            });
                        }
                    });
                }
            });

            podcast.studentTracks.forEach(track => {
                const includes = updateHistorical.some(item => String(item.id) === String(track.id))
                if (!includes) {
                    updateHistorical.push(track)
                }
            });
        });


        if (!action.payload.length) {
            return {
                ...state,
                error: false,
                isLoading: false,
                podcasts: action.payload,
                currentPlay: initialPlay,
                currentPodcast: {},
                availableClassesEpisodes: [],
            };
        }

        return {
            ...state,
            error: false,
            isLoading: false,
            podcasts: action.payload,
            availableClassesEpisodes,
            historical: [
                ...updateHistorical
            ]
        }
    },
    // FETCH_BY_CLASS
    [constants.PODCAST_FETCH_BY_CLASS.ACTION]: (state: PodcastState) => {
        return { ...state, error: false, isLoading: true }
    },
    [constants.PODCAST_FETCH_BY_CLASS.FAILED]: (state: PodcastState) => {
        return { ...state, error: false, isLoading: false }
    },
    [constants.PODCAST_FETCH_BY_CLASS.SUCCESS]: (state: PodcastState, action: { payload: IEpisode }) => {
        const podcast = {};
        Object.assign(podcast, {
            id_podcast: action.payload.podcast.id_podcast,
            title: action.payload.podcast.title,
            author: action.payload.podcast.author,
            description: action.payload.podcast.description,
            tag: action.payload.podcast.tag,
        });

        return { 
            ...state, 
            error: false, 
            isLoading: false,
            currentPodcast: {
                ...action.payload,
                podcast
            },
            currentPlay: {
                ...initialPlay,
                source: action.payload.audio_source,
                id: action.payload.id,
                duration: action.payload.duration,
                currentTime: 0,
                isPlaying: false
            },
        }
    },

    [constants.PODCAST_TRACK.ACTION]: (state: PodcastState) => {
        return { ...state, error: false, isTracking: true }
    },
    [constants.PODCAST_TRACK.FAILED]: (state: PodcastState) => {
        return { ...state, error: false, isTracking: false }
    },
    [constants.PODCAST_TRACK.SUCCESS]: (state: PodcastState, action: {
        currentTime: number;
        id: string;
    }) => {
        const exist = state.historical.find(historic => String(action.id) === String(historic.id));

        let historical: IHistoric[] = [];
        if (exist) {
            historical = state.historical.map(historic => {
                if (String(action.id) === String(historic.id)) {
                    return {
                        ...historic,
                        currentTime: action.currentTime
                    };
                } 
                return historic;
            });
        } else {
            historical = [...state.historical, {
                id: String(action.id),
                currentTime: action.currentTime
            }];
        }

        return {
            ...state,
            error: false, 
            isTracking: false,
            lastCurrentTimeTrack: action.currentTime,
            historical: [
                ...historical
            ] 
        }
    },
    [constants.PODCAST_SELECTED_ITEM]: (state: PodcastState, action: { episode: IEpisode, podcast: IPodcast }) => {  
        const podcast = {};
        Object.assign(podcast, {
            id_podcast: action.podcast.id_podcast,
            title: action.podcast.title,
            author: action.podcast.author,
            description: action.podcast.description,
            tag: action.podcast.tag
        });

        return { 
            ...state,
            currentPodcast: {
                ...action.episode,
                podcast
            },
            currentPlay: {
                ...state.currentPlay,
                id: action.episode.id,
                duration: action.episode.duration,
                currentTime: 0,
                isPlaying: false,
                source: action.episode.audio_source,
            },
            historical: [
                ...state.historical,
            ]
        }
    },
    [constants.PODCAST_OPEN_PLAYER_FOOTER]: (state: PodcastState) => {
        return { 
            ...state,
            currentPlay: {
                ...state.currentPlay,
                isFooterPlayerOpen: true,
            }
        }
    },
    [constants.PODCAST_CLOSE_PLAYER_FOOTER]: (state: PodcastState) => {
        return { 
            ...state,
            currentPlay: {
                ...state.currentPlay,
                isPlaying: false,
                isFooterPlayerOpen: false,
            }
        }
    },
    [constants.PODCAST_PLAY]: (state: PodcastState) => {
        return { 
            ...state,
            currentPlay: {
                ...state.currentPlay,
                isPlaying: true
            }
        }
    },
    [constants.PODCAST_PAUSE]: (state: PodcastState) => {
        return { 
            ...state,
            currentPlay: {
                ...state.currentPlay,
                isPlaying: false,
            }
        }
    },
    [constants.PODCAST_SET_DURATION]: (state: PodcastState, action: { duration: number }) => {
        return {
            ...state,
            currentPlay: {
                ...state.currentPlay,
                duration: action.duration
            }
        }
    },
    [constants.PODCAST_SET_CURRENT_TIME]: (state: PodcastState, action: { currentTime: number }) => {
        return {
            ...state,
            currentPlay: {
                ...state.currentPlay,
                currentTime: action.currentTime
            }
        } 
    },
    [constants.PODCAST_SET_VELOCITY]: (state: PodcastState, action: { velocity: number, callback?: () => void }) => {    
        return {
            ...state,
            currentPlay: {
                ...state.currentPlay,
                velocity: action.velocity === 2.25 ? 1 : action.velocity,
            }
        }
    },
    [constants.PODCAST_FULLSCREEN]: (state:PodcastState, action: { open: boolean }) => {
        return {
            ...state,
            fullScreen: action.open
        };
    }
};

const initialPlay = {
    isFooterPlayerOpen: false,
    duration: 0,
    currentTime: 0,
    isPlaying: false,
    source: undefined,
    velocity: 1.0
};

export const initialState: PodcastState = {
    error: false,
    isLoading: true,
    isTracking: false,
    lastCurrentTimeTrack: 0,
    currentPlay: initialPlay,
    historical: [] as {id: string, currentTime: number}[],
    currentPodcast: {} as IEpisode,
    podcasts: [] as IPodcast[],
    fullScreen: false,
    availableClassesEpisodes: []    
};

export default createReducer(initialState, (state: PodcastState, action: any) => {
    const handler = ACTION_HANDLERS[action.type]

    return handler ? handler(state, action) : { ...state, isLoading: false }
});