import styled from 'styled-components';

export const Container = styled.div`
    background: linear-gradient(96.64deg, #222B82 26.19%, #000A66 102.4%);
    width: 100%;
    height: 20.76em;

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Img = styled.img`
    width: 17.605vw;
    height: 4.225vw;
`;
