import { all, fork, call, put, takeEvery } from 'redux-saga/effects'

import { constants } from '../modules/checkout';
import * as api from '../api/checkout';

import { notification } from 'antd'
import { noCourse } from '../../components/NewCheckout/shared/constants';

function* cleanCupom(action) {
    try {
        const payload = {}
      
        yield put({
            type: constants.CHECKOUT_CUPOM_CLEAR.SUCCESS,
            payload
        });
        
        action.next && action.next(payload);
    } catch (e) {
      yield put({
        type: constants.CHECKOUT_CUPOM_CLEAR.FAILED,
        message: e.message || e,
      });

      notification['error']({
        message: 'Falha ao limpar o cupom!',
      });
    }
};

function* cupomValidator(action) {
    try {
      const payload = yield call(api.cupomValidator, action.tagCourse, action.cupom, action.courseClassId);
      
      yield put({
        type: constants.CHECKOUT_CUPOM_VALIDATOR.SUCCESS,
        payload
      });
      
      action.next && action.next(payload);
    } catch (e) {

      yield put({
        type: constants.CHECKOUT_CUPOM_VALIDATOR.FAILED,
        message: e.message || e,
      });

      notification['error']({
        message: 'Cupom não encontrado ou não está mais disponível.',
      });
    }
};

function* getCourseDetailsByTag(action) {
    try {
        const payload = yield call(api.getCourseDetailsByTag, action.courseTag, action.id_turma, action.token)
        yield put({
            type: constants.CHECKOUT_COURSE_DETAIL.SUCCESS,
            payload
        })

        action.next && action.next(payload)
    } catch (e) {
        yield put({
            type: constants.CHECKOUT_COURSE_DETAIL.FAILED,
            message: e.message || e,
        });

        notification['error']({
            message: noCourse,
        });

        action.error && action.error(e);
    }
};


function* proccess(action) {
  try {
    const payload = yield call(api.proccess, action.formData, action.token)
    yield put({
        type: constants.CHECKOUT_PROCCESS.SUCCESS,
        payload
    });

    action.next && action.next(payload);
  } catch (e) {
    yield put({
    type: constants.CHECKOUT_PROCCESS.FAILED,
    message: e.message || e,
    });

    notification['error']({
        message: e.message || e,
    });
    action.nextError && action.nextError(e);
  }
};

function* saveByStep(action) {
    try {
        const payload = yield call(api.saveByStep, action.formData);
        yield put({
            type: constants.CHECKOUT_SAVE_BY_STEP.SUCCESS,
            payload
        });

        action.success && action.success();
    } catch (e) {
        yield put({
            type: constants.CHECKOUT_SAVE_BY_STEP.FAILED,
            message: e.message || e
        });

        action.error && action.error();
    } 
};


function* checkStudent(action) {
    try {
        const payload = yield call(api.findStudentByCPFAndEmail, action.cpf, action.email, action.token)
        yield put({
            type: constants.CHECKOUT_CHECK_STUDENT.SUCCESS,
            payload
        });
  
        action.success && action.success(payload);
    } catch (e) {
        yield put({
            type: constants.CHECKOUT_PROCCESS.FAILED,
            message: e.message || e,
        });

        action.error && action.error(e);
    }
};

function* checkEmail(action) {
    try {
        const payload = yield call(api.findStudentByEmail, action.email);
        yield put({
            type: constants.CHECKOUT_CHECK_STUDENT.SUCCESS,
            payload
        });
        
        action.success && action.success(payload);
    } catch(e) {
        yield put({
            type: constants.CHECKOUT_CHECK_STUDENT.FAILED,
            message: e.message || e,
        });

        action.error && action.error(e);
    }
}

function* watchCupomClear() {
    yield takeEvery(constants.CHECKOUT_CUPOM_CLEAR.ACTION, cleanCupom);
};

function* watchCupomValidator() {
    yield takeEvery(constants.CHECKOUT_CUPOM_VALIDATOR.ACTION, cupomValidator);
};

function* watchGetCourseDetailsByTag() {
    yield takeEvery(constants.CHECKOUT_COURSE_DETAIL.ACTION, getCourseDetailsByTag);
};

function* watchProccess() {
    yield takeEvery(constants.CHECKOUT_PROCCESS.ACTION, proccess);
};

function* watchSaveByStep() {
    yield takeEvery(constants.CHECKOUT_SAVE_BY_STEP.ACTION, saveByStep);
};

function* watchCheckStudent() {
    yield takeEvery(constants.CHECKOUT_CHECK_STUDENT.ACTION, checkStudent);
};

function* watchCheckEmail() {
    yield takeEvery(constants.CHECKOUT_CHECK_EMAIL.ACTION, checkEmail);
}

export function* rootSaga() {
    yield all([
        fork(watchCupomClear),
        fork(watchCupomValidator), 
        fork(watchGetCourseDetailsByTag), 
        fork(watchProccess), 
        fork(watchCheckStudent),
        fork(watchCheckEmail),
        fork(watchSaveByStep)
    ])
};
