
import { all, fork, call, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import { constants, ErrorCallback, FormDataFillAnswersProps, FormDataMakeTokenProps, FormDataStoreLeadProps, SuccessCallback } from '../modules/gabarito';
import * as api from '../api/gabarito';
import { IGabarito } from '../../shared/Models/gabarito/IGabarito';
import { IGabaritoStudent } from '../../shared/Models/gabarito/IGabaritoStudent';
import { IModule } from '../../shared/Models/gabarito/IModule';

type ActionProps = {
    type: string;
    success: SuccessCallback;
    error: ErrorCallback;
};

type FetchAllActionProps = ActionProps & {
    filters: {};
};

type StoreLeadActionProps = ActionProps & {
    formData: FormDataStoreLeadProps;
};

type GetByTokenActionProps = ActionProps & {
    token: string;
};

type MakeTokenActionsProps = ActionProps & {
    formData: FormDataMakeTokenProps
};

type FillAnswersActionProps = ActionProps & {
    formData: FormDataFillAnswersProps;
    token: string;
};

type GetModulesByIdProps = ActionProps & {
    gabaritoId: number;
};

type ResendResult = ActionProps & {
    gabaritoId: number;
    email: string;
};

// functions
function* fetchAll(action: FetchAllActionProps) {
    try {
        const payload: IGabarito[] = yield call(api.fetchAll, action.filters);
        yield put({ type: constants.GABARITO_FETCH_ALL.SUCCESS, payload });

        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;

        yield put({
            type: constants.GABARITO_FETCH_ALL.FAILED,
            error: true,
            message
        });

        notification.error({
            message
        });

        action.error && action.error();
    }
};

function* storeLead(action: StoreLeadActionProps) {
    notification.info({
        key: 'gabarito_store_lead',
        message: 'Salvando dados...'
    });
    
    try {
        const payload: {token: string} = yield call(api.storeLead, action.formData);
        yield put({ type: constants.GABARITO_STORE_LEAD.SUCCESS, payload });

        notification.close('gabarito_store_lead');    
        notification.success({
            message: 'Dados salvos com sucesso!'
        });

        action.success && action.success(payload);
    } catch (e: any) {
        notification.close('gabarito_store_lead');

        const message = e.message || e;

        yield put({
            type: constants.GABARITO_STORE_LEAD.FAILED,
            error: true,
            message
        });

        notification.error({
            message
        });

        action.error && action.error();
    }
};

function* getStudentByToken(action: GetByTokenActionProps) {
    try {
        const payload: IGabaritoStudent = yield call(api.getStudentByToken, action.token);
        yield put({ type: constants.GABARITO_GET_STUDENT_BY_TOKEN.SUCCESS, payload });

        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;

        notification.error({
            message
        });

        yield put({
            type: constants.GABARITO_GET_STUDENT_BY_TOKEN.FAILED,
            error: true,
            message
        });

        action.error && action.error();
    }
};

function* getGabaritoByToken(action: GetByTokenActionProps) {
    try {
        const payload: IGabarito = yield call(api.getGabaritoByToken, action.token);
        yield put({ type: constants.GABARITO_GET_GABARITO_BY_TOKEN.SUCCESS, payload });
        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;

        notification.error({
            message
        });

        yield put({
            type: constants.GABARITO_GET_GABARITO_BY_TOKEN.FAILED,
            error: true,
            message
        });

        action.error && action.error();
    }
};

function* makeTokenExistsStudent(action: MakeTokenActionsProps) {
    try {
        const payload: { token: string; } = yield  call(api.makeTokenExistsStudent, action.formData);

        yield put({ type: constants.GABARITO_MAKE_TOKEN_EXISTS_USER.SUCCESS, payload });
         
        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;

        notification.error({
            message
        });

        yield put({
            type: constants.GABARITO_MAKE_TOKEN_EXISTS_USER.FAILED,
            error: true,
            message
        });

        action.error && action.error();
    }
}

function* fillAnswers(action: FillAnswersActionProps) {
    try {
        const payload: IGabarito = yield call(api.fillAnswers, action.formData, action.token);
        yield put({ type: constants.GABARITO_FILL_ANSWERS.SUCCESS, payload });
         
        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;

        notification.error({
            message
        });

        yield put({
            type: constants.GABARITO_FILL_ANSWERS.FAILED,
            error: true,
            message
        });

        action.error && action.error();
    }
}

function* getModulesByIdGabarito(action: GetModulesByIdProps) {
    try {
        const payload: IModule[] = yield call(api.getModulesByIdGabarito, action.gabaritoId);
        yield put({ type: constants.GABARITO_GET_MODULES_BY_ID.SUCCESS, payload });
        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;

        notification.error({
            message
        });

        yield put({
            type: constants.GABARITO_GET_MODULES_BY_ID.FAILED,
            error: true,
            message
        });

        action.error && action.error();
    }
};

function* resendResult(action: ResendResult) {
    try {
        const payload: boolean = yield call(api.resendResult, action.gabaritoId, action.email);
        yield put({
            type: constants.GABARITO_RESEND_RESULT.FAILED,
            payload
        });

        notification.success({
            message: 'Email enviado com sucesso !'
        });

        action.success && action.success(payload);
    } catch (e: any) {
        const message = e.message || e;
        notification.error({
            message
        });

        yield put({
            type: constants.GABARITO_RESEND_RESULT.FAILED,
            error: true
        });

        action.error && action.error();
    }
};


function* watchFetchStudents() {
    yield takeLatest(constants.GABARITO_FETCH_ALL.ACTION, fetchAll);
};

function* watchStoreLead() {
    yield takeLatest(constants.GABARITO_STORE_LEAD.ACTION, storeLead);
};

function* watchGetStudentByToken() {
    yield takeLatest(constants.GABARITO_GET_STUDENT_BY_TOKEN.ACTION, getStudentByToken);
};

function* watchGetResultByToken() {
    yield takeLatest(constants.GABARITO_GET_GABARITO_BY_TOKEN.ACTION, getGabaritoByToken);
};

function* watchMakeTokenExistsStudent() {
    yield takeLatest(constants.GABARITO_MAKE_TOKEN_EXISTS_USER.ACTION, makeTokenExistsStudent);
};

function* watchFillAnswers() {
    yield takeLatest(constants.GABARITO_FILL_ANSWERS.ACTION, fillAnswers);    
}

function* watchGetModulesByIdGabarito() {
    yield takeLatest(constants.GABARITO_GET_MODULES_BY_ID.ACTION, getModulesByIdGabarito);
}

function* watchResendResult() {
    yield takeLatest(constants.GABARITO_RESEND_RESULT.ACTION, resendResult);
};

export function* rootSaga() {
    yield all([
        fork(watchFetchStudents),
        fork(watchStoreLead),
        fork(watchGetStudentByToken),
        fork(watchGetResultByToken),
        fork(watchMakeTokenExistsStudent),
        fork(watchFillAnswers),
        fork(watchGetModulesByIdGabarito),
        fork(watchResendResult)
    ]);
};