import React from 'react';
import TextsHeader from './TextsHeader';
import { Content } from './styles';
import BarchartH from './Report/Charts/BarChartH/BarChartH';
import LegendChart from './Report/Charts/SubtitleChart';

function MediaPonderada(props) {
  const data = props.data;
  const average = data?.average?.acertos_avg * 100;
  const fortmattedAverage = isNaN(Math.round(average)) === true ? 0 : Math.round(average);

  return (
    <div style={{ width: '85vw', marginTop: "-370px" }}>
      <TextsHeader>
        <p className="text-media">
          Logo abaixo apresento a Média PONDERADA do seu desempenho em todos os Simulados realizados (média de todas as questões que você fez na plataforma) e seu desempenho por MÓDULO. Esta orientação está baseada na nossa ferramenta própria, a ferramenta PIA
          (peso-importância-absorção).
        </p>
      </TextsHeader>

      <Content style={{marginTop: "200px"}}>
        <p>MÉDIA GERAL DOS SIMULADOS</p>
        <BarchartH
          height='4vw'
          percent={Math.round(average)}
          percentText={`${fortmattedAverage}%`}
          colorbk1={'#F89C3A'}
          displaySubtitle={'none'}
          colorText={'white'}
          total={'100%'}
          zIndex={1}
        />

        <BarchartH
          height='4vw'
          percent={80}
          percentText={'80%'}
          colorbk1={'#000A66'}
          displaySubtitle={'none'}
          colorText={'white'}
          total={'100%'}
          zIndex={1}
        />
        <LegendChart
          displaySubtitle={'flex'}
          subtitleMin={"seu desempenho"}
          subtitleRecommended={"recomendado"}
          positionSubtitle={'left'}
        />
      </Content>
    </div>
  );
};

export default MediaPonderada;
