import React from 'react'
import ReactDOM from 'react-dom'
// import { debugContextDevtool } from 'react-context-devtool'
import registerServiceWorker from './registerServiceWorker'
import App from './App'


const container = document.getElementById('root')

ReactDOM.render(<App />, container)

registerServiceWorker()