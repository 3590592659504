import React from 'react';

import { LectureQuestionsArea, SelectedAnswer } from './styles';

import { AnswerType, LessonLectureGoingType, QuestionType } from 'pages/lectures/utils/types';
import { Title } from 'pages/lectures/styles';
import { SubTitle } from 'pages/lectures/components/commons/styles';

export function LecturesQuestions({
  checkedAnswersDontKnow,
  currentLecture,
  handleAnswersQuestion,
  lectureAnswers,
  questionSelected,
  reviewLecture,
}: LecturesQuestionsProps) {
  return (
    <LectureQuestionsArea data-testid="lecturesquestions-area">
      <SubTitle data-testid="lecturesquestions-subtitle">
        Questão ({questionSelected?.codigo}){' '}
        {questionSelected?.pivot?.ordem?.toString().padStart(2, '0')}/
        {currentLecture?.length.toString().padStart(2, '0')}
      </SubTitle>
      <Title textAlign="left" data-testid="lecturesquestions-title">
        {questionSelected.enunciado}
      </Title>
      {questionSelected.answers?.map((answer: AnswerType) => {
        const hasLecturesAnswers = Object.keys(lectureAnswers).length > 0;
        const checked =
          (hasLecturesAnswers &&
            !reviewLecture.isReview &&
            lectureAnswers.question[questionSelected.id_questao] === answer.id_resposta) ||
          answer?.marcada;

        let classAnswersReview = '';
        let dataTestId = 'lecturesquestions-answer-question-data';
        if (reviewLecture.isReview) {
          if (answer?.correta === 1) {
            classAnswersReview = 'corrected';
            dataTestId = 'lecturesquestions-answer-question-corrected-question';
          } else {
            classAnswersReview = 'wrong';
            dataTestId = 'lecturesquestions-answer-question-wrong-question';
          }
        }

        return (
          <SelectedAnswer
            key={`answer-${answer.id_resposta}-${Math.random()}`}
            htmlFor={`answer-${answer.id_resposta}`}
            data-testid="lecturesquestions-selected-answer"
          >
            <input
              type="radio"
              id={`answer-${answer.id_resposta}`}
              name="answer"
              value={answer.id_resposta}
              onChange={(e) => handleAnswersQuestion(e.target.value)}
              checked={checked}
              disabled={reviewLecture.isReview}
              hidden
              data-testid={
                reviewLecture.isReview ? dataTestId : 'lecturesquestions-answer-question-data'
              }
            />
            <span
              className={classAnswersReview}
              data-testid={
                reviewLecture.isReview
                  ? dataTestId + '-answer-check'
                  : 'lecturesquestions-answer-question-data-answer-check'
              }
            >
              <div className="checkbox-answer" data-testid="lecturesquestions-checkbox-answer" />
              {answer.resposta}
            </span>
          </SelectedAnswer>
        );
      })}
      <SelectedAnswer
        htmlFor={`answer-${questionSelected.id_questao}+idk`}
        data-testid="lecturesquestions-selected-answer-idk"
      >
        <input
          type="radio"
          id={`answer-${questionSelected.id_questao}+idk`}
          name="answer"
          value="idk"
          onChange={(e) => handleAnswersQuestion(e.target.value)}
          checked={checkedAnswersDontKnow}
          disabled={reviewLecture.isReview}
          hidden
          data-testid="lecturesquestions-answer-question-idk"
        />
        <span data-testid="lecturesquestions-span-idk">
          <div className="checkbox-answer" data-testid="lecturesquestions-checkbox-answer-idk" />
          Não sei
        </span>
      </SelectedAnswer>
    </LectureQuestionsArea>
  );
}

interface LecturesQuestionsProps {
  questionSelected: QuestionType;
  currentLecture: QuestionType[];
  lectureAnswers: LessonLectureGoingType;
  reviewLecture: {
    isReview: boolean;
    testId: string;
  };
  checkedAnswersDontKnow: boolean;
  handleAnswersQuestion: (value: string) => void;
}
