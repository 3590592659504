import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions as cashbackActions } from '../../../redux/modules/cashback';

import FormCoupon from '../../../components/Cashback/FormCoupon';
import Statement from '../../../components/Cashback/Statement';
import Balance from '../../../components/Cashback/Balance';
import MainLayout from '../../../components/MainLayout';
import HeaderInside from '../../../components/HeaderInside';

import { Container, Content } from './styles';

export default function Cashback() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cashbackActions.fetchTransactions());
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout className="cashback">
      <Container>
        <HeaderInside title="Carteira" />
        <Content>
          <section className="box-historic">
            <Balance />
            <Statement />
          </section>
          <FormCoupon />
        </Content>
      </Container>
    </MainLayout>
  );
};