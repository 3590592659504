export const calculateRightAns = ({ ansArray }) => {
  let rightAns = 0

  ansArray.forEach(ans => {
    if (ans?.resposta?.correta === 1) {
      rightAns++
    }
  });

  return rightAns
}
