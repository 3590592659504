import { Bounce, TypeOptions, toast as toastMessage } from 'react-toastify';

export function toast({ message, type }: toastProps) {
  toastMessage(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
    transition: Bounce,
    type: type,
  });
}

interface toastProps {
  message: string;
  type: TypeOptions;
}
