import React from 'react';
import PropTypes from 'prop-types'
import { Button, Modal, Row } from 'antd';

function VideoTypeModal({modalVideoOption, setModalVideoOption, setLSBVideo, setQuestionVideoStart}) {
  return (
    <Modal
      visible={modalVideoOption}
      destroyOnClose={true}
      onCancel={() => setModalVideoOption(false)}
      footer={false}
      title='Tipo de video'
    >
      <Row>
        <Button
          block
          size="large"
          onClick={() => {
            setLSBVideo(false);
            setQuestionVideoStart(true);
          }}>
            Vídeo
          </Button>
      </Row>
      <Row>
        <Button
          block
          size="large"
          onClick={() => {
            setLSBVideo(true);
            setQuestionVideoStart(true);
          }}>
          Vídeo em Libras
        </Button>
      </Row>
    </Modal>
  );
}

VideoTypeModal.propTypes = {
  modalVideoOption: PropTypes.bool.isRequired,
  setModalVideoOption: PropTypes.func.isRequired,
  setLSBVideo: PropTypes.func.isRequired,
  setQuestionVideoStart: PropTypes.func.isRequired,
}

export default VideoTypeModal;