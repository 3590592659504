import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 78px;
    width: 742px;
    @media(max-width: 720px) {
        width: 98%;
        padding: 10px;
    }
    height: 522px;

    background-color: #FFFFFF;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
        font-size: 4.625rem;
        color: #F89C3A;
    }

    h1 {
        margin-top: 4.375rem;
        font-weight: 700;
        font-size: 1.625rem;
        color: #000000;
        text-align: center;
    }

    h2 {
        margin-top: 1.875rem;
        font-weight: 500;
        font-size: 1.375rem;
    }

    button {
        margin-top: 3.75rem;

        width: 297px;
        height: 45px;
        background-color: #F89C3A;
        border-radius: 4px;
        border:0;

        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

        cursor: pointer;

        transition: filter 0.2s;

        &:hover {
            filter: brightness(0.8);
        }
    }
`;