import styled from 'styled-components';


interface ButtonProps {
    center: boolean;
}

export const Container = styled.div`
    position: relative;
    background: #FFF;
    box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);

    padding: 1.9375rem 3.125em;

    @media(max-width: 650px) {
        padding: 1.25em;
    }

    .descriptions {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
        font-size: 1rem;
    }

    .progressbar {
        margin-top: 3rem;
    }

    .title {
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            margin-top: 3.5rem;
            font-size: 1.5rem;
            @media(max-width: 720px) {
                font-size: 1.25rem;
            }
            font-weight: 500;
            text-align: center;
        }

    }

    .header-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-top: 7rem;

        h1 {
            font-weight: 700;
            font-size: 2rem;
        }

        h2 {
            font-weight: 500;
            font-size: 1.5rem;
        }
    }

    .header-expanded {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 8rem;

        background-color: #FFF;
        box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.11);

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 0 4.375rem; 
        @media(max-width: 560px) {
            padding: 0 2rem;
        }
        & > .info {
            h1 {
                font-weight: 700;
                font-size: 1.875rem;
                @media(max-width: 560px) {
                    font-size: 1.1rem;
                }
            }

            h2 {
                font-weight: 500;
                font-size: 1.5rem;
                @media(max-width: 560px) {
                    font-size: 0.85rem;
                }
            }
        }

        & > .status {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            h2 {
                font-weight: 500;
                font-size: 1.25rem;
                @media(max-width: 560px) {
                    font-size: 0.85rem;
                }
            }
        }
    }
`;

export const AnswersWrapper = styled.div`
    
`;

export const WrapperArrows = styled.div`
    @media (max-width: 720px) {
        display:none;
    }
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    padding: 1% 8%;

    display: flex;
    justify-content: space-between;

    svg {
        font-size: 2rem;
        color: ${({ theme }) => theme.colors.warning};
        font-weight: bold;

        cursor: pointer;
        transition: filter 2s;

        &:hover {
            filter: brightness(0.5);
        }
    }
`;

export const ButtonsWrapper = styled.div<ButtonProps>` 
    margin-top: 2rem;

    display: flex;
    justify-content: ${({ center }) => center ? 'center' : 'space-between'};

    button {
        border-radius: 4px;
        box-sizing: border-box;
        width: 11rem;
        height: 2.8125rem;
        border: none;

        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.0625rem;

        cursor: pointer;

        transition: filter 0.3s;

        &:hover {
            filter: brightness(0.8);
        }

        &.back {
            background-color: transparent;
            border: 2px solid ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.primary};
        }

        &.next {
            background-color: ${({ theme }) => theme.colors.warning};
            color: #FFF;

            svg {
                color: white;
            }
        }
    }

    span.back-top {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
    }

    .container-back-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button.next {
            margin-top: 2rem;
        }
    }
`;

export const BoxLoading = styled.div`
    height: 200px;

    display: flex;
    
    justify-content: center;
    align-items: center;
`;

