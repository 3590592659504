import styled, { css } from 'styled-components';
import { Modal, Radio, Button } from 'antd'
import stopwatch from '../../../../../../img/stopwatch.svg';
import pause from '../../../../../../img/pause.svg';
import play from '../../../../../../img/play.svg';


type Props = {
    visibleUnanswered?: boolean;
    wideVersion?: boolean;
};

type ProgressProps = {
    percent: number;
};

type AnswersItemProps = {
    checked: boolean;
    wrong: boolean;
    correct: boolean;
}

export const Container = styled(Modal)`
    width: 100% !important;
    max-width: 100% !important;
    height: 100vh !important;
    max-height: 100vh !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;

    .ant-modal-content {
        height: 100vh !important;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-modal-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }

    @media only screen and (max-width: 650px) {
        height: auto !important;
        max-height: auto !important;

        .ant-modal-content {
            height: auto !important;
            display: block !important;

            .ant-modal-footer {
                position: relative;
                bottom: auto;
            }
        }
    }
`;

export const Box = styled.div<Props>`
    margin: 0 auto;
    width: calc(100vw - 100px);    
    background: #fff;
    height: calc(100vh - 150px);
    max-height: calc(100vh - 150px);
    overflow: hidden;

    @media only screen and (max-width: 1000px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 52px);
        max-height: calc(100vh - 52px);
    }

    @media only screen and (max-width: 650px) {
        position: relative;
        max-height: none;
        height: auto;
        padding-bottom: 80px;
    }
`;

export const BoxTwo = styled.div<{withButton?: boolean}>`
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    max-height: calc(100vh - 220px);

    ${({ withButton }) => withButton ? css`
        margin-bottom: 75px;
    ` : css`
        margin-bottom: 0;
    `}

    @media only screen and (max-width: 1000px) {
        margin-bottom: 0;
    }

    @media only screen and (max-width: 650px) {
        max-height: none;
    }

    & > div {
        flex-direction: column;
        flex-basis: 100%;
        flex: 1;
        max-width: 100%;
    }
`;

export const BoxAnswer = styled.div<Props>`
    ${({ visibleUnanswered, wideVersion }) => (visibleUnanswered && wideVersion) && css`
        width: 75%;
    `}
`;

export const Header = styled.header`
    display: flex;
    background: linear-gradient(100.59deg, #D81E5B -8.92%, #000A66 120.67%);
    padding: 0 60px !important;
    min-height: 80px;

    @media only screen and (max-width: 650px) {
        padding: 20px 20px 20px 50px !important;
    }
`;

export const TitleQuestionArea = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-bottom: 0px !important;
    color: #fff !important;

    @media only screen and (max-width: 650px) {
        font-size: 18px !important;
    }
  }

  @media only screen and (max-width: 700px) {
    justify-content: flex-end;
    
    h1:not('.onlyTitle') {
        display: none;
    }
  }

  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
  }

  time {
    padding-left: 32px;
    background: url(${stopwatch}) no-repeat;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 113px;
    height: 40px;
    background: #FF4D4F;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;

    span {
        display: flex;
        width: 24px;
        height: 24px;
        background: url(${pause}) no-repeat;
    }
  }

  button.stopped span {
    background: url(${play}) no-repeat center;
  }
`;

export const QuestionIdentification = styled.p`
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;

    span {
        font-weight: 400;
        font-size: 18px;
    }
`;

export const QuestionStatement = styled.p`
    margin-bottom: 15px;
    white-space: pre-wrap;
`;

export const RadioStyled = styled(Radio)`
    padding: 10px 18px;
    background: #EFEFEF;
    border: 2px solid #EFEFEF;
    border-radius: 5px;
    width: 100%;

    ${({ checked }) => checked && css`
        border-color: #5768FF !important;
        background: #F0F2FF !important;
    `}
`;

export const QuestionFooter = styled.div`
   
`;

export const QuestionPagination = styled.div<{border?: boolean}>`
    ${({ border }) => border ? css`
        border-bottom: 1px solid #999;
        margin-bottom: 20px;
        padding: 20px;
    ` : css`
        padding: 20px 50px;
    `}

    @media only screen and (max-width: 650px) {
        padding: 20px 0 !important;
    }
    
    & > div {
        padding: 5px;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
    }
`;

export const QuestionProgress = styled.div<ProgressProps>`
    height: 8px;
    background: #E4E4E4;
    margin-bottom: 25px;

    p {
        position: relative;
        height: 8px;
        background: #5768FF;
        border-radius: 0px 5px 5px 0px;
        text-indent: -5000em;

        ${({ percent }) => percent ? css`
            width: ${percent}%;
        ` : `
            width: 0;
        `}
        
        strong {
            display: block;
            position: absolute;
            bottom: -25px;
            right: 0;
            text-indent: 0;
            font-family: 'Work Sans';
            font-weight: 500;
            font-size: 16px;
            color: #5768FF;

            ${({ percent }) => (!percent || percent < 11) && css`
                right: -25px;
            `}

            ${({ percent }) => (!percent || percent > 90) && css`
                right: 20px;
            `}
        }
    }
`;

export const QuestionResult = styled.p`
    padding: 30px 0 0 60px;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;

    @media only screen and (max-width: 650px) {
        padding: 30px 0 0 40px;
        font-size: 18px;
    }
`;

export const QuestionAnsewers = styled.ul``;

export const QuestionAnsewersItem = styled.li<AnswersItemProps>`
    display: flex;
    align-items: center;
    gap: 14px;
    margin-bottom: 10px !important;
    padding: 10px 18px;
    background: #EFEFEF;
    border: 2px solid #EFEFEF;
    border-radius: 5px;
    width: 100%;

    ${({ checked, wrong, correct }) => {
        if((checked && !wrong) || correct) {
            return css`
                border-color: #62CF7A !important;
                background: #F7FFF0 !important;
            `;
        } else if(checked && wrong) {
            return css`
                border-color: #D81E5B !important;
                background: #FFEAF1 !important;
            `;
        }
    }}
`;

export const ButtonStyled = styled(Button)`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    margin: 10px 0;
    width: 234px;
    height: 40px;
    background: #5768FF;
    border: 1px solid #5768FF;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
    border-radius: 5px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #fff;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 20px;
`;