import React, { useState, useEffect } from 'react';
import { Container, Box, Question } from '../styles/UnansweredMobile';
import _ from 'lodash'

type Question = {
    codigo: string;
    id_questao: number;
    pivot: {
        ordem: number;
    }
};

type Props = {
    questions: Array<Question>;
    answers: Array<any>;
    redoAnswers: Array<any>;
    handleQuestionByIndex: (index: number) => void;
    currentQuestion: {
        id_questao: number;
    }; 
};

const UnansweredMobile = ({ 
    questions, 
    answers, 
    redoAnswers, 
    handleQuestionByIndex,
    currentQuestion: _currentQuestion
}: Props) => {     
    const [currentQuestion, setCurrentQuestion] = useState<number | undefined>(_currentQuestion?.id_questao ?? undefined);

    function handleChangeQuestion(question: Question, index: number) {
        handleQuestionByIndex(index);
        setCurrentQuestion(question.id_questao);
    }

    useEffect(() => {
        if (_currentQuestion?.id_questao) {
            setCurrentQuestion(_currentQuestion.id_questao);
        }

    }, [_currentQuestion]);

    return (
        <Container>
            <h3>NÃO RESPONDIDAS</h3>
            <Box>
                {
                    questions.map((question: Question, index: number) => {
                        const forgotten = !_.keys(answers).find((item) => question.id_questao === Number(item));
                        return (
                            <Question 
                                forgotten={forgotten ?? false}
                                active={question.id_questao === currentQuestion} 
                                key={question.id_questao}
                                onClick={() => handleChangeQuestion(question, index)}    
                            >
                                {(index + 1)}
                            </Question>
                        );
                    })
                }
            </Box>
        </Container>
    );
};

export default UnansweredMobile;