import React from 'react';
import { Container, Progress } from './styles';
import { useTheme } from 'styled-components';

import { RENDER_CAPTIONBAR_BY_PERCENT } from '../../../pages/CFP/common';

type Props = {
    percent: number;
    description: string;
    color?: string;
    background?: string;
};

export default function LegendBarChar ({
    percent,
    description,
    background,
    color='#000a6666',
}: Props) {
    const theme = useTheme();
    return (
        <Container background={background ?? theme.colors.gray_75}>
            <Progress percent={percent} color={color ?? '#000a6666'} className="report-cfp-legendbar">
                <span className="legend-bar-char__percent">{percent}%</span>
                {
                    !!(percent >= RENDER_CAPTIONBAR_BY_PERCENT) && (
                        <span className="legend-bar-char__description">{description}</span>
                    )
                }
            </Progress>
            {
                !!(percent < RENDER_CAPTIONBAR_BY_PERCENT) && (
                    <span className="legend-bar-char__description-out">{description}</span>
                )
            }
        </Container>
    );
};
