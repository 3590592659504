import request, { makeURLToken } from '../../shared/request'

export function fetchById(id) {
  return request(`/v1/crud/aluno/${id}`)
}

export async function fetchScoreToro(params) {
  const body = {
    students:  params.students,
    id_team: params.id_team,
    id_curse: params.id_curse,
    metrics: [
      'avarage',
      'score',
      'provided',
      'max'
    ]
  };

  return request(`/v1/services/student/metrics?date_start=${params.date_start}&date_end=${params.date_end}`, {
    method: 'POST',
    body: JSON.stringify(body)
  });
}

export async function fetchReportCFP(courseClassId, courseId) {
  return request(`/v1/services/student/report-cfp-all/${courseClassId}/${courseId}`);
}

export async function fetchReportSimulatedFullResult(courseClassId, courseId, classId) {
  return request(`/v1/services/student/simulated-full-result/${courseClassId}/${courseId}/${classId}`);
}

export function edit(formData) {
  return request(`/v1/crud/aluno/${formData.id}`, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
}

export function fetchByStudentId(id) {
  return request(`v1/services/aluno/enrollments/${id}`)
}

export function changeStudentPassword(formData){
  const { userId } = formData;
  return request(`/v1/services/aluno/${userId}/trocarsenha`, {
    method: 'POST',
    body: JSON.stringify(formData),
  })
}

export function storeCommunicationsChannels(formData) {
  return request(`/v1/usuario-channels/${formData.type}`, {
    method: 'POST',
    body: JSON.stringify(formData)
  }, {'x-api-key': makeURLToken()});
}

export function saveScheduledTest(formData) {
  return request('/v1/services/student/scheduled-test', {
    method: 'POST',
    body: JSON.stringify(formData)
  });
};

export function trackDownloadReport(formData) {
  return request('/v1/services/student/report-cfp/track', {
    method: 'POST',
    body: JSON.stringify(formData)
  });
};

export function getScoreToroSugestionsByType(categoryCourseId, typeReportId, categoryReportId) {
    return request(
        `/v1/report/score-information-by-types?id_course_category=${categoryCourseId}&id_type_report=${typeReportId}&id_category_report=${categoryReportId}`
    );
};

export function handleCfpSimulado(userId, courseId, classId, media, start, end, kind, classes) {
  try {
    return request(`/v1/report/course/cfp?user_id=${userId}&course_id=${courseId}&class_id=${classId}&media=${media}&start=${start}&end=${end}&kind=${kind}&classes=${classes}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: ''
    });
  } catch (e) {
    console.log('deu ruim');
  }
};

export async function handleChartCFP(userId, courseId, classId, start, end ) {
  try {
    const response = await request(`/v1/report/course/cfp?user_id=${userId}&course_id=${courseId}&class_id=${classId}&media=pdf&start=${start}&end=${end}&kind=aula&classes=31,44,50,56,1608,1611,1612,1613,1614,1615,1616,1617,1618,1619,1620,1621,1622,1623,1624,1625,1626,1627,1628,1629,1630,1631,1632,1633,1634,1635`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: ''
  });

  return response;
  } catch (error) {
    return error;
  }

};

export async function confirmExam(courseId, courseClassId, modules) {
  try {
    const response = await request(`/v1/services/student/confirm-exam/${courseId}/${courseClassId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(modules),
    });

    return response;
  } catch (e) {
    console.log(e);
    return {
      status: false,
      errorMessage: e.message,
    };
  }
};

export async function getConfirmExamStatus(courseClassId) {
  try {
    const response = await request(`/v1/services/student/confirmed-exam/${courseClassId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    return response;
  } catch (error) {
    console.log(error);
    return {
      status: false,
      errorMessage: error.message,
    };
  }

};


export async function getDataFinancialInstitions(){
  try {
    const response = await request(`/v1/data/financialInstitutions`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    
    return response.map(item => ({ 
      value: item.code,
      label: item.name
     }));
  } catch (error) {
    return []
  }
  
}

