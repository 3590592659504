import React from 'react';
import { ReactComponent as CalendarSVG } from './Calendar.svg';
import { ReactComponent as BookSVG } from './Book.svg';
import * as S from './styles'

export interface Props {
  type: 'Calendar' | 'PDF';
  onClick: () => void;
  titleButton: string;
  titleClass: string;
  href?: string;
  target?: string;
  rel?: string;
}

export default function CardMaterial({titleButton, type='Calendar', onClick, titleClass, href, target, rel}: Props) {
  return (
    <S.BoxMaterial>
      <S.CardMaterial>
        <S.DescriptionTitle data-testid="title-description">{titleClass}</S.DescriptionTitle>

            <S.Button data-testid="button"  onClick={onClick} href={href} target={target} rel={rel}>
              <S.TitleButton data-testid="title-button">{titleButton}</S.TitleButton>
              {type === 'Calendar' ? (
              <CalendarSVG />
              ) : (
                <BookSVG />
              )}
            </S.Button>

      </S.CardMaterial>
    </S.BoxMaterial>
  );
}
