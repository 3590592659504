import { all, fork, call, put, takeLatest } from 'redux-saga/effects'

import { notification } from 'antd'
import * as api from '../api/mentorship'
import { constants } from '../modules/mentorship'

function* getAllAvalibleClassToMentorship(action) {
  try {
    const payload = yield call(api.getAllAvalibleClassToMentorship)
    yield put({ type: constants.GET_ALL_AVALIBLE_CLASS.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_ALL_AVALIBLE_CLASS.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* askForMentorship(action) {
  try {
    notification.info({
      key: 'ask_mentorship',
      message: 'Solicitando mentoria',
    })
    const payload = yield call(api.askForMentorship, action.formData)
    yield put({ type: constants.ASK_FOR_MENTORSHIP.SUCCESS, payload })
    notification.close('ask_mentorship')
    notification.success({
      message: 'Mentoria solicitada com sucesso',
    })

    action.next && action.next()
  } catch (e) {
    notification.close('ask_mentorship')
    notification.error({
      message: 'Problemas ao solicitar mentoria',
      description: e.message || e,
    })
    yield put({
      type: constants.ASK_FOR_MENTORSHIP.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* getMyMentorships(action) {
  try {
    const payload = yield call(api.getMyMentorships)

    yield put({ type: constants.GET_MY_MENTORSHIPS.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_MY_MENTORSHIPS.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* getMyMadeMentorships(action) {
  try {
    const payload = yield call(api.getMyMadeMentorships)
    yield put({ type: constants.GET_MY_MADE_MENTORSHIPS.SUCCESS, payload })
    action.next && action.next(payload)
  } catch (e) {
    yield put({
      type: constants.GET_MY_MADE_MENTORSHIPS.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* cancelMyMentorship(action) {
  try {
    notification.info({
      key: 'cancel_mentorship',
      message: 'Solicitando mentoria',
    })

    const payload = yield call(api.cancelMyMentorship, action.id)
    yield put({ type: constants.CANCEL_MY_MENTORSHIP.SUCCESS, payload })
    notification.close('cancel_mentorship')
    notification.success({
      message: 'Mentoria cancelada com sucesso',
    })

    action.next && action.next()
  } catch (e) {
    notification.close('cancel_mentorship')
    notification.error({
      message: 'Problemas ao cancelar mentoria',
      description: e.message || e,
    })
    yield put({
      type: constants.CANCEL_MY_MENTORSHIP.FAILED,
      error: true,
      errorMessage: e.message || e,
    })
  }
}

function* watchGetAllAvalibleClassToMentorship() {
  yield takeLatest(constants.GET_ALL_AVALIBLE_CLASS.ACTION, getAllAvalibleClassToMentorship)
}

function* watchAskForMentorship() {
  yield takeLatest(constants.ASK_FOR_MENTORSHIP.ACTION, askForMentorship)
}

function* watchGetMyMentorships() {
  yield takeLatest(constants.GET_MY_MENTORSHIPS.ACTION, getMyMentorships)
}

function* watchGetMyMadeMentorships() {
  yield takeLatest(constants.GET_MY_MADE_MENTORSHIPS.ACTION, getMyMadeMentorships)
}

function* watchCancelMyMentorship() {
  yield takeLatest(constants.CANCEL_MY_MENTORSHIP.ACTION, cancelMyMentorship)
}

export function* rootSaga() {
  yield all([
    fork(watchGetAllAvalibleClassToMentorship),
    fork(watchAskForMentorship),
    fork(watchGetMyMentorships),
    fork(watchGetMyMadeMentorships),
    fork(watchCancelMyMentorship),
  ])
}
