import styled from 'styled-components';
import { Row, Col } from 'antd';

type Props = {
    linePercent?: number;
    linePercentTwo?: number;
}

export const Content = styled(Row)` 
    background: #FFFFFF;
    box-shadow: 0px 0px 1.408vw rgba(0, 0, 0, 0.05); //40
    border-radius: 0.704vw; //20

    padding: 2.112vw; //60
    height: 25.650vw; //728.48

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const ColContent = styled(Col)` 
    display: flex;
    justify-content: flex-start;
`;
export const LineDashed = styled.div<Props>`
    position: absolute;
    width: 73.871vw;
    height: 0px;
    margin-left: -3vw;

    display: flex;
    border: 0.06vw dashed #252525;
    z-index: 0;
    bottom: 0px;
    bottom: ${({ linePercent }) => linePercent}%;

    ::after {
    content: '${({ linePercent }) => linePercent}%';

    position: absolute;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.408vw; //40
    line-height: 1.725vw; //49

    color: #A4A4A4;
    margin-top: -2vw;
    }
`;
export const LineDashedTwo = styled.div<Props>`
    position: absolute;
    width: 73.871vw;
    height: 0px;
    margin-left: -3vw;

    display: flex;
    border: 0.06vw dashed #252525;
    z-index: 0;
    bottom: 0px;
    bottom: ${({ linePercentTwo }) => linePercentTwo}%;

    ::after {
    content: '${({ linePercentTwo }) => linePercentTwo}%';

    position: absolute;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 1.408vw; //40
    line-height: 1.725vw; //49

    color: #A4A4A4;
    margin-top: -2vw;
    }
`;

export const ColM = styled.div` 
    display: flex;
    align-items: center;
    width: 100%;
    gap: 7.8vw;
    margin-left: 7vw;

    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.584vw; //45
        line-height: 1.936vw; //55

        color: #A4A4A4;
    }
`;

export const Line = styled.div` 
    border: 0.0352vw solid #999999;
    width: 67.68vw;
    height: 0px;
    display: flex;
    justify-content: center;
    margin-left: 5.5vw;
    
`;

export const LastTitle = styled.div` 
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 2.640vw; //75
    line-height: 3.204vw; //91

    color: #000000;
`;


export const Text = styled.div` 
    p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.584vw; //45
        line-height: 2.464vw; //70

        text-align: justify;

        color: #434343;
        margin-top: 2.112vw;
    }

    p:last-child {
        margin-top: 0.112vw;
    }
`;

