import _ from 'lodash'

export const getIsLoading = state => _.get(state, 'auth.isLoading', false)

export const getError = state => _.get(state, 'auth.error')

export const getUser = state => _.get(state, 'auth.user', {})

export const getAuthToken = state => _.get(state, 'auth.token', '')

export const getAuthTokenId = state => _.get(state, 'auth.token_id', '')

export const getCustomer = state => _.get(state, 'auth.customer', {})

export const token = state => _.get(state, 'auth.token', '')
