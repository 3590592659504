import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';

import { actions as podcastActions, PodcastState, PodcastStateAudioPlayerSelector } from '../../../redux/modules/podcast';
import { secondsToMinutes } from '../../../helpers';
import useDocumentSize from '../../../hooks/useDocumentSize';

import { ReactComponent as FowardSVG } from '../../../img/podcast/Foward.svg';
import { ReactComponent as BackSVG } from '../../../img/podcast/Back.svg';
import { ReactComponent as DarkFowardSVG } from '../../../img/podcast/DarkFoward.svg';
import { ReactComponent as DarkBackSVG } from '../../../img/podcast/DarkBack.svg';
// import { ReactComponent as BlockSharedSVG } from '../../../img/podcast/BlockShared.svg';
// import { ReactComponent as BlockSpotifySVG } from '../../../img/podcast/BlockSpotify.svg';

import { Container } from './styles';

type Props = {
    controls?: boolean;
    layout?: 'clean' | 'dark';
    isFooter?: boolean;
    contentStyle?: object
};

export default function AudioPlayer ({ 
    controls = true,
    layout = 'clean',
    isFooter = false,
    contentStyle = {}
}: Props) {
    const { currentPlay, currentPodcast, historical, lastCurrentTimeTrack } = useSelector((state: { podcast: PodcastState }) => state.podcast);
    const selectorRef = useRef<PodcastStateAudioPlayerSelector | null>(null);
    const [current, setCurrent] = useState(currentPlay.currentTime);
    const [initialId, setInitialId] = useState<string | undefined>(currentPodcast.id);
    const dispatch = useDispatch();
    const { width } = useDocumentSize();
    const playerRef = useRef<HTMLAudioElement>(null);
    const progressBarRef = useRef<HTMLInputElement>(null);
    const animationRef = useRef(0);
    const location = useLocation();
    const [progressBarLastTimeChanged, setProgressBarLastTimeChanged] = useState<number>(Date.now());


    function dispatchTrack(finished: boolean = false) {
        if (!selectorRef.current) {
            return;
        }

        const { currentPlay, currentPodcast, lastCurrentTimeTrack } = selectorRef.current;

        dispatch(podcastActions.track({
            ...currentPodcast,
            finished,
            currentTime: currentPlay.currentTime,
            lastCurrentTimeTrack
        }));
    };

    function dispatchPlay() {
        playerRef.current?.play();
        dispatch(podcastActions.play());
    };

    function dispatchPause() {
        playerRef.current?.pause();
        dispatch(podcastActions.pause());
    };

    function handlePlayPause () {
        if (currentPlay.isPlaying) {
            dispatchPause();
            cancelAnimationFrame(animationRef.current);
        } else {
            dispatchPlay();
            animationRef.current = requestAnimationFrame(whilePlaying);
        }

        dispatchTrack();
    };

    function handleForwardThirtySeconds() {
        if (progressBarRef.current && (Number(progressBarRef.current.value)+30) < currentPlay.duration ) {
            progressBarRef.current.value = `${Number(progressBarRef.current.value) + 30}`;
            handleChangeCurrentTime();
        }
    };

    function handleBackTenSeconds() {
        if (progressBarRef.current && progressBarRef.current.value && Number(progressBarRef.current.value) > 10) {
            progressBarRef.current.value = `${Number(progressBarRef.current.value) - 10}`;
            handleChangeCurrentTime();
        }
    };

    function handleChangeCurrentTime () {
        if (progressBarRef.current && playerRef.current) {
            playerRef.current.currentTime = Number(progressBarRef.current.value);
            changePlayerCurrentTime();
        }

        setProgressBarLastTimeChanged(Date.now());
    };

    function handleAddVelocity() {
        if (playerRef.current && playerRef.current.playbackRate) {
            const newerVelocity = currentPlay.velocity === 2.0 ? 1.0 : (currentPlay.velocity+0.25);
            playerRef.current.playbackRate  = newerVelocity;
            dispatch(podcastActions.setVelocity(newerVelocity));
        }
    };

    function handlePlaybackEnded() {
        dispatchPause();
        dispatchTrack(true);
    }

    function whilePlaying() {
        if (progressBarRef.current && playerRef.current) {
            progressBarRef.current.value = `${playerRef.current.currentTime}`;
            animationRef.current = requestAnimationFrame(whilePlaying);
            changePlayerCurrentTime();
        }
    };

    const changePlayerCurrentTime = () => {
        const value = progressBarRef.current?.value ? Number(progressBarRef.current?.value) : 0;
        setCurrent(value);
    }

    useEffect(() => {
        if (current) {
            dispatch(podcastActions.setCurrentTime(current));
        }
        // eslint-disable-next-line
    }, [current]);

    useEffect(() => {
        const player = playerRef.current;

        player?.addEventListener('ended', handlePlaybackEnded);

        if (player) {
            if (currentPodcast.id !== initialId || (location?.pathname ?? '').includes('courses')) {
                const exist = historical.find((item) => String(item.id) === String(currentPodcast.id));
                if (exist) {
                    playerRef.current.currentTime = exist.currentTime;
                    animationRef.current = requestAnimationFrame(whilePlaying);
                } else {
                    playerRef.current.currentTime = 0;
                    animationRef.current = requestAnimationFrame(whilePlaying);
                }
            }
        } else {
            setInitialId(undefined);
        }

        return () => {
            player?.removeEventListener('ended', handlePlaybackEnded);
        };
        // eslint-disable-next-line
    }, [currentPodcast.id]);

    // The function dispatchEvent was not getting updated values when called by the event listener handlePlaybackEnded.
    // The solution found to this issue is use a ref that is updated in this useEffect.
    useEffect(() => {
        selectorRef.current = { currentPlay, currentPodcast, historical, lastCurrentTimeTrack };
    }, [currentPlay, currentPodcast, historical, lastCurrentTimeTrack]);

    // Call `track` endpoint only after 1 second without interaction with the media slider, addressing 2 issues:
    // 1 - The endpoint was being called with outdated data;
    // 2 - When dragging the slider, too many calls to the endpoint were being made.
    useEffect(() => {
        const trackProgressBarChangeTimeout = setTimeout(() => {
            if (currentPlay.isPlaying) {
                const percentFinish = Math.floor((current * 100) / currentPlay.duration);
                dispatchTrack(percentFinish > 90);
            }
        }, 1000);

        return () => {
            clearTimeout(trackProgressBarChangeTimeout);
        };
        // eslint-disable-next-line
    }, [progressBarLastTimeChanged]);

    useEffect(() => {
        if (playerRef.current && currentPlay.currentTime) {
            if (currentPlay.currentTime > 0) {
                playerRef.current.currentTime = currentPlay.currentTime;
                animationRef.current = requestAnimationFrame(whilePlaying);
            } else {
                const exist = historical.find(item => String(item.id) === String(currentPodcast.id));
                if (exist) {
                    playerRef.current.currentTime = exist.currentTime;
                    animationRef.current = requestAnimationFrame(whilePlaying);
                }
            }

            if (currentPlay.velocity) {
                playerRef.current.playbackRate = currentPlay.velocity;
            }
        }

        return () => {
            const percentFinish = Math.floor((current * 100) / currentPlay.duration);
            dispatchTrack(percentFinish > 90);
        };
    // eslint-disable-next-line
    }, []);

    return (
        <Container className="player" style={contentStyle}>
            <audio 
                src={currentPlay.source} 
                preload="metadata" 
                ref={playerRef}>
            </audio>
            {
                isFooter ? (
                    <div className="player-buttons player-buttons-footer">
                        {/*
                            controls && (
                                <button>
                                    <BlockSpotifySVG /> 
                                </button>
                            )
                            */}
                        <div className="player-buttons-actions">
                            {
                                controls && (
                                    <button onClick={handleBackTenSeconds}>
                                        {
                                            layout === 'dark' ? <DarkBackSVG /> : <BackSVG />
                                        }
                                    </button>
                                )
                            }

                            <button onClick={handlePlayPause}>
                            {
                                currentPlay.isPlaying ? (
                                    <PauseCircleFilled className="pause"/>
                                ) : <PlayCircleFilled className="play"/> 
                            }
                            </button>
                            
                            {
                                controls && (
                                    <button onClick={handleForwardThirtySeconds}>
                                        {
                                            layout === 'dark' ? <DarkFowardSVG /> : <FowardSVG className="foward" />
                                        }
                                    </button>
                                )
                            }
                        </div>
                        {/*
                            controls && (
                                <button>
                                    <BlockSharedSVG /> 
                                </button>
                            )
                            */}
                    </div>
                ) : (
                    <div className="player-buttons">
                    {
                        controls && (
                            <button onClick={handleBackTenSeconds}>
                                {
                                    layout === 'dark' ? <DarkBackSVG /> : <BackSVG />
                                }
                            </button>
                        )
                    }
                    <button onClick={handlePlayPause}>
                        {
                            currentPlay.isPlaying ? (
                                <PauseCircleFilled className="pause"/>
                            ) : <PlayCircleFilled className="play"/> 
                        }
                    </button>
                    {
                        controls && (
                            <button onClick={handleForwardThirtySeconds}>
                                {
                                    layout === 'dark' ? <DarkFowardSVG /> : <FowardSVG style={{zoom: '150%'}} className="foward" />
                                }
                            </button>
                        )
                    }
                </div>
                )
            }

            {
                controls && (
                    <>
                    <div className="progress">
                        <div>
                            {
                                (width > 600) && (
                                    <span className="velocity" onClick={handleAddVelocity}>
                                        {currentPlay.velocity}x
                                    </span>
                                )
                            }
                            <span className="currentTime">
                                {secondsToMinutes(current)}
                            </span>
                            <span className="progressBar">
                                <input 
                                    type="range" 
                                    defaultValue="0"
                                    ref={progressBarRef}
                                    onChange={handleChangeCurrentTime}
                                    max={currentPlay.duration}
                                />
                            </span>
                            <span className="duration">
                                {secondsToMinutes(currentPlay.duration)}
                            </span>
                        </div>
                    </div>
                    {
                        (width<=600) && (
                            <div className="mobile-velocity">
                                <span onClick={handleAddVelocity}>
                                    {currentPlay.velocity}x
                                </span>
                            </div>
                        )
                    }
                    </>
                )
            }
        </Container>
    );
};