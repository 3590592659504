import React from 'react';
import Button from '../Button';
import { Container, ButtonsWrapper } from './styles';


type Props = {
    isVisible: boolean;
    setVisible(isVisible: boolean): void;
    title: string;
    onConfirm: () => void;
    isLoading?: boolean;
};

const ModalConfirm = ({isVisible, setVisible, onConfirm, title, isLoading}: Props) => {
    const handleCancel = () => {
        setVisible(false);
    };

    const handleConfirm = () => {
        onConfirm();
    }
    
    return (
        <Container
            zIndex={998}
            closable={true}
            visible={isVisible}
            destroyOnClose={true}
            maskClosable={false}
            footer={false}
            onCancel={handleCancel}>
                <div className="body">
                    <h1>{title}</h1>
                    <ButtonsWrapper> 
                        <Button layout="warning" onClick={handleCancel}>
                            Não
                        </Button>

                        <Button layout="outline-primary" onClick={handleConfirm} isLoading={isLoading}>
                            Sim
                        </Button>                        
                    </ButtonsWrapper>
                </div>
        </Container>
    );
};

export default ModalConfirm;