import styled from 'styled-components';
import { Formik } from 'formik';

export const Container = styled.div`    
    @media(max-width: 720px) {
        width: 100%;
    }

    form {
        label {
            color: white;
            margin-left: 1rem !important;
            font-size: 0.875rem;
        } 
    }

    .form {
        &__student {
            margin-bottom: 30px;
            .transparent {  
                width: 100%;
                cursor: pointer;
                border-radius: 4px;
                height: 45px; 
                border: none;
                color: white;
                background-color: transparent;
                border: 2px solid #FFFFFF;
            
                transition: filter 0.2s;
                &:hover {
                    filter: brightness(0.7);
                }
            }
        }

        padding: 3.6875rem 5rem;
        @media(max-width: 720px) {
            padding: 3.6875rem 1rem;
        }

        label {
            color: white;
            margin-left: 1rem !important;
            font-size: 0.875rem;
        }

        .ipt, .ipt-select {
            font-size: 0.875rem;

            border-radius: 4px;
            height: 2.8125rem;
            background: #F6F6F6;
        }

        .ipt {
            padding-left: 1rem;
        }

        .box-buttons {
            margin-top: 1rem;
            
            display: flex;
            flex-direction: column;
            button {  
                cursor: pointer;
                border-radius: 4px;
                height: 45px; 
                border: none;
                
                & + button {
                    margin-top: 1.8125rem;
                }

                svg {
                    color: white;
                }

                &.warning {
                    color: white;
                    background-color: ${({ theme }) => theme.colors.warning};
                }

                &.transparent {
                    color: white;
                    background-color: transparent;
                    border: 2px solid #FFFFFF;
                }

                transition: filter 0.2s;
                &:hover {
                    filter: brightness(0.7);
                }
            }
        }
    }

    .form__footer {
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        @media(max-width: 728px) {
            margin-top: 1.5rem;
            flex-direction: column;
            gap: 10px;
        }

        span {
            color: #FFFFFF;
            .link {
                cursor: pointer;
                color: #FFFFFF;
                border-bottom: 1px solid #FFFFFF;
                transition: filter 0.2s;
                &:hover {
                    filter: brightness(0.7);
                }
            }
        }

        .resend {
            cursor: pointer;
            transition: filter 0.2s;
            &:hover {
                filter: brightness(0.7);
            }
        }
    }
`;

export const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        font-size: 1.25rem;
        color: #FFFDED;
        font-weight: 600;
        span {
            font-weight: 400;
            font-size: 1.375rem;
        }
    }

    h3 {
        color: #FFFDED;
        font-weight: 500;
    }
`;

export const FormWrapper = styled(Formik)``;

export const CheckboxesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    
    .input-contract {
        font-size: 0.875rem;
        font-weight: 500;

        padding: 0px;
        margin-left: -1px !important;
        & + .input-contract {
            margin-top: 5px;
        }

        .ant-checkbox-inner {
            border: 1px solid ${({ theme }) => theme.colors.primary};
            border-radius: 50%;
            clip: rect(0 0 0 0);
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a {
            color: #FFFFFF;
            line-height: 1.0625rem;
            border-bottom: 1px solid #FFFFFF;
        }

        .material {
            color: #FFFFFF;
            border-bottom: 1px solid #FFFFFF;
        }
    }
`;

export const Unsubscribe = styled.span`
    position: absolute;
    bottom: 0px;
    @media(max-width: 1700px) {
        bottom: -100px;
    }
    @media(max-width: 480px) {
        bottom: -150px;
    }
    right: 4rem;
    
    a {
        color: white;
    }

    span {
        display: block;
        border-bottom: 0.5px solid white;
    }
`;