import React from 'react';
import { WhatsAppOutlined } from '@ant-design/icons';
import { whatsappSupport } from '../../../../components/NewCheckout/shared/constants';


import { Container } from './styles';
import Button from '../../../../components/Button';

const Notification = () => {

    function handleSupport() {
        window.open(whatsappSupport, '_blank');
    };

    return (
        <Container>
            <h1>Descadastro efetuado :(</h1>
            <h2>Esperamos ver você novamente</h2>
            <h3>Precisando de algo, é só chamar</h3>

            <div className="box-button">
                <Button 
                    onClick={handleSupport}
                    layout="warning" 
                    antIcon={() => (<WhatsAppOutlined />)}>
                    Fale com a gente
                </Button>
            </div>
        </Container>
    );
};

export default Notification;