import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Button, Popconfirm, Spin } from 'antd';

import * as mentorshipSelecters from '../../redux/selectors/mentorship';
import { actions as mentorshipActions } from '../../redux/modules/mentorship';

const propTypes = {
  setMentorshipOngoingtModal: PropTypes.func.isRequired,
  getMyMentorships: PropTypes.func.isRequired,
  cancelMyMentorship: PropTypes.func.isRequired,
};

function MentorshipOngoing(props) {
  const { getMyMentorships, cancelMyMentorship, setMentorshipOngoingtModal } = props;
  const [isVisible, setIsVisible] = useState(true);
  const [initialData, setInitialData] = useState();

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.mentorship);

  useEffect(() => {
    getMyMentorships((mentors) => {
      setInitialData(mentors.mentoriaaluno);
    });
    // eslint-disable-next-line
  }, [isVisible]);

  const handleCancel = () => {
    setMentorshipOngoingtModal(false);
    dispatch(mentorshipActions.getMyMentorships());
    setIsVisible(false);
  };

  //POPCONFORM
  const handleConfirm = (id) => {
    cancelMyMentorship(id, () => {
      setInitialData(!isVisible);
    });
  };

  return (
    <>
      <Modal
        width={500}
        footer={false}
        closable={true}
        maskClosable={true}
        style={{ top: 30 }}
        visible={isVisible}
        destroyOnClose={true}
      >
        <Row className="row-modal">
          <Col>
            <p className="modal-title-mentoria">Mentorias</p>
            <br />
            <p className="modal-subTitle-mentoria">SOLICITAÇÃO DE MENTORIA</p>
            <br />
            <>
              {initialData
                ? initialData.map((mentor) =>
                    mentor.id_professor ? (
                      <Row>
                        <Col>
                          <p className="modal-subTitle-mentoria">Mentoria Confirmada</p>
                        </Col>
                        <Col>
                          <Row className="row-box">
                            <Col key={mentor?.id_mentoria} xs={20}>
                              <h5 className="font-bold">{`${mentor?.professor?.nome} ${mentor?.professor?.sobre_nome}`}</h5>
                              <br />
                              <p className="font-bold">{mentor?.aula.titulo}</p>
                              <p>{`${mentor?.hora_inicio} - ${mentor?.hora_fim}`}</p>
                              <p>{moment(mentor?.dia_mentoria).format('LL')}</p>
                              <br />
                            </Col>
                            <Col xs={24} className="d-flex-jc-gp">
                              {/* <Button disabled>CONTATAR PROFESSOR</Button> */}
                              <Popconfirm
                                title="Você tem certeza de que deseja CANCELAR a mentoria?"
                                onConfirm={() => handleConfirm(mentor?.id_mentoria)}
                                okText="Sim"
                                cancelText="Não"
                              >
                                <Button className="button-cancelar-mentoria">CANCELAR</Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="row-box">
                        <Col>
                          <p className="modal-subTitle-mentoria">Mentoria aguardando confirmação</p>
                        </Col>
                        <Col key={mentor?.id_mentoria} xs={20}>
                          <br />
                          <p className="font-bold">{mentor?.aula.titulo}</p>
                          <p>{`${mentor?.hora_inicio} - ${mentor?.hora_fim}`}</p>
                          <p>{moment(mentor?.dia_mentoria).format('LL')}</p>
                          <br />
                        </Col>
                        <Col xs={24} className="d-flex-jc-gp">
                          {/* <Button disabled>CONTATAR PROFESSOR</Button> */}
                          <Popconfirm
                            title="Você tem certeza de que deseja CANCELAR a mentoria?"
                            onConfirm={() => handleConfirm(mentor?.id_mentoria)}
                            okText="Sim"
                            cancelText="Não"
                          >
                            <Button type="primary" className="button-cancelar-mentoria">
                              CANCELAR
                            </Button>
                          </Popconfirm>
                        </Col>
                      </Row>
                    )
                  )
                : isLoading && <Spin />}
            </>
          </Col>
          <Col xs={24} className="botao-voltar">
            <Button onClick={handleCancel}>VOLTAR</Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

MentorshipOngoing.propTypes = propTypes;

const mapStateToProps = (state, props) => {
  mentorshipSelecters.mentorship(state, props);
};

const mapDispatchToProps = (dispatch) => ({
  getMyMentorships: (next) => dispatch(mentorshipActions.getMyMentorships(next)),
  cancelMyMentorship: (id, next) => dispatch(mentorshipActions.cancelMyMentorship(id, next)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MentorshipOngoing);
