import styled from 'styled-components';
import { Modal } from 'antd';

export const Container = styled(Modal)`
    .ant-modal-content {
        border-radius: 10px;
        height: 412px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .body {
        h1{
            text-align: center;
            font-size: 19px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-top: 55px;

    button + button {
        margin-top: 33px;
    }

    button:hover {
        filter: brightness(0.8);
    }
`;