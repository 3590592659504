import styled from 'styled-components';

export const Container = styled.div`
    padding: 83px 0px;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 113px;
    h1 {
        font-weight: 700;
        color: #D81E5B;
        font-size: 36px;
        width: 100%;
        line-height: 5px;
        text-align: left;
        @media (max-width: 720px) {
            text-align: center ;
        }
    }

    h2 {
        width: 100%;
        font-size: 20px;
        font-weight: 600;
        line-height: 5px;
    }

    h3 {
        width: 100%;
        font-size: 1rem;
        font-weight: 500;
        line-height: 5px;
    }

    text-align: left;
        
    @media (max-width: 720px) {
        padding: 0;
        text-align: center ;
    }

    .box-button {
        margin-top: 1rem;
        button {
            width: 279px;
        }
    }
`;