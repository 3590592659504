//eslint-disable-next-line
import React, { ChangeEvent } from 'react';

interface FunctionsChangeProps {
  event: ChangeEvent<HTMLInputElement>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export function useUser() {
  const formatPhoneNumber = (telefone: string) => {
    if (!telefone) return '';
    telefone = telefone.replace(/\D/g, '');
    telefone = telefone.replace(/(\d{2})(\d)/, '($1) $2');
    telefone = telefone.replace(/(\d)(\d{4})$/, '$1-$2');
    return telefone;
  };

  const formatCPF = (cpf: string) => {
    cpf = cpf.replace(/[^\d]/g, '');

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const handlePhoneNumberChange = ({ event, setFieldValue }: FunctionsChangeProps) => {
    const value = event.target.value
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '');
    if (isNaN(Number(value))) return;

    const telefone = formatPhoneNumber(value);

    setFieldValue('telefone', telefone);
  };

  const handleDocumentChange = ({ event, setFieldValue }: FunctionsChangeProps) => {
    const value = event.target.value.replaceAll('-', '').replaceAll('.', '');
    if (isNaN(Number(value))) return;
    if (value.length > 11) return;

    setFieldValue('documento', value);
  };

  return {
    handlePhoneNumberChange,
    handleDocumentChange,
    formatCPF,
    formatPhoneNumber,
  };
}
