import React from "react";

import Download from "../../img/svg/Download";

import {
  Container,
  SelectField,
  Option,
  Text,
  Button,
  SelectAndButtonContainer,
} from "./styles";

type TOption = {
  value: string;
  text: string;
  download: boolean;
}

type Props = {
  title: string;
  optionArray: TOption[];
  onChange: (e: {
    target: {
      value: any
    };
  }) => void;
  value: string;
  onClick: () => void;
}

export default function Select({ title, optionArray, onChange, value, onClick }: Props) {
  const dropdownDisable = optionArray.map((item) => !item.download);

  return (
      <Container>
        <Text className="title" dropdownDisable={dropdownDisable[0]}>
          {title}
        </Text>
        <SelectAndButtonContainer>
          <SelectField value={value} onChange={onChange} disabled={dropdownDisable[0]}>
            {optionArray.map((item) => {
              return (
                <Option value={item.value}>{item.text}</Option>
              )
            })}
          </SelectField>
          <Button onClick={onClick} disabled={dropdownDisable[0]}>
              Baixar
            <Download stroke={dropdownDisable[0] ? '#BBBBBB' : '#FFFFFF'} />
          </Button>
        </SelectAndButtonContainer>
      </Container>
  )
};
