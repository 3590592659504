import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconPenLastStepHover: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.5 0H34.5V15H31.5V0Z" fill="#F0F2FF" />
      <rect y="15" width="66" height="66" rx="33" fill="#F0F2FF" />
      <path d="M23.784 64.1502L23.7826 64.1502L17.584 64.1668C17.5836 64.1668 17.5833 64.1668 17.5829 64.1668C17.3841 64.1665 17.1946 64.087 17.0542 63.9466C16.9137 63.8061 16.8341 63.6164 16.834 63.4174C16.834 63.4172 16.834 63.417 16.834 63.4168L16.8506 57.2311C16.8638 57.0084 16.9395 56.8351 17.0709 56.7037L41.7209 32.0537C41.8615 31.9131 42.0514 31.8335 42.2507 31.8335C42.4499 31.8335 42.6398 31.9131 42.7804 32.0537L48.9471 38.2204C49.2352 38.5085 49.2352 38.9919 48.9471 39.2799L24.2971 63.93L24.2906 63.9365L24.2843 63.9433C24.1678 64.0687 23.9867 64.1502 23.784 64.1502ZM43.3971 42.0037L43.7506 42.3573L44.1042 42.0037L47.0042 39.1037L47.3578 38.7502L47.0042 38.3966L42.6042 33.9966L42.2507 33.6431L41.8971 33.9966L38.9971 36.8966L38.6435 37.2502L38.9971 37.6037C39.0343 37.6409 39.0703 37.6677 39.0959 37.6852C39.1129 37.6968 39.1294 37.7071 39.1406 37.7139L43.2953 41.8686C43.3142 41.9022 43.3462 41.9529 43.3971 42.0037Z"
      fill="#5768FF"
      stroke={color || '#5768FF'}
    />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconPenLastStepHover.defaultProps = defaultProps;

export default IconPenLastStepHover;
