import styled, {css} from "styled-components";
import { Row, Col, Input, Select, Button, List } from 'antd';

export const RowStyled = styled(Row)`
    margin-top: 10px;
    padding: 0 24px 10px;

    @media screen and (max-width: 768px) {
        padding: 0 16px 10px;
    }
`;

export const RowStyledTwo = styled(Row)`
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        margin-top: 0;
    }
`;

export const ColStyled = styled(Col)`
    @media screen and (max-width: 768px) {
        display: none;

        ${({ open }) => open && css`
            display: block;
        `}
    }
`;

export const ColStyledTwo = styled(Col)`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
    }
`;

export const ColStyledThree = styled(Col)`
    margin-bottom: 20px;
    padding: 0 24px;

    @media screen and (max-width: 768px) {
        padding: 0 16px;
    }
`;

export const LabelStyled = styled.label`
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.42px;
`;

export const InputStyled = styled(Input)`
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    height: 36px;
`;

export const SelectStyled = styled(Select)`
    margin-top: 25px;
    width: 100%;

    @media screen and (max-width: 768px) {
        margin-top: 10px;
    }

    .ant-select-selector {
        border: 1px solid #D6D6D6;
        border-radius: 8px;
        background-color: #fff !important;
        height: 36px !important;
    }
    
    &.ant-select-disabled {
        .ant-select-selector {
            background-color: #f6f6f6 !important;
        }
    }

    .ant-select-selection-search {
        width: 0 !important;
    }

    .ant-select-selection-item {
        margin-left: 11px;
    }
`;

export const ButtonStyled = styled(Button)`
    margin-top: 25px;
    border: none;
    border-radius: 8px;
    width: 100%;
    height: 34px;
    background-color: #5768FF !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.42px;
    line-height: 20px;

    ${({ disabled }) => disabled && css`
        opacity: 0.7;
    `}

    @media screen and (max-width: 768px) {
        margin-top: 10px;
    }

    &.seeResult {
        width: auto; 
        margin-top: 0; 
        margin-left: auto;

        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
`;

export const ButtonStyledTwo = styled(Button)`
    border-color: #D6D6D6 !important;
    border-radius: 8px;
    height: 34px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.42px;
    line-height: 20px;
    color: #101010 !important;

    ${({ active }) => active && css`
        border-color: #5768FF !important;
        background-color: #5768FF !important;
        color: #fff !important;

        @media screen and (max-width: 768px) {
            border-color: #000 !important;
            background-color: #000 !important;
        }
    `}
`;

export const ButtonStyledThree = styled(Button)`
    border-radius: 8px;
    width: 100%;
    height: 34px;
    color: #101010 !important;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.42px;
    line-height: 20px;

    @media screen and (max-width: 768px) {
        margin-top: 10px;
    }
`;

export const ListStyled = styled(List)`
    @media screen and (max-width: 768px) {
        margin-bottom: 70px;
    }
    
    .ant-spin-container {
        border-top: 1px solid #EFEFEF;
        border-bottom: 1px solid #EFEFEF;
        padding: 20px 24px 10px;
        height: calc(100vh - 470px);
        overflow-y: auto;

        @media screen and (max-width: 768px) {
            padding: 20px 16px 10px;
            height: auto;
        }
    }

    .ant-spin-container::-webkit-scrollbar {
        display: none;
    }

    .ant-list-pagination {
        text-align: center;
    }

    .ant-pagination-item-link {
        border: none;
        border-radius: 8px;
        background-color: #5768FF;
        width: 32px;
        height: 32px;
        color: #fff;

        &:hover {
            color: #fff;
        }

        &:disabled {
            background-color: #EFEFEF;
        }
    }

    .ant-pagination-item {
        border-radius: 8px;
        width: 30px;
        height: 26px;
        line-height: 24px;
        font-size: 14px;

        &.ant-pagination-item-active {
            border-color: #5768FF;
            background-color: #5768FF;
            
            a {
                color: #fff;
            }
        }
    }
`;

export const ItemStyled = styled(List.Item)`
    background-color: #F9F9FB;
    margin-bottom: 12px;
    padding-left: 14px;
    padding-right: 14px;
    border-bottom: none !important;
    border-radius: 10px;
    cursor: pointer;
`;

export const CodeStyled = styled.p`
    font-size: 16px;
    font-weight: 500;
    color: #858585;

    span {
        display: none;
    }

    @media screen and (max-width: 768px) {
        position: absolute;
        top: -43px;
        left: 60px;
        right: 60px;
        text-align: center;
        width: calc(100% - 120px);

        span {
            display: inline;
        }
    }
`;

export const ContentStyled = styled.div`
    border-top: 1px solid #EFEFEF;
    border-bottom: 1px solid #EFEFEF;
    padding: 20px 24px 10px;
    width: 100%;
    height: calc(100vh - 340px);
    overflow-y: auto;

    &::-webkit-scrollbar {
        display: none;
    }

    @media screen and (max-width: 768px) {
        padding: 20px 10px 10px;
        height: calc(100vh - 174px);
    }
`;

export const QuestionHeaderStyled = styled.div`
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: 600;
    color: #101010;

    @media screen and (max-width: 768px) { 
        font-size: 14px;
    }
`;

export const AnswersStyled = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
    width: 100%;

    @media screen and (max-width: 768px) {
        font-size: 14px;
    }
`;

export const AnswerStyled = styled.div`
    display: flex;
    padding: 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background-color: #F9F9FB;
    cursor: pointer;
    border: 2px solid #F9F9FB;

    ${({ checked, correct, incorrect }) => {
        if(correct) {
            return css`
                border: 2px solid #62CF7A;
                background-color: #F7FFF0;
                color: #62CF7A; 
            `;
        } else if(checked && incorrect) { 
           return css`
                border: 2px solid #D81E5B;
                background-color: #FFEAF1;
                color: #D81E5B;
            `;
        } else if(checked){
            return css`
                border: 2px solid #5768FF;
                background-color: #F0F2FF;
                color: #5768FF;
            `;
        }
    }}
`;

export const QuestionFooterStyled = styled.div`
    padding: 0 24px;
    display: flex;
    gap: 10px;
    flex-direction: row;
    background-color: #fff;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 14px 16px;
        border-top: 1px solid #EFEFEF;
        position: fixed;
        bottom: 54px;
        width: 100%;
    }
`;