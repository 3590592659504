import '../style/free.scss';
import React, { useContext } from 'react';
import { CheckoutContext } from '../../shared/CheckoutProvider';
import { GiftOutlined, FormOutlined } from '@ant-design/icons';

const PaymentFree = () => {
  const { removeCupom } = useContext(CheckoutContext);

  return (
    <>
      <div className="box-cupom-free">
        <div className="new-checkout-card">
          <GiftOutlined />
          <h1>Parabéns</h1>

          <h2>Cupom com 100% de desconto</h2>

          <span onClick={removeCupom} className="payment-free-remove">
            <FormOutlined title="Remover cupom ?"/>
          </span>
        </div>
      </div>
    </>
  );
};

export default PaymentFree;