import React from 'react';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';

import useDocumentSize from '../../../hooks/useDocumentSize';

import { ReactComponent as SmileSVG } from '../../../img/svg/smile.svg';
import { ReactComponent as BanSVG } from '../../../img/podcast/Ban.svg';
import AudioPlayer from '../AudioPlayer';

import { cursiveDate } from '../../../helpers';
import { formattedBigText } from '../../../helpers/renderFormatted';
import { PodcastState } from '../../../redux/modules/podcast';
import { Container, BoxEmpty } from './styles';

export default function Player () {
    const { currentPodcast, podcasts, isLoading } = useSelector((state: { podcast: PodcastState }) => state.podcast);
    const formattedTitle = formattedBigText(currentPodcast.title);
    const { width } = useDocumentSize();

    return (
        <Container>
            <>
                {
                    isLoading ? (
                        <BoxEmpty>
                            <Spin />
                        </BoxEmpty>
                    ) : !podcasts.length ? (
                        <BoxEmpty>
                            <BanSVG />
                            <span>Ops! Você ainda não possui nenhum podcast disponível.</span>
                        </BoxEmpty>
                    ) : !(currentPodcast?.id) ? (
                        <BoxEmpty>
                            <SmileSVG 
                                width="30"
                                height="30"
                            />
                            <span>Estamos aguardando você selecionar um episódio do podcast.</span>
                        </BoxEmpty>
                    ) : (
                        <>
                        {
                            (width > 600) ? (
                                <main>
                                    <div className="box-info">
                                        <div className="box-img">
                                            <img src={currentPodcast.img} alt="Epiśodio atual"/>
                                        </div>

                                        <div className="box-description">
                                            <h3>{formattedTitle}</h3>
                                            <time>{cursiveDate(currentPodcast.published_at)}</time>
                                            {
                                                currentPodcast?.description && (
                                                    <div dangerouslySetInnerHTML={{ __html: currentPodcast.description }} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </main>
                            ) : (
                                <main>
                                    <div className="box-info">
                                        <div className="box-img">
                                            <img src={currentPodcast.img} alt="Episódio atual"/>
                                        </div>
                                        <div className="box-description">
                                            <h3>{formattedTitle}</h3>
                                            <time>{cursiveDate(currentPodcast.published_at)}</time>
                                        </div>
                                    </div>
                                </main>
                            )
                        }
                        <AudioPlayer 
                            layout="dark"
                            isFooter={width <= 600} 
                        />
                        </>
                    )
                }
            </>
        </Container>
    );
};