import { all, fork, call, put, takeLatest } from 'redux-saga/effects';

import * as api from '../api/podcast';
import { constants } from '../modules/podcast';
import { IPodcast } from '../../shared/Models/podcast/IPodcast';
import { IEpisode } from '../../shared/Models/podcast/IEpisode';

function* fetchAll(action: { 
    type: string;
    success?: (payload: IPodcast) => void,
    error?: () => void, 
}) {
    try {
        const payload: IPodcast = yield call(api.fetchAll);
        yield put({
            type: constants.PODCAST_FETCH_ALL.SUCCESS,
            payload
        });
        
        action.success && action.success(payload);
    } catch (e: any) {
        yield put({
            type: constants.PODCAST_FETCH_ALL.FAILED
        });

        action.error && action.error();
    }
};

function* fetchByClass(action: {
    type: string;
    courseId: number;
    tags: string;
    callback?: (payload?: IEpisode) => void;
}) {
    try {
        const payload: IEpisode = yield call(api.fetchByClass, action.courseId, action.tags);
        yield put({
            type: constants.PODCAST_FETCH_BY_CLASS.SUCCESS,
            payload
        });
        
        action.callback && action.callback(payload);
    } catch(e) {
        yield put({
            type: constants.PODCAST_FETCH_BY_CLASS.FAILED
        });
    }

}

function* track(action: {
    type: string,
    formData: {
        finished: boolean;
        currentTime: number;
        id: string;
        lastCurrentTimeTrack: number;
    },
    callbackSuccess?: () => void,
    callbackError?: () => void,
}) {
    try {
        if (action.formData.currentTime !== action.formData.lastCurrentTimeTrack) {
            yield call(api.track, action.formData);
        }

        yield put({
            type:constants.PODCAST_TRACK.SUCCESS,
            currentTime: action.formData.currentTime,
            id: action.formData.id
        });

        action.callbackSuccess && action.callbackSuccess();
    } catch (e) {
        yield put({
            type: constants.PODCAST_TRACK.FAILED
        });

        action.callbackError && action.callbackError();
    }
}

function* watchFetchAll() {
    yield takeLatest(constants.PODCAST_FETCH_ALL.ACTION, fetchAll);
};

function* watchFetchByClass() {
    yield takeLatest(constants.PODCAST_FETCH_BY_CLASS.ACTION, fetchByClass);
};

function* watchTrack() {
    yield takeLatest(constants.PODCAST_TRACK.ACTION, track);
};

export function* rootSaga() {
    yield all([
        fork(watchFetchAll),
        fork(watchTrack),
        fork(watchFetchByClass),
    ]);
};