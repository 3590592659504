import React from 'react';
import arrowRight from 'img/arrow-right-white.svg';
import {
  Button,
  Divider,
  InformationsItemsContainer,
  PanelArea,
  Title,
} from 'pages/lectures/styles';
import { informationsItemsArray } from 'pages/lectures/utils';
import { LectureType } from 'pages/lectures/utils/types';
import { InformationsItems } from 'pages/lectures/components/InformationsItems';
import { SubTitle } from 'pages/lectures/components/commons/styles';
import { PanelHeader } from 'pages/lectures/components/commons/styles';

export function Instructions({ handleStartLecture, lecture }: InformationsProps) {
  return (
    <PanelArea data-testid="instructions-panel-area">
      <PanelHeader>
        <Title width="fit-content" data-testid="instructions-title">
          {lecture?.titulo}
        </Title>
        <SubTitle data-testid="instructions-subtitle">Vamos começar?</SubTitle>
      </PanelHeader>
      <InformationsItemsContainer data-testid="instructions-information-container">
        {informationsItemsArray.map((item, index) => {
          const title = item.title.replace(
            '%%questionsQuantities%%',
            lecture?.questions?.length.toString()
          );
          return (
            <div key={index} data-testid="instructions-information-item">
              <InformationsItems icon={item.icon} title={title} />
              <Divider />
            </div>
          );
        })}
      </InformationsItemsContainer>
      <Button
        variant="primary"
        width="100%"
        onClick={handleStartLecture}
        data-testid="instructions-button-start-test"
      >
        Começar a prova
        <img src={arrowRight} alt="Começar" width={18} height={18} />
      </Button>
    </PanelArea>
  );
}

type InformationsProps = {
  lecture: LectureType;
  handleStartLecture: () => void;
};
