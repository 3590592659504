import styled from 'styled-components';
import { Row, Col } from 'antd';

export const AulaCard = styled(Row)`
  box-sizing: border-box;
  display: flex;
  justify-items: space-between;
  padding: 14px;
  margin-left: 0px !important;
  margin-right: 20px !important;
  width: 264px;
  height: 127px;
  background-color: ${({ theme}) =>  theme.colors.white};
  border: 0.6px solid ${({ theme }) => theme.colors.gray_125};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;

  .block-class {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    width: 34px;
    height: 34px;

    background: ${({ theme}) =>  theme.colors.gray_95};
    color: ${({ theme}) => theme.colors.warning};
    border-radius: 100px;
  }

  &.current {
    border: 0.6px solid ${({ theme }) => theme.colors.warning};
    box-shadow: none;
  }
  &.locked {
      color: ${({ theme}) => theme.colors.gray_125};
    }
  &.locked .block-class {
    color: ${({ theme}) => theme.colors.default};
    background-color: ${({ theme }) =>theme.colors.gray_125};
  }
  &.locked .tag-style-class {
    color: ${({ theme}) => theme.colors.gray_125};
  }
  &.locked .tag-percent {
    color: ${({ theme}) => theme.colors.gray_125};
  }
  &.locked .tag-simulado {
    background-color: ${({ theme}) => theme.colors.gray_125};
    color: ${({ theme}) => theme.colors.white};
  }
  .colBase {
   width: 100%;
  }

  .colIconDateTitle {
    padding: 0px !important;
    flex-flow: nowrap !important;
    gap: 10px;

    font-family: 'Work Sans';
    font-style: normal;

    .data-class {
      font-style: normal;
      color: ${({ theme }) => theme.colors.gray_500};
      font-weight: 400;
      font-size: 12px;
     }


    .title-class {
      font-style: normal;
      white-space: normal;
      font-weight: 600;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.gray_500};
      line-height: 1.5em;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 44px);
      border: 1px silid blue solid;
      min-height: 40px;
    }

  }
  &.locked .title-class {
    color: ${({ theme }) => theme.colors.gray_125};
  }
`;


export const BoxPercent = styled(Col)`
  display: flex;
  margin-top: 5px;
  .tag-style-class  {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-right: 10px;
    color: ${({ theme }) => theme.colors.gray_500};
    background-color:  ${({  theme }) =>  theme.colors.white};
  }

  .tag-percent {
    border: none;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: auto;
    background-color:  ${({  theme }) =>  theme.colors.white};
    color: ${({ theme }) => theme.colors.gray_500};
  }

  .tag-simulado {
    background-color:  ${({  theme }) =>  theme.colors.warning };
    color:  ${({  theme }) =>  theme.colors.white};
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    border:none;
  }
`;

