import React from 'react'

type Props = {
  stroke: string;
}

export default function Download({stroke}: Props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9375 9V13.59C15.964 14.1844 15.754 14.7651 15.3534 15.205C14.9528 15.645 14.3943 15.9083 13.8 15.9375H4.19998C3.6057 15.9083 3.04717 15.645 2.64658 15.205C2.246 14.7651 2.03598 14.1844 2.06248 13.59V9" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.99997 2.0625V11.25" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.99997 11.25L12 8.25" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.99997 11.25L5.99997 8.25" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}
