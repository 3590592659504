import styled from 'styled-components';

interface GridAreaProps {
  items: number;
}

export const FormaArea = styled.form`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 30px;
`;

export const GridArea = styled.div<GridAreaProps>`
  display: grid;
  grid-template-columns: repeat(${({ items }) => items}, 1fr);
  grid-gap: 24px;

  width: 100%;

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ErrorsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 9px;

  margin: 16px 0;
`;

export const ErrorsPassword = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  gap: 8px;

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.42px;

  color: var(--escala-de-cinza-80, #252525);

  margin: 0;
  padding: 0;

  &.neutro {
    color: var(--escala-de-cinza-80, #252525);
  }

  &.error {
    color: var(--Primrias-Rosa, #d81e5b);
  }

  &.success {
    color: var(--Mensagens-Success, #62cf7a);
  }
`;
