import React from 'react';
import logo  from '../../../img/gabaritoro/logo.png';
import darkLogo from '../../../img/gabaritoro/dark-logo.png';
import { Container} from './styles';


type Props = {
    layoutTheme?: string;
};

const Logo = ({ layoutTheme }: Props) => {
    return (
        <Container>
            {
                layoutTheme !== 'light' ? (
                    <img 
                        src={logo} 
                        alt="Gabaritoro" 
                    />
                ) : (
                    <img 
                        src={darkLogo} 
                        alt="Gabaritoro" 
                    />
                )
            }
        </Container>   
    );
};

export default Logo;