import React from 'react';

import Logo from '../../../img/svg/logo-white.svg'

import { Container, Img } from './styles';


export default function Header() {
    return (
        <Container>
            <Img src={Logo} alt="logo" />
        </Container>
    );
};
