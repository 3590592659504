function sanitizeQuestionIdk({ question }) {
  const questionAsArray = Object.entries(question)
  const questionWithoutIdkArray = questionAsArray.filter(([, value]) => {
    return value !== 'idk'
  })
  return Object.fromEntries(questionWithoutIdkArray)
}

function sanitizeQuestionTimeIdk({ questionTime, question }) {
  //
  // check all idk questions
  const questionArray = Object.entries(question)
  let questionsIdkIdArray = []
  questionArray.forEach(([key, value]) => {
    if (value === 'idk') {
      questionsIdkIdArray.push(key)
    }
  })

  const questionTimeArray = Object.entries(questionTime)
  const questionTimeWithoutIdkArray = questionTimeArray.filter(([key,]) => !questionsIdkIdArray.includes(key))
  return Object.fromEntries(questionTimeWithoutIdkArray)
}

//
// remove all questions marked as "nao sei"
// from the ongoing payload avoinding API crash and
// lecture logic glitch
export function sanitizeOngoingPayload({ ongoingPayload }) {
  const { question, question_time } = ongoingPayload
  const questionWithoutIdk = sanitizeQuestionIdk({ question })
  const questionTimeWithoutIdk = sanitizeQuestionTimeIdk({ questionTime: question_time, question })
  return {
    ...ongoingPayload,
    ...{
      question: questionWithoutIdk,
      question_time: questionTimeWithoutIdk
    }
  }
}

//
// remove all questions marked as "nao sei"
// from the final payload avoinding API crash and
// lecture logic glitch
export function sanitizeFinalPayload({ finalPayload }) {
  const { question, answersWithLetters } = finalPayload
  const questionWithoutIdk = question ? sanitizeQuestionIdk({ question }) : {}
  return {
    ...finalPayload,
    ...{
      question: questionWithoutIdk,
      answersWithLetters
    }
  }
}
