import styled from 'styled-components';

export const ContainerExpiteDate = styled.div`

  .date-course-warning {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 7px;
    gap: 6px;
    width: 100%;
    background: #FFF4DF;
    border-radius: 2px;
  }
  .date-course-warning img {
    width: 15px !important;
    height: 15px !important;
    margin: 0 !important;
  }
  .date-course-warning p, .date-course-warning strong {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #252525;
    margin: 0 !important;
  }
  .date-course-warning strong {
    color: #E59F18;
    font-weight: 600;
    text-transform: capitalize;
  }

  .date-course-danger {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 7px;
    gap: 6px;
    width: 100%;
    background: #FFEEF4;
    border-radius: 2px;
  }

  .date-course-danger img {
    width: 15px !important;
    height: 15px !important;
    margin: 0 !important;
  }
  .date-course-danger p, .date-course-danger strong {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #252525;
    margin: 0 !important;
  }
  .date-course-danger strong {
    color: #D81E5B;
    font-weight: 600;
  }
`;


