import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { LoadingOutlined, UpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';

import Status from '../../../../components/Gabarito/Status';
import Module from '../../../../components/Gabarito/Module';
import ProgressBar from '../../../../components/Gabarito/ProgressBar';

import { IGabaritoState } from '../../../../shared/Models/gabarito/IGabaritoState';
import { IGabarito } from '../../../../shared/Models/gabarito/IGabarito';

import { useGabaritoro } from '../../../../hooks/useGabaritoro';
import { actions as gabaritoActions } from '../../../../redux/modules/gabarito';

import { FillProps, ParamProps } from './types';

import { AnswersWrapper, ButtonsWrapper, BoxLoading, Container, WrapperArrows } from './styles';

import PreGabarito from '../../../../components/Gabarito/PreGabarito';

const scrollTop = function () {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const Fill = ({ setPage, current, setCurrent }: FillProps) => {
  const { userAnswers, onValidation, startMarkedAnswers, startEmptyAnswers } = useGabaritoro();
  const { token, exam } = useParams<ParamProps>();

  const dispatch = useDispatch();
  const { gabarito, isLoading, finishLoading } = useSelector(
    (state: { gabarito: IGabaritoState }) => state.gabarito
  );

  useEffect(() => {
    if (!exam) {
      dispatch(
        gabaritoActions.fetchAll({
          pre: false,
        })
      );
    } else {
      dispatch(gabaritoActions.getGabaritoByToken(token));
    }
  }, [dispatch, token, exam]);

  useEffect(() => {
    if (gabarito.filled && gabarito.student_filled && !gabarito.pre) {
      startMarkedAnswers(gabarito);
    } else {
      startEmptyAnswers();
    }

    // eslint-disable-next-line
  }, [gabarito]);

  const isValid = () => {
    if (!(gabarito.filled && gabarito.student_filled)) {
      const questions = gabarito.modules[current].questions;
      const valid = questions.filter(
        (question) =>
          !userAnswers.find(
            (answer) => answer.id_gabarito_question === question.id_gabarito_question
          )
      );

      return !valid.length;
    }

    return true;
  };

  const handleNext = () => {
    if (!(gabarito.filled && gabarito.student_filled)) {
      scrollTop();
    }

    onValidation(true, gabarito.modules[current].questions.length);
    if (isValid()) {
      onValidation(false, 0);
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    setCurrent(current - 1);
  };

  const handleFinish = () => {
    onValidation(true, gabarito.modules[current].questions.length);

    if (isValid()) {
      onValidation(false, gabarito.modules[current].questions.length);
      dispatch(
        gabaritoActions.fillAnswers(
          {
            answers: userAnswers,
          },
          token,
          (payload: unknown) => {
            const gabarito = payload as IGabarito;
            if (gabarito.filled && gabarito.student_filled) {
              setCurrent(0);
              setPage('fill');
              scrollTop();
            } else {
              setPage('registered');
              scrollTop();
            }
          }
        )
      );
    }
  };

  const renderHeader = () => {
    if (gabarito.filled && gabarito.student_filled) {
      if (current === 0 && gabarito.gabarito_type === 'FULL') {
        return (
          <div className="header-center">
            <h1>{gabarito.name}</h1>
            <h2>{gabarito.student_name}</h2>
          </div>
        );
      } else {
        return (
          <div className="header-expanded">
            <div className="info">
              <h1>{gabarito.name}</h1>
              <h2>{gabarito.student_name}</h2>
            </div>
            {gabarito.gabarito_type === 'FULL' && (
              <div className="status">
                <Status approved={gabarito.approved} />
                <h2>{gabarito.percent_correct}% da prova</h2>
                <h2>
                  {gabarito.total_correct}/{gabarito.total_questions} acertos
                </h2>
              </div>
            )}
          </div>
        );
      }
    } else {
      return (
        <>
          <div className="descriptions">
            <span>Gabarito</span>
            <span>Exame: {gabarito.name}</span>
          </div>

          <div className="progressbar">
            <ProgressBar />
          </div>

          <div className="title">
            <h1>Marque aqui as alternativas que você marcou na prova: {gabarito.name}</h1>
          </div>
        </>
      );
    }
  };

  const renderNextButton = () => {
    if (gabarito.modules.length - 1 === current) {
      if (finishLoading) {
        return (
          <button className="next">
            <Spin indicator={<LoadingOutlined spin />} />
          </button>
        );
      } else {
        return (
          <button className="next" onClick={handleFinish}>
            Finalizar
          </button>
        );
      }
    }

    if (gabarito.filled && gabarito.student_filled && current === 0) {
      return (
        <div className="container-back-top">
          <span className="back-top" onClick={() => scrollTop()}>
            <UpOutlined />
            Voltar ao topo
          </span>
          <button className="next" onClick={handleNext}>
            Próximo
          </button>
        </div>
      );
    }

    return (
      <button className="next" onClick={handleNext}>
        Próximo
      </button>
    );
  };

  return !isLoading && gabarito.modules ? (
    <>
      {!!!gabarito.pre && gabarito.filled && gabarito.student_filled && (
        <WrapperArrows>
          {current !== 0 ? <LeftOutlined onClick={handlePrevious} /> : <span>&nbsp;</span>}

          {gabarito?.modules?.length - 1 !== current ? (
            <RightOutlined onClick={handleNext} />
          ) : (
            <span>&nbsp;</span>
          )}
        </WrapperArrows>
      )}
      <Container>
        {gabarito.pre && gabarito.filled && gabarito.student_filled && !exam && current === 0 ? (
          <PreGabarito />
        ) : (
          <>
            {renderHeader()}
            <Form>
              <AnswersWrapper>
                <Module module={gabarito.modules[current]} current={current} />
              </AnswersWrapper>

              <ButtonsWrapper center={current === 0}>
                {gabarito.filled && gabarito.student_filled && current !== 0 && (
                  <>
                    <button className="back" onClick={handlePrevious}>
                      Voltar
                    </button>
                    <span className="back-top" onClick={() => scrollTop()}>
                      <UpOutlined />
                      Voltar ao topo
                    </span>
                    {current + 1 === gabarito.modules.length && <span></span>}
                  </>
                )}

                {!(gabarito.filled && gabarito.student_filled) && current !== 0 && (
                  <>
                    <button className="back" onClick={handlePrevious}>
                      Voltar
                    </button>
                  </>
                )}
                {renderNextButton()}
              </ButtonsWrapper>
            </Form>
          </>
        )}
      </Container>
    </>
  ) : (
    <BoxLoading>
      <Spin />
    </BoxLoading>
  );
};

export default Fill;
