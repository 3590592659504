import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import { Container } from './styles';

import Button from '../../../../components/Button';


type Props = {
    onFinish(): void;
};

const Notification = ({ onFinish }: Props) => {
    return (
        <Container>
            <CheckCircleOutlined />
            <h1>Obrigado!</h1>

            <Button 
                layout="outline-primary" 
                onClick={onFinish}
            >Ok</Button>
        </Container>
    );
};

export default Notification;