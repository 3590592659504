import React from 'react';

import { useSelector } from 'react-redux';

import { Container } from './styles';
import { FileDoneOutlined } from '@ant-design/icons';
import { IGabaritoState } from '../../../../shared/Models/gabarito/IGabaritoState';

const RegisteredAnswers = () => {
  const { gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito);

  return (
    <Container>
      <FileDoneOutlined />
      <div>
        <h1>Oi {gabarito.student_first_name}, suas respostas foram registradas!</h1>
        <h4>
          Você receberá uma notificação por email <br />
          quando sair o resultado da prova.
        </h4>
        <h3>Boa sorte!</h3>
      </div>
      <button>Aguardando resultado</button>
    </Container>
  );
};

export default RegisteredAnswers;
