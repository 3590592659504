import request from '../../shared/request';

export function getLectureDetails(classId) {
  return request(`/v1/services/questions/lecture/${classId}`, {
    method: 'GET',
  });
}

export function persistOngoingLecture(userId, classId, ongoingPayload) {
  return request(`/v1/services/student/${userId}/lecture/${classId}/tests/ongoing/store`, {
    method: 'POST',
    body: JSON.stringify(ongoingPayload),
  });
}

export function getOngoingLecture(userId, classId, courseTag) {
  return request(`/v1/services/student/${userId}/lecture/${classId}/${courseTag}/tests/ongoing`, {
    method: 'GET',
  });
}

export function finishLecture(userId, classId, finalPayload, idCourseClass) {
  return request(
    `/v1/services/student/${userId}/lecture/${classId}/tests/finish?id_turma=${idCourseClass}`,
    {
      method: 'POST',
      body: JSON.stringify(finalPayload),
    }
  );
}

export function registerPdfDownload(userId, courseTag, classId, idCourseClass) {
  return request(
    `/v1/services/aluno/pdf_downloaded?id_aluno=${userId}&tag_curso=${courseTag}&id_aula=${classId}&id_turma=${idCourseClass}`,
    {
      method: 'GET',
    }
  );
}

export function registerVideoPlay(userId, courseTag, classId, videoId, idCourseClass) {
  return request(
    `/v1/services/aluno/play_video?id_aluno=${userId}&tag_curso=${courseTag}&id_aula=${classId}&id_video=${videoId}&id_turma=${idCourseClass}`,
    {
      method: 'GET',
    }
  );
}

export function getFinishedLectures(classId) {
  const courseId = window.location.href.split('/')[4];
  return request(`/v1/services/student/tests/${classId}/course/${courseId}`, {
    method: 'GET',
  });
}

export function getLectureWrongQuestions(lectureId) {
  return request(`/v1/services/student/test/questions/${lectureId}`, {
    method: 'GET',
  });
}

export function getClassesById(classId) {
  return request(`/v1/services/aulas/${classId}/response`, {
    method: 'GET',
  });
}

export const fetchGeneralText = async(filters) => {
  return request(`/v1/report/scoreperformancetext?get=all&filtro[id_type_report]=${filters.typeReportId}&filtro[id_category_report]=${filters.categoryReportId}`);
};
