import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;

    #audio-player-foward {
        svg {
            width: 300px;
        }
    }
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .player-buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            margin: 0 5px;
            @media(max-width: 728px) {
                margin: 0;
            }
            
            background: none;
            border: none;
            cursor: pointer;

            svg {
                width: 30px;
                height: 30px;
            }

            .play, .pause {
                svg {
                    color: #DADADA;
                    margin: 0 10px;
                    width: 45px;
                    height: 45px;
                }
                transition: filter 0.2s;
                &:hover {
                    filter: brightness(0.8);
                }
            }

            .foward {
                svg {
                    width: 35px;
                    height: 35px;
                }
                transition: filter 0.2s;
            }
        }

        .player-buttons-actions {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .player-buttons-footer {
        width: 100%;
        justify-content: center;
        padding: 0 10px;
    }

    .progress {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        > div {
            width: 60%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                margin-right: 15px;
            }

            .velocity {
                background: #C4c4c4;
                color: black;
                font-size: 10px;
                height: 20px;
                width: 40px;
                border-radius: 5px;
                font-weight: bold;

                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                transition: filter 0.2s;
                &:hover {
                    filter: brightness(0.8);
                }
            }

            .currentTime, .duration {
                font-size: 14px;
                color: #eee;
            }

            .progressBar {
                width: 100%;
                display: flex;
                input {
                    background-color: blue;
                    flex: 1;
                }
            }
        }
    }

    .mobile-velocity {
        margin-top: -15px;
        
        background-color: #C4C4C4;
        border-radius: 5px;
        width: 55px;
        height: 25px;
        
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 13px;
            font-weight: bold;
        }

        transition: filter 0.2s;
        &:hover {
            filter: brightness(0.8);
        }
    }
`;