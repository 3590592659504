import React from 'react';
import { Spin } from 'antd';

import './styles.scss';

export default function CourseListEmpty() {
    return (
        <div className="course-container__loading">
            <Spin />
            <span>Carregando cursos...</span>
        </div>
    );
}
