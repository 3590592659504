import styled, { css } from 'styled-components';

type PercentType = {
    percent: number;
};

export const Container = styled.div<PercentType>`
    background: #F89C3A;
    position: relative;
    border-radius: 10px 10px 0px 0px;

    width: 8.6em; //120
    height: ${({ percent }) => percent}%;

    display: flex;
    justify-content: center;
    padding: 1.5em 0px; //30px 10px 10px

    z-index: 1;

    width: 87px ;

    & > span {
        color: white;
        font-size: 1.6em;
        font-weight: 600;
    }

    ${({ percent }) => (percent <= 7) && css`
        padding: 0px;
    `};

    ${({ percent }) => (percent <= 2) && css`
        padding: 0px;
        span {
            position: absolute;
            top: -1.5em;
            color: black;
        }
    `};
`;
