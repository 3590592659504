import styled from 'styled-components';

export const Container = styled.div`
    background-color: #FFF;
    padding: 3.125em 1.25em;

    display: flex;

    flex-direction: column;
    justify-content: space-evenly;
    align-items: center; 

    svg {
        font-size: 80px;
    }

    > div {
        text-align: center;
        h1 {
            font-size: 18px;
            font-weight: 700;
            margin-top: 1rem;
        }

        h4 {
            font-weight: 400;
            font-size: 16px;
            margin-top: 1rem;
        }

        h3 {
            font-weight: 500;
            font-size: 22px;
            margin: 1em 0;
        }
    }

    > button {
        background-color: #9A9A9A;
        border-radius: 4px;
        color: white;
        height: 45px;
        width: 18.56rem;
        @media(max-width: 720px) {
            width: 15rem;
        }
        border: none;
    }
    
`;