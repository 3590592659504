import request from '../../shared/request';

export async function fetchAllNotifications(dataSend: FetchNotificationsData) {
  const pageUrl = dataSend.page ? `?page=${dataSend.page}` : '';
  const url = `/v1/services/notifications${pageUrl}`;
  const { data } = await request(url);
  return data;
}

export async function readNotification(id: string) {
  const url = `/v1/services/notifications/is-read/${id}`;
  const { data } = await request(url, {
    method: 'POST',
  });
  return data;
}

export async function deleteNotification(id: string[]) {
  const notificationsDeleteString = id.map((id) => `notifications_ids[]=${id}`).join('&');
  const url = `/v1/services/notifications/bulk-delete?${notificationsDeleteString}`;
  const { data } = await request(url, {
    method: 'DELETE',
  });
  return data;
}

type FetchNotificationsData = {
  page?: number;
};
