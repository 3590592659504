import styled from 'styled-components';

export interface PercentProps {
    percent: number;
};


export const Container = styled.div`
    width: 100%;
    height: 40px;
    background: ${({ theme }) => theme.colors.gray_75};
    border-radius: 6px;
    overflow: hidden;
    @media(max-width: 960px) {
        height: 20px;
    }
`;

export const Progress = styled.div<PercentProps>`
    background-color: ${({ theme }) => theme.colors.warning};
    height: 95%;
    width: ${({ percent }) => percent}%;
    display: flex;
    flex-direction: flex-start;
    align-items: center;
`;

export const Percent = styled.div<PercentProps>`
    width: 100%;
    padding-left: ${({ percent }) => percent}%;
    margin-top: -5%;
    display: flex;
    justify-content: flex-start;

    @media(max-width: ${({ theme }) => theme.media.tablet}px) {
        margin-top: 0px;
    }

    font-weight: bold;
    font-size: 16px;
`;
