import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import P from 'prop-types';

import '../style/style.scss';


import { CheckoutContext } from '../../shared/CheckoutProvider';
import PaymentCreditOrDebit from './PaymentCreditOrDebit';
import PaymentFree from './PaymentFree';
import PaymentPix from './PaymentPix';
import FormOfPayment from './FormOfPayment';


const propTypes = {
    onFinish: P.func.isRequired,
    form: P.any.isRequired
};

const MethodPayment = ({ onFinish, form }) => {
    const { values } = useContext(CheckoutContext);

    const renderPayment = () => {
        switch (values.type) {
            case 'free':
                return (<PaymentFree />);
            case 'pix':
                return (<PaymentPix onFinish={onFinish} />);
            case 'debit':
            case 'credit':
                return (<PaymentCreditOrDebit form={form} />);
            default:
                return false;
        }
    };

    return (
        <>
            <Row>
                <Col xs={24}>
                <h5 className="title-steps">Pagamento</h5>
                </Col>
            </Row>
            <Row className="max-container-row-form" gutter={[24, 24]}>
                <FormOfPayment form={form} />
                {renderPayment()}
            </Row>
        </>
    );
};

MethodPayment.propTypes = propTypes; 
export default MethodPayment;