//eslint-disable-next-line
import React, { ChangeEvent, ChangeEventHandler } from 'react';

import { ItemsFormProps, UserDataType } from '../FormRegister';
import { ErrorsType, UserPassWordDataType } from '../FormPassword';

import { useUser } from '../../../hooks/useUser';

export default function useUserForms() {
  const { formatCPF, handleDocumentChange, handlePhoneNumberChange } = useUser();

  const formRegisterValidate = (values: UserDataType) => {
    const errors = {} as UserDataType;

    if (!values.nome) {
      errors.nome = 'Preencha o nome';
    }

    if (!values.sobre_nome) {
      errors.sobre_nome = 'Preencha o sobrenome';
    }

    if (!values.email) {
      errors.email = 'Preencha o email';
    }

    if (!values.documento) {
      errors.documento = 'Preencha o CPF';
    }

    if (values.telefone && values.telefone?.length < 15) {
      errors.telefone = 'Telefone inválido';
    }

    if (!values.telefone) {
      errors.telefone = 'Preencha o telefone';
    }

    if (!values.dt_nascimento) {
      errors.dt_nascimento = 'Preencha a data de nascimento';
    }

    if (!values.rua) {
      errors.rua = 'Preencha a rua';
    }

    if (!values.numero) {
      errors.numero = 'Preencha o número';
    }

    if (!values.bairro) {
      errors.bairro = 'Preencha o bairro';
    }

    if (!values.cidade) {
      errors.cidade = 'Preencha a cidade';
    }

    if (!values.uf) {
      errors.uf = 'Preencha o Estado';
    }

    return errors;
  };

  const formPasswordValidate = (values: UserPassWordDataType) => {
    const errors = {} as ErrorsType;
    //eslint-disable-next-line
    const caracter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (!values.senha_atual) {
      errors.senha_atual = 'Preencha a senha atual';
    }

    if (!values.nova_senha) {
      errors.nova_senha = 'Preencha a nova senha';
    }

    if (!values.reNewPassword) {
      errors.reNewPassword = 'Repita a nova senha';
    } else {
      if (values.reNewPassword !== values.nova_senha)
        errors.reNewPassword = 'As senhas não coincidem';
    }

    if (Number(values.nova_senha?.length) < 8 || Number(values.nova_senha?.length) > 16) {
      errors.minMax = true;
    }

    if (!(/[A-Z]/.test(values.nova_senha || '') && /[a-z]/.test(values.nova_senha || ''))) {
      errors.uppercaseLowercase = true;
    }

    if (!/[0-9]/.test(values.nova_senha || '')) {
      errors.letterAndNumber = true;
    }

    if (!caracter.test(values.nova_senha || '')) {
      errors.specialCharactere = true;
    }

    return errors;
  };

  const getItemsRegisterForm = (
    values: UserDataType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    return [
      [
        { label: 'Nome', target: 'nome', type: 'text' },
        { label: 'Sobrenome', target: 'sobre_nome', type: 'text' },
      ],
      [
        { label: 'Email', target: 'email', type: 'text' },
        {
          label: 'CPF',
          target: 'documento',
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            handleDocumentChange({ event, setFieldValue }),
          value: formatCPF(values.documento || '') || '',
          type: 'text',
        },
      ],
      [
        { label: 'Data de nascimento', target: 'dt_nascimento', type: 'date' },
        {
          label: 'Telefone',
          target: 'telefone',
          onChange: (event: ChangeEvent<HTMLInputElement>) =>
            handlePhoneNumberChange({ event, setFieldValue }),
          type: 'tel',
          maxLength: 15,
        },
        {
          label: 'Instituição Financeira',
          target: 'instituicao_financeira',
          type: 'select',
          onChange: (event: ChangeEvent<HTMLSelectElement>) => {
            setFieldValue('instituicao_financeira', event.target.value);
          },
          value: values.instituicao_financeira || '',
        },
      ],
      [
        { label: 'Rua', target: 'rua', type: 'text' },
        { label: 'Número', target: 'numero', type: 'text' },
        { label: 'Complemento', target: 'complemento', type: 'text' },
      ],
      [
        { label: 'Bairro', target: 'bairro', type: 'text' },
        { label: 'Cidade', target: 'cidade', type: 'text' },
        { label: 'Estado', target: 'uf', type: 'text' },
      ],
    ] as ItemsFormProps[][];
  };

  const getItemsPasswordForm = () => {
    return [
      [{ label: 'Senha atual', target: 'senha_atual', type: 'password' }],
      [
        { label: 'Nova senha', target: 'nova_senha', type: 'password' },
        { label: 'Repita a nova senha', target: 'reNewPassword', type: 'password' },
      ],
    ] as ItemsFormProps[][];
  };

  return { formRegisterValidate, formPasswordValidate, getItemsRegisterForm, getItemsPasswordForm };
}
