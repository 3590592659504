import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash'
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

import { Container, Question } from '../styles/Unanswered';

type Question = {
  codigo: string;
  id_questao: number;
  pivot: {
    ordem: number;
  };
};

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

type Props = {
  questions: Array<Question>;
  answers: Array<any>;
  redoAnswers: Array<any>;
  handleQuestionByIndex: (index: number) => void;
  firstQuestion: number;
  currentQuestion: {
    id_questao: number;
  };
  paginationRef: React.MutableRefObject<scrollVisibilityApiType>;
};

const Unanswered = ({
  questions,
  answers,
  redoAnswers,
  firstQuestion,
  handleQuestionByIndex,
  currentQuestion: _currentQuestion,
  paginationRef,
}: Props) => {
  const [currentQuestion, setCurrentQuestion] = useState<number | undefined>(
    _currentQuestion?.id_questao ?? undefined
  );
  const firstQuestionRef = useRef<HTMLLIElement>(null);

  function handleChangeQuestion(
    question: Question,
    index: number,
    paginationRef: React.MutableRefObject<scrollVisibilityApiType>
  ) {
    handleQuestionByIndex(index);
    setCurrentQuestion(question.id_questao);
    paginationRef.current.scrollToItem(
      paginationRef.current.getItemById(`question-${index}`),
      'smooth',
      'center',
      'nearest'
    );
  }

  useEffect(() => {
    if (firstQuestion && firstQuestionRef.current) {
      firstQuestionRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [firstQuestion]);

  useEffect(() => {
    if (paginationRef.current) {
      paginationRef.current.scrollToItem(
        paginationRef.current.getItemById(paginationRef.current.items.toItems()[0]),
        'smooth',
        'center',
        'nearest'
      );
    }
  }, [paginationRef]);

  useEffect(() => {
    if (_currentQuestion?.id_questao) {
      setCurrentQuestion(_currentQuestion.id_questao);
    }
  }, [_currentQuestion]);

  return (
    <Container>
      <ul>
        {questions.map((question, index) => {
          const forgotten = !_.keys(answers).find((item) => question.id_questao === Number(item));
          return (
            <Question
              forgotten={forgotten}
              active={question.id_questao === currentQuestion}
              key={question.id_questao}
              onClick={() => handleChangeQuestion(question, index, paginationRef)}
              ref={firstQuestion === question.pivot.ordem - 1 ? firstQuestionRef : null}
            >
              <span>
                Questão {index + 1} <em>({question.codigo})</em>
              </span>
            </Question>
          );
        })}
      </ul>
    </Container>
  );
};

export default Unanswered;