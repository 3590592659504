import styled from 'styled-components';
import { Row, Col } from 'antd';


//base vw 2840px

export interface PercentProps {
    progress: number;
};

export const Header = styled(Row)` 
    h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 2.640vw; //75
        line-height: 3.204vw;
        color: #000A66;
    }
    margin: 1.760vw 0; //50
`;

export const Content = styled.div` 
    background: #FFFFFF;
    box-shadow: 0px 0px 1.408vw rgba(0, 0, 0, 0.05);//40
    border-radius: 0.704vw; //20 
    padding: 2.112vw; //60
    width: 100%;
`;

export const RowContent = styled(Row)` 
    gap: 5.140vw; //146
`;

export const Graphs = styled.div` 
    gap: 1vw;
    width: 100%;
    height: 26.161vw; //743
    display: flex;
    justify-content: space-between;
    align-items: end;
`;

export const GraphBar = styled.div<PercentProps>` 
    background: #F89C3A;
    border-radius: 0.403vw 0.403vw 0px 0px;
    width: 6vw; //120 4.225vw
    height: ${({ progress }) => progress}%;
    /* height: 19.612vw; //557 */
    display: flex;
    justify-content: center;
    padding: 1.056vw 0.352vw 0.352vw; //30px 10px 10px
    p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 1.408vw; //40
        line-height: 1.725vw; //49
        text-align: center;
        color: #FFFFFF;
    }
`;

export const Line = styled.div`
    border: 0.035vw solid #999999; //1
    height: 0px;
    opacity: 0.3;
`;

export const ColM = styled.div` 
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4.8vw;
    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.584vw; //45
        line-height: 1.936vw; //55
        color: #A4A4A4;
    }
`;

export const ColBar = styled(Col)` 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
`;