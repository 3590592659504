import styled from 'styled-components';

export const SelectComponent = styled.select`
  display: flex;
  height: 40px;
  width: 100%;
  padding: 8px 12px;
  align-items: center;
  align-self: stretch;

  position: relative;

  outline: none;
  background: none;

  border-radius: 5px;

  cursor: pointer;

  color: var(--escala-de-cinza-80, #252525);

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;

  border: 1px solid #252525;
  box-shadow: none;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  transition: all 0.2s ease-in-out;
`;

export const SelectLabel = styled.p`
  color: #252525;

  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.48px;

  transition: all 0.2s ease-in-out;
`;

export const SelectArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;

  position: relative;

  > svg {
    position: absolute;
    right: 10px;
    top: 45px;

    transition: all 0.2s ease-in-out;
  }

  &:focus-within {
    ${SelectComponent} {
      border: 1px solid #5768ff;
      box-shadow: 0px 0px 4px 0px rgba(114, 123, 210, 0.7);

      + svg {
        transform: rotate(180deg);
      }
    }

    ${SelectLabel} {
      color: #5768ff;
    }
  }
`;
