import styled from "styled-components";

import { Col } from 'antd';

export const ColCard = styled(Col)`
  @media only screen and (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
`

export const Card = styled.div`
  @media only screen and (min-width: 1920px) {
      .btn-card {
          font-size: 1vw;
          height: 2vw;
          border-radius: 0.1vw;
      }
  }
    position: relative;
    display: flex;
    background-color: #ffffff;
    margin-bottom: 2vw;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    transition: 0.3s;
    width: 270px;
    &:hover {
        box-shadow: 0px 0px 13px 4px rgba(0, 0, 0, 0.20);
        img {
            transition: 0.3s;
        }
    }
    .card-course-body {
      margin: 16px 0;
      padding: 0 20px;
    }
    .card-course-body div:first-child span {
      height: 80px;
      align-items: center;
      display: flex;
    }
    .card-course-body span {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
    }
    .card-course-body p {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #252525;
    }
    .card-course-body p:last-child {
      margin: 0;
    }
    img {
        width: 100%;
        height: 154px;
        border-radius: 5px 5px 0px 0px;
        margin-bottom: 1vw;
        transition: 0.3s;
    }
    p {
        margin-bottom: 1vw;
        font-size: 1vw;
        font-weight: 500;
    }
    .btn-card-courses {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px;
      gap:  10px;
      width: 100%;
      border-radius: 5px;
      margin-top: 15px;
      background:
      linear-gradient(to right, white, white),
      linear-gradient(to right, #D81E5B , #000A66);
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      border: 1.5px solid transparent;
    }
    .btn-card-courses img {
      width: 20px;
      height: 20px;
      margin: 0;
    }
    .btn-card-courses p {
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #D81E5B;
      margin: 0;
    }
    @media only screen and (max-width: 575px) {

      img {
        height: 154px;
        margin-bottom: 15px;
      }
      .card-course-body span {
        font-size: 18px;
        line-height: 22px;
      }

      .btn-card-courses {
        gap: 10px;
        height: 35px;
        margin-top: 15px;
      }
      .btn-card-courses img {
        width: 22px;
        height: 22px;
      }
      .btn-card-courses p {
        font-size: 16px;
        line-height: 21px;
      }
  }
`;

export const BoxImg = styled.div`
  background: #000A66;
  border-radius: 5px 5px 0px 0px;
  height: 154px;
    span {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      background: #FFFFFF;
      border-radius: 2px;
      padding: 3px 8px;
      display: inline-table;
    }
    span p {
      margin: 0;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px; //12
      line-height: 16px; //16
      color: #000000;
    }
    .default-img {
      padding: 0 15px;
    }
  @media only screen and (max-width: 575px) {
    height: 154px;
    span {
      position: absolute;
      top: 10px;
      left: 12px;
      background: #FFFFFF;
      border-radius: 2px;
      padding: 3px 8px;
    }
    span p {
      margin: 0;
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
    }
  }
`;
