import { Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { moneyBR } from '../../../shared/utils/common';
import { CashbackStateType } from '../../../redux/modules/cashback';
import './styles.scss';

export default function Balance() {
    const { balance, isLoading } = useSelector((state: { cashback: CashbackStateType }) => state.cashback);

    const formattedBalance = moneyBR(balance);

    return (
        <div className="box-balance">
            <h3>Saldo</h3>
            {
                isLoading ? (
                    <div className="box-balance-isloading">
                        <Spin />
                    </div>
                ) : (
                    <div className="box-balance">
                        <span className="balance-title">Seu saldo é de: </span>
                        <span className="balance-value">{formattedBalance}</span>
                    </div>
                )
            }
        </div>
    );
};