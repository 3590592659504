import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { useDispatch, useSelector } from 'react-redux';

import { WrapperCheckbox } from '../../../../components/Gabarito/Layout/styles';
import Button from '../../../../components/Button';
import { actions as studentActions } from '../../../../redux/modules/student';

import { Container } from './styles';

type Props = {
    setPage(page: string): void;
    setSubscriptionIsVisiable(isVisible: boolean): void;
};

type StateProps = {
    student: {
        channels: {
            phone: boolean;
            email: boolean;
            whatsapp: boolean;
        }
    }
};

const Subscription = ({ setPage, setSubscriptionIsVisiable }: Props) => { 
    const dispatch = useDispatch();
    const { channels } = useSelector((state: StateProps) => state.student);

    const handleChannel = (type: string, e: CheckboxChangeEvent) => {
        dispatch(studentActions.setChannels({
            ...channels,
            [type]: e.target.checked
        }));
    };

    const handleConfirm = () => {
        if (channels.email || channels.phone || channels.whatsapp) {
            setPage('notification');
        }
    };

    const handleCancel = () => {
        setSubscriptionIsVisiable(false);
    };

    return (
        <Container>
            <h1>Como você deseja receber nossos conteúdos de cursos e informativos ?</h1>
            <div className="checkboxes">
                <WrapperCheckbox 
                    className="checkbox" 
                    marked={channels.phone}>
                    <Checkbox onChange={(e) => handleChannel('phone', e)} checked={channels.phone}>
                        <span className="checkbox-text">
                            Contato telefônico
                        </span>
                    </Checkbox>
                </WrapperCheckbox>

                <WrapperCheckbox 
                    className="checkbox" 
                    marked={channels.email}>
                    <Checkbox 
                        onChange={(e) => handleChannel('email', e)} 
                        checked={channels.email}>
                        <span className="checkbox-text">
                            Email
                        </span>
                    </Checkbox>
                </WrapperCheckbox>

                <WrapperCheckbox 
                    className="checkbox" 
                    marked={channels.whatsapp}>
                    <Checkbox 
                        onChange={(e) => handleChannel('whatsapp', e)} 
                        checked={channels.whatsapp}>
                        <span className="checkbox-text">
                            WhatsApp
                        </span>
                    </Checkbox>
                </WrapperCheckbox>
            </div>

            <div className="buttons">
                <Button 
                    onClick={handleConfirm} 
                    layout={(channels.email || channels.phone || channels.whatsapp) ? 'warning' : 'default'}
                    disabled={(!channels.email && !channels.phone && !channels.whatsapp)} 
                    >
                    Confirmar
                </Button>

                <Button layout="outline-primary" onClick={handleCancel}>
                    Cancelar
                </Button>
            </div> 
        </Container>
    );
};

export default Subscription;