import React from 'react'

import PropTypes from 'prop-types'

//
// general components
import Youtube from 'react-youtube'
import { getVideo } from '../helpers'
import Vimeo from '@u-wave/react-vimeo'


const propTypes = {
  videoEmbed: PropTypes.string,
  handlePlayVideo: PropTypes.func.isRequired,
}

function RenderVideo(props) {
    const { handlePlayVideo, videoEmbed } = props

    const video = getVideo(videoEmbed)
    const videoId = video.id

    switch(video.type){
      case 'vimeo':
        return (
          <Vimeo
            pip={true}
            responsive
            quality="540p"
            textTrack="pt"
            video={videoId}
            speed onPlay={handlePlayVideo}
          />
        )
      case 'youtube':
        return <Youtube videoId={videoId} onPlay={handlePlayVideo} />
      default:
        return <div dangerouslySetInnerHTML={{ __html: videoEmbed }} />
    }
}

RenderVideo.propTypes = propTypes
export default RenderVideo
