import _ from 'lodash';
import { createSelector } from 'reselect';

export const getEnrollmentsList = (state) => _.get(state, 'course.enrollmentsList', {});
export const isLoading = (state) => _.get(state, 'course.isLoading', {});
export const courseClasses = (state) => _.get(state, 'course.classes', []);
export const courseMocks = (state) => _.get(state, 'course.simulados', []);
export const getCourserClassId = (state) => _.get(state, 'course.id_turma', '');
export const course = (state) => _.get(state, 'course', {});
export const getMocklListById = (state) => (id) =>
  createSelector([courseMocks], (mocks) => {
    return mocks[id];
  })(state);

export const getClassById = (state) => (classId) => {
  return state.course.classes.find((classItem) => classItem.id === classId);
};
