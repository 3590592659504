import React, { useState } from 'react';

import Layout from '../../../components/Gabarito/Layout';
import { Container, Box } from './styles';

import Notification from './Notification';
import Unsubscription from './Unsubscription';

const UnsubscriptionChannels = () => {
    const [step, setPage] = useState('unsubscription');

    const renderStep = () => {
        switch (step) {
            case 'unsubscription':
                return (
                    <Box size={710}>
                        <Unsubscription setPage={setPage} />
                    </Box>
                );
            case 'notification':
                return (
                    <Box size={462}>
                        <Notification />
                    </Box>
                );
            default:
                return (
                    <Box>
                        <Unsubscription setPage={setPage} />
                    </Box>    
                );
        }
    };

    return (
        <Layout layoutTheme="light" logo={false}>
            <Container>
                {renderStep()}
            </Container>
        </Layout>
    );
};

export default UnsubscriptionChannels;