import config from "../config";

//add on const id_course_category number, is not random
export const COURSE_CATEGORY_CFP    = 1;
export const COURSE_CATEGORY_CEA    = 2;
export const COURSE_CATEGORY_CPA_20 = 3;
export const COURSE_CATEGORY_CPA_10 = 4;
export const COURSE_CATEGORY_CFG    = 5;
export const COURSE_CATEGORY_CGA    = 7;
export const COURSE_CATEGORY_ANCORD = 9;
export const COURSE_CATEGORY_CGE = 8;
export const COURSE_CATEGORY_CNPI = 10;

export const allowedFeature = (feature: string) => {
    const hiddenMenu: string[] | string = (config.hiddenMenu ?? []);
    return !hiddenMenu.includes(feature);
};

export const allowedScoreToro = (courseCategoryId?: number) => {
    if (!courseCategoryId) return false;

    return [
        COURSE_CATEGORY_CEA,
        COURSE_CATEGORY_CPA_20,
        COURSE_CATEGORY_CFG,
        COURSE_CATEGORY_CPA_10,
        COURSE_CATEGORY_CGA,
        COURSE_CATEGORY_ANCORD,
        COURSE_CATEGORY_CGE,
        COURSE_CATEGORY_CNPI,
    ].includes(courseCategoryId);
};

export const allowedExamFullResult = (courseCategoryId?: number, classTitle?: string) => {

    // When you need to active again just uncomment this

    if (!classTitle || !courseCategoryId) return false;

    return !config?.hiddenMenu?.includes('fullResultExamCEA')
    && [COURSE_CATEGORY_CEA].includes(courseCategoryId)
    && classTitle.toLocaleLowerCase().includes('simulado');

    // return false;
};

export const allowedReportDownload = (courseCategoryId?: number) => {
    if (!courseCategoryId) return false;
    return [COURSE_CATEGORY_CFP].includes(courseCategoryId);
};


export function checkIfIsModularOrNot(name: string){
    return !(name?.toLocaleLowerCase()?.includes("modular") || name?.toLocaleLowerCase()?.includes("módulo"))
}