import styled, { css } from 'styled-components';

export interface PercentProps {
    percent: number;
};

type BackgroundType = {
    background: string;
};

type ColorType = {
    color: string;
};

export const Container = styled.div<BackgroundType>`
    width: 100%;
    height: 3.5em;
    background: rgba(#C4C4C4, .1);
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .legend-bar-char__description-out {
        font-size: 1.6em;
        font-weight: bold;
        margin-left: 2em;
        text-transform: uppercase;
    }
`;

export const Progress = styled.div<PercentProps & ColorType>`
    background-color: ${({ color }) => color};
    height: 95%;
    width: ${({ percent }) => percent}%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2em;

    .legend-bar-char__percent {
        font-size: 1.8em;
        font-weight: bold;
    }

    .legend-bar-char__description {
        font-size: 1.6em;
        font-weight: bold;
    }

    ${({ percent }) => (percent <= 1) && css`
        padding: 2em 0em;
    `};

    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
`;
