import { all, fork } from 'redux-saga/effects'

import { rootSaga as auth } from './auth'
import { rootSaga as course } from './course'
import { rootSaga as classSaga } from './class'
import { rootSaga as student } from './student'
import { rootSaga as question } from './question'
import { rootSaga as mentorship } from './mentorship'
import { rootSaga as checkout } from './checkout'
import { rootSaga as gabarito } from './gabarito'
import { rootSaga as podcast } from './podcast'
import { rootSaga as cashback } from './cashback'

function* rootSaga() {
  yield all( [
    fork(auth),
    fork(course),
    fork(student),
    fork(question),
    fork(classSaga),
    fork(mentorship),
    fork(checkout),
    fork(gabarito),
    fork(podcast),
    fork(cashback)
  ])
};

export default rootSaga
