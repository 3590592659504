import React from 'react';
import { useSelector } from 'react-redux';

import { useGabaritoro } from '../../../hooks/useGabaritoro';
import { IGabaritoState } from '../../../shared/Models/gabarito/IGabaritoState';
import { Container, Progress, Percent } from './styles';

const ProgressBar = () => {
    const { gabarito } = useSelector((state: { gabarito: IGabaritoState }) => state.gabarito);
    const { totalQuestions, userAnswers } = useGabaritoro();
    
    let percentage = 0;
    const total = totalQuestions();
    if (total) {
        percentage = Math.floor((userAnswers.length * 100) / total);
    }

    if (gabarito.filled && gabarito.student_filled) {
        return null;
    }

    return (
        <Container >
            <Progress percent={percentage} />
            <Percent percent={percentage}>{percentage}%</Percent>
        </Container>
    );
};

export default ProgressBar;