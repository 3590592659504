import styled from 'styled-components';

export const Container = styled.aside`
  position: fixed;
  z-index: 99;
  top: 74px;
  left: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 235px;
  padding-bottom: 15px;
  width: 235px;
  max-width: 235px;
  min-width: 235px;
  min-height: calc(100vh - 74px);
  background: #fff;

  @media (max-width: 768px) {
    position: fixed;
    z-index: 10;
    bottom: 0;
    padding: 0;
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    top: auto;
  }
`;
