import React from 'react';
import P from 'prop-types';

import './style/modalContract.scss';
// import { LeftOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
// import { CheckoutContext } from '../shared/CheckoutProvider';
// import config from '../../../config';

const propTypes = {
    isVisible: P.bool.isRequired,
    setVisible: P.func.isRequired
};

const ModalAlertContract = (props) => {
    
    // const { course } = useContext(CheckoutContext);

    const handleCancel = () => {
        props.setVisible(false);
    };

    // const handleAcceptContract = () => {
    //     props.setVisible(false);
    //     //setOpenModalDownloadContract(true);
    //     window.open(config.s3Url + course.contrato, '_blank');
    // };

    return (
        <Modal
            zIndex="998"
            closable={true}
            visible={props.isVisible}
            destroyOnClose={true}
            maskClosable={false}
            className="container-alert-contract"
            footer={false}
            onCancel={handleCancel}>
                <div className="container-alert-contract-body">
                    <h1>
                        Aceite os termos de serviço e política de privacidade para continuar
                    </h1>

                    <div className="container-alert-contract-buttons"> 
                        <button className="button-primary" onClick={handleCancel}>
                            Ok
                        </button>
                    </div>

                    {/* <div className="container-alert-contract-buttons"> 
                        <button className="button-primary" onClick={handleCancel}>
                            <LeftOutlined />
                            Voltar
                        </button>

                        <button className="button-warning" onClick={handleAcceptContract}>
                            Termos de Serviço
                        </button>
                    </div> */}
                </div>
        </Modal>
    );
};

ModalAlertContract.propTypes = propTypes;
export default ModalAlertContract;