import React from 'react';
import Icon from '@ant-design/icons'


function LinkedinIcon(props) {
  const linkedinSvg = () => (
    <svg width="1em" height="1em" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.8447 11.0396V17.7052C18.8447 17.7834 18.8137 17.8583 18.7584 17.9136C18.7032 17.9688 18.6282 17.9999 18.5501 17.9999H15.1125C15.0337 17.9999 14.9581 17.9691 14.9018 17.914C14.8455 17.8589 14.813 17.784 14.8113 17.7052V11.511C14.8113 9.88064 14.2285 8.76751 12.7683 8.76751C12.3137 8.77 11.8709 8.91286 11.5006 9.17657C11.1302 9.44027 10.8503 9.81195 10.6992 10.2408C10.5977 10.5578 10.5534 10.8904 10.5683 11.2229V17.7052C10.5666 17.784 10.5341 17.8589 10.4778 17.914C10.4215 17.9691 10.3458 17.9999 10.2671 17.9999H6.82947C6.79053 18.0008 6.75182 17.9938 6.71567 17.9793C6.67951 17.9648 6.64667 17.9431 6.61913 17.9156C6.59159 17.888 6.56992 17.8552 6.55543 17.8191C6.54094 17.7829 6.53393 17.7442 6.53483 17.7052C6.53483 16.0552 6.57411 8.04725 6.53483 6.16804C6.53395 6.1288 6.54093 6.08977 6.55535 6.05326C6.56977 6.01675 6.59132 5.98351 6.61877 5.95545C6.64622 5.92739 6.67901 5.9051 6.71519 5.88989C6.75137 5.87467 6.79022 5.86684 6.82947 5.86685H10.2605C10.3393 5.86683 10.4149 5.89765 10.4712 5.95273C10.5275 6.00781 10.56 6.08274 10.5617 6.16149V7.58892V7.6282V7.58892C10.926 6.95385 11.4574 6.43072 12.098 6.07634C12.7387 5.72196 13.4642 5.54986 14.1958 5.57874C16.8542 5.57874 18.8382 7.3139 18.8382 11.0396H18.8447ZM0.563195 17.9737H4.0008C4.07956 17.9737 4.15521 17.9429 4.21152 17.8878C4.26782 17.8327 4.3003 17.7578 4.30202 17.679V6.18768C4.3003 6.10894 4.26782 6.034 4.21152 5.97893C4.15521 5.92385 4.07956 5.893 4.0008 5.89302H0.563195C0.485048 5.89302 0.410117 5.92407 0.354859 5.97933C0.299601 6.03458 0.268555 6.10954 0.268555 6.18768V17.7314C0.280968 17.8002 0.317412 17.8623 0.371384 17.9067C0.425356 17.9511 0.49333 17.9748 0.563195 17.9737Z"/>
      <path d="M2.16077 4.32153C3.35413 4.32153 4.32153 3.35413 4.32153 2.16077C4.32153 0.967408 3.35413 0 2.16077 0C0.967408 0 0 0.967408 0 2.16077C0 3.35413 0.967408 4.32153 2.16077 4.32153Z"/>
    </svg>
  );

  return (
    <Icon component={linkedinSvg} {...props}/>  
  )
}

export default LinkedinIcon;