import styled from 'styled-components';
import { Row, Layout } from 'antd';

//base converssao vw 2840px

export const Container = styled(Layout)`
    background: rgba(196, 196, 196, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4.861vw 6.944vw 6.944vw; //70px 100px 100px

  .reportContainer {
      /* margin-top: 45vw; */
      width: 100%;
  }
  .text-media {
    margin: 4vw 0;
    margin-top: 47vw;
  }
`;

export const Header = styled(Row)`
    background: linear-gradient(96.64deg, #222B82 26.19%, #000A66 102.4%);
    width: 100%;
    height: 9.718vw; //276px

    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Img = styled.img`
    width: 17.605vw; //500px
    height: 4.225vw; //120px
`;

export const ImgLogo = styled.img`
    width: 6.520vw; //185.17px
    height: 6.338vw; //180px
`;

export const NameRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    margin-bottom: 2.112vw; //60

    h1 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 2.816vw; //80px
        line-height: 3.450vw;//98px
        color: #000A66;
    }
`;

export const TextOne = styled.div`
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 1.784vw; //45
    line-height: 2.288vw; //65
    text-align: justify;
    color: #434343;
    margin-bottom: 1.760vw; //50px
  }
`;

export const Content = styled(Row)`
    background: #FFFFFF;

    box-shadow: 0px 0px 1.408vw rgba(0, 0, 0, 0.05);//40
    border-radius: 0.704vw; //20
    padding: 2.112vw; //60
    margin-bottom: 1.760vw; //50

    p{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.760vw; //50
        line-height: 1.795vw; //61
        text-align: center;
        margin-bottom: 2.112vw; //60

        color: #252525;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
`;

export const PageOne = styled.div`
    /* height: 133vw; */
`;
