import request, { makeURLToken } from '../../shared/request';
import { IGabarito } from '../../shared/Models/gabarito/IGabarito';
import { FormDataFillAnswersProps, FormDataMakeTokenProps, FormDataStoreLeadProps } from '../modules/gabarito';
import { IGabaritoStudent } from '../../shared/Models/gabarito/IGabaritoStudent';
import { IModule } from '../../shared/Models/gabarito/IModule';


export const fetchAll = (filters = {}): Promise<IGabarito[]> => (
    request('/v1/gabaritoro', {
        qsParams: { ...filters }
    }, {'x-api-key': makeURLToken() })
);

export const storeLead = (formData: FormDataStoreLeadProps): Promise<{token: string}> => ( 
    request('/v1/gabaritoro/lead', {
        method: 'POST',
        body: JSON.stringify(formData) 
    }, {'x-api-key': makeURLToken()})
);

export const getStudentByToken = (token: string): Promise<IGabaritoStudent> => ( 
    request(`/v1/gabaritoro/student/${token}`, {
        method: 'GET'
    }, {'x-api-key': makeURLToken()})
);

export const getGabaritoByToken = (token: string): Promise<IGabarito> => (
    request(`/v1/gabaritoro/result/${token}`, {
        method: 'GET',
    }, {'x-api-key': makeURLToken()})
);

export const makeTokenExistsStudent = (formData: FormDataMakeTokenProps): Promise<{ token: string }> => (
    request(`/v1/gabaritoro/student-make-token`, {
        method: 'POST',
        body: JSON.stringify(formData)
    }, {'x-api-key': makeURLToken()})
);

export const fillAnswers = (formData: FormDataFillAnswersProps, token: string): Promise<IGabarito> => (
    request(`/v1/gabaritoro/student-filled/${token}`, {
        method: 'PUT',
        body: JSON.stringify(formData)
    }, {'x-api-key': makeURLToken()})
);

export const getModulesByIdGabarito = (gabaritoId: number): Promise<IModule[]> => (
    request(`/v1/gabaritoro/modules/${gabaritoId}`, {
        method: 'GET'
    }, {'x-api-key': makeURLToken()})
);

export const resendResult = (gabaritoId: number, email: string): Promise<boolean> => (
    request(`/v1/gabaritoro/resend-result`, {
        method: 'POST',
        body: JSON.stringify({ id_gabarito: gabaritoId, email })
    }, {'x-api-key': makeURLToken()})
);