import React from 'react';
import Header from '../../../components/NewCheckout/Header';
import Footer from '../../../components/NewCheckout/Footer';
import { SmileFilled } from '@ant-design/icons'


import './styles.scss';

export default function NotFound() {
    return (
        <>
            <Header />
                <div className="checkoutNotFound">
                    <div className="content">
                        <SmileFilled />
                        <h1>Desculpe.</h1>
                        <p>O curso que você está procurando, não tem mais vagas ou não está mais disponível.</p>
                    </div>
                </div>
            <Footer />
        </>
    );
};