import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { Container } from './styles';


type Props = {
    approved: boolean;
};

const Status = ({ approved }: Props) => {
    return (
        <Container approved={approved}>
            {
                approved ? (
                    <CheckCircleOutlined />
                ) : (
                    <CloseCircleOutlined />
                )
            }
            <h1>{ approved ? 'Aprovado!' : 'Reprovado' }</h1>
        </Container>
    );
};

export default Status;