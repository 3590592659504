export * from './history';

const getVimeoId = (code) => {
  const matches = (code || '').match(/vimeo.com\/(video\/)?([\d]+)/)
  return matches ? matches[2] : ''
}

const getYoutubeId = (code) => {
  code = code.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
  return code[2] !== undefined ? code[2].split(/[^0-9a-z_-]/i)[0] : false
}

export const getVideo = (videoEmbed) => {
  if (videoEmbed) {
    let id
    if ((id = getVimeoId(videoEmbed))) {
      return {
        type: 'vimeo',
        id,
      }
    }
    if ((id = getYoutubeId(videoEmbed))) {
      return {
        type: 'youtube',
        id,
      }
    }
  }
  return {}
}

export const msToTime = (duration) => {
  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
}

export const secondsToMinutes = (secs) => {
  const minutes = Math.floor(secs / 60);
  const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  return `${returnedMinutes}:${returnedSeconds}`;
};

export const stringTimetoMillisseconds = (stringTime) => {
  const timeArray = stringTime.split(':')

  let millisecondsCounter = 0

  // get hours
  millisecondsCounter += parseInt(timeArray[0]) * (1000 * 60 * 60)

  // get minutes
  millisecondsCounter += parseInt(timeArray[1]) * (1000 * 60)

  // get seconds
  millisecondsCounter += parseInt(timeArray[2]) * 1000

  return millisecondsCounter
}

export const sumAllQuestionTimes = timeObject => {
  let timeCount = 0

  for (const [key, value] of Object.entries(timeObject)) {
    if ( key === 0 ) {}
    const timeToSum = value === 'NaN:NaN:NaN' ? '00:00:00' : value
    timeCount += stringTimetoMillisseconds(timeToSum)
  }

  return msToTime(timeCount)
}

export const getRightQuestions = (arrayAnswered, arrayQuestions) => {
  let rightQuestions = 0

  for (const answered of arrayAnswered) {
    //
    // 1. find the question and ansewers from arrayQuestions
    // 2. check if the answered questions is correct
    // 3. add or not to rightQuestuions counter

    const question = arrayQuestions.find(question => question.id_questao === answered.id_questao)
    const questionCorrectAnswer = question.answers.find(answer => answer.correta === 1)

    if (questionCorrectAnswer.id_resposta === answered.id_resposta) {
      rightQuestions++
    }
  }

  return rightQuestions
}


//
// this function is a workaround to iterate in a question object
// TODO: remove this pleaseeeee!!!!
export const getRightQuestionsByObject = (arrayAnswered, arrayQuestions) => {
  let rightQuestions = 0

  for (const [answeredIdQuestao, answeredIdResposta] of Object.entries(arrayAnswered)) {
    //
    // 1. find the question and ansewers from arrayQuestions
    // 2. check if the answered questions is correct
    // 3. add or not to rightQuestuions counter

    const question = arrayQuestions.find(question => parseInt(question.id_questao) === parseInt(answeredIdQuestao))
    const questionCorrectAnswer = question.answers.find(answer => answer.correta === 1)

    if (questionCorrectAnswer.id_resposta === answeredIdResposta) {
      rightQuestions++
    }
  }

  return rightQuestions
}

export const getWrongQuestionsArray = (studentQuestions, ongoingLecture) => {
  let wrongQuestions = studentQuestions.filter(studentQuestion => {
    //
    // get the right question's answer
    let rightAnswer = studentQuestion.answers.find(answer => answer.correta === 1)

    //
    // check if the student marked the right answer for the current question in the loop
    const ongoingQuestionAnswer = ongoingLecture.question[studentQuestion.id_questao]
    if (!ongoingQuestionAnswer || ongoingQuestionAnswer !== rightAnswer.id_resposta) {
      return studentQuestion
    }
    return false
  })
  return wrongQuestions
}

export const checkStepFinished = (selectedClass, step) => {
  let render = true

  if(step === 'lecture') {
    render = selectedClass.first_test
  } else if (step === 'video') {
    render = selectedClass.video_played
  } else if (step === 'file') {
    render = selectedClass.downloaded_pdf
  }

  return render
}

export function strSplit (string, splitLength) {
  if (splitLength === null) {
    splitLength = 1
  }
  if (string === null || splitLength < 1) {
    return false
  }
  string += ''
  const chunks = []
  let pos = 0
  const len = string.length
  while (pos < len) {
    chunks.push(string.slice(pos, pos += splitLength))
  }
  return chunks
}

export const numberFormat = (number, decimals = 2, dec_point = ',', thousands_sep = '.') => {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
  var n = !isFinite(+number) ? 0 : +number,
      prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
      sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
      dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
      s = '',
      toFixedFix = function (n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.round(n * k) / k;
      };

  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
  if (s[0].length > 3) {
      s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }
  if ((s[1] || '').length < prec) {
      s[1] = s[1] || '';
      s[1] += new Array(prec - s[1].length + 1).join('0');
  }
  return s.join(dec);
}

export const cursiveDate = (date) => {
  return new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
};

//how to use:
//NumberPreciseDecimal(1,334, 1)
//number 1 after comma is how many decimal numbers you want
export const NumberPreciseDecimal = (numero, casas) => {
  const method = Math.pow(10, casas)
  return Math.floor(numero * method) / method;
}
