import { ITransactions, ITransaction } from '../../shared/Models/cashback/ITransactions';
import request from '../../shared/request';

export const fetchTransactions = (): Promise<ITransactions>  => {
    return request('/v1/services/cashback/transaction');
};

export const fetchTransactionById = (id: number): Promise<ITransaction> => {
    return request(`/v1/services/cashback/coupon/${id}`); 
};

export const createCoupon = (formData = {}): Promise<ITransaction> => {
    return request('/v1/services/cashback/coupon', {
        method: 'POST',
        body: JSON.stringify(formData),
    });
};

export const cancelCoupon = (couponId: number): Promise<void> => {
    return request(`/v1/services/cashback/coupon/${couponId}`, {
        method: 'DELETE'
    });
};