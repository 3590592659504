import styled from 'styled-components';
import { Col } from 'antd';

type ContainerProps = {
    isHome: boolean;
};

export const Container = styled.div<ContainerProps>`
    padding-bottom: 1%;
    margin-right: 20px;
    height: 125px;
    border: 4px solid ${({ theme, isHome }) => isHome && theme.colors.warning};
    .list-percent {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Box = styled(Col)`
    width: 100%;
    main {
        @media(max-width: 1020px) {
            display: flex;
            flex-direction: column;
        }
        .info {
            color: ${({ theme }) => theme.colors.warning};
            font-weight: 700;
            font-size: 16px;
            margin-left: 5px;
            @media(max-width: 1020px) {
                margin-top: 5px;
                font-size: 14px;
            }
        }
    }
`;

export const Title = styled.span`
    > div {
        display: flex;
        align-items: center;
    }

    span {
        margin-left: 3%;
        font-weight: 400;
        font-size: 16px;
    }
`;
