import styled from "styled-components";

export const Aside = styled.div`
  padding: 25px;

  h3 {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    h3 {
      display: none;
    }
  }
`;

export const ItemStep = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  /* margin-bottom: 10px; */
  cursor: pointer;

  .img-arrow {
    opacity: 0;
    transition: 1s;
    width: 20px;
    height: 25px;
    left: 15px;
    margin-top: 25px !important;
  }
  .icon-desktop:hover .img-arrow {
    opacity: 1;
    transition: ease-in-out 0.5s;
    width: 20px;
    height: 25px;
    margin-top: 25px !important;
  }
  .first-icon {
    display: flex;
    transition: all 0.5s;
    align-items: center;
    gap: 15px;
    margin-bottom: 6px !important;
  }
  .icon-desktop {
    display: flex;
    transition: all 0.5s;
    align-items: center;
    gap: 15px;
    margin-top: -8px;
  }

  .icon-item {
    opacity: 1;
    transition: all 0.5s;
  }
  .icon-desktop:hover .icon-item {
    opacity: 0;
    transition: all 0.5s;
    z-index: 0;
  }
  .icon-item-done {
    opacity: 1;
    z-index: 2;
  }
  .icon-desktop:hover .icon-item-done {
    display: block;
    transition: 0.1s;
  }
  .icon-item-hover {
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
  }
  .icon-desktop:hover .icon-item-hover{
    opacity: 1;
    z-index: 1;
    transition: all 0.5s;
  }

  /* .icons-done::before {
    content: '';
    width: 3.9px;
    height: 12px;
    background-image: linear-gradient(to right, #acb6ff, #4d5fe7, #c4cbfe);
    margin-left: 31px;
    position: absolute;
    margin-top: 90px;
  }

  .icons:before {
    content: '';
    width: 3px;
    height: 12px;
    background-color: #f0f2ff;
    margin-left: 31.4px;
    position: absolute;
    margin-top: 90px;
  } */

  .icon-mobile {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    gap: 7px;

    .icon-desktop {
      display: none !important;
    }
    .icon-mobile {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
`;

export const TitleAside = styled.div`
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray_850};
  max-width: 55px;
  text-align: center;

  @media only screen and (max-width: 1200px) {
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.colors.gray_500};
  }
`;



