import React from 'react';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const IconDownloadLastStepDone: React.FC<Props> = ({ color, ...props }: Props) => {
  return (
    <svg width="66" height="96" viewBox="0 0 66 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M31.5 0H34.5V15H31.5V0Z" fill="#5768FF" />
      <rect y="15" width="66" height="66" rx="33" fill="#5768FF" />
      <path d="M48.4164 48V58.2C48.4753 59.5209 48.0086 60.8113 47.1184 61.789C46.2282 62.7666 44.987 63.3519 43.6664 63.4167H22.3331C21.0125 63.3519 19.7713 62.7666 18.8811 61.789C17.9909 60.8113 17.5242 59.5209 17.5831 58.2V48" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M33 32.5835V53.0002" stroke={color || 'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M33 53.0002L39.6667 46.3335" stroke={color || 'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M33.0007 53.0002L26.334 46.3335" stroke={color || 'white'} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
const defaultProps: Props = {
  width: undefined,
  height: undefined,
  color: undefined,
};

IconDownloadLastStepDone.defaultProps = defaultProps;

export default IconDownloadLastStepDone;
