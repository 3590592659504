import config from '../../config';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Upload, message, Button } from 'antd'
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { string, func, number, array, any } from 'prop-types'
import * as authSelectors from '../../redux/selectors/auth'
import { actions as authActions } from '../../redux/modules/auth'
import React, { useEffect, useState } from 'react'
import ImgCrop from 'antd-img-crop';

const propTypes = {
  onChange: func,
  maxFiles: number,
  initialFileList: array,
  token: string.isRequired,
  allowedExtentions: array,
  typeUpload: string,
  imageUrl: string,
  updatePhoto: func,
  avatar: string,
  type: string,
  ImageAvatar: any
}

const defaultProps = {
  maxFiles: 1,
  initialFileList: null,
  allowedExtentions: ['image/png', 'image/jpeg', 'application/pdf'],
  typeUpload: 'services/s3/student'
}

function UploadInput({ token, onChange, initialFileList, maxFiles, allowedExtentions, typeUpload, imageUrl, updatePhoto, avatar, type, ImageAvatar }) {

  const [fileList, setFileList] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (initialFileList) {
      setFileList(initialFileList)
    }
    // eslint-disable-next-line
  }, [initialFileList])

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }


  const uploadProps = {
    action: `${config.API_BASE}/v1/${typeUpload}`,
    listType: "picture",
    headers: {
      Authorization: `Bearer ${token}`
    },
    onChange(info) {
      if (info?.file?.status==='error') {
        message.error('ERRO')
      } else {
        if (info.file.status === 'done') {
          message.success(`${info.file.name} upload com sucesso`)
          getBase64(info.file.originFileObj, setImageUrl => setImageUrl, setLoading(false))
          updatePhoto(info.file.response);

        }
        setFileList(info.fileList)
        onChange(info.fileList)
      }
    },
    async onRemove (file) {
      // Mandar a exclusão pro servidor
      const url = `${config.API_BASE}/v1/${typeUpload}/destroy`;
      try {
        fetch(url, {
          method: 'POST',
          body: JSON.stringify({
            file: file.response ?? file
          }),
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        updatePhoto('');
        message.success('Arquivo deletado com sucesso');
      } catch (e) {
        updatePhoto('');
      }
    },

    beforeUpload(file) {
    const extensionMapper = {
      'image/png': 'PNG', 'image/jpeg': 'JPEG', 'image/jpg': 'JPG', 'image/heic' : 'HEIC',
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    const translateExtensions = allowedExtentions.map(extension => extensionMapper[extension])

      if (!isLt2M) {
        message.error('Imagem deve ser menor que 2MB!');
        return Upload.LIST_IGNORE
      }

      if (!allowedExtentions.indexOf( file.type ) >= 0 ) {
        return true
      } else {
        message.error(`Extensão de arquivo não permitida. Extensões permitidas: ${translateExtensions.join(', ')}`, 4)
        return Upload.LIST_IGNORE
      }
    },

    maxCount: maxFiles,
    fileList,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: percent => `${parseFloat(percent.toFixed(2))}%`,
    },

  }

  const uploadButton = (
    <div className="upload-photo-perfil">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Upload</div>
    </div>
  );

  const RenderImageAvatar = () => {
    if(type === "avatar") {
      return ImageAvatar
    }

    if(!avatar) return uploadButton
    return <img src={config.s3Url + avatar} alt="avatar" style={{ width: '100%' }} />
  }

  return (
    <>
      <ImgCrop rotate>
        <Upload { ...uploadProps}
          className={type === "avatar" ? 'upload-photo-avatar' : 'avatar-uploader'}
          listType="picture-card"
          showUploadList={false}
        >
        {RenderImageAvatar()}
        </Upload>
      </ImgCrop>
      {avatar && type !== "avatar" ?
      <Button type="dashed" block danger className="button-delete-img" onClick={() => uploadProps.onRemove(imageUrl)}>
        <DeleteOutlined />
      </Button>
      : ''
      }
    </>
  )
}

const mapStateToProps = (state, props) => ({
  token: authSelectors.token(state, props),
  avatar: state?.auth?.avatar
});

const mapDispatchToProps = (dispatch) => {
  return {
    updatePhoto: (foto) => dispatch(authActions.updatePhoto(foto)),
  }
}

UploadInput.propTypes = propTypes
UploadInput.defaultProps = defaultProps
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UploadInput)
)
