import React, { useState } from 'react';
import axios from 'axios';
import { ShareAltOutlined } from '@ant-design/icons';
import config from '../../config.js';
import Button from '../Button';
import { Container } from './styles';

interface PropsType {
  token: string;
  isStudent: boolean;
  type: 'completa' | 'modular';
  hits: number;
}

const ShareButton = ({ token, isStudent, type, hits }: PropsType) => {
  const [loading, setLoading] = useState(false);

  const openImageToShare = (data: File) => window.open(URL.createObjectURL(data), '_self');

  const handleClick = () => {
    setLoading(true);

    axios({
      url: `${config.API_BASE}/v1/gabaritoro/share/${isStudent}/${type}/${hits}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => openImageToShare(response.data))
      .finally(() => setLoading(false));
  };

  return (
    <Container>
      <Button
        onClick={handleClick}
        isLoading={loading}
        layout="primary"
        disabled={loading}
        antIcon={() => <ShareAltOutlined />}
      >
        Compartilhe com o mundo
      </Button>
    </Container>
  );
};

export default ShareButton;
