import React, { useContext } from 'react'

//
// context
import { CourseContext } from '../../../ClassContent/CourseDetail.container'

//
// general components
import Intro from '../Intro.component'
import ClassFile from '../ClassFile.component'
import ClassVideo from '../ClassVideo.component'
import DesktopLecture from './DesktopLecture.component'
import ClassLectureResult from '../lecture/LectureResult.component'
import ClassLectureReview from '../lecture/LectureReviewTwo.component'

function DesktopClassContent() {
  const {
    classStep,
    setClassStepNumber
  } = useContext(CourseContext)

  const renderDesktopClassContent = () => {
    switch (classStep) {
      case 'intro':
        setClassStepNumber(0)
        return <Intro />
      case 'file':
        setClassStepNumber(1)
        return <ClassFile />
      case 'video':
        setClassStepNumber(2)
        return <ClassVideo />
      case 'lecture':
        setClassStepNumber(3)
        return <DesktopLecture />
      case 'lectureResult':
        return <ClassLectureResult />
      case 'lectureReview':
        return <ClassLectureReview />
      case 'lectureRedo':
        setClassStepNumber(4)
        return <DesktopLecture redo />
      case 'lectureRedoResult':
        return <ClassLectureResult redo />
      default:
        return ''
    }
  }
  return <div style={{ marginTop: 30, maxWidth: '910px'}}>
            {renderDesktopClassContent()}
         </div>
}
export default DesktopClassContent
