import React from "react";
import { Subtitle, Min, Recomended, ColSubtitle } from './styles';

type Props = {
  displaySubtitle?: string;
  subtitleMin?: string;
  subtitleRecommended?: string;
  positionSubtitle?: string;
  colorOne?: string;
  colorTwo?: string;
}

const LegendChart: React.FC<Props> = (
  {
    displaySubtitle,
    subtitleMin,
    subtitleRecommended,
    positionSubtitle,
    colorOne,
    colorTwo
  }
) => {
  return (
    <ColSubtitle displaySubtitle={displaySubtitle} xs={24} positionSubtitle={positionSubtitle}>
      <Subtitle>
        <Min colorOne={colorOne}>
          <div />
          <p>{subtitleMin}</p>
        </Min>
        <Recomended colorTwo={colorTwo}>
          <div />
          <p>{subtitleRecommended}</p>
        </Recomended>
      </Subtitle>
    </ColSubtitle>
  )
}

export default LegendChart;
