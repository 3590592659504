import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

const Login = React.lazy(() => import('../Login'))

function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/auth/login" render={props => <Login {...props} />} />
      <Redirect to="/auth/login" />
    </Switch>
  )
}

export default AuthRoutes
