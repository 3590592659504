import styled from 'styled-components';

export const Container = styled.div`
     display: flex;
     flex-direction: column;
     justify-content: space-evenly;
     width: 100%;
     height: 639px;

     h1 {
        text-align: center;
        font-size: 19px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.primary};
     }

     .checkboxes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > .checkbox + .checkbox {
            margin-top: 1rem;
        }
     }

     .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & > button + button {
            margin-top: 1rem;
        }
     }
`;

export const WrapperCheckboxes = styled.div`
    
`