import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  flex: 1 0 0;

  padding: 10px;

  gap: 30px;

  overflow: auto;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

interface PanelProps {
  height: number;
  hasItems: boolean;
}

export const Panel = styled.div<PanelProps>`
  width: 100%;
  height: 100%;
  min-height: ${({ height }) => `${String(height)}px`};

  display: flex;
  flex-direction: column;
  align-items: ${({ hasItems }) => (hasItems ? 'flex-start' : 'center')};
  justify-content: ${({ hasItems }) => (hasItems ? 'flex-start' : 'center')};

  flex: 1 0 0;

  background: #ffffff;

  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);

  padding: 24px;

  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    box-shadow: none;
    border-radius: 0;

    flex-direction: column;
    min-height: fit-content;

    padding: 0;

    background: none;

    > div {
      margin-right: 0;
      overflow-y: hidden;
    }
  }
`;
export const NotificationArea = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  gap: 24px;

  overflow-y: auto;
  margin-right: 40px;

  @media screen and (max-width: 768px) {
    gap: 0;
    margin-bottom: 100px;
    overflow: hidden;
  }
`;

export const PanelNoNotification = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 30px;

  img {
    width: 80px;
    height: 80px;
  }

  h3 {
    color: var(--escala-de-cinza-70, #434343);

    /* Web/H2 */
    font-family: 'DM Sans';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px; /* 125% */
    letter-spacing: 0.2px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 100px;
  }
`;

interface NotificationAreaItemProps {
  readed: boolean;
}

export const NotificationAreaItem = styled.div<NotificationAreaItemProps>`
  width: 98.5%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  padding: 26px 22px 20px 22px;
  gap: 12px;

  border-radius: 16px;
  border: 1px solid var(--escala-de-cinza-20, #d6d6d6);
  background: ${({ readed }) =>
    readed ? 'var(--escala-de-cinza-10, #F0F0F0)' : 'var(--escala-de-cinza-0, #fff)'};

  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--escala-de-cinza-20, #d6d6d6);
    border-radius: 0;
  }
`;

interface ImageNotificationProps {
  image: string;
}

export const ImageNotification = styled.div<ImageNotificationProps>`
  width: 40px;
  height: 40px;

  background-image: url(${({ image }) => image});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  border-radius: 6px;
`;

export const NotificationDescriptionArea = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;

  > img {
    width: 40px;
    height: 40px;
  }

  > div.text-title-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    gap: 6px;
    flex: 1 0 0;

    > h3 {
      color: var(--escala-de-cinza-80, #252525);

      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0.48px;
    }

    > h4 {
      color: var(--escala-de-cinza-60, #636363);

      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
  }
`;

export const NotificationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  padding-left: 60px;

  > p {
    color: var(--escala-de-cinza-30, #bbb);

    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.24px;
  }

  > button {
    border: 0;
    background: none;

    color: var(--Primrias-Lils, #5768ff);
    text-align: center;

    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.42px;

    cursor: pointer;

    transition: all 1s ease-in-out;

    > img {
      margin-left: 8px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  > div {
    display: flex;
    flex-direction: row;

    > p {
      color: var(--escala-de-cinza-30, #bbb);
      text-align: center;

      /* Web/Alt Text */
      font-family: Manrope;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.42px;
    }

    > img {
      margin-left: 8px;
    }
  }
`;

export const SkeletonLoadingArea = styled.div`
  width: 100%;
  margin-bottom: auto;
`;

export const ButtonLoadingMoreNotifications = styled.button`
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  margin: 0 auto;

  cursor: pointer;

  border: none;
  background: none;

  color: var(--Primrias-Lils, #5768ff);
  font-weight: 400;
  font-size: 14px;

  transition: all 0.3s ease-in-out;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const ButtonDelete = styled.button`
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  cursor: pointer;

  border: none;
  background: none;

  color: #d81e5b;
  font-weight: 500;
  font-size: 14px;

  transition: all 0.3s ease-in-out;

  gap: 5px;

  &:hover {
    filter: brightness(0.95);
  }
`;

export const ButtonDeleteArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media(max-width: 728px) {
        margin: 0 20px;
    }
`;
