import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    position: relative;
`;

export const Header = styled.div`
    display: flex;
    padding: 5px 15px;
    @media(max-width: 728px) {
        padding: 5px;
    }
    .box-img {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 120px;
            height: 120px;
        }
    }
    .description {
        margin-left: 20px;
        width: 50%;
        padding: 15px;
        span {
            font-size: 15px;
            @media(max-width: 600px) {
                font-size: 13px;
            }
            font-weight: 500;
        }
        
        h2 {
            font-weight: 600;
            font-size: 30px;
            @media(max-width: 600px) {
                font-size: 20px;
            }
            @media (max-width: 1650px) {
                font-size: 28px;
            }            
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const Content = styled.div`
    padding: 5px 15px;
    @media(max-width: 728px) {
        margin-top: 15px;
        padding: 5px 0px;
    }
`;

export const BoxLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const BoxEmpty = styled.div`
    padding: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;

    svg {
        width: 60px;
        height: 60px;
    }

    span {
        margin-top: 10px;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
`;

export const BoxArrows = styled.div`
    position: absolute;
    width: 100%;
    top: 20px;
    display: flex;
    justify-content: space-between;
    
    button {
        background:none;
        border:none;
        cursor: pointer;
        transition: filter 0.2s;
        &:hover {
            filter: brightness(0.4);
        }
        
        svg {
            font-size: 25px;
            color: ${({ theme }) => theme.colors.gray_600};
        }
    }
`;