import React from 'react';

import { secondsToMinutes } from '../../../../helpers';
import { formattedBigText } from '../../../../helpers/renderFormatted';
import { IEpisode } from '../../../../shared/Models/podcast/IEpisode';
import { IPodcast } from '../../../../shared/Models/podcast/IPodcast';
import { Container } from  './styles';

type Props = {
    episode: IEpisode,
    podcast: IPodcast,
    selected?: boolean;
    onClick: (episode: IEpisode, podcast: IPodcast) => void;
};

export default function Item ({ 
    episode,
    podcast, 
    onClick, 
    selected = false 
}: Props) {
    
    const formattedDuration = secondsToMinutes(episode.duration);
    const formattedTitle = formattedBigText(episode.title, 65);
     
    return (
        <Container selected={selected} onClick={() => onClick(episode, podcast)}>
            <div className="description">
                <span className="item-title">{formattedTitle}</span>
                <span className="item-professor">{episode.professor}</span>
            </div>
            <div className="class">
                <span>
                {episode.classDescription}
                </span>
            </div>
            <div className="time">
                <span>
                    {formattedDuration}
                </span>
            </div>
        </Container>
    );
};