import styled from 'styled-components';


//style={{display: 'flex', justifyContent: 'center', width: '100%'}}
//style={{display: 'flex', flexDirection: 'column', width: '150px'}}


export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 300px;

        label {
            margin-top: 5px;
        }

        .ant-select {
            margin-top: 5%;
            width: 70% !important;
        }

        button {
            width: 70%;
        }

        .error {
            margin-top: 2px;
            font-size: 0.9rem;
            color: ${({ theme }) => theme.colors.danger};
        }
    }
`;